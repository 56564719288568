import React, { Component } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { getChildItemStyle, getChildListStyle } from "./utils";
import { DateIcon, StatusIcon, UserIcon } from "../statusMode";
import AdvancedFeatures from "../advancedFeatures";
import drag_dots from '../../../../ui/images/drag_dots.svg'
import UsersDropDown from "../usersDropDown";
import Datepicker from "../datepicker";
import { CHOOSE_A_DATE } from "../../../../ui/strings";
import './tableStyle.css'
import Editor from "../editor/quill";

export const Child = props => {

  const {
    process,
    parentIndex,
    onChange,
    handleTaskChanges,
    handleClick,
    setRole,
    newTask,
    isYesodTask,
    editMode,
    onSection,
    isCurrentRow,
    endDateViewMode,
    users,
    addProcessStep,
    deleteRow,
    setDoneClicked
  } = props;

  function getDetails(field = '', id, index) {
    return newTask
      ? { parent: parentIndex, self: index, field: field }
      : { parent: process.id, self: id, field: field }
  }

  function onFocus(id, index) {
    return isCurrentRow(process.id, id, index) && editMode && onSection
  }

  function onStatusChanged(child, index) {
    !isYesodTask &&
      newTask
      ? handleTaskChanges(null, index, 'checked', !child.checked)
      : handleTaskChanges(child.parent_id, child.id, 'checked', !child.checked)
    setDoneClicked(true)
  }

  return (
    <Droppable
      droppableId={`droppable${parentIndex}`}
      isDropDisabled={!editMode}
      type={`${parentIndex}`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getChildListStyle(snapshot.isDraggingOver)}
        >
          {process.children.map((child, index) => {
            return (
              <Draggable
                key={`${parentIndex}${index}`}
                draggableId={`${parentIndex}${index}`}
                index={index}
                isDragDisabled={!editMode}
              >
                {(provided, snapshot) => (
                  <div key={child.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className='tr pr-2'

                    style={getChildItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    onClick={(e) => {
                      if (editMode) {
                        (onSection !== 1 ? () => props.setOnSection(1) : null)
                          (newTask
                            ? handleClick(e, 'child', { parentIndex: parentIndex, index: index })
                            : handleClick(e, 'child', {
                              parentId: process.id,
                              id: child.id,
                              index: index
                            })
                          )
                      }
                    }}
                  >
                    <td style={{ width: '50px', marginLeft: 'auto' }} {...provided.dragHandleProps}>
                      {editMode && <img src={drag_dots} />}
                    </td>
                    <td style={{ textAlign: 'right', fontSize: '17px', width: '100%', paddingRight: '7px' }}>
                      <Editor
                        id={`p${parentIndex}c${index}`}
                        readOnly={!onFocus(child.id, index)}
                        displayToolbar={onFocus(child.id, index)}
                        details={getDetails("row", child.id, index)}
                        value={child.description}
                        edit={editMode}
                        newTask={newTask}
                        onChange={onChange} />
                    </td>
                    {users && !newTask &&
                      <td style={{ minWidth: '120px' }} className={'vertically'}>
                        <UsersDropDown
                          id={newTask ? index : child.id}
                          index={index}
                          parent_id={newTask ? null : child.parent_id}
                          parentIndex={parentIndex}
                          editMode={editMode}
                          disabled={false}
                          multiple={false}
                          options={users}
                          value={child.performer}
                          field='performer'
                          onChange={handleTaskChanges}
                          setRole={setRole}
                          role={child.role}
                        />
                      </td>}
                    <td style={{ minWidth: '150px' }} className={'vertically'}>
                      {editMode && !newTask
                        ? <Datepicker
                          id={newTask ? index : child.id}
                          parent_id={newTask ? parentIndex : child.parent_id}
                          value={child.end_date}
                          onChanged={handleTaskChanges}
                        />
                        : endDateViewMode(child.end_date, '')
                      }
                    </td>
                    <td style={{ minWidth: '90px' }} className={'vertically'}>
                      {child.checked
                        ? <StatusIcon id={`ch-${index}`} done={true} onClicked={onStatusChanged} object={child}
                          index={index} />
                        : <StatusIcon id={`ch-${index}`} done={false} onClicked={onStatusChanged} object={child}
                          index={index} />
                      }
                    </td>
                    <td style={{ minWidth: '70px' }} className={'vertically'}>
                      {users && <AdvancedFeatures
                        onClick={addProcessStep}
                        delete={deleteRow}
                        details={{ parentIndex: parentIndex, index: index }}
                        child={true}
                        editMode={editMode}
                      />}
                    </td>
                  </div>

                )}
              </Draggable>
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default Child;
