import React from 'react';
import './App.css';
import store from './components/store/store';
import First from './first';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={store}>
      <div>
        <First/>
      </div>
    </Provider>
  );
}

export default App
