import React, {Component} from 'react';
import {Router, Switch, Route, Link, BrowserRouter, Redirect} from 'react-router-dom'
import TextInputToRegister from '../form/textInputToRegister';
import PasswordInput from '../form/passwordInput';
import PrimaryBtn from '../../ui/buttons/primaryBtn';
import {registerUser} from '../../components/apis/registerUser'
import './createUser.css';
import CreateGroup from "../groups/createGroup";
import PhoneInput from "react-phone-number-input";
import {Col, Row, Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {connect} from "react-redux";
import {setGroupUsers} from "../../components/store/dashBoardStore/dashboardActions";
import GroupSelectToRegister from "../form/groupSelect";

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      firstName: "",
      lastName: "",
      role: "",
      email: "",
      password: "",
      validatePassword: "",
      phone: "",
      group_id: "",
      message: "",
      isRedirect: false,
      isRequest: false,
      incompatiblePasswords: false
    };

  }

  timeOut = 3000;

  updateState = (category, value) => {
    let obj = {}
    obj[category] = value
    this.setState(obj)
  }

  buildDataToSend() {
    return {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      title: this.state.role,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.validatePassword,
      agree_the_conditions_of_use: true,
      phone: this.state.phone,
      group_id: this.state.group_id
    }
  }

  registerUser = async () => {

    this.myPasswordTest();
    if (this.validateRegistration()) {
      let ans = await registerUser(this.buildDataToSend());
      if (ans && ans.data && ans.data.success) {
        this.setState({isRedirect: true})
      }
    }
  }

  validateRegistration() {
    // first names
    if (!this.validateFirstOrLastNames('firstName')) {
      this.setState({firstNameValidation: true});
      setTimeout(() => this.setState({firstNameValidation: false}), this.timeOut);
      return false
    }
    // last name
    if (!this.validateFirstOrLastNames('lastName')) {
      this.setState({lastNameValidation: true});
      setTimeout(() => this.setState({lastNameValidation: false}), this.timeOut);
      return false
    }
    //email
    if (!this.validateEmail()) {
      this.setState({emailValidation: true});
      setTimeout(() => this.setState({emailValidation: false}), this.timeOut);
      return false
    }
    //phone number
    // if (!this.validatePhoneNumber(this.state.phone)) {
    //   this.setState({phoneValidation: true});
    //   setTimeout(() => this.setState({phoneValidation: false}), this.timeOut);
    //   return false
    // }
    //group
    if (!this.validateFirstOrLastNames('group_id')) {
      this.setState({groupValidation: true});
      setTimeout(() => this.setState({groupValidation: false}), this.timeOut);
      return false
    }
    // password
    if (!this.validatePassword2()) {
      this.setState({passwordValidation: true});
      setTimeout(() => this.setState({passwordValidation: false}), this.timeOut);
      return false;
    }
    // password Authentication
    if (!this.passwordAuthentication()) {
      this.setState({passwordAuthentication: true});
      setTimeout(() => this.setState({passwordAuthentication: false}), this.timeOut);
      return false;
    }
    this.setState({message: "", isRequest: true});
    return true

  }

  validateFirstOrLastNames(kyeName) {
    if (!this.state[kyeName]) {
      return false
    } else {
      return true
    }
  }

  validateEmail() {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(this.state.email);
  }

  // validatePhoneNumber(str) {
  //   const regexPhoneNumber = /^((\+)33|0)[1-9](\d{2}){4}$/;
  //
  //   if (str.match(regexPhoneNumber)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }


  validatePassword2() {
    if (!this.state.password || this.state.password.length < 5) {
      return false
    } else return true
  }

  validatePassword() {
    if (this.state.password !== this.state.validatePassword || this.state.password.length < 5) {
      this.setState({incompatiblePasswords: true})
      return false
    } else return true
  }

  myPasswordTest = () => {

    if (this.state.password !== this.state.validatePassword &&
      this.state.validatePassword.length > 0 && this.state.password.length >= 5) {
      this.setState({incompatiblePasswords: true});
      setTimeout(() => this.setState({incompatiblePasswords: false}), this.timeOut)
      return false
    } else return true
  }

  passwordAuthentication() {
    if (!this.state.validatePassword) {
      return false
    } else return true
  }

  keyPress(e) {
    if (e.keyCode == 13) {
      this.registerUser();
    }
  }

  render() {

    if (this.state.isRedirect) {
      alert('registration successful completed !!!!');
      return <Redirect to='/dashboard'> </Redirect>
    } else {
      return (<div className='registration_page'>
          <div className='create_form'>
            <div>
              <div className="create-user-header-a">יצירת משתמש</div>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <TextInputToRegister
                      placeholder={'שם פרטי' + '*'}
                      onChange={this.updateState}
                      category={'firstName'}
                      validation={this.state.firstNameValidation}
                      tabIndex={1}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <TextInputToRegister
                      placeholder={'שם משפחה' + '*'}
                      onChange={this.updateState}
                      category={'lastName'}
                      validation={this.state.lastNameValidation}
                      tabIndex={2}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <TextInputToRegister
                      placeholder={'תפקיד'}
                      onChange={this.updateState}
                      category={'role'}
                      tabIndex={3}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <TextInputToRegister
                      placeholder={'דוא"ל' + '*'}
                      onChange={this.updateState}
                      category={'email'}
                      validation={this.state.emailValidation}
                      tabIndex={4}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <PhoneInput
                      id={'cu_phone'}
                      className="login-text-input"
                      placeholder={'טלפון'}
                      defaultCountry={'IL'}
                      useNationalFormatForDefaultCountryValue={true}
                      country="US"
                      value={this.state.phone}
                      onChange={(e) => this.updateState("phone", e)}/>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <GroupSelectToRegister
                      onChange={this.updateState}
                      category={'group_id'}
                      validation={this.state.groupValidation}
                      tabIndex={8}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <PasswordInput
                      placeholder={'סיסמא* (אותיות אנגלית או/ו מספרים. 5 תווים לפחות)'}
                      asCheckLogo={true}
                      minimumOfCharacters={5}
                      type='check'
                      checkLine={true}
                      onChange={this.updateState}
                      category={'password'}
                      validation={this.state.passwordValidation}
                      tabIndex={5}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <PasswordInput
                      placeholder={'אימות סיסמא*'}
                      downLabelText='הסיסמאות אינן תואמות'
                      type={'confirm Password'}
                      onChange={this.updateState}
                      category={'validatePassword'}
                      confirmPassword={this.state.incompatiblePasswords}
                      validation={this.state.passwordAuthentication}
                      tabIndex={7}
                      keyPress={(e) => this.keyPress(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="create-user-btn">
                <PrimaryBtn width='142px' height='45px' primaryBtnText='צור משתמש'
                            primaryBtnOnClick={this.registerUser}/>
                <span className='create-user-btn-cancel'><a href="/dashboard">ביטול וחזרה</a></span>
              </div>
            </div>
          </div>
          <div className='create_form'>
            <div className="create-user-header-a">יצירת ועריכת אירגון</div>
            <CreateGroup/>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {

  return {
    categories: state.dashboard.categories
  }
}

export default connect(mapStateToProps)(CreateUser);
