import React, { Component } from 'react';
import './CreateTaskPage.css'

class ShowDescription extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{marginRight:'70px'}} className={this.props.className} dangerouslySetInnerHTML={{__html: this.props.htmlText}}></div>
        )
    }
}
export default ShowDescription;
