import {basePath, calendarEvent, comment, specificTask} from './paths';
import {createRequest} from './middleWareToken';

export async function insertGoogleEvent(data){
  return await createRequest(`${basePath}${calendarEvent}`, 'post', data)
}
export async function deleteGoogleEvent(taskId){
  return await createRequest(`${basePath}${calendarEvent}${taskId}`, 'delete')
}


