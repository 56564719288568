import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import './homePageLogo.css';

function App() {
    return <Logo />;
}

function Logo() {

    const logo = <div className="my-home-page-logo">
        <HomeIcon color="primary" fontSize='large' />
    </div>;
    return logo;
}

export default App;