import React, { Component } from 'react';
import { updateIsAllowedAccess, updateUserDetails, updateIsAllowedAccessLogin } from '../../store/loginStore/loginActions';
import {
    LOG_IN_TO_SYSTEM_HEADER, TEXT_MAIL_TO_PLACEHOLDER, TEXT_PASSWORD_TO_PLACEHOLDER,
    DOWN_LABEL_TEXT_TO_WRONG_PASSWORD, LOGIN_BUTTON_TEXT, LINK_REGISTER_TO_SYSTEM_PART_1,
    LINK_REGISTER_TO_SYSTEM_PART_2, INTERNET_CONNECTION
} from '../../../ui/strings';
import { isAllowedAccess } from '../../apis/middleWareToken';
import logoUp from '../../../ui/images/yesode_logo_up.svg';
import PasswordInput from '../../../ui/form/passwordInput';
import Modal from '../registerSystem/registerToTheSystem';
import MyYesodLogo from '../../../ui/myLogos/myYesodLogo';
import TextInput from '../../../ui/form/textInput';
import { userLogin } from '../../apis/login';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import './loginPage.css';


class LoginPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            message: "",
            email: "",
            password: "",
            loggedIn: false,
            redLine: false,
            isAllowedAccess: false,
            test: false
        };

    };

    updateState = (category, value) => {
        let obj = {}
        obj[category] = value
        this.setState(obj)
    }

    toggle() {
        this.modal.toggle();
        this.setState({ toggle: !this.state.toggle });
    }

    async sendRequest() {
        if (this.state.email && this.state.password) {
            userLogin(this.buildJson())
                .then((answer) => {
                    this.props.updateUserDetails(answer);
                    this.props.updateIsAllowedAccess(true);
                })
                .catch(error => {
                    if (error.response)
                        this.updateState('redLine', true);

                })

        }
        else
            this.updateState('redLine', true);
    }

    buildJson() {
        return {
            email: this.state.email,
            password: this.state.password
        }
    }

    async componentDidMount() {
        if (await isAllowedAccess()) {
            this.props.updateIsAllowedAccess(true);
        }
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            this.sendRequest();
        }
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/dashboard' } };

        if (this.props.isAllowedAccess) {
            return <Redirect to='/dashboard/userArea' ></Redirect>
            // return <Redirect to={from} />
        } else {

            return (
                <div className='background-login'>
                    {!this.state.toggle ?
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <div className='class-login-logoUp'>
                                <img alt='' src={logoUp} />
                            </div>
                            <div className='login-details'>
                                <div className='class-logo-login-area'>
                                    <MyYesodLogo />
                                </div>
                                <div className='login-to-system-text'>{LOG_IN_TO_SYSTEM_HEADER}</div>
                                <TextInput
                                    placeholder={TEXT_MAIL_TO_PLACEHOLDER}
                                    width='100%'
                                    onChange={this.updateState}
                                    category={'email'}
                                    tabIndex={1}
                                    keyPress={(e) => this.keyPress(e)}
                                />
                                <PasswordInput
                                    placeholder={TEXT_PASSWORD_TO_PLACEHOLDER}
                                    downLabelText={DOWN_LABEL_TEXT_TO_WRONG_PASSWORD}
                                    onChange={this.updateState}
                                    category={'password'}
                                    redLine={this.state.redLine}
                                    type={'regular'}
                                    tabIndex={2}
                                    keyPress={(e) => this.keyPress(e)}
                                />
                                <button className='login-btn' onClick={() => this.sendRequest()}>
                                    {LOGIN_BUTTON_TEXT}
                                </button>
                            </div>

                            {/*<div className='class-first-time-login'>*/}
                            {/*    <div >{LINK_REGISTER_TO_SYSTEM_PART_1} {' '}*/}
                            {/*        <a className='yesod-login-a' href='#' onClick={() => this.toggle()} >{LINK_REGISTER_TO_SYSTEM_PART_2}</a>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        : <React.Fragment/>}
                    <Modal ref={obj => this.modal = obj} />
                </div>
            );

        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isAllowedAccess: state.login.isAllowedAccess,
        // isAllowedAccessLogin: state.login.isAllowedAccessLogin,
        user: state.login.user
    }
}


// We normally do both in one step, like this:
export default connect(
    mapStateToProps,
    { updateIsAllowedAccess, updateUserDetails, /*updateIsAllowedAccessLogin*/ }

)(LoginPage)
