import Editor from "../editor/quill";
import UsersDropDown from "../usersDropDown";
import {DateIcon, StatusIcon} from "../statusMode";
import Datepicker from "../datepicker";
import AdvancedFeatures from "../advancedFeatures";
import React, {Component} from "react";
import {format} from 'date-fns'
import '../taskPage.css'
import {CHOOSE_A_DATE} from "../../../../ui/strings";

class TableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: this.props.task,
      index: this.props.index,
      child: this.props.child,
      users: this.props.users,
      parentIndex: this.props.parentIndex,
      hover: false
    }
  }

  handleState(field, value) {
    this.setState({[field]: value})
  }

  handleHover(mode) {
    this.props.handleHover
      ? this.props.handleHover(mode ? this.props.index : null)
      : this.handleState('hover', mode)
  }

  getDetails(field = '') {
    return this.props.newTask
      ? {parent: this.props.parentIndex, self: this.props.index, field: field}
      : {parent: this.props.task.parent_id, self: this.props.task.id, field: field}
  }

  endDateViewMode(def){
    return(
      <div>{this.props.task.end_date
        ? format(new Date(this.props.task.end_date), 'dd/MM/yyyy')
        :def
      }</div>)
  }

  render() {
    return (
      <tr style={{backgroundColor: this.state.hover || this.props.onHover ? '#f8f9fa' : ''}}
          className={this.props.onFocus ? 'choose box-shadow fs-16' : 'fs-16'}
          onMouseOver={() => !this.props.editMode ? this.handleHover(true) : null}
          onMouseLeave={() => !this.props.editMode ? this.handleHover(false) : null}
          onClick={(e) => {
            if (this.props.editMode) {
              this.props.newTask
                ? this.props.handleClick(e, {
                  parentIndex: this.props.parentIndex,
                  index: this.props.index})
                : this.props.handleClick(e, {
                  parentId: this.props.task.parent_id,
                  id: this.props.task.id,
                  index: this.props.index
                })
            }
          }}
      >
        {this.props.child ? <td></td> : null}
        <td colspan={!this.props.child ? "2" : ""} id='forEditor'>
          <Editor
            id={this.props.index}
            readOnly={!this.props.onFocus}
            displayToolbar={this.props.onFocus}
            details={this.getDetails("row")}
            value={this.props.task.description}
            edit={this.props.editMode}
            newTask={this.props.newTask}
            onChange={this.props.handleDescriptionsChanges}/>
        </td>
        {!this.props.isYesodTask &&
        <td>
          {this.props.users && !this.props.newTask
            ? !this.props.isYesodTask
              ? <UsersDropDown
                id={this.props.newTask ? this.props.index : this.props.task.id}
                parent_id={this.props.newTask ? this.props.parentIndex : this.props.task.parent_id}
                editMode={this.props.editMode}
                disabled={false}
                multiple={false}
                options={this.props.users}
                value={this.props.task.performer}
                field='performer'
                onChange={this.props.handleTaskChanges}
              />
              : <span/>
            : <span/>}
        </td>}
        {!this.props.isYesodTask &&
        <td>
          {this.props.editMode && !this.props.newTask
            ? !this.props.isYesodTask
              ? <DateIcon
                date={
                  this.props.editMode
                    ? <Datepicker
                      id={this.props.newTask ? this.props.index : this.props.task.id}
                      parent_id={this.props.newTask ? this.props.parentIndex : this.props.task.parent_id}
                      value={this.props.task.end_date}
                      onChanged={this.props.handleTaskChanges}
                    />
                    : `${this.endDateViewMode(CHOOSE_A_DATE)}`
                }/>
              : this.endDateViewMode('')
            : this.endDateViewMode('')
          }
        </td>}
        {!this.props.isYesodTask &&
        <td
          className={this.props.task.checked ? 'done' : 'inProgress'}
          onClick={() => {
            !this.props.isYesodTask ?
              this.props.newTask
                ? this.props.handleTaskChanges(this.props.parentIndex, this.props.index, 'checked', !this.props.task.checked)
                : this.props.handleTaskChanges(this.props.task.parent_id, this.props.task.id, 'checked', !this.props.task.checked)
              : console.log('')
          }}
        >{this.props.task.checked ? <StatusIcon id={`pa-${this.props.index}`} done={true}/> : <StatusIcon id={`pa-${this.props.index}`} done={true}/>}
        </td>}
        <td>
          {this.props.users && <AdvancedFeatures
            onClick={this.props.addProcessStep}
            delete={this.props.deleteRow}
            details={{parentIndex: this.props.parentIndex, index: this.props.index}}
            child={(this.props.task.parent_id || this.props.parentIndex) !== null}
            editMode={this.props.editMode}
            // event={{
            //   user: this.props.task.performer
            //     ? this.props.users.find(user => user.id === this.props.task.performer)
            //     : null,
            //   title: this.props.task.title,
            //   doneStatus:this.props.doneStatus
            // }}
          />}
        </td>
      </tr>
    )
  }
}

export default TableRow
