export const UPDATE_TASK_TAGS = 'UPDATE_TASK_TAGS';
export const TASK_CREATION = 'TASK_CREATION';
export const UPDATE_CURRENT_TASK_DATA = 'UPDATE_CURRENT_TASK_DATA';
export const SAVE_CURRENT_TASK_DATA = 'SAVE_CURRENT_TASK_DATA';
export const UPDATE_CREATED_TASK = 'UPDATE_CREATED_TASK';
export const UPDATE_IS_EDIT = 'UPDATE_IS_EDIT';
export const UPDATE_IS_CREATE = 'UPDATE_IS_CREATE';
export const UPDATE_NEW_LINE = 'UPDATE_NEW_LINE';
export const ADD_ORDER_TO_WORK_PROCESS = 'ADD_ORDER_TO_WORK_PROCESS';
export const CHANGE_SAVE_DRAFT_TO_TRUE = 'CHANGE_SAVE_DRAFT_TO_TRUE';
export const CHANGE_ADVERTISING_ON_CLICK_TO_TRUE = 'CHANGE_ADVERTISING_ON_CLICK_TO_TRUE';