import axios from 'axios';

export async function getHebrewDate(YYYY,MM,DD){
    // in this url you set the date as a parameter and as an answer you get the Hebrew date.
    let path = `https://www.hebcal.com/converter/?cfg=json&gy=${YYYY}&gm=${MM}&gd=${DD}&g2h=1`;
    try {
        const res = await axios.get(path);
        return res;
    } catch (e) {
        console.error(e);
    }

 }