import React, { useEffect, useRef } from "react";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";
import { updateFolder } from "../../../apis/foldersHandling";
import { dndTypes } from "./dndTypes";

const BetweenCards = ({
  className,
  itemInfo, 
  aroundedItem, 
  typeOfItem, 
  isActineWhenOver, 
  isFirst, 
  updateOrder 
}) => {

  // const allFolders = useSelector(state => state.dashboard.folders)

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [dndTypes.TASK, dndTypes.FOLDER],
    canDrop: (dropItem, monitor) => monitor.getItemType() === typeOfItem,
    drop: (dragItem) => {
      updateOrder(dragItem, {order: getOrderInfo()} )
      return dragItem
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    })
  }),[])

  const getOrderInfo = () => {
    if(isFirst){
      return {
        before: null,
        after: itemInfo.id
      }
    }
    return {
      before: itemInfo.id,
      after: aroundedItem.after ? aroundedItem.after.id : null
    }
  }

  return (
      <div ref={drop} className="between-cards">
        <div className={`wrap-line-between ${typeOfItem} ${className?className:""}`}>
          { <div className={`${isOver && isActineWhenOver() ? "line-between-tasks " + typeOfItem :""} `} />}
        </div>
      </div>
  )
}

export default BetweenCards