
import React, {ReactNode, useState} from 'react';
import {ProSidebar, Menu, MenuItem, SubMenu,SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import { connect, useSelector, useDispatch} from 'react-redux';
import { Link, } from 'react-router-dom'
import logo from '../../../ui/images/logo_yesod_navbar.svg';
import userConfig from '../../../ui/images/userConfig.svg';
import HomePageLogo from '../../../ui/myLogos/homePageLogo';
import {isSuperUser, isUserStaff} from '../../apis/middleWareToken';
import { isAllowedAccess } from '../../apis/middleWareToken';
import { updateCurrentCategory } from '../../store/dashBoardStore/dashboardActions'
import Logo from '../../../ui/images/yesod_logo_name.svg'
// import { FaGem } from "react-icons/fa";
// import 'react-pro-sidebar/dist/css/styles.css';
import '../sideBar/sideBar.scss'
import Header from "./Header";

const Sidebar = props => {

  const categories = useSelector(state => state.dashboard.categories)
  const currentCategory = useSelector(state => state.dashboard.currentCategory)
  const currentGroup = useSelector(state => state.dashboard.currentGroup)
  const dispatch = useDispatch();
  // const isStaff  = isUserStaff() || isSuperUser();
  // const [toggle, setToggle] =  useState(false)


  function handleClick(e) {
    let isStaff = isUserStaff();
    if (!isStaff) {
      e.preventDefault();
      alert('רק מנהל יכול ליצור משתמש חדש.');
    }
  }

  async function checkIfAsAccessToken() {
    dispatch(updateCurrentCategory({categoryId: 0, folderId: 0}))

    if (!await isAllowedAccess()) {
      window.location.reload()
    }
  }


// return <div className='yesod-navbar'>
//   <Link onClick={() => checkIfAsAccessToken()} to='/dashboard'><img className='class-yesod_logo_navbar' src={logo} /></Link>
//   <Link onClick={(e) => { handleClick(e); checkIfAsAccessToken()}} to='/dashboard/createuser'><img className='class-Links' src={userConfig} /> </Link>
//   <Link onClick={() => checkIfAsAccessToken() } to='/dashboard'><HomePageLogo /></Link>
// </div>
  return <Header
    categories={categories}
    accessToken={checkIfAsAccessToken}
    handleCreateNewFolderClick={props.handleCreateNewFolderClick}
  />

}
export default Sidebar





// export default connect(mapStateToProps,{ updateCurrentCategory })(SideBar,);

