import React, { Component } from 'react';
import { getMessage, postMessage } from '../../apis/getAndSendForumMessage';
import { putLeadingId } from '../../../components/apis/putTaskLeadingId';
import { getHebrewDate } from '../../../components/apis/getHebrewDate';
import attechFileLogo from '../../../ui/images/attach_files_logo.svg';
import { updateCurrentTask } from '../../store/taskStore/taskActions';
import { getUserId, isUserStaff } from '../../apis/middleWareToken';
import forumHeaderLogo from '../../../ui/images/forum_logo.svg';
import { sortMyDateWithoutTime } from './moreInfoFunction';
import arrowDown from '../../../ui/images/arrow_down.svg';
import TaskMissionHistory from './taskMissionHistory';
import arrowUp from '../../../ui/images/arrow_up.svg';
// import { getUsers } from '../../apis/getAllUsers';
import ChatBoxOther from './chatBoxOther';
import ChatBoxUser from './chatBoxUser';
import { connect } from 'react-redux';
import ListLeads from './listLeads';
import './moreInfo.css';
import comment_icon from "../../../ui/images/comment_icon.svg";
import {ImAttachment} from "react-icons/im";


class MoreInfo extends Component {

    chatDiv = null;
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            messageArr: [],
            currentMessage: '',
            users: [],
            taskLeads: {}
        }
        this.userId = getUserId();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async componentDidMount() {
        // let users = await getUsers();
        let myTask = this.props.tasks.find(task => task.id === parseInt(this.props.id));
        this.setState({ myTask: myTask });
        // if (users && users.data && users.data.data) {
        //     this.setState({ users: users.data.data });
        // }
        this.getAllMessageFromDB();
        if (this.props.data !== undefined) {
            let data = this.props.data.created.split(' ')[0].split('-');
            let hebrewDate = await getHebrewDate(data[0], data[1], data[2]);
            if (hebrewDate && hebrewDate.data) {
                this.setState({ hebrewDate: hebrewDate.data.hebrew });
            }
        }
        this.interval = setInterval(() => this.getAllMessageFromDB(), 30000);
    }


    async getAllMessageFromDB() {

        if (!this.props.nextToCreateTask) {
            let messageFromDB = await getMessage(this.props.id);
            if (messageFromDB && messageFromDB.data && messageFromDB.data.data.constructor.name === 'Array') {
                let arr = [];
                messageFromDB.data.data.map((m, i) => {
                    m.myDate = sortMyDateWithoutTime(m.created, 'shortMonth');
                    arr.push(m);
                });
                this.setState(() => ({
                    messageArr: arr
                }), () => {
                    this.chatDiv.scrollTop = this.chatDiv.scrollHeight;
                });
            }
        }
    }

    selectLeads(e) {
        this.setState({ clicked: !this.state.clicked });
    }

    onChangeLeads(e) {
        let id = e.target.value;
        // this.setState(prevState => {
        //     let myTask = Object.assign({}, prevState.myTask);
        //     myTask.leading_id = parseInt(id);
        //     return { myTask };
        // }, () => {
        // if (this.props.isCreate) {
        let task = this.props.isCreate ? Object.assign({}, this.props.currentTaskData) : Object.assign({}, this.props.data);

        task.leading_id = parseInt(id);

        this.props.updateCurrentTask(task);
        // }
        // else {
        //     putLeadingId(this.state.myTask, this.props.id);
        // }
        // });
    }

    handleForumMessage(e) {
        this.setState({ currentMessage: e.target.value });
    }

    async sendForumMessage() {
        let message = this.state.currentMessage;
        if (message.length > 0) {
            await this.sendMessageToDB(message);
            this.setState(() => ({
                currentMessage: ''
            }), () => {
                this.getAllMessageFromDB();
            });
        }
    }

    async sendMessageToDB(message) {
        let objMessage = {
            "content": message,
            "content_type_id": 1,
            "task_id": this.props.id
        }
        await postMessage(objMessage);
    }

    getUserInformation(title) {
        return localStorage.getItem(title)
    }

    getLeadsName() {
        let myDefault = 'בחר מוביל';
        let name;
        if (this.props.data !== undefined) {
            if (this.props.data.leading_name !== '') {
                name = this.props.data.leading_name;
            }
            else name = myDefault;
        }
        else name = myDefault;
        return name;
    }
    calculatesTaskStatus() {
        if (this.props.data) {
            let data = this.props.data;
            let allProcessLineLength = this.props.data.work_process.length;
            let totalCheckedCount = 0;
            let checkedCountChildrenArr = [];
            if (this.props.data) {
                data.work_process.map(w => {
                    if (w.checked) {
                        totalCheckedCount++;
                    }
                    if (w.children.length > 0) {
                        let checkedCountChildren = 0;
                        w.children.map(c => {
                            if (c.checked && !w.checked) {
                                checkedCountChildren++;
                            }
                        });
                        let childrenObj = {
                            fatherId : w.id,
                            checkedCount: checkedCountChildren,
                            allChildren: w.children.length
                        }
                        checkedCountChildrenArr.push(childrenObj);
                    }
                });
            }
            let total = totalCheckedCount / allProcessLineLength * 100;
            let onePartOfAll = 1 / allProcessLineLength * 100;
            checkedCountChildrenArr.map( children => {
                let totalChildren = onePartOfAll * children.checkedCount / children.allChildren;
                total = total + totalChildren;
            });
            return this.isInt(total) ? total : this.isFloat(total) ? total.toFixed(1) : 0;
        }
        else return 0;
    }

    isInt(n){
        return Number(n) === n && n % 1 === 0;
    }

    isFloat(n){
        return Number(n) === n && n % 1 !== 0;
    }

    render() {

        let userFullName = this.getUserInformation('userFirstName') + ' ' + this.getUserInformation('userLastName');
        let createdDate = !this.props.nextToCreateTask && this.state.hebrewDate ?
            sortMyDateWithoutTime(this.props.data.created, 'longMonth') + ' ' + this.state.hebrewDate + ' ע"י ' +
            this.props.data.group_name : '...';
        let taskLeadsName = this.getLeadsName();

            // <div className=''>
                {/*<span className="more-info-header">*/}
                {/*    מידע נוסף*/}
                {/*</span>*/}
                {/*<div className="line-ander-header"></div>*/}
                {/*<div className="d-flex div-status-a">*/}
                {/*    <div className="status-text">*/}
                {/*        סטטוס משימה*/}
                {/*    </div>*/}
                {/*    <div className="status-num">*/}
                {/*        {`${this.calculatesTaskStatus()}%`}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="more-info-progress">*/}
                {/*    <div style={{ width: `${this.calculatesTaskStatus()}%` }} className="more-info-progress-colourful"></div>*/}
                {/*</div>*/}
                {/*<div className="Leading-mission-text">*/}
                {/*    מוביל משימה*/}
                {/*</div>*/}
                {/* <button onClick={() => this.selectLeads()} className="select-leads-btn">
                    <div className="select-leads-btn-intenal">
                        <span className='select-leads-btn-text'>{this.state.leadsName ? this.state.leadsName : 'בחר מוביל'}</span>
                        {this.state.clicked ?
                        <img src={arrowUp} alt="" className="select-leads-btn-logo" /> :
                        <img src={arrowDown} alt="" className="select-leads-btn-logo" />}
                    </div>
                </button>
                {this.state.clicked ? <div className='list-of-leads'>
                    {data.leadsFullNames.map((name, i) => {
                        return(
                           <ListLeads leadsOnClick={(e) => this.leadsOnClick(e,name)} key={i} leadsName={name}/>
                        )

                    })}
                </div> : <></>} */}
                {/*<select*/}
                {/*    style={this.state.clicked ? { backgroundImage: 'url(' + arrowUp + ')' } :*/}
                {/*        { backgroundImage: 'url(' + arrowDown + ')' }}*/}
                {/*    className="select-leads-btn"*/}
                {/*    id="myLeadsSelect"*/}
                {/*    onClick={(e) => this.selectLeads(e)}*/}
                {/*    onChange={(e) => this.onChangeLeads(e)}*/}
                {/*    disabled={this.props.isEdit || this.props.isCreate ? false : true}*/}
                {/*>*/}
                {/*    <option>*/}
                {/*        {taskLeadsName}*/}
                {/*    </option>*/}
                {/*    {this.props.users.map((user, i) => {*/}
                {/*        return (*/}
                {/*            <ListLeads*/}
                {/*                key={i} item={user}*/}
                {/*                formatText={item => item.first_name + ' ' + item.last_name}*/}
                {/*            />*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</select>*/}
                {/*<div className="line-ander-Leading-mission"></div>*/}
                {/*<div className="forum-header">*/}
                {/*    <img src={forumHeaderLogo} alt="" />*/}
                {/*    <span className="forum-header-text">*/}
                {/*        פורום המערך*/}
                {/*    </span>*/}
                {/*</div>*/}
                {/*{this.state.messageArr.length === 0 ? <span className='no-message-text'>אין הודעות</span> : <></>}*/}
        return (


              <div className='comment d-flex'>
                <img className='mb-auto' src={comment_icon}/>
                <div className='comment-box ml-2'>
                  <div className='comment-title'>תגובות</div>
                  <div className="forum-chat" ref={(div) => this.chatDiv = div}>
                  <div className=''> {this.state.messageArr.map((message, i) => {
                    // let currentUserId = parseInt(this.getUserInformation('userId'));
                    if (this.userId !== message.user_id) {
                      return (
                        <ChatBoxOther
                          userName={message.username}
                          key={'other' + i}
                          textMessage={message.content}
                          created={message.myDate} />
                      )
                    }
                    if (this.userId === message.user_id) {
                      return (
                        <ChatBoxUser
                          userName={message.username}
                          key={'user' + i}
                          textMessage={message.content}
                          created={message.myDate}
                        />
                      )
                    }
                  })}</div>
                  </div>
                  <div className='textarea-container'>
                    <textarea
                      className='chat-write-message'
                      placeholder={'תגובה חדשה...'}
                      onChange={(e) => this.handleForumMessage(e)}
                      value={this.state.currentMessage}
                      disabled={this.props.nextToCreateTask}>
                    </textarea>
                    <div>
                      <button><img src={attechFileLogo} alt="" /></button>
                      <button
                        onClick={() => this.sendForumMessage()}
                        disabled={this.props.nextToCreateTask}>{"שליחה"}</button>
                    </div>
                  </div>
                </div>
              </div>

        );
                {/*<textarea*/}
                {/*    className='chat-write-message'*/}
                {/*    placeholder='כתיבת הודעה...'*/}
                {/*    onChange={(e) => this.handleForumMessage(e)}*/}
                {/*    value={this.state.currentMessage}*/}
                {/*    disabled={this.props.nextToCreateTask}*/}
                {/*>*/}
                {/*</textarea>*/}
                {/*<div className="under-write-message">*/}
                {/*    <div className='div-ander-ander-write-message-write-by'>*/}
                {/*        <span className="write-by">*/}
                {/*            נכתב ע"י*/}
                {/*        </span>*/}
                {/*        <span className="chat-name-me">*/}
                {/*            {userFullName}*/}
                {/*        </span>*/}
                {/*    </div>*/}
                {/*    <div>*/}
                {/*        <button disabled={false} className="attach-files-btn">*/}
                {/*            <img src={attechFileLogo} alt="" />*/}
                {/*        </button>*/}
                {/*        <button*/}
                {/*            className="forum-send-message"*/}
                {/*            onClick={() => this.sendForumMessage()}*/}
                {/*            disabled={this.props.nextToCreateTask}*/}
                {/*        >*/}
                {/*            שליחה*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="line-ander-Leading-mission"></div>*/}
                {/*<TaskMissionHistory*/}
                {/*    created={createdDate}*/}
                {/*    track={!this.props.nextToCreateTask ? this.props.data.track : null}*/}
                {/*/>*/}

            // </div>
    }
}
const mapStateToProps = (state) => {

    return {
        tasks: state.dashboard.tasks,
        isEdit: state.task.task.isEdit,
        isCreate: state.task.task.isCreate,
        currentTaskData: state.task.task_undo.currentTaskData,
        users: state.task.users
        // categories: state.dashboard.categories,
        // tags: state.dashboard.tags
    }
}

export default connect(
    mapStateToProps,
    { updateCurrentTask }
)(MoreInfo);
// export default MoreInfo;
