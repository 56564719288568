import React, {useState} from 'react'
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';
import "react-datepicker/dist/react-datepicker.css";
import {DatePickerPopover} from "./statusMode";
import trash_white from "../../../ui/images/delete_white.svg";

function Datepicker(props) {

registerLocale('he', he)
  const [startDate, setStartDate] = useState(Date.parse(props.value));

  const ExampleCustomInput = ({value, onClick}) => (

    <button className="example-custom-input" onClick={onClick}>
      <span
        style={{color: value? '':'#6178FF', cursor:"pointer"}}>
        <DatePickerPopover onDelete={props.value && deleteEndDate()} id={props.id} title={props.value && value}/>
      </span>
    </button>
  );

  const deleteEndDate = () => {
    return <button className="ml-auto pl-2 pr-0" onClick={(event) => {
      event.stopPropagation();
      props.onChanged(props.parent_id, props.id, 'end_date', null)
    }}><img src={trash_white} alt=''/></button>
  }

  return (

    <DatePicker
      selected={startDate}
      onChange={date => {
        setStartDate(date)
        props.onChanged(props.parent_id, props.id, 'end_date', date)
      }
      }
      dateFormat="dd/MM/yyyy"
      locale="es"
      customInput={<ExampleCustomInput/>}
    />
  );
}
export default Datepicker
