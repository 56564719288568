import { createStore, combineReducers } from 'redux';
import {dashboardReducer} from './dashBoardStore/dashboardReducer';
import {taskReducer} from './taskStore/taskReducer';
import {loginReducer} from './loginStore/loginReducer';



const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    login: loginReducer,
    task: taskReducer
});

const dashboardStore = createStore(rootReducer);
export default dashboardStore;
