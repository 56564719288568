import { taskData } from './initTaskData'
import undoable from 'redux-undo'
import {
    UPDATE_TASK_TAGS,
    UPDATE_CURRENT_TASK_DATA,
    SAVE_CURRENT_TASK_DATA,
    UPDATE_CREATED_TASK,
    TASK_CREATION,
    UPDATE_IS_EDIT,
    UPDATE_IS_CREATE,
    UPDATE_NEW_LINE,
    ADD_ORDER_TO_WORK_PROCESS,
    CHANGE_SAVE_DRAFT_TO_TRUE,
    CHANGE_ADVERTISING_ON_CLICK_TO_TRUE
} from './taskActionType';
import {combineReducers} from "redux";

const task_undo_reducer = (state = task_undo_reducer, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {

        case (UPDATE_CURRENT_TASK_DATA): {
            return Object.assign(newState, { currentTaskData: action.payload })
        }
        case ADD_ORDER_TO_WORK_PROCESS: {
            // let data = buildOrderForWorkProcess(action.payload);
            // return Object.assign(newState, { currentTaskData: data });
            let order = 0
            let data = action.payload;
            data = Object.assign(data, {work_process: data.work_process.filter(w=>["", "<p></p>"].indexOf(w.description) == -1).map(w=>{
                return Object.assign(w, {/*order: ++order*/}, {children: w.children.filter(c=>["", "<p></p>"].indexOf(c.description) == -1).map(c=>{
                   return  Object.assign(c,{/*order: ++order*/})
                })})
            })})
                // let newWorkProcess = Object.assign({}, d, {order: i})
                // let children = d.children.map((c, j)=>Object.assign(c,{order: j}))
            //     return Object.assign(n, Object.assign(newWorkProcess, {children, children}) )
            // }
            // ,{})})
            return Object.assign(newState, { currentTaskData: data });
        }
        default:
            return state
    }
}

const task_reducer = (state = taskData, action) => {

  let newState = Object.assign({}, state);
  switch (action.type) {
    case (UPDATE_TASK_TAGS): {
      return Object.assign(newState, { selectedTags: action.payload })
    }
    case TASK_CREATION: {
      return Object.assign(newState, { isTaskCreated: action.payload })
    }
    case SAVE_CURRENT_TASK_DATA: {
      return Object.assign(newState, { dataToEdit: action.payload })
    }
    case UPDATE_CREATED_TASK: {
      return Object.assign(newState, { createdTask: action.payload })
    }
    case UPDATE_IS_EDIT: {
      return Object.assign(newState, { isEdit: action.payload })
    }
    case UPDATE_IS_CREATE: {
      return Object.assign(newState, { isCreate: action.payload })
    }
    case UPDATE_NEW_LINE: {
      return Object.assign(newState, { newLine: action.payload })
    }
    case CHANGE_SAVE_DRAFT_TO_TRUE: {
      return Object.assign(newState, { saveDraft: action.payload })
    }
    case CHANGE_ADVERTISING_ON_CLICK_TO_TRUE: {
      return Object.assign(newState, { advertisingOnClick: action.payload });
    }
    default:
      return state
  }
}

const task_undo = undoable(task_undo_reducer)
export const taskReducer = combineReducers({
  task_undo: task_undo,
  task: task_reducer
});

// export default taskReducer


