import ppt from "../../../ui/images/typefile-icons/ppt.svg";
import docx from "../../../ui/images/typefile-icons/docx.svg";
import delete_x from "../../../ui/images/delete_x.svg";
import excel from "../../../ui/images/excel.svg";
import pdf from "../../../ui/images/PDF.svg";
import React, {useEffect, useState} from "react";


function FilesItem(props) {

  const [files, setFiles] = useState(props.task.files)
  const [fileName, setFileName] = useState(null)
  useEffect(()=>{
    setFiles(props.task.files)
  },[props.task.files])

  function onChangeFileName(index, value) {
    let suffix = fileName.split('.').pop()
    let fixSuffix = value.includes(suffix) ? value : value !== '' ? `${value.includes('.') ? value.split('.')[0] : value}.${suffix}` : files[index].name
    // console.log(suffix)
    // console.log(fixSuffix)
    setFileName(fixSuffix)
  }

  function updateFiles(index) {
    if (fileName === null) return
    let filesArr = [...files]
    filesArr[index].name = fileName
    setFiles(filesArr)
    // console.log(filesArr)
    props.onChange({...props.task, 'files': filesArr})
  }

  function filesList() {

    return (
      <div className={`row pb-3 pl-3`} style = {{paddingRight : `${props.printMode && "80px"}`}} id='files'>
      <div style={{position: 'relative', display: 'contents'}}>{files.map((file, index) =>
        !props.editMode
          ? <a href={file.url} target="_blank" style={{textDecoration: 'none'}}>{fileItem(file, index)}</a>
          : fileItem(file, index)
      )}
      </div>
    </div>)
  }

  function fileItem(file, index) {
    const {fileIcon, titleColor} = getIconAndColor(file)

    return <div key={index} id={'extensions-' + index} className='mr-3 mb-3'>
      <div style={{position: 'relative'}}>
         {!props.printMode && <span style={{
            width: '4px',
            height: props.editMode ? '72px' : '100%',
            backgroundColor: titleColor,
            position: 'absolute'
          }}/>}
        {props.editMode
          ? <img src={delete_x}
                 style={{position: 'absolute', top: '-4px', left: '-4px', cursor: 'pointer'}}
                 className='d-flex ml-auto'
                 onClick={() => {
                   props.onDelete(index, 'files')
                 }}
          />
          : <span/>
        }
        <span className={`${props.editMode ? 'edit' : 'show'} d-flex justify-content-between align-items-center `}>
          {props.printMode &&  <div className='col-3 icon'>
            <img className='' src={fileIcon}/>
          </div>}
          <div className='col-9 '>
               <div href={file.url} target="_blank">
                 <span>
                {/* to rename file */}
                   {/*{props.editMode*/}
                   {/*  ? <input className='title'*/}
                   {/*           defaultValue={file.name}*/}
                   {/*           style={{border: 'none'}}*/}
                   {/*           onClick={()=>setFileName(file.name)}*/}
                   {/*           onChange={(e) => onChangeFileName(index, e.target.value)}*/}
                   {/*           onBlur={()=>props.editMode ? updateFiles(index): null}*/}
                   {/*  />*/}
                   {/*  : <div className='title' style={{color: `${titleColor}`}}>{file.name}</div>}*/}
                   <div className='title' style={{color: `${props.printMode ? "#6C7582" : titleColor}`}}>{file.name}</div>
                {!props.printMode && <div className='type'>{file.name.split('.').pop()}</div>}
            </span>
           </div>
          </div>
          {!props.printMode && <div className='col-3 icon'>
            <img className='' src={fileIcon}/>
          </div>}
       </span>
      </div>
    </div>

  }

  function getIconAndColor(file) {
    let fileIcon;
    let titleColor;
    switch (file.name.split('.').pop()) {
      case 'pptx':
      case 'ppt':
        fileIcon = ppt
        titleColor = '#E88600'
        break
      case 'docx':
        fileIcon = docx
        titleColor = '#4D89EA'
        break
      case 'xlsx':
        fileIcon = excel
        titleColor = '#239529'
        break
      case 'pdf':
        fileIcon = pdf
        titleColor = '#DE2502'
        break
      default:
        fileIcon = ''
        titleColor = 'blue'
        break
    }
    return {fileIcon, titleColor}
  }

  return files && files.length > 0 ? filesList() : <span/>
}

export default FilesItem
