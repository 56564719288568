import { CreateNewFolder } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ADD2, NEW_FOLSER as NEW_FOLDER, NEW_TASK, USER_AREA } from "../../../../ui/strings";
import { createNewFolder } from "../../../apis/foldersHandling";
import { isAdminUser, isAreaUser, isSuperUser, isUserStaff } from "../../../apis/middleWareToken";
import RectangleButton from "../../../CreateTaskPage/screens/rectangleButton";
import { insertNewFolderInAllFoldersArr, updateFilteredFolders } from "../../../store/dashBoardStore/dashboardActions";
import PopupAction from "./popupAction";

const AddFolsersAndTasks = ({ myGroup, currentFolder, setNewFolderCreated }) => {

  const history = useHistory()

  const dispatch = useDispatch()

  const getIsAllowedToCreateFolder = () => {
    if (
      isSuperUser() ||
      isAreaUser() ||
      (isAdminUser() && myGroup.id !== 2) ||
      (isUserStaff() && myGroup.name === USER_AREA)
    ) {
      return true
    }
    return false
  }

  const CreateNewFolder = async () => {
    const data = await createNewFolder({
      title: NEW_FOLDER,
      user: myGroup.name === USER_AREA ? localStorage.getItem("userId") : null,
      group: myGroup.id,
      area: myGroup.areaId ? myGroup.areaId : null,
      parent: currentFolder ? currentFolder.id : null
    })
    if(data.status === 201){
      setNewFolderCreated(data.data.data)
      dispatch(insertNewFolderInAllFoldersArr(data.data.data))
    }
    
  }

  const CreateNewTask = async () => {
    history.push("/dashboard/createtask")
  }

  const getPopupBody = () => {
    const addItemContent = [
      {
        text: NEW_TASK,
        class: "task-icon",
        onClick: () => { CreateNewTask() },
        isAllowed: isUserStaff(),
      },
      {
        text: NEW_FOLDER,
        class: "add-folder-icon",
        onClick: () => { CreateNewFolder() },
        isAllowed: getIsAllowedToCreateFolder()
      },
    ]
    return addItemContent.map((item, i) => {
      return item.isAllowed &&
        <PopupAction key={i} item={item} />
    })
  }

  const getButtenStyle = () => ({
    lineHieght: "20px",
    fontSize: "15px",
    fontWeight: "700",
    height: "45px",
    width: "140px",
  })

  return (
    <React.Fragment>
      <RectangleButton
        style={getButtenStyle()}
        text={ADD2}
        isAllowed={true}
        icon={<span className="add-task-icon" />}
        onClick={() => { }}
        id={"add-task"}
        withPopup={true}
        popperClass={"add-task"}
        popupBody={getPopupBody()}
      />
    </React.Fragment>
  )
}

export default AddFolsersAndTasks