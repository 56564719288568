import {
  GET_CURRENT_TAGS,
  GET_USER_DATA,
  GET_USERS,
  GET_INPUT_SEARCH,
  UPDATE_CURRENT_TASKS_ARRAY,
  GET_ALL_DATA,
  GET_CATEGORIES,
  GET_TAGS,
  DATA_RECEIVED,
  UPDATE_CURRENT_CATEGORIES,
  UPDATE_SEARCH_RESULTS,
  RESTART_REQUEST_TASKS,
  UPDATE_iS_ROUTES_BOUNDED,
  UPDATE_CURRENT_TASK_ARRAY_FILTERED,
  UPDATE_TASK_ARRAY,
  UPDATE_CURRENT_TASK_IN_ARRAY,
  INSERT_NEW_TASK_TO_TASKS_ARRAY,
  DELETE_TASK,
  SPINNER_ON, 
  START_FILTER, 
  TASK_DELETED, 
  UPDATE_SELECTED_GROUP, 
  UPDATE_FILTERED_FOLDERS, 
  INSERT_NEW_FOLDER_IN_ALL_FOLDERS,
  UPDATE_FOLDER_IN_ALL_FOLDERS,
  REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR
} from './dashboardActionTypes';
import { dashboardData } from './initDashboardStoreData';
import { tasks } from "../../apis/paths";


export const dashboardReducer = function (state = dashboardData, action) {
  let newState = Object.assign({}, state);
  
  switch (action.type) {


    case SPINNER_ON: {
      newState = Object.assign(newState, { spinnerOn: action.payload })
      return newState
    }

    case GET_USERS: {
      newState = Object.assign(newState, { users: action.payload })
      return newState
    }

    case GET_INPUT_SEARCH: {
      newState = Object.assign(newState, { inputSearch: action.payload })
      return newState
    }

    case GET_CURRENT_TAGS: {
      newState = Object.assign(newState, { currentTags: action.payload, currentTasksFiltered: false })
      return newState
    }

    case START_FILTER: {
      newState = Object.assign(newState, { isStartFilter: action.payload })
      return newState
    }

    case GET_ALL_DATA: {

      return Object.assign(newState, {
        tasks: action.data.tasks,
        tags: action.data.tags,
        categories: action.data.categories,
        areas: action.data.areas,
        folders: action.data.folders,
        isTasksReceived: action.data.isTasksReceived
      })
    }

    case INSERT_NEW_TASK_TO_TASKS_ARRAY: {
      return Object.assign(newState, newState.tasks.push(action.payload))
    }

    case UPDATE_CURRENT_TASK_IN_ARRAY: {
      let copyTasks = [...state.tasks]
      let index = copyTasks.findIndex(task => task.id === action.payload.id)
      copyTasks[index] = action.payload.data

      let copyCurrentTasks = [...state.currentTasks]
      let indexCurrentTasks = copyCurrentTasks.findIndex(task => task.id === action.payload.id)
      copyCurrentTasks[indexCurrentTasks] = action.payload.data
      return Object.assign(newState, {tasks: [...copyTasks], currentTasks: [...copyCurrentTasks]})      
    }

    case UPDATE_CURRENT_TASKS_ARRAY: {
      return Object.assign(newState, { currentTasks: action.data, currentTasksFiltered: true })
    }

    case UPDATE_CURRENT_TASK_ARRAY_FILTERED: {
      return Object.assign(newState, { currentTasksFiltered: action.payload })
    }

    case INSERT_NEW_FOLDER_IN_ALL_FOLDERS: {
      let folders = state.folders
      folders.push(action.payload)
      return Object.assign(newState, {folders: [...folders]})
    }
    
    case UPDATE_FOLDER_IN_ALL_FOLDERS:{
      let copyFolders = [...state.folders]
      let index = copyFolders.findIndex(fold => fold.id === action.payload.id)
      copyFolders[index] = action.payload
      return Object.assign(newState, {folders: [...copyFolders]})
    }

    case REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR: {
      let copyFolders = [...state.folders]
      let index = copyFolders.findIndex(folder => folder.id === action.payload)
      copyFolders.splice(index, 1)
      return Object.assign(newState, {folders: copyFolders.length? copyFolders : [1]})
    }
    case UPDATE_FILTERED_FOLDERS: {
      return Object.assign(newState, {filteredFolders: action.payload})
    }

    case GET_CATEGORIES: {
      return Object.assign(newState, { categories: action.payload, });
    }

    case GET_TAGS: {
      return Object.assign(newState, { tags: action.payload, });
    }

    case DATA_RECEIVED: {
      return Object.assign(newState, { isTasksReceived: action.payload });
    }

    case UPDATE_CURRENT_CATEGORIES: {
      return Object.assign(newState, {
        currentCategory: action.payload.categoryId,
        currentGroup: action.payload.folderId,
        currentTasksFiltered: false
      });
    }

    case UPDATE_SEARCH_RESULTS: {
      return Object.assign(newState, { searchResults: action.payload });
    }

    case UPDATE_SELECTED_GROUP: {
      return Object.assign(newState, { groupSelected: action.payload });
    }

    case RESTART_REQUEST_TASKS: {
      return Object.assign(newState, { isTasksReceived: action.payload /*false*/ });
    }

    case UPDATE_TASK_ARRAY: {
      return Object.assign(newState, { tasks: action.payload });
    }

    case UPDATE_iS_ROUTES_BOUNDED: {
      return Object.assign(newState, { isRoutesBounded: action.payload });
    }

    case TASK_DELETED: {
      return Object.assign(newState, { isTaskDeleted: action.payload });
    }

    default:

      return state
  }

}
// const dashboardStore = createStore(dashboardReducer,initState)
// export default dashboardStore
