import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { TEXT_GO_BACK_IN_VIEW_TASK, TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK } from '../../ui/strings';

class CancellationAndReturn extends Component {

    render() {
        return (
            <div>
                {/* <Link to='/dashboard' > &#129122;  ביטול וחזרה </Link> */}
                <Link onClick={this.props.cancelAndReturnOnClick} to='/dashboard' > <ArrowForwardIcon style={{ marginBottom: '2px' }} fontSize="small" />
                    {this.props.viewTask ? TEXT_GO_BACK_IN_VIEW_TASK : this.props.editTask ? TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK : ''}
                </Link>
            </div>
        )
    }
}

export default CancellationAndReturn;