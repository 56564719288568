export function returnWorkProcessLine(value, id, task_id, isChecked, children, parent_id = null) {
    return {
        id: id,
        description: value,
        task_id: task_id,
        parent_id: parent_id,
        checked: isChecked,
        children: children,
    }
}

export function addNewWorkProcessLine(currentTaskData, value, isChild, index, childrenIndex, task_id, parent_id = null) {
    let currentTask = Object.assign({}, currentTaskData);
    let currentTaskWorkProcess = [...currentTaskData.work_process];
    if (isChild) {
        // currentTaskWorkProcess[index].children.push(returnWorkProcessLine("<p></p>",null, task_id, false, [], parent_id));

        currentTaskWorkProcess[index].children.splice(childrenIndex + 1, 0, returnWorkProcessLine("<p></p>", null, task_id, false, [], parent_id));
        currentTaskWorkProcess[index].checked = false;

        currentTask.work_process = currentTaskWorkProcess;
    } else {
        if (index === undefined) {
            index = currentTask.work_process.length;
        }
        currentTask.work_process.splice(index + 1, 0, returnWorkProcessLine(value, null, task_id, false, [], null));
        // currentTask.work_process.push(returnWorkProcessLine(value, null, task_id, false,[], null));
    }
    return currentTask;
}

export async function isManagerOfTheCurrentLibraryToCopy(library, userGroupId, isStuff, isSuperUser = false) {
    if (isSuperUser) {
        return true;
    }
    if (isStuff) {
      let lib = await library ? library: false
        if ( lib && lib.id === parseInt(userGroupId) || lib && lib.id === 2) {
            return true;
        }
    }
    return false
}

export async function isManagerOfTheCurrentLibraryToEditEndDelete(library, userGroupId, isStuff, isSuperUser = false) {
    if (isSuperUser) {
        return true;
    }
    if (isStuff) {
      let lib = await library ? library: false
        if ( lib.id === parseInt(userGroupId)) {
            return true;
        }
    }
    return false
}
