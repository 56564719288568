import React, { useRef, useEffect } from "react";
import {useHistory} from "react-router-dom";


export default function usePrompt(message, when) {
  const history = useHistory();
  const unblock = useRef(null);

  useEffect(() => {
    if (when) {
      unblock.current = history.block(message);
    } else {
      unblock.current = null;
    }
    return () => {
      if (unblock.current) {
        unblock.current();
      }
    };
  }, [when, history, message]);
}


