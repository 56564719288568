export const loginInitData = {
    isAllowedAccess: false,
    isAllowedAccessLogin: false,
    user: {
        email: "",
        first_name: "",
        group_id: null,
        id: null,
        is_superuser: false,
        last_name: "",
        title: ""
    }
}