export const GET_INPUT_FROM_HEADER_SEARCH = 'GET_INPUT_FROM_HEADER_SEARCH';
export const GET_CURRENT_TAGS = 'GET_CURRENT_TAGS';
export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USERS = 'GET_USERS';
export const GET_ALL_DATA = 'GET_ALL_DATA';
export const GET_INPUT_SEARCH = 'GET_INPUT_SEARCH';
export const UPDATE_CURRENT_TASKS_ARRAY = 'UPDATE_CURRENT_TASKS_ARRAY'
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_TAGS = 'GET_TAGS';
export const DATA_RECEIVED = 'DATA_RECEIVED';
export const UPDATE_CURRENT_CATEGORIES = 'GET_CURRENT_CATEGORIES';
export const UPDATE_SEARCH_RESULTS = 'UPDATE_SEARCH_RESULTS';
export const RESTART_REQUEST_TASKS = 'RESTART_REQUEST_TASKS';
export const UPDATE_iS_ROUTES_BOUNDED = 'UPDATE_iS_ROUTES_BOUNDED';
export const UPDATE_CURRENT_TASK_ARRAY_FILTERED = 'UPDATE_CURRENT_TASK_ARRAY_FILTERED';
export const UPDATE_TASK_ARRAY = 'UPDATE_TASK_ARRAY';
export const UPDATE_CURRENT_TASK_IN_ARRAY ='UPDATE_CURRENT_TASK_IN_ARRAY'
export const START_FILTER ='START_FILTER';
export const TASK_DELETED ='TASK_DELETED';
export const UPDATE_SELECTED_GROUP ='UPDATE_SELECTED_GROUP';
export const SPINNER_ON ='SPINNER_ON';
export const INSERT_NEW_TASK_TO_TASKS_ARRAY ='INSERT_NEW_TASK_TO_TASKS_ARRAY';
export const UPDATE_FILTERED_FOLDERS = "UPDATE_FILTERED_FOLDERS";
export const INSERT_NEW_FOLDER_IN_ALL_FOLDERS = "INSERT_NEW_FOLDER_IN_ALL_FOLDERS";
export const UPDATE_FOLDER_IN_ALL_FOLDERS = "UPDATE_FOLDER_IN_ALL_FOLDERS";
export const REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR = "REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR"
