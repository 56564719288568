import React, { useEffect, useRef, useState } from "react"
import sanitizeHtml from 'sanitize-html';
import yesodLogo from '../../../../ui/images/yesod_card_logo.svg';
import footerLogo from '../../../../ui/images/logo-task-card-footer.svg';


import { DRAFT, FILES, USER_AREA } from "../../../../ui/strings"
import './taskCard.css'
import { useDispatch, useSelector } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import { dndTypes } from "./dndTypes";
import { isAdminUser, isAreaUser, isSuperUser, isUserStaff } from "../../../apis/middleWareToken";
import BetweenCards from "./betweenCards";
import { updateCurrentTaskInArray } from "../../../store/dashBoardStore/dashboardActions";
import { updateTasksOrder } from "../../../apis/taskHandling";

const TaskCard = ({ task, folder, index, myGroup, aroundedTasks }) => {

  const categories = useSelector(state => state.dashboard.categories)

  const dispatch = useDispatch()

  const [isMouseOver, setIsMouseOver] = useState(false)
  const [categoryLogo, setCategoryLogo] = useState()

  const ref = useRef(null)

  useEffect(() => {
    if (!categoryLogo && categories) {
      let myCategory = categories.find(c => c.id === task.group_id);
      setCategoryLogo(myCategory.logo)
    }
  }, [])

  const [{ isOver, item, itemType }, drop] = useDrop(() => ({
    accept: dndTypes.TASK,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
      itemType: monitor.getItemType()
    })
  }),[])

  const [{ isDragging, }, drag] = useDrag(() => ({
    type: dndTypes.TASK,
    item: {
      ...task,
      index,
      aroundedTasks,
      folderId: folder ? folder.id : null
    },
    canDrag: () => (getIsAllowedToUpdateTask()),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  }),[])

  drag(drop(ref))

  const getIsAllowedToUpdateTask = () => {
    if (
      isSuperUser() ||
      isAreaUser() ||
      (isAdminUser() && myGroup.id !== 2) ||
      (isUserStaff() && myGroup.name === USER_AREA)
    ) {
      return true
    }
    return false
  }

  const cleanHtml = (html) => {
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    })
  }

  const getIsActineWhenOver = (isFirst) => {
    if (!item || (item && item.index === index) || (item.index - 1 === index && !isFirst)) {
      return false
    } else if (itemType === dndTypes.TASK) {
      return true
    }
    return false
  }

  const updateOrderOfTask = async (taskToUpdate, order) => {
    const detials = { ...order, folder: folder ? folder.id : null }
    let data = await updateTasksOrder(taskToUpdate.id, detials)
    data = data && data.data && data.data.data ? data.data.data : null;
    data && dispatch(updateCurrentTaskInArray({ data: data, id: data.id }))
  }


  return (
    <div ref={ref} style={{ display: "flex" }}>
      {aroundedTasks.before === null &&
        <BetweenCards
          className="first"
          itemInfo={task}
          aroundedItem={aroundedTasks}
          typeOfItem={dndTypes.TASK}
          isActineWhenOver={() => getIsActineWhenOver(true)}
          isFirst={true}
          updateOrder={updateOrderOfTask}
        />}
      <div
        className='div-task-card'
        onMouseOver={() => { setIsMouseOver(true) }}
        onMouseLeave={() => { setIsMouseOver(false) }}
        style={{
          boxShadow: isMouseOver ? '0 20px 34px 0 rgba(21,36,120,0.13)' : "",
          opacity: isDragging ? "0.5" : "1",
        }}
      >
        <span className="wrap-img">
          <img
            src={categoryLogo === undefined ? yesodLogo : categoryLogo}
            className={categoryLogo !== undefined && categoryLogo !== null ? 'card-logo' : 'categoryLogo'}
            alt="" />
        </span>
        <div className="my-card-header">
          <div className="div-my-card-header-text">
            {task.title}
            {/* {task.status_name === 'draft' ? <span className='class-draft-header-text'>{DRAFT}</span> : ''} */}
          </div>
        </div>
        <div className="my-card-line"></div>
        <div
          className="my-essence-mission" dangerouslySetInnerHTML={{ __html: cleanHtml(task.description ? task.description : "") }}>
        </div>
        <div className="my-card-footer">
          <div className='d-flex'>
            <div className="footer-logo">
              <img src={footerLogo} alt="" />
            </div>
            <div className="my-footer-text">
              {task.files_count} {FILES}
            </div>
          </div>
        </div>
      </div>
      <BetweenCards
        itemInfo={task}
        aroundedItem={aroundedTasks}
        typeOfItem={dndTypes.TASK}
        isActineWhenOver={getIsActineWhenOver}
        updateOrder={updateOrderOfTask}
      />
    </div>
  )
}

export default TaskCard