// a little function to help us with reordering the result
export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 6;
export const getParentItemStyle = (isDragging, draggableStyle, isOpen, isDone, editMode, hover, onFocus) => {
  let heightSelector = isOpen? 'min-height':'height'
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2 ,
    border:isOpen || hover? '1px solid #6178FF':'none',
    boxShadow:isOpen|| hover? '0 2px 8px 0 rgba(40,44,69,0.42)': onFocus ?'0 4px 20px 0 rgba(18,26,78,0.14)':'none',
    borderRadius:'2px',
    margin: `0 0 ${grid * 2.5}px 0`,
    width:'100%',
    // [heightSelector]: '80px',
    // change background colour if dragging
    backgroundColor: isDragging? `rgb(97,120,255,0.5)` :isDone? `rgba(7,220,205,0.1)`: '#F8F9FF',
    // styles we need to apply on draggables
    ...draggableStyle
  };
};

export const getChildItemStyle = (isDragging, draggableStyle) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    border:'0.5px solid rgba(28,40,78,0.2)',
    maxWidth:'98%',
    marginTop:'2px',
    marginRight:'27px',
    // change background colour if dragging
    background: isDragging ? `rgb(97,120,255,0.5)` : "white",
    // styles we need to apply on draggables
    ...draggableStyle
  };
};

export const getParentListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  background: 'none',
  padding: 8,
  width: '100%'
});

export const getChildListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: '0',
  width: '100%'
});
