import React, {useRef} from "react";
import './uploadFilesBtn.css'
import {ADD_FILE_BTN_IN_CREATE_LIBRARY_MODAL_TEXT} from "../../../../../ui/strings";
import {createRequest} from "../../../../apis/middleWareToken";
import {basePath, singleFile} from "../../../../apis/paths";

function UploadFilesButton(props) {
  const upload = useRef(null);

  function newFile(e) {
    let files =  e.target.files
    if (files && files.length > 0 ) {
      [...files].forEach(file =>{
        let formData = new FormData();
        formData.append('task_id',props.task.id);
        formData.append('file', file);
        createRequest(`${basePath}${singleFile}`, 'post', formData)
          .then(res =>{
            let task = {...props.task}
            task.files.push(res.data.data);
            props.onChange({...props.task, 'files': task.files, "files_count": task.files_count + 1})
          }
        )
      })
    }
      // this.updatingStoreTask('files', files);
  };

  return <div className="upload-btn-wrapper">
    <button className="upload-btn"
    >{ADD_FILE_BTN_IN_CREATE_LIBRARY_MODAL_TEXT}</button>
    <input type="file"
           ref={upload}
           style={{backgroundColor: '#6178ff', color: '#FFFFFF', marginRight: '10px'}}
           onChange={(e) => newFile(e)}
           multiple
    />
  </div>

}

export default UploadFilesButton
