import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCurrentTags} from '../../components/store/dashBoardStore/dashboardActions'
import Tag from './tag';
import './allTags.css';
import filter from '../images/filterIcon.svg'
import {ADVANCED_SEARCH} from "../strings";



class AllTags extends Component {
  constructor(props) {
    super(props);
    this.state={isOpen:false}
  }

  returnTags() {
    let tagArr = [<Tag key='all' tagName={"הכל"}/>];
    if (this.props.tags && this.props.tags.length > 0) {
      this.props.tags.map((tag, i) => {
        tagArr.push(
          <Tag key={i} tagName={tag.name}/>
        )
      });
    }
    return tagArr;
  }


  render() {
    return (
      <div style={{display: 'flex', padding: '20px'}}>
        <span //className='filtering-text'
          style={{cursor:"pointer", marginLeft:'10px'}}
          onClick={()=>this.setState({'isOpen':!this.state.isOpen})}>
          <img src={filter} height={'25px'} width={'25px'} />
          {ADVANCED_SEARCH}
        </span>
        {this.state.isOpen && this.returnTags()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tags: state.dashboard.tags,
    currentTags: state.dashboard.currentTags
  }

}


export default connect(
  mapStateToProps,
  {getCurrentTags}
)(AllTags);


