import delete_x from "../../../ui/images/delete_x.svg";
import icon_link from "../../../ui/images/icon_link.svg";
import React from "react";

const LinksItems =(props)=> {

  if (props.links && props.links.length > 0) {
    return <div className='row pb-3 pl-3' style = {{paddingRight : `${props.printMode && "80px"}`}}>{props.links.map((link, index) => {
      return <div key={index} id='extensions'  className=' mr-3 mb-3'>
        <div style={{position:'relative'}}>
          {!props.printMode && <span style={{width:'4px', height: props.editMode?'72px':'100%', backgroundColor:  `#6178FF`, position:'absolute'}}/>}
          {props.editMode
            ? <img src={delete_x}
                   style={{position: 'absolute', top: '-4px', left: '-4px',cursor:'pointer'}}
                   className='d-flex ml-auto'
                   onClick={()=>{props.onDelete(index,'links')}}
            />
            : <span/>
          }
          <span className={`${props.editMode ? 'edit' : 'show'} d-flex justify-content-between align-items-center `}>
          {props.printMode && <div className='col-3 icon'>
            <img className='' src={icon_link}/>
          </div>}
          <div className='col-9 '>
                <a href={link.url} target="_blank"><div className='title' style={{color: `${props.printMode ? "6C7582" : "#6178FF"}`}}>{link.text || link.url}</div></a>
                {!props.printMode && <div className='type'>URL</div>}
          </div>
          {!props.printMode && <div className='col-3 icon'>
            <img className='' src={icon_link}/>
          </div>}
       </span>
        </div>
      </div>
    })}</div>
  }
  return <span/>
}

export default LinksItems
