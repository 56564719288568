import React, { Component } from 'react';
import './chatBoxUser.css';

class ChatBoxUser extends Component {
    render() {
        return (
            <div>
                <div className="div-chat-box-user">
                    <span className="chat-text-user">
                        {this.props.textMessage}
                    </span>
                    <div className="chat-box-footer-user">
                        <span>
                            <span className="write-by-user">
                                נכתב ע"י
                            </span>
                            <span className="chat-name-user">
                                {this.props.userName}
                            </span>

                        </span>
                        <span className="write-by-user">
                            {this.props.created}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatBoxUser;