import React, { Component } from 'react';
import { connect } from 'react-redux'
import MoreInfo from '../../containers/moreInfoZone/moreInfo';
import ViewTask from '../../CreateTaskPage/ViewTask';
import CopyTaskModal from '../../../ui/modal/copyTaskModal';
import DeleteTaskModal from '../../../ui/modal/deleteTaskModal';
import { DISCONNECTED_ALERT_1, ONLY_MANAGER_CAN_DELETE_A_TASK } from '../../../ui/strings';
import { isUserStaff, isSuperUser, createRequest } from '../../apis/middleWareToken';
import { isManagerOfTheCurrentLibraryToEditEndDelete } from '../../../components/CreateTaskPage/commonFunctions';
import axios from "axios";
import { basePath, tasks } from "../../apis/paths";
import { SAVE_CURRENT_TASK_DATA } from "../../store/taskStore/taskActionType";
import { saveTaskToEdit, updateCreatedTask, updateCurrentTask, updateIsEdit } from "../../store/taskStore/taskActions";
import {
  spinnerOn,
  updateCurrentTaskInArray,
  updateSelectedGroup,
  updateTaskArray
} from "../../store/dashBoardStore/dashboardActions";
import { ThreeSixty } from '@material-ui/icons';
import PrintPage from '../../../utils/pdf/printPage';
import { isThisMinute } from 'date-fns';
import { menuItems } from '../../../ui/enums/sidebar'
import { getIsTaskEditable } from '../../apis/editable';

class ViewTaskAndForum extends Component {

  constructor(props) {
    super(props);
    this.isStaff = isUserStaff();
    this.isSuperUser = isSuperUser();
    this.state = {
      toPrint: false
    }
  }

  handleToPrint = (task) => {
    this.taskToPdf = task
    this.setState({
      toPrint: true
    })
  }

  clostToPrint = () => {
    this.setState({
      toPrint: false
    })
  }

  async isInternetConnection() {
    let promise = new Promise((resolve, reject) => {
      axios.get(`${basePath}isConnection`)
        .catch(e => {
          if (e.toJSON().message === 'Network Error') {
            resolve(false)
            // alert('no internet connection')
          } else if (e.response.status == (404 || 502)) {
            resolve(true)
            // setIsConnection(true)
          } else {
            resolve(false)
            // setIsConnection(false)
            // alert(e.toJSON().message)
          }
        });
    })
    return promise
  };

  componentDidMount() {
    this.props.spinnerOn(true)
    if (this.isInternetConnection()) {
      createRequest(`${basePath}${tasks}/${this.props.id}`, 'get')
        .then((res) => {
              this.props.updateCurrentTaskInArray({ data:{ ...res.data.data }, id: this.props.id })
              this.props.spinnerOn(false)
              this.props.updateSelectedGroup(
                res.data.data.status_name !== "draft" 
                  ? menuItems.GROUP_ID + res.data.data.group_id + (res.data.data.group_id ==2 ? menuItems.AREA_ID + res.data.data.area_id:"") 
                  : "userArea"
              )
        })
        .catch(e => {
          console.log("ERROR: ", e)
          this.props.spinnerOn(false)
        })
    } else {
      alert(DISCONNECTED_ALERT_1)
      this.props.spinnerOn(false)
    }
  }

  handleCopyTaskClick() {
    this.modal.toggle();
  }

  deleteArrayOnClick(e, libraryManagerToEditEndDelete) {

    if (this.isSuperUser || libraryManagerToEditEndDelete) {
      this.deleteModal.toggle();
    }
  }

  render() {
    let task = this.props.tasks.find(t => t.id == this.props.id)


    return (
      task.files ?
        // this.state.toPrint ?
        //   <PrintPage
        //     task={this.taskToPdf} 
        //     closeToPrint={this.clostToPrint}
        //     categories = {this.props.categories}

        //     />:
        <React.Fragment>
          {this.state.toPrint &&
            <PrintPage
              task={this.taskToPdf}
              closeToPrint={this.clostToPrint}
              categories={this.props.categories}

            />}
          <ViewTask
            id={this.props.id}
            data={this.props.data}
            handleCopyTaskClick={() => this.handleCopyTaskClick()}
            deleteArrayOnClick={(e, bool) => this.deleteArrayOnClick(e, bool)}
            dataToPdf={this.props.dataToPdf}
            print={this.handleToPrint}

          />
          {/*<MoreInfo*/}
          {/*  nextToCreateTask={false}*/}
          {/*  id={this.props.id}*/}
          {/*  data={this.props.data}*/}
          {/*/>*/}
          <CopyTaskModal data={this.props.data} ref={obj => this.modal = obj} />
          <DeleteTaskModal ref={ref => this.deleteModal = ref} data={this.props.data} />
        </React.Fragment> : <React.Fragment />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.dashboard.tasks
  }
}


export default connect(
  mapStateToProps,
  { updateCurrentTaskInArray, updateSelectedGroup, spinnerOn }
)(ViewTaskAndForum);
