import React, { Component } from 'react';

class MyYesodLogo extends Component {

    render() {
        return (
            <svg width="90px" height="97px" viewBox="0 0 90 97" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                <title>Group 6 Copy</title>
                <desc>Created with Sketch.</desc>
                <defs>
                    <path d="M45.75,3.46410162 L68.1745098,16.4108984 C71.8873227,18.5544919 74.1745098,22.5160162 74.1745098,26.8032032 L74.1745098,52.6967968 C74.1745098,56.9839838 71.8873227,60.9455081 68.1745098,63.0891016 L45.75,76.0358984 C42.0371871,78.1794919 37.4628129,78.1794919 33.75,76.0358984 L11.3254902,63.0891016 C7.61267728,60.9455081 5.3254902,56.9839838 5.3254902,52.6967968 L5.3254902,26.8032032 C5.3254902,22.5160162 7.61267728,18.5544919 11.3254902,16.4108984 L33.75,3.46410162 C37.4628129,1.32050808 42.0371871,1.32050808 45.75,3.46410162 Z" id="path-1"></path>
                    <linearGradient x1="59.9957192%" y1="25.5707274%" x2="94.2030537%" y2="69.8759148%" id="linearGradient-3">
                        <stop stopColor="#6178FF" offset="0%"></stop>
                        <stop stopColor="#4F67F4" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="34.2475043%" x2="100%" y2="100%" id="linearGradient-4">
                        <stop stopColor="#0AE1D9" offset="0%"></stop>
                        <stop stopColor="#00CEA7" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Group-6-Copy" transform="translate(5.000000, 9.000000)">
                        <g id="Path-2">
                            <mask id="mask-2" fill="white">
                                <use href="#path-1"></use>
                            </mask>
                            <path stroke="#EEF2FE" strokeWidth="10" strokeOpacity={this.props.transparency ? this.props.transparency : ''} d="M48.25,-0.866025404 L70.6745098,12.0807714 C75.9343281,15.1175289 79.1745098,20.7296882 79.1745098,26.8032032 L79.1745098,52.6967968 C79.1745098,58.7703118 75.9343281,64.3824711 70.6745098,67.4192286 L48.25,80.3660254 C42.9901817,83.4027829 36.5098183,83.4027829 31.25,80.3660254 L8.8254902,67.4192286 C3.56567189,64.3824711 0.3254902,58.7703118 0.3254902,52.6967968 L0.3254902,26.8032032 C0.3254902,20.7296882 3.56567189,15.1175289 8.8254902,12.0807714 L31.25,-0.866025404 C36.5098183,-3.90278292 42.9901817,-3.90278292 48.25,-0.866025404 Z"></path>
                            <path d="M45.75,3.46410162 L68.1745098,16.4108984 C71.8873227,18.5544919 74.1745098,22.5160162 74.1745098,26.8032032 L74.1745098,52.6967968 C74.1745098,56.9839838 71.8873227,60.9455081 68.1745098,63.0891016 L45.75,76.0358984 C42.0371871,78.1794919 37.4628129,78.1794919 33.75,76.0358984 L11.3254902,63.0891016 C7.61267728,60.9455081 5.3254902,56.9839838 5.3254902,52.6967968 L5.3254902,26.8032032 C5.3254902,22.5160162 7.61267728,18.5544919 11.3254902,16.4108984 L33.75,3.46410162 C37.4628129,1.32050808 42.0371871,1.32050808 45.75,3.46410162 Z" id="Mask" fill="#FFFFFF" mask="url(#mask-2)"></path>
                        </g>
                        <g id="Group" transform="translate(12.000000, 7.500000)">
                            <path d="M30.7470619,1.94009043 L53.6220619,15.1010493 C54.3198696,15.5025277 54.75,16.2462406 54.75,17.0512998 L54.75,45.9487002 C54.75,46.7537594 54.3198696,47.4974723 53.6220619,47.8989507 L31.1152064,60.8481004 C28.7996812,62.1803204 25.9503188,62.1803204 23.6347936,60.8481004 L1.12793807,47.8989507 C0.430130439,47.4974723 -2.40403549e-15,46.7537594 0,45.9487002 L0,20.4866105 C2.91909616e-16,19.2439698 1.00735931,18.2366105 2.25,18.2366105 C2.76795262,18.2366105 3.27004491,18.4153106 3.67153002,18.7425484 L23.70347,35.0699516 C24.6666888,35.8550409 26.0839727,35.7106384 26.869062,34.7474195 C27.1962999,34.3459344 27.375,33.8438421 27.375,33.3258895 L27.375,3.89034093 C27.375,2.64770024 28.3823593,1.64034093 29.625,1.64034093 C30.0188076,1.64034093 30.4057182,1.74370087 30.7470619,1.94009043 Z" id="Polygon" fill="#4B63F1"></path>
                            <path d="M27.375,31.5 L54.75,15.75 L54.75,47.25 L28.8710826,62.1392402 C27.9448725,62.6721282 26.8051275,62.6721282 25.8789174,62.1392402 L0,47.25 L0,47.25 L0,19.6403409 C-5.96268804e-16,18.3977002 1.00735931,17.3903409 2.25,17.3903409 C2.64380759,17.3903409 3.03071818,17.4937009 3.37206193,17.6900904 L27.375,31.5 L27.375,31.5 Z" id="Polygon" fill="url(#linearGradient-3)"></path>
                            <path d="M27.75,32.8101139 L27.75,61.711944 C27.75,62.1261576 27.4142136,62.461944 27,62.461944 C26.870221,62.461944 26.7426628,62.4282681 26.6297959,62.3642085 L1.13938758,47.8966794 C0.435139111,47.4969708 2.31961468e-15,46.7496595 0,45.9398861 L0,19.6141679 C-1.52179594e-16,18.3715272 1.00735931,17.3641679 2.25,17.3641679 C2.63933699,17.3641679 3.02201152,17.4651957 3.36061242,17.6573746 L26.6106124,30.8533206 C27.3148609,31.2530292 27.75,32.0003405 27.75,32.8101139 Z" id="Polygon" fill="url(#linearGradient-4)"></path>
                        </g>
                    </g>
                </g>
            </svg >
        );
    }
}

export default MyYesodLogo;