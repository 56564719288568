import {createRequest, makeRequest} from "../middleWareToken";
import {basePath, getAllUsers, register} from "../paths";

export async function getUsers(groupId) {
  return await makeRequest( `${basePath}${getAllUsers}?group_id=${groupId}`, 'GET' );
}

export async function createUser(data){
  return await createRequest(`${basePath}${register}`, 'post', data)
}
