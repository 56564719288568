import React, { Component, useEffect, useState } from 'react';
import arrow_down from '../../../ui/images/arrow_down.svg';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, Input } from 'reactstrap';
import { connect } from "react-redux";

import './usersDropDown.css'
import { ADD_ROLE_ITEM_AT_USER_DROPDOWN, PERFORMER, SELECT_LEADER, SELECT_PERFORMER } from "../../../ui/strings";
import { isInt } from "./auxiliaryFunctions";
import { createUser } from "../../apis/restApi/userApi";
import { setGroupUsers } from "../../store/dashBoardStore/dashboardActions";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import trash from '../../../ui/images/delete.svg';
import trash_white from '../../../ui/images/delete_white.svg';
import user_icon from '../../../ui/images/user_icon.svg';
import { IconMouseOverPopover } from "./popover/popover";

const PerformerPopover = (props) => {
  let icon = <img src={user_icon}
    height='30px'
    width='30px'
    style={{ cursor: 'pointer' }}
  />
  return <IconMouseOverPopover
    onDelete={props.onDelete}
    id={props.id}
    title={props.title || <span>{props.leading && SELECT_LEADER}<span>{icon}</span></span>}
    popoverData={props.leading ? SELECT_LEADER : SELECT_PERFORMER}
  />
}
class UsersDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.value,
      options: this.props.users,
      isCreateUserForm: false,
      onCreateUser: false,
      isLeading: this.props.field === 'leading_id',
      isAddRoleOpen: false,
      addRole: false,
      dropdownOpen: false,
      fn: '',
      ln: '',
      title: 'guest',
      email: '',
      phone: ''
    }
  }

  toggle = () => this.setState({ 'dropdownOpen': !this.state.dropdownOpen, 'isCreateUserForm': false });

  validate() {
    let validate = true
    if (!this.state.fn.length > 0) {
      alert('שם הינו שדה חובה!')
      validate = false
    }
    if (!this.state.ln.length > 0) {
      alert('שם משפחה הינו שדה חובה!')
      validate = false
    }
    if (!this.state.email.length > 0) {
      alert('דוא"ל הינו שדה חובה!')
      validate = false
    } else {
      if (!this.validateEmail(this.state.email)) {
        alert(`  הכתובת ${this.state.email} אינה תקפה. `)
        validate = false
      }
    }
    return validate
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  tempPerformer() {
    if (!this.validate()) {
      return
    }
    let groupId = localStorage.getItem('groupId') !== ('null' || null) ? localStorage.getItem('groupId') : '2'
    let data = {
      "first_name": this.state.fn,
      "last_name": this.state.ln,
      "title": this.state.title,
      "email": this.state.email,
      "password": "1234a",
      "confirm_password": "1234a",
      "agree_the_conditions_of_use": true,
      "group_id": groupId,
      "phone": this.state.phone
    }
    createUser(data)
      .then(res => {
        this.setState({ 'isCreateUserForm': false })
        let performer = res.data.data
        let addUser = [...this.props.users]
        addUser.push(performer)
        this.setState({ 'options': addUser })
        this.pressed(performer)
        this.toggle()
        // setGroupUsers(addUser)
      })
      .catch(e => {
        let error = e.response.data.errors[0]
        alert(`${error.field} ${error.message}`)
      })
  }

  createUserForm() {
    if (!this.state.isCreateUserForm) {
      return (<div className="dropdown-item" style={{ backgroundColor: this.state.onCreateUser ? `#e9ecef` : '' }}
        onMouseLeave={() => this.setState({ "onCreateUser": false })}
        onMouseMove={() => this.setState({ "onCreateUser": true })}>
        <button style={{ height: "100%", width: "100%", textAlign: "start" }} onClick={() => {
          this.setState({ 'isCreateUserForm': true })
        }}>הוסף אורח
        </button>
        <DropdownItem divider />
      </div>)
    }
    return (
      <div>
        <div className="form-group col-md-12">
          <div className="form-row pt-4">

            <div className="form-group col-md-6">
              <label htmlFor="p_fn">שם</label>
              <input
                type="text" className="form-control" id="p_fn"
                onChange={(e) => this.setState({ 'fn': e.target.value })}
              />
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="p_ln">שם משפחה</label>
              <input type="text" className="form-control" id="p_ln"
                onChange={(e) => this.setState({ 'ln': e.target.value })}
              />
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="p_email">דואר אלקטרוני</label>
              <input type="email" className="form-control" id="p_email"
                onChange={(e) => this.setState({ 'email': e.target.value })}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="p_role">תפקיד</label>
              <input type="text" className="form-control" id="p_role"
                onChange={(e) => this.setState({ 'title': e.target.value })}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <label htmlFor="p_tel">טלפון</label>
              <PhoneInput
                className="form-control"
                defaultCountry='IL'
                useNationalFormatForDefaultCountryValue={true}
                id='p_tel'
                country="US"
                value={this.state.phone}
                onChange={(e) => this.setState({ "phone": e })} />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12">
              <button style={{ color: 'white' }} id='p_btn' className="btn btn-primary ml-2"
                onClick={() => this.tempPerformer()}>צור
              </button>
              <button style={{ color: 'white' }} id='p_btn' className="btn btn-secondary"
                onClick={() => this.setState({ 'isCreateUserForm': false })}>סגור
              </button>
            </div>
          </div>
          <DropdownItem divider />
        </div>
      </div>)
  }

  addRole() {
    return (
      <div className="dropdown-item mt-2" style={{
        backgroundColor: this.state.addRole && !this.state.isAddRoleOpen ? `#e9ecef` : 'white',
        height: `${this.state.isAddRoleOpen ? 'auto' : '60px'}`
      }}
        onMouseLeave={() => this.setState({ "addRole": false })}
        onMouseMove={() => this.setState({ "addRole": true })}>
        <button style={{ height: "100%", width: "100%", textAlign: "start" }} onClick={() => {
          this.setState({ 'isAddRoleOpen': true })
        }}>{ADD_ROLE_ITEM_AT_USER_DROPDOWN}
        </button>
        {this.state.isAddRoleOpen && <div>
          <div className="form-row pt-4">
            <div className="form-group" style={{ width: '100%' }}>
              {/*<label htmlFor="p_fn">שם</label>*/}
              <input
                type="text" className="form-control" id="addRole"
              // onChange={(e) => this.setState({'fn': e.target.value})}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-12">
              <button style={{ color: 'white' }} id='p_btn' className="btn btn-primary ml-2"
                onClick={() => {
                  this.props.onChange(this.props.parent_id, this.props.id, this.props.field, null)
                  this.props.setRole(this.props.parentIndex, this.props.index, document.getElementById('addRole').value)
                  this.setState({ 'isAddRoleOpen': false })
                  this.toggle()
                }}>צור
              </button>
              <button style={{ color: 'white' }} id='p_btn' className="btn btn-secondary"
                onClick={(e) => this.setState({ 'isAddRoleOpen': false })}>סגור
              </button>
            </div>
          </div>
        </div>}
        <DropdownItem divider />
      </div>)
  }


  //  getSelected(){
  //   let selected = this.state.options.find(u => u.id === this.props.value ? u : null)
  //   // console.log(selected)
  //   return selected
  // }

  optionsCreator() {
    if (this.state.options) {
      return this.state.options.map((option, i) => (
        <span key={i}>
          {/*{i === 0 && this.props.field === 'performer' && this.createUserForm()}*/}
          {i === 0 && this.props.field === 'performer' && this.addRole()}
          <DropdownItem
            className="my-1"
            onClick={() => this.pressed(option)}
          >
            {/*<img src='assets/voicemail-2.svg' alt="" className="" />*/}
            <div className='option-title'>{`${option.first_name} ${option.last_name}`}</div>
            <div className='option-description'>{`${option.title}`}</div>
          </DropdownItem>
        </span>
      ))
    }
    return []
  }

  pressed(e) {
    // this.setState({'selected': e})
    switch (this.props.field) {
      case 'leading_id':
        this.props.onChange(this.props.parent_id, this.props.id, this.props.field, e)
        break
      case 'performer':
        this.props.onChange(this.props.parent_id, this.props.id, this.props.field, e.id)
        break
    }
  }

  getPerformerFullName(val) {
    let performer = this.state.options.find(u => u.id === val)
    let fullName = performer ? `${performer.first_name} ${performer.last_name}` : ''
    return fullName
  }

  deleteUser = () => {
    return <button className="ml-auto pl-2 pr-0" onClick={(event) => {
      event.stopPropagation();
      this.state.isLeading
        ? this.props.onChange(null, null, this.props.field, { id: null, first_name: null, last_name: null })
        : this.props.onChange(this.props.parent_id, this.props.id, this.props.field, null)
    }}><img src={trash_white} /></button>
  }

  deleteRole = () => {
    return <button className="ml-auto pl-2 pr-0" onClick={(event) => {
      event.stopPropagation();
      this.props.setRole(this.props.parentIndex, this.props.index, null)
    }}><img src={trash_white} /></button>
  }

  render() {
    return (
      <div>
        {this.props.editMode ?
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} direction={"down"}>
            <DropdownToggle color={'white'} className=''>
              <span className='d-flex'>
                <span className='mr-0' style={{ color: '#6178FF' }}>{isInt(this.props.value)
                  ? <PerformerPopover
                    title={this.getPerformerFullName(this.props.value)}
                    onDelete={this.deleteUser()}
                    id={this.state.isLeading ? 'leader' : `per_${this.props.id}`}
                    leading={this.state.isLeading} />
                  : this.state.selected && this.state.selected.id
                    ? this.getPerformerFullName(this.state.selected.id)
                    : <PerformerPopover
                      title={this.props.role}
                      onDelete={this.props.role && this.deleteRole()}
                      id={this.state.isLeading ? 'leader' : `per_${this.props.id}`}
                      leading={this.state.isLeading} />}
                </span>
                {/*<img src={arrow_down} alt="" className="ml-auto p-2"/>*/}
              </span>
            </DropdownToggle>
            <DropdownMenu modifiers={{
              setMaxHeight: {
                enabled: true,
                order: 890,
                fn: (data) => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: '340px',
                      minWidth: '325px'
                    },
                  };
                },
              },
            }}
              className='box-shadow' style={{ textAlign: 'start' }}>
              {this.optionsCreator()}
            </DropdownMenu>
          </Dropdown>
          : this.props.value ? this.getPerformerFullName(this.props.value) : this.props.role !== null && this.props.role}
      </div>
    );
  }
};
const mapStateToProps = (state) => {

  return {
    users: state.dashboard.users
  }
}

export default connect(
  mapStateToProps,
  {
    setGroupUsers
  }
)(UsersDropDown);

