import React, { useEffect } from 'react';
import { updateCurrentTasksArray } from '../../store/dashBoardStore/dashboardActions';
import TaskCard from './ui/taskCard';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { menuItems } from "../../../ui/enums/sidebar"
import './foldersBuilder.css'
import { TASKSּ, USER_AREA } from '../../../ui/strings';



const TesksBuilder = ({ myGroup, currentFolder, filteredTasks, setFilteredTasks }) => {

  const tasks = useSelector(state => state.dashboard.tasks)
  const currentTasks = useSelector(state => state.dashboard.currentTasks)

  const dispatch = useDispatch()

  useEffect(() => {
    if (tasks.length) {
      dispatch(updateCurrentTasksArray(filterTasksByRegion()))
    }
  }, [myGroup])

  useEffect(() => {
    if (tasks.length) {
      setFilteredTasks(getFilteredTasks())
    }
  }, [currentTasks, currentFolder, tasks])

  const filterTasksByRegion = () => {
    let regionTasks = [];
    if (myGroup.name === USER_AREA) {
      regionTasks = tasks.filter(task => task.status_name === 'draft')
    }
    else {
      regionTasks = tasks.filter(task => task.group_id === myGroup.id && task.status_name !== "draft")
      if (myGroup.areaId) {
        regionTasks = regionTasks.filter(task => task.area_id === myGroup.areaId && task.status_name !== "draft")
      }
    }
    return regionTasks
  }

  const getFilteredTasks = () => {

    const getTaskByFolderId = (folders) => {
      const folder = currentFolder
        ? folders.find(fold => fold.folder === currentFolder.id)
        : folders.find(fold => fold.folder === null)
      return currentFolder === null
        ? (folder && folder.folder === null)
        : (folder && folder.folder === currentFolder.id)
    }

    const correctTasks = currentTasks.filter(task => {
      if (
        (task.status_name === "draft" && parseInt(localStorage.getItem("userId")) === task.created_by_id)
        || task.status_name === "publish"
      ) {
        return getTaskByFolderId(task.folders)
      }
      else {
        const user = task.shared.find(shared => shared.id === parseInt(localStorage.getItem("userId")))
        return getTaskByFolderId(user.folders)
      }
    }).sort(compareOrder)
    return correctTasks
  }

  const extractTheValueOfOrder = (folders) => {
    const folder = currentFolder
      ? folders.find(fold => fold.folder === currentFolder.id)
      : folders.find(fold => fold.folder === null)
    return folder ? folder.order : 0
  }

  const getOrder = (task) => {
    if (
      (task.status_name === "draft" && parseInt(localStorage.getItem("userId")) === task.created_by_id)
      || task.status_name === "publish"
    ) {
      return extractTheValueOfOrder(task.folders)
    }
    else {
      const user = task.shared.find(shared => shared.id === parseInt(localStorage.getItem("userId")))
      return extractTheValueOfOrder(user.folders)
    }
  }

  const compareOrder = (a, b) => {
    let orderA = getOrder(a)
    let orderB = getOrder(b)
    return orderA - orderB
  }


  const buildTasks = () => {
    if (filteredTasks && filteredTasks.length > 0) {

      const getAroundedTasks = (index) => {
        return {
          before: filteredTasks[index - 1] ? filteredTasks[index - 1] : null,
          after: filteredTasks[index + 1] ? filteredTasks[index + 1] : null,
        }
      }
      let tasks = filteredTasks.map((task, i) => {
        return (
          <Link key={(task.id * (getOrder(task) + 1)) * ((i + 1) * 17 )} to={`/dashboard/task/${task.id}?folder=${currentFolder? currentFolder.id : null}`}>
            <TaskCard
              index={i}
              task={task}
              folder={currentFolder}
              myGroup={myGroup}
              aroundedTasks={getAroundedTasks(i)}
            />
          </Link>
        );
      });
      return tasks;
    }
  }


  return (
    <div className='tasks-area'>
      {filteredTasks.length > 0 && <div className="folders-header">{TASKSּ}</div>}
      {buildTasks()}
    </div>
  )
}

export default TesksBuilder

//   isTagsFiltered() {
//     if (this.props.currentTags && this.props.currentTags.length > 1) {
//       return true
//     } else if (this.props.currentTags && this.props.currentTags.length === 1) {
//       if (this.props.currentTags[0] === 'הכל') {
//         return false
//       } else {
//         return true
//       }
//     } else {
//       return false
//     }
//   }


//   // filtering by selected tags
//   isTaskTagSelected(task) {

//     if (task.tags && task.tags.length > 0) {
//       let exist = [];
//       for (let i = 0; i < task.tags.length; i++) {
//         exist = this.props.currentTags.filter(e => e == task.tags[i].name)
//         if (exist.length > 0) return true
//       }
//     } else return false
//   }


//   filterByTags(tasksArray) {
//     if (this.isTagsFiltered()) {
//       tasksArray = tasksArray.filter((e) => {
//         if (this.isTaskTagSelected(e)) {
//           return e
//         }
//       })
//       return tasksArray
//     } else return tasksArray
//   }