import React, { Component } from 'react';
import './primaryBtn.css';

class PrimaryBtn extends Component {
    render() {
        return (
            <div style={{ marginTop: this.props.marginTop}}>
                <button
                 onClick={this.props.primaryBtnOnClick}
                 className='yesod-primary-btn'
                 style={{ width: this.props.width, height: this.props.height }}
                 >
                    {this.props.primaryBtnText}
                </button>
            </div>
        );
    }
}

export default PrimaryBtn;
