import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getInputChange, updateSearchResults} from '../../components/store/dashBoardStore/dashboardActions'
import {getSearchInput} from '../../components/apis/searchGet'
import {rebuildSearch} from '../../components/apis/searchRebuild'
import tempLogo from '../images/input_search_logo.svg'
import AllFindInSearchInput from './allFindInSearchInput';
import './searchInput.css';
import {
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_1,
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_2,
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_3,
  SEARCH_TASK
} from "../strings";


class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      countChar: 0,
      searchText: "",
      isRebuildElasticsearch: false
    }
  }

  componentDidMount() {
    this.moveFocus();
  }

  moveFocus() {
    const node = this.myRef.current;
    node.addEventListener('keydown', function (e) {
      const active = document.activeElement;
      if (e.keyCode === 40 && active.nextSibling) {
        active.nextSibling.focus();
      }
      if (e.keyCode === 38 && active.previousSibling) {
        active.previousSibling.focus();
      }
    });
  }

  async getValueFromInput(e) {
    if (e.length > 1) {
      this.props.getInputChange(e)
      let response = await getSearchInput(e);
      this.updateReceivedResults(response);
      if ((!response || response.data.length === 0) && !this.state.isRebuildElasticsearch) {
        // window.location.reload();
        this.setState({isRebuildElasticsearch: true})
        await rebuildSearch()
          .then(() => {
            this.setState({isRebuildElasticsearch: false})
          })
          .catch(() => {
            this.setState({isRebuildElasticsearch: false})
          })

      } else {
      }
    } else if (e.length === 0) {
      this.cleanInput()
    }
  }

  cleanInput(e) {
    // if (e && e.target && e.target.value) {
    //     e.target.value = ""
    // }
    this.props.getInputChange("")
    setTimeout(() => {
      this.props.updateSearchResults([])
    }, 300); // maybe its a foolish way but it works
  }

  updateReceivedResults(response) {
    if (response && response.data && response.data.data && response.data.data.length > 0) {
      this.props.updateSearchResults(response.data.data)
    } else {
      this.props.updateSearchResults([])
    }
  }

  render() {

    let inputStyle = this.props.findSearch ? {borderBottom: '2px solid rgb(97, 120, 255)'} : {};
    return (
      <div>
        <div className='search-field' style={{display: 'flex'}}>
          <div className="div-search-input-logo" style={inputStyle}>
            <img src={tempLogo}/>
          </div>
          <input
            ref={this.myRef}
            className='search-input'
            style={inputStyle}
            type="text"
            placeholder={SEARCH_TASK}
            onChange={(e) => this.getValueFromInput(e.target.value)}
            onBlur={(e) => this.cleanInput(e)}
          />
        </div>
        {this.props.searchResults.length > 0 ? <AllFindInSearchInput/> : <React.Fragment/>}
      </div>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    inputSearch: state.dashboard.inputSearch,
    searchResults: state.dashboard.searchResults
  }

}


export default connect(
  mapStateToProps,
  {getInputChange, updateSearchResults}
)(SearchInput);
