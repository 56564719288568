import React, { Component } from 'react';
import './CreateTaskPage.css';
import delete_x  from '../../ui/images/delete_x.svg';

class TaskLink extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        let e = this.props.e;
        return (
            <div className='class-task-link'>
                <a href={e.url} style={{ textDecoration: 'underline', marginLeft: '13px' }} target="_blank">{e.text}</a>
                <button onClick={() => this.props.handleDeleteLinkOnClick(this.props.e, this.props.index)}>
                    <img src={delete_x} alt=""/>
                </button>
            </div>
        );
    }
}

export default TaskLink;