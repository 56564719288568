import React from "react"
import { useDrop } from "react-dnd"
import { useDispatch } from "react-redux";
import { updateFolder} from "../../../apis/foldersHandling";
import { updateTasksParentFolder } from "../../../apis/taskHandling";
import { updateCurrentTaskInArray, updateFolderInAllFoldersArr } from "../../../store/dashBoardStore/dashboardActions";
import { dndTypes } from "./dndTypes";

const PatnItem = ({folder, currentFolder, className, onClick, body}) =>{

  const dispatch = useDispatch()

  const [{isOver}, drop] = useDrop(()=>({
    accept: [dndTypes.TASK, dndTypes.FOLDER],
    drop: (item, monitor)=>{
      if(monitor.getItemType() == dndTypes.FOLDER && (folder ? folder.id != item.id: true)){
        updateFolderParent(item)
      }
      if(monitor.getItemType() === dndTypes.TASK && (folder ? folder.id != item.folderId: true)){
        updateTaskParent(item)
      }
    },
    collect: (monitor)=>({
      isOver: monitor.isOver(),
      item: monitor.getItem(),
    })
  }),[])

  const updateFolderParent = async (item) =>{
    const data = await updateFolder(item.id, {parent: folder ? folder.id : null, update_parent:true})
    data && data.data && data.data.data && dispatch(updateFolderInAllFoldersArr(data.data.data))
  }

  const updateTaskParent = async (item) => {
    const details = { 
      folder: folder? folder.id : null, 
      update_folder: true,
      old_folder: currentFolder ? currentFolder.id : null
    }
    let data = await updateTasksParentFolder(item.id, details)
    data = data && data.data && data.data.data ? data.data.data : null;
    data && dispatch(updateCurrentTaskInArray({data:data, id: data.id}))
  }

  return (
    <span 
      ref={drop}
      className = {className}
      onClick = {onClick} 
      style = {{backgroundColor : isOver ? "#E7EBFF" : ""}} 
    >
      {body}
    </span>
  )
}

export default PatnItem