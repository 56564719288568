import React from 'react';
import '../../../ui/userName/userNameLogo.css';
import {Redirect} from "react-router-dom";


const SidebarHeaderDetails = (props) => {
if(props.userFirstName && props.userLastName){
  return (
        <div className='bottom-header'>
          <div className='username-logo' >
            <div className='username-logo-text text-uppercase'>{`${props.userFirstName[0]}${props.userLastName[0]}`}</div>
          </div>
          <div className='username-text'>{props.userFirstName + ' ' + props.userLastName}</div>
          <div className='user-role'>{props.role}</div>
        </div>
  );
} else {
  return <Redirect to='/login'></Redirect>
}
}

export default SidebarHeaderDetails;
