import axios from 'axios';
import { register, basePath } from './paths';
import { userLogin } from './login';
import { AxiosUtils } from '../../utils/axios-utils';
import { NOT_UNIQUE_EMAIL, GENERAL_INTERNAL_ERROR } from '../../ui/strings';

export async function registerNewUser(data) {
  let answer;
  await axios({
    method: "POST",
    data: data,
    url: basePath + register,
  })
    // make login 
    .then(async function (response) {
      await userLogin({ email: data.email, password: data.password }).then((res) => {

        if (res) {
          answer = true
        } else {
          answer = false
        }

      })

    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 400)
          alert(NOT_UNIQUE_EMAIL);
        else
          alert(GENERAL_INTERNAL_ERROR)
      }
      return Promise.reject(error);

      answer = false

    });
}