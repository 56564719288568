import React, { Component } from 'react';
import { updateSearchResults } from '../../components/store/dashBoardStore/dashboardActions'
import purple_icon_to_search from '../../ui/images/purple_icon_to_search.svg';
import blue_icon_to_search from '../../ui/images/blue_icon_to_search.svg';
import green_icon_to_search from '../../ui/images/green_icon_to_search.svg';
import orange_icon_to_search from '../../ui/images/orange_icon_to_search.svg';
import yesod_find_logo from '../../ui/images/yesod_logo_find.svg';
import homeIconBlue from '../images/home_icon_blue.svg'
import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './findInSearchInput.css';

class FindInSearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightedLine: false
    }
  }

  handleFindOver() {
    this.setState({ highlightedLine: true });
  }
  handleFindLeave() {
    this.setState({ highlightedLine: false });
  }

  cleanSearchResults() {
    this.props.updateSearchResults([])

  }

  cleanHtml(html) {
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'em', 'strong', 'a'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    })
  }


  render() {
    let myCategory = this.props.categories.find(category => category.id === this.props.groupId)
    myCategory = myCategory ? myCategory.logo : null;
    // myCategory = this.props.allCategories.find(c => c.id === this.props.group_id);

    // console.log('findSearchInput myCategory, ', myCategory);

    let findObj = this.state.highlightedLine ? { backgroundColor: '#F8F9FF' } : { backgroundColor: '#FFFFFF' };
    let folderLogo;
    if (this.props.category_name === 'שוטף') {
      folderLogo = blue_icon_to_search;
    }
    if (this.props.category_name === 'תכנון השנה הבאה') {
      folderLogo = purple_icon_to_search;
    }
    if (this.props.category_name === 'רב שנתי/אסטרטגיה') {
      folderLogo = green_icon_to_search;
    }
    if (this.props.category_name === 'חרום') {
      folderLogo = orange_icon_to_search;
    }
    return (

      <Link to={`/dashboard/task/${this.props.taskId}`}>
        <button
          onClick={() => this.cleanSearchResults()}
          onMouseOver={() => { this.handleFindOver() }}
          onMouseLeave={() => { this.handleFindLeave() }}
          className='div-line-find'
          style={findObj}
          tabIndex={this.props.tabIndex}

        >
          <img src={folderLogo} alt="" />
          <img className='yesod-find-logo' src={this.props.status === "publish" ? myCategory : homeIconBlue} alt="" height='20px' width='20px' />
          <span className='text-find-header' dangerouslySetInnerHTML={{ __html: this.cleanHtml(this.props.findHeader) }}></span>
        </button>
      </Link>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inputSearch: state.dashboard.inputSearch,
    searchResults: state.dashboard.searchResults,
    tasks: state.dashboard.tasks,
    categories: state.dashboard.categories
  }
}


export default connect(
  mapStateToProps,
  { updateSearchResults }
)(FindInSearchInput);
