import React, { forwardRef, useEffect, useState } from "react"
import { 
  SHARE_TASK_WITH_YOUR_TEAMMATAS, 
  ANUONE_WEO_SHARES_THE_TASK_WILL_SEE_IT_IN_USER_AREA, 
  CANCEL_TEXT_BTN, 
  GROUP_USERS, 
  SHARED_USERS,
  TYPE_A_NAME,
  UPDATE
} from "../../../../ui/strings"
import { putSharedUsers } from "../../../apis/sharingUsers"
import GroupUserRow from "./sharing-groupUserRow"
import RectangleButton from "../rectangleButton"
import "./sharingModal.css"
import UserLogo from "./sharing-userLogo"

// const SharingModal = forwardRef(({
const SharingModal = ({
  id, 
  alreadyShared,
  currentTask, 
  setCurrentTask, 
  openSharingModal, 
  setOpenSharingModal,
  users}) => {

  const [groupUsers, setGroupUsers] = useState(users);
  const [filteredgroupUsers, setFilteredGroupUsers] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([])
  const [valueSearch, setValueSearch] = useState("")
  const [isOneRemoveLogoActive, setIsOneRemoveLogoActive] = useState(false)
  

  useEffect(()=>{
    populateAlreadySharedUsers() 
    removeMeFromGroupUsers()
  },[])

  const usersFilter = (users, value)=>{
    const filteredUsers = users.filter(user => {
      const userName = user.first_name + " " + user.last_name;
      return userName.startsWith(value)
    })
    return filteredUsers
  }
  
  const filterUsersByInputValue = (value)=>{
    const filteredUsers = usersFilter(groupUsers, value);
    setFilteredGroupUsers(filteredUsers)
  }

  const getCorrectArr = () =>{
    if(valueSearch){
      return filteredgroupUsers
    }
    else{
      return groupUsers
    }
  }

  const populateUsersRows = (value) => {
    const getUserRows = (users)=>{
      return users.map((user, i) => {
        return ( 
          <GroupUserRow 
            key={i}
            user = {user}
            onClick={() => {
              addToArr(setSharedUsers, user)
              removeFromArr(setGroupUsers, user)
              filteredgroupUsers.length && removeFromArr(setFilteredGroupUsers, user)
              filteredgroupUsers.length === 1 && setValueSearch("")
            }}
          />
        )
      })
    }
    if(valueSearch){
      return getUserRows(filteredgroupUsers)
    }
    return getUserRows(groupUsers)
  }

  const populateSharedUsers = () => {
    return sharedUsers.map((shared, i) => {
      return (
        <UserLogo 
          key={i} 
          user = {shared}
          onClick = {() => {
                  addToArr(setGroupUsers, shared)
                  removeFromArr(setSharedUsers, shared)
                }} 
          usegeFor ={"sharedUser"}
          isOneRemoveLogoActive = {isOneRemoveLogoActive}
          setIsOneRemoveLogoActive = {setIsOneRemoveLogoActive}
        />
      ) 
    })
  }

  const populateAlreadySharedUsers = ()=>{
    alreadyShared.length && alreadyShared.forEach(sharedUser => {
      let user = groupUsers.find(user => user.id == sharedUser.id)
      if(user){
        addToArr(setSharedUsers, user)
        removeFromArr(setGroupUsers, user)
      }
    });
  }

  const removeMeFromGroupUsers = ()=>{
    removeFromArr(setGroupUsers, groupUsers.find(user => user.id == localStorage.getItem("userId")))
  }

  const addToArr = (setArr, obj) => {
    setArr(arr => [...arr, obj])
  }

  const removeFromArr = (setArr, obj) => {
    setArr(arr => {
      let copyArr = [...arr]
      let index = copyArr.findIndex(item => item.id === obj.id)
      copyArr.splice(index, 1)
      return copyArr
    })
  }

  const sharingUsersHandler = async()=>{
    const addUsersToShare = []
    const removeSharedUsers = []
    sharedUsers.forEach(user => {
      !alreadyShared.find(shUser => shUser.id == user.id) && addUsersToShare.push(user.id)
    })
    groupUsers.forEach(user => {
      alreadyShared.find(shUser => shUser.id == user.id) && removeSharedUsers.push(user.id)
    })
    const res = await putSharedUsers(id, addUsersToShare, removeSharedUsers)
    setCurrentTask({...currentTask, "shared": res.data.data.shared})
  }

  return (
    <div 
      // ref = {ref} 
      className="shareContainer">
      <div className="sharing-header">
        <span className="wrap-header-icon"><span className="header-icon"></span></span>
        {SHARE_TASK_WITH_YOUR_TEAMMATAS}</div>
      <div className="sub-header">{ANUONE_WEO_SHARES_THE_TASK_WILL_SEE_IT_IN_USER_AREA}</div>
      <div className="wrap-input">
        <span className="search-icon"/>
        <input 
          type='search' 
          placeholder={TYPE_A_NAME}
          value = {valueSearch}
          onChange = {(e)=>{
            filterUsersByInputValue(e.target.value)
            setValueSearch(e.target.value)
          }}
          />
      </div>
      {sharedUsers.length > 0 && 
        <div className="users-header">{SHARED_USERS + " (" + sharedUsers.length + ")"}</div>
      }
      <div className="shared-user-wrap">{populateSharedUsers()}</div>
      {getCorrectArr().length > 0 && 
        <div className="users-header">{GROUP_USERS + " (" + getCorrectArr().length + ")"}</div>}
      <div className="group-user-wrap">
        {populateUsersRows(valueSearch)}
      </div>
      <div className="handler-button">
        <RectangleButton
          style = {{
            width: "110px",
            background: "#E7EBFF",
            color: "#6178FF",
          }}
          text={CANCEL_TEXT_BTN}
          icon={" "}
          onClick={()=>{setOpenSharingModal(!openSharingModal)}}
          isAllowed={true}
          isEdit={true}
        />
        <RectangleButton
          style = {{width: "110px"}}
          className = {"users-icon"}
          text={UPDATE}
          icon={" "}
          onClick={()=>{
            sharingUsersHandler() 
            setOpenSharingModal(!openSharingModal)
          }}
          isAllowed={true}
        />
      </div>
    </div>
  )
// })
}
export default SharingModal