import React from 'react';
import done from '../../../ui/images/done.svg'
import check_mark_grey from '../../../ui/images/check_mark_blue.svg'
import calendar from '../../../ui/images/calendar.svg'
import send_plane_fill from '../../../ui/images/send_plane_fill.svg'
import {CHOOSE_A_DATE} from "../../../ui/strings";
import {IconMouseOverPopover} from "./popover/popover";

export const StatusIcon = (props) => {
  let icon = <img src={props.done? done: check_mark_grey}
                  height='20px'
                  width='20px'
                  style={{cursor: 'pointer'}}
                  onClick={()=>props.onClicked(props.object, props.index)}/>
  return  <IconMouseOverPopover
    id={props.id}
    title={icon}
    popoverData={props.done? 'ביטול': 'סמנ/י כבוצע'}
  />
}

export const DatePickerPopover = (props) => {
  let icon = <img src={calendar}
                  height='20px'
                  width='20px'
                  style={{cursor: 'pointer'}}
                  />
  return  <IconMouseOverPopover
    onDelete={props.onDelete}
    id={props.id}
    title={props.title || icon}
    popoverData={CHOOSE_A_DATE}
  />
}


export const SendMessage = () => {
  return <div className='d-flex justify-content-center'>
    <span className='ml-2'>
      <img src={send_plane_fill} height='20px' width='20px'/>
    </span>
    <span>{'שלח'}</span>
  </div>
}

export const UserIcon = (props) => {
  return <div className='d-flex justify-content-center'>
    <span className='ml-2'>
      <div className='userIcon'>{`${props.firstName[0]}${props.lastName[0]}`}</div>
    </span>
    <span>{`${props.firstName} ${props.lastName}`}</span>
  </div>
}

export const DateIcon = (props) => {
  return <div className='d-flex justify-content-center'>
    <span className='mr-2'>
      <img src={calendar} height='20px' width='20px'/>
    </span>
    <span>{props.date}</span>
  </div>
}
