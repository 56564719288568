import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default class RechEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      // initialValue: this.props.initialValue ? this.props.initialValue : this.props.textToEdit,
    }
    this.counter = 0
  }

  handleEditorChange = (content, e) => {
    // if (this.props.onChange) {
    //   this.props.onChange(e.target.getContent())
    // }
    if (this.props.onChange) {
      this.props.onChange(content)
    }
  }

  onKeyDown = (e) => {
    if (this.counter == 0 && !this.props.toEdit) {
      e.target.innerHTML = null
      this.counter = 1
    }
  }

  render() {
    
    return (
      <Editor
        className="RechEditor-ProcessLine"
        initialValue={this.props.initialValue ? this.props.initialValue : this.props.textToEdit}
        init={{
          height: this.props.height,
          inline: this.props.inline,
          menubar: false,
          plugins: "directionality",
          directionality: "rtl",
          toolbar: this.props.toolbar,
          toolbar_sticky: true,
          // selector: 'textarea'
          // toolbar: this.props.showToolbar ? this.props.toolbar : false
        }}
        // value={this.props.newLine || this.props.isNewChild ? this.props.value : undefined}
        value={this.props.value}
        onKeyDown={(e) => { this.onKeyDown(e) }}
        onEditorChange={(content, e) => this.handleEditorChange(content, e)}
      />
    );
  }
}