// export const basePath = process.env.REACT_APP_API_URL + '/api/'//== 'production'? 'https://yesod.ravtech.co.il/api/' : 'https://yesod-test.ravtech.co.il/api/' ;
// export const basePathUrl = process.env.REACT_APP_API_URL// == 'production' ? 'https://yesod.ravtech.co.il/' : 'https://yesod-test.ravtech.co.il/' || "http://localhost:3000/";
// export const basePath = 'http://localhost:8005/api/';

// export const basePathUrl = 'http://localhost:8000/';
// export const basePath = 'http://localhost:8000/api/';

export const basePathUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ?':8000/api/':'/api/'}`;
export const basePath = `${window.location.protocol}//${window.location.hostname}${window.location.port.length > 0 ?':8000/api/':'/api/'}`;
export const metabaseHost = `${window.location.protocol}//${window.location.hostname}:4000`;
// export const basePathUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port?':8000/api/':'/api/'}`;
// export const basePath = `${window.location.href}${window.location.port === ""?'8000/api/':'/api/'}`;

// export const basePathUrl = 'https://yesod-test.ravtech.co.il/';
// export const basePath = 'https://yesod-test.ravtech.co.il/api/';

export const amazon = `https://yessod.s3-eu-central-1.amazonaws.com`;
export const register = 'users';
export const login = 'login';
export const refresh = 'refresh';
export const folders = 'folders';
export const tasks = 'tasks';
export const users = 'users';
export const groupsAndTags = 'groups_and_tags';
export const getSearch = 'search/tasks/?search_multi_match=';
export const specificTask = 'tasks/';
export const getForumMessage = 'forum/?task_id=';
export const sendForumMessage = 'forum';
export const getAllUsers = 'users';
export const putLeadingId = 'tasks/';
export const updateTasklocation = '/task_location';
export const singleFile = 'files';
export const newCategory = 'groups';
export const copy_task = '/copy_task';
export const rebuildSearchUrl = 'search/rebuild';
export const calendarEvent = 'calendar_events/';
export const comment = 'comments/';
export const children_comments = 'children_comments/';
export const aliases = 'aliases/';
export const statistics = 'statistics/';
export const groups = 'groups/';
export const history = 'history/';
export const editableTask = 'task/editable/'
export const editableTaskBy = 'task/editing_by/'
export const taskShared = 'task_shared/'


