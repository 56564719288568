import React, {useEffect, useState} from "react";
import {addAlias, getAliases, deleteAlias, editAlias} from "../../../apis/restApi/taskApi";
import {ADD_A_KEYWORD_TO_THE_SEARCH_ENGINE} from "../../../../ui/strings";

const Aliases = (props) => {

  const [aliases, setAliases] = useState(null)
  const [editAliasId, setEditAliasId] = useState(null)

  useEffect(() => {
    if (aliases === null) {
      getAliases(props.taskId)
        .then(res => {
          let response = res.data
          Array.isArray(response.data) && setAliases(response.data)
        })
        .catch(e => console.log(e))
    }
  }, [aliases])

  function sendAlias() {
    let data = {
      "word": document.getElementById("aliases").value,
      "task": props.taskId
    }
    let type = !editAliasId? addAlias(data): editAlias(editAliasId, data)
      type.then(res => {
      let response = res.data
        if (editAliasId !== null){
          let copyAliases = aliases.map(alias =>  alias.id === response.data.id?  response.data: alias)
          response.data && setAliases(copyAliases)
        } else {
          response.data && setAliases(aliases? [...aliases, res.data.data]: [response.data])
        }
      document.getElementById("aliases").value = ""
      setEditAliasId(null)
      }
    )
  }

  function removeAlias(eliasId) {
    deleteAlias(eliasId)
      .then(() => {
        getAliases(props.taskId)
          .then(res => {
            let response = res.data
            Array.isArray(response.data) ? setAliases(response.data) : setAliases([]);
          })
      })
  }

  function edit(eliasId, value) {
    document.getElementById("aliases").value = value
    setEditAliasId(eliasId)

  }

  return (<span>
    <div className='row'>
  <div className="col-8 form-group">
    <label htmlFor="aliases">{ADD_A_KEYWORD_TO_THE_SEARCH_ENGINE}</label>
    <input type="text" className="form-control" id="aliases" disabled={!props.editMode}
           placeholder="הכנס מילה"/>
  </div>
      <div className="col-2" style={{position: "relative"}}>
        <button className="rectangle-btn" style={{
          position: "absolute",
          bottom: "15px",
          right: "-10px",
          backgroundColor: !props.editMode && "gray"
        }} id='send-b' onClick={() => props.editMode && sendAlias()}>{editAliasId ? 'עדכן' : 'שלח'}</button>
   </div>
      </div>
    {aliases && <div style={{display: "grid", maxHeight: '150px', overflowY: 'scroll'}}>{aliases.map(alias => <div>
      { props.editMode && <span><button type="button" className="" onClick={() => {
        removeAlias(alias.id)
      }}>{decodeURIComponent(JSON.parse('"\u{1F5D1}"'))}
      </button>
        <button type="button" className="" onClick={() => {
        edit(alias.id, alias.word)
      }}>{decodeURIComponent(JSON.parse('"\u{270E}"'))}
        </button></span>}
      {alias.word}</div>)}</div>}
   </span>)

}
export default Aliases

