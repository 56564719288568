import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CalendarForShow from '../../../../ui/images/CalendarForShow.svg'
import '../taskPage.css'
import AddGoogleCalendarEvent from "./google";

const SelectCalendarButton = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen}  toggle={toggle} >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <img className='event-icon ' src={CalendarForShow}/>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem >
          <AddGoogleCalendarEvent
            event={props.event}
            eventDetails={props.eventDetails}
            taskId={props.taskId}
            addEventId={props.addEventId}
            processValue={props.processValue}
          />
        </DropdownItem>
        <DropdownItem disabled>Outlook</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default SelectCalendarButton;
