import {createRequest} from "../middleWareToken";
import {aliases, basePath, children_comments, comment, statistics, history} from "../paths";

export async function addComment(data){
  return await createRequest(`${basePath}${comment}`, 'post', data)
}

export async function getComment(taskId){
  return await createRequest(`${basePath}${children_comments}?task_id=${taskId}`, 'get')
}

export async function addAlias(data){
  return await createRequest(`${basePath}${aliases}`, 'post', data)
}

export async function deleteAlias(aliasId){
  return await createRequest(`${basePath}${aliases}${aliasId}`, 'delete')
}

export async function editAlias(aliasId, data){
  return await createRequest(`${basePath}${aliases}${aliasId}`, 'put',data)
}

export async function getAliases(taskId){
  return await createRequest(`${basePath}${aliases}?task_id=${taskId}`, 'get')
}

export async function getStatistics(taskId){
  return await createRequest(`${basePath}${statistics}?task_id=${taskId}`, 'get')
}

export async function getHistory(taskId){
  return await createRequest(`${basePath}${history}?task_id=${taskId}`, 'get')
}
