import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { tz } from 'moment-timezone';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';

import '../CreateTaskPage.css';
import './rectangleButton.css';
import './addToCalendar.css';
import './taskPage.css';
import './taskProcessTable/contextMenu.scss';

import PrintForShow from '../../../ui/images/PrintForShow.svg';
import ShareForShow from '../../../ui/images/ShareForShow.svg';
import CloudDownloadForShow from '../../../ui/images/ClouDownloadForShow.svg';
import CalendarForShowDisabled from '../../../ui/images/CalendarForShowDisabled.svg';
import PrintForShowDisable from '../../../ui/images/PrintForShowDisable.svg';
import ShareForShowDisabled from '../../../ui/images/ShareForShowDisabled.svg';
import CloudDownloadForShowDisabled from '../../../ui/images/ClouDownloadForShowDisabled.svg';
import Metabase from '../../../ui/images/metabase.svg';

import { calculatesTaskStatus, getUserInfo, isInt } from './auxiliaryFunctions';
import EditLogo from '../LogoEdit';
import { isUserStaff, isSuperUser, isAllowedAccess, isAdminUser, isAreaUser } from '../../apis/middleWareToken';
import { putTaskById } from '../../apis/putSpecificTask';
import { basePath } from '../../apis/paths';
import { postTask } from '../../apis/postTask';
import {
  isManagerOfTheCurrentLibraryToCopy,
  isManagerOfTheCurrentLibraryToEditEndDelete,
} from '../commonFunctions';

import {
  TEXT_COPY_TASK_BUTTON,
  EDIT,
  SAVE,
  SAVE_AS_DRAFT,
  TEXT_DELETE_TASK_1,
  EDIT_TASK,
  TEXT_TASK_ARRAY,
  TEXT_DRAFT,
  TEXT_SUCCESSFULLY_CREATED_MALE,
  TEXT_SUCCESSFULLY_CREATED_FEMALE,
  YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_TASK,
  SUCCESS_SAVED,
  TASK_TITLE_IS_REQUIRED_FIELD,
  THE_CHANGES_NOT_SAVED,
  DISCONNECTED_ALERT,
  DISCONNECTED_NEW_TASK,
  publish,
  TASK_LEADING,
  STATUS,
  SHARE,
  SHARE_TASK_WITH_YOUR_TEAMMATAS,
  ENABLE_EDITING,
  DISABLE_EDITING,
} from '../../../ui/strings';
import RectangleButton from './rectangleButton';
import Section from './callape';
import ProgressTable from './taskTable/progressTable';
import FilesAndLinks from './FilesAndLinks/filesAndLinks';
import {
  taskCreation,
  updateCreatedTask,
  updateCurrentTask,
  updateIsEdit,
} from '../../store/taskStore/taskActions';
import Description from './description/taskDescription';
import TagsItems from '../Tags';
import {
  insertNewTaskToTasksArray,
  restartTasksRequest, spinnerOn,
  updateCurrentTaskInArray,
  updateSelectedGroup,
} from '../../store/dashBoardStore/dashboardActions';
import AddToCalendar from 'react-add-to-calendar';
import Success from '../Success';
import usePrompt from './usePrompt';
import SelectCalendarButton from './calendarEvent/selectCalendarButton';
import CalendarForShow from '../../../ui/images/CalendarForShow.svg';
import { addComment, getComment } from '../../apis/restApi/taskApi';
import TaskMissionHistory from '../../containers/moreInfoZone/taskMissionHistory';
import GetChildrenComment from './adminPanel/comments';
import Aliases from './adminPanel/aliases';
import Statistics from './adminPanel/statistics';
import Process from './taskProcessTable/parentRow';
import UsersDropDown from './usersDropDown';

import { menuItems } from '../../../ui/enums/sidebar';
import UndoRedo from '../undoRedo';
import { putIsTaskEditable, getIsTaskEditable } from '../../apis/editable';
import { getEditingBy, putEditingBy } from '../../apis/editingBy';
import { data } from './../../store/dashBoardStore/initDashboardStoreData';
import SharingModal from './sharingModal/sharingModal';
import SharingPopper from './sharingModal/sharingPopper';
import { getTask } from '../../apis/taskHandling';
import { deepCopyIntoObject } from '../../../utils/deepCopy';


let getStorageTask = () => {
  return window.localStorage.getItem('tasks-offline');
};
const TaskPage = (props) => {

  const categories = useSelector(state => state.dashboard.categories);
  const tags = useSelector(state => state.dashboard.tags);
  const users = useSelector(state => state.dashboard.users);
  const task = useSelector(state => state.task.task_undo.present.currentTaskData);
  const isTaskDeleted = useSelector(state => state.dashboard.isTaskDeleted);

  const [isStaff, setIsStaff] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const [isAreauser, SetIsAreauser] = useState(null);
  const [isSuperuser, SetIsSuperuser] = useState(null);
  const [libraryManager, SetLibraryManager] = useState(null);
  const [IsAllowEditAndDelete, SetIsAllowEditAndDelete] = useState(null);

  const [currentTask, setCurrentTask] = useState(props.task);
  const [taskModeStatus, setTaskModeStatus] = useState(props.task.status_name === 'draft' && true);
  const [editMode, setEditMode] = useState(props.newTask || false);
  const [newTask, setNewTask] = useState(props.newTask);
  const [category] = useState(categories.find(c => c.id == currentTask.group_id));
  const [SaveDraft, setSaveDraft] = useState(false);
  const [show, setShow] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [onSection, setOnSection] = useState(0);
  const [saved, setSaved] = useState(false);
  const [items, setItems] = useState(getStorageTask);
  const [sentLocal, setSentLocal] = useState(false);
  const [isConnection, setIsConnection] = useState(false);
  const [doneClicked, setDoneClicked] = useState(false);
  const [processValue, setProcessValue] = useState(100);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [leftClickIndex, setLeftClickIndex] = useState(null);
  const [showAll, setShowAll] = useState(true);
  const [textSuccessSavedAlert, setTextSuccessSavedAlert] = useState('');
  const [openSharingModal, setOpenSharingModal] = useState(false)


  const dispatch = useDispatch();

  useEffect(() => {

    if (isStaff === null) {
      setIsStaff(isUserStaff());
    }
    if (isAdmin === null) {
      setIsAdmin(isAdminUser());
    }
    if (isAreauser === null) {
      SetIsAreauser(isAreaUser());
    }
    if (isSuperuser === null) {
      SetIsSuperuser(isSuperUser());
    }
    if (libraryManager === null && category) {
      isManagerOfTheCurrentLibraryToCopy(category, getUserInfo('groupId'), isStaff, isSuperuser).then(res => {
        SetLibraryManager(res);
      });
    }
    if (IsAllowEditAndDelete === null) {
      isManagerOfTheCurrentLibraryToEditEndDelete(category, getUserInfo('groupId'), isStaff, isSuperuser).then(res => {
        SetIsAllowEditAndDelete(res);
      });
    }
  }, [isStaff, isAdmin, isAreauser, isSuperuser, libraryManager, IsAllowEditAndDelete, editMode, currentTask.work_process, currentTask.tags, isTaskDeleted, category]);

  useEffect(() => {
    if (doneClicked) {
      saveChanged().then(() => {
        setDoneClicked(false);
      });

    }
  }, [doneClicked]);

  useEffect(() => {
    if (!sentLocal) {
      saveFromLocalStorage();
    }
  }, [sentLocal]);

  useEffect(() => {
    if (taskModeStatus && currentTask.status_name === 'publish') {
      saveChanged(currentTask, 'status_name', true);
    }
  }, [currentTask.status_name]);

  useEffect(() => {

    dispatch(updateCurrentTaskInArray({ data: currentTask, id: currentTask.id }));
  }, [currentTask.editable]);

  useEffect(() => {
    if (newTask) {
      currentTask.work_process.length === 0 && addProcessStep([null, currentTask.work_process.length - 1, 'down']);
      saveChanged()
    }
  }, [newTask]);

  useEffect(() => {
    if (editMode) {
      let interval = setInterval(() => {
        saveChanged(currentTask, null, false);
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [editMode, currentTask]);

  const setEditingByToNull = (e) => {
    putEditingBy(currentTask.id, null)
  }

  useEffect(() => {
    window.addEventListener('beforeunload', setEditingByToNull)
    window.addEventListener('unload', setEditingByToNull)
    return () => {
      setEditingByToNull()
      window.removeEventListener('beforeunload', setEditingByToNull)
      window.removeEventListener('unload', setEditingByToNull)
    }
  }, [])

  // const alertUser = e => {
  //   e.preventDefault()
  //   e.returnValue = ''
  // }

  async function saveFromLocalStorage() {
    setSentLocal(true);
    if (getStorageTask()) {
      let access = await isAllowedAccess();
      if (isInternetConnection()) {
        if (access) {
          const storage = JSON.parse(getStorageTask());
          try {
            await storage.map((task) => {
              putTaskById(task, task.id);
            });
            localStorage.removeItem('tasks-offline');
          } catch (e) {
            alert(e.message);
          }
        }
      }
    }
  }

  let handleOffline = (task) => {
    setItems(() => {
      let newItems;
      if (items !== (null || undefined)) {
        try {
          const storage = JSON.parse(getStorageTask());
          let newArr = storage.filter(t => t.id !== task.id);
          newItems = [...newArr, task];
        } catch (e) {
          console.log(e.message);
          newItems = [task];
        }
      } else {
        newItems = [task];
      }
      window.localStorage.setItem('tasks-offline', JSON.stringify(newItems));
      return newItems;
    });
  };

  function doneStatusColor(status) {
    if (status <= 30) {
      return decodeURIComponent(JSON.parse('"\u{1F534}"'));
    } else if (80 >= status && status >= 30) {
      return decodeURIComponent(JSON.parse('"\u{1F7E1}"'));
    } else if (status > 80) {
      return decodeURIComponent(JSON.parse('"\u{1F7E2}"'));
    }
  }

  const setDoneStatus = (status) => {
    if (status <= 30 && processValue > 30) {
      setProcessValue(30);
    } else if (80 >= status && status > 30 && (80 < processValue || processValue <= 30)) {
      setProcessValue(80);
    } else if (status > 80 && processValue <= 80) {
      setProcessValue(100);
    }
  };



  /*===========================================Components======================================================*/

  let event = {
    summary: `${currentTask.title}  ${doneStatusColor(Math.round(calculatesTaskStatus(currentTask.work_process)))}`,
    description: `${window.location.href}`,
    location: ``,
    start: {
      dateTime: new Date().toISOString(),
      timeZone: tz.guess(),
    },
    end: {
      dateTime: new Date(new Date().setHours(new Date().getHours() + 1)).toISOString(),
      timeZone: tz.guess(),
    },
    reminders: {
      useDefault: false,
      overrides: [
        // {'method': 'email', 'minutes': 24 * 60},
        // { method: 'popup', 'minutes': 10 }
      ],
    },
  };

  /* */
  function header() {
    return <div className={'sticky'}>
      <div className='rightDiv'>
        <div className='createHeader ml-4'>{editMode ? EDIT_TASK : currentTask.title}</div>
        <span style={{ display: 'contents' }}>

          {/*<WhatsAppMessage/>*/}
          {isSuperuser ?
            <SelectCalendarButton
              event={event}
              eventDetails={
                currentTask.google_calendar_events[0] !== undefined ?
                  currentTask.google_calendar_events[0] :
                  null
              }
              taskId={currentTask.id}
              addEventId={handleEventId}
              processValue={processValue}
            />
            : <img className='event-icon-disabled' src={CalendarForShowDisabled} />}
          {/*<img className='event-icon' src={CalendarForShow}/>}*/}
          {/*<img className='event-icon' src={ShareForShow}/>*/}
          {/*<img className='event-icon' src={CloudDownloadForShow}/>*/}
          {/*<img className='event-icon' src={PrintForShow}/>*/}
          <img className='event-icon-disabled' src={ShareForShowDisabled} />
          <img className='event-icon-disabled' src={CloudDownloadForShowDisabled} />
          <img className='event-icon' src={PrintForShow} onClick={() => {
            props.print(currentTask);
          }} />
          {/*<img className='event-icon' src={PrintForShow} onClick={() => setPdfOpen(!pdfOpen)}/>*/}
          {/*{isSuperuser &&<img className='event-icon' src={Metabase} onClick={()=>window.open(metabaseHost)}/>}*/}
          {/*{isSuperuser && <a className={'nav-link'} target="_blank" rel="noopener noreferrer" href={metabaseHost}>Metabase</a>}*/}


          {/* <UndoRedo/> */}
        </span>
      </div>
      {isSuperuser || isAreauser || isAdmin || isStaff ?
        <span>
          {
            !newTask && currentTask.status_name === "draft" &&
            <SharingPopper
              currentTask={currentTask}
              setCurrentTask={setCurrentTask}
              editMode={editMode}
            />
          }
          {
            editMode ?
              <RectangleButton
                style={{ backgroundColor: 'rgba(255,97,97,0.11)', color: '#FF6161' }}
                text={TEXT_DELETE_TASK_1}
                icon={''}
                onClick={(e) => {
                  props.deleteArrayOnClick(e, IsAllowEditAndDelete, currentTask);
                }
                }
                isAllowed={isSuperuser || isStaff}
                isEdit={editMode} /> : <span />
          }
          {/* <RectangleButton
            text={SHARE}
            icon={" "}
            // onClick={() => {setOpenSharingModal(!openSharingModal)}}
            onClick={() => {}}
            isAllowed={currentTask.status_name === 'draft' && !editMode}
            isEdit={editMode}
            withPopup={true}
            withPopover={true}
            textOver={SHARE_TASK_WITH_YOUR_TEAMMATAS} 
            /> */}
          {
            !editMode ?
              <RectangleButton
                id={TEXT_COPY_TASK_BUTTON}
                className={"copy-icon"}
                style={editMode ? { backgroundColor: ' #E8EBFB', color: ' #121A4E' } : {}}
                text={editMode ? SAVE_AS_DRAFT : TEXT_COPY_TASK_BUTTON}
                icon={''}
                onClick={editMode ? () => alert('not working') : props.handleCopyTaskClick}
                isAllowed={editMode ? '' : libraryManager || isSuperUser}
                isEdit={editMode}
                withPopover={true}
                textOver={'בחר לאן לשכפל עותק של המשימה'} /> : <span />
          }
          <RectangleButton
            text={editMode ? SAVE : EDIT}
            icon={<EditLogo />}
            onClick={() => { editBottenClickHandler() }}
            isAllowed={isAllowedToEdit()}
            isEdit={editMode}
          />
          {/* <RectangleButton
            text={!currentTask.editable === true ? ENABLE_EDITING : DISABLE_EDITING}
            // icon={<EditLogo />}
            onClick={() => {
              editMode && (saveChanged(currentTask, null, true) && setTextSuccessSavedAlert(SUCCESS_SAVED));
              putIsTaskEditable(currentTask.id).then(res => {
                setCurrentTask({ ...currentTask, editable: res.data.data.editable });
              });
            }}
            isAllowed={isSuperuser || (isAdmin && currentTask.group_id == localStorage.getItem('groupId'))}
            isEdit={editMode}
          /> */}
          {/*<CopyTaskButton isAllowed={libraryManager ||isSuperUser} handleCopyTaskClick={props.handleCopyTaskClick} />*/}
          {/*<button style={{justifyContent: 'center', alignItems: 'center'}}*/}
          {/*        disabled={!IsAllowEditAndDelete && !this.isSuperUser}*/}
          {/*        className={IsAllowEditAndDelete || this.isSuperUser ? 'editListButtonForShow' : 'editListButtonForShowOver'}*/}
          {/*        onClick={this.editTask}*/}
          {/*>*/}
          {/*    <EditLogo />*/}
          {/*    <span style={{padding: '3px'}}>עריכת מערך</span>*/}
          {/*</button >*/}
        </span>
        : <span />}
    </div>;
  }

  // Checked if do display edit butten for the user
  const isAllowedToEdit = () => {
    if (isSuperuser || isAreauser) {
      return true;
    }
    if (isAdmin) {
      return currentTask.group_id != 2
    }
    if (isStaff) {
      return currentTask.status_name == "draft"
    }
    return false;
  }

  const editBottenClickHandler = async () => {
    if (editMode) {
      saveChanged(currentTask, null, true)
      setTextSuccessSavedAlert(SUCCESS_SAVED);
      setEditingByToNull()
    } else {
      isInternetConnection().then(r => {
        if (r) {
          putEditingBy(currentTask.id, localStorage.getItem('userId')).then(res => {
            const data = res.data.data
            if (data.editing_by == localStorage.getItem('userId') && !data.isInEditByMe) {
              getTask(currentTask.id).then(taskRes => {
                const task = taskRes.data.data
                let setIsOpen = initialWorkProcessIsOpen(task.work_process);
                setCurrentTask({ ...task, work_process: setIsOpen });
                setEditMode(!editMode)
              })
            }
            else {
              alert(`המשימה נמצאת כרגע בעריכה ע"י ${data.first_name} ${data.last_name}`);
            }
          })
        } else {
          alert(DISCONNECTED_NEW_TASK)
        }
      })
    }
  }

  /* process progress indicator */
  const ProgressItem = () => {
    let statusMode = calculatesTaskStatus(currentTask.work_process);
    return <div style={{ fontSize: '18px', fontWeight: 'normal', textAlign: 'left', padding: '13px 0 15px' }}>
      <div style={{ marginBottom: '-10px' }}>{`${statusMode}%`}</div>
      <div className='d-flex align-items-center'>
        <LeaderSelector />
        <div className='p-2 pr-5 sub-title'>{STATUS}</div>
        <div className='progress d-flex'>
          <div className='progress-status' style={{ width: `${statusMode}%` }}></div>
        </div>
      </div>
    </div>;
  };

  const LeaderSelector = () => (
    <span onClick={(event) => event.stopPropagation()}>
      {!currentTask.group_id !== 2 ? editMode
        ? <UsersDropDown
          id={null}
          parent_id={null}
          editMode={editMode}
          disabled={false}
          multiple={false}
          options={users}
          value={currentTask.leading_id}
          onChange={setTaskLeader}
          field='leading_id'
        />
        : <React.Fragment>
          <span className='sub-title'>{TASK_LEADING}</span>
          {currentTask.leading_name}
        </React.Fragment> : ''}
    </span>
  );

  function successAlert() {
    return (
      <Success
        name={currentTask.title}
        line_1={
          !SaveDraft
            ? `${TEXT_TASK_ARRAY} "${currentTask.title}"`
            : TEXT_DRAFT
        }
        line_2={
          !SaveDraft
            ? TEXT_SUCCESSFULLY_CREATED_MALE
            : TEXT_SUCCESSFULLY_CREATED_FEMALE
        }
        line_3={YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_TASK}
      />
    );
  }

  function successSavedAlert() {
    return (
      <Success
        name={currentTask.title}
        line_1={textSuccessSavedAlert}
        line_2={''}
        line_3={''}
      />
    );
  }

  async function sortTheOrderField(task) {
    let counter = 1;
    await task.work_process.forEach(parent => {
      parent.order = counter;
      counter = counter + 1;
      parent.children.forEach(child => {
        child.order = counter;
        counter = counter + 1;
      });
    });
    return task;
  }

  function initialWorkProcessIsOpen(data, prevData) {
    return data.map((elm, i) => {
      let isOpen;
      if (prevData) {
        isOpen = prevData.work_process[i].isOpen === true;
      } else {
        isOpen = currentTask.work_process[i].isOpen === true;
      }
      if (isOpen) {
        elm.isOpen = true;
      }
      return elm;
    });
  }

  function findNewRowOrder(data) {
    let order;
    data.work_process.forEach((p, i) => {
      if (p.id === null) {
        order = p.order
        return
      }
      let child;
      if (child = p.children.find(ch => ch.id === null)) {
        order = child.order
        return
      }
    })
    return order
  }

  function setNewRowIsCurrent(data, order) {
    data.forEach((obj, i) => {
      if (obj.order === order) {
        setShowAll(false);
        setLeftClickIndex({ parentId: obj.parent_id, id: obj.id, index: i });
      }
      obj.children.forEach((ch, j) => {
        if (ch.order === order) {
          obj.isOpen = true;
          setLeftClickIndex({ parentId: ch.parent_id, id: ch.id, index: j });
        }
      });
    });
    // data.forEach((obj, i) => {
    //   if (obj.id !== currentTask.work_process[i].id && currentTask.work_process[i].id === null) {
    //     setShowAll(false);
    //     setLeftClickIndex({ parentId: obj.parent_id, id: obj.id, index: i });
    //   }
    //   obj.children.forEach((ch, j) => {
    //     if (ch.id !== currentTask.work_process[i].children[j].id && currentTask.work_process[i].children[j].id === null) {
    //       obj.isOpen = true;
    //       setLeftClickIndex({ parentId: ch.parent_id, id: ch.id, index: j });
    //     }
    //   });
    // });
  }

  /*===========================================Events======================================================*/

  /* sent the changes to server for save */
  async function saveChanged(task = currentTask, key = null, clicked = false) {
    let access = await isAllowedAccess();
    if (access) {
      let isConnected = await isInternetConnection();
      if (!isConnected) {
        newTask ? await handleOffline(task) : alert(DISCONNECTED_NEW_TASK);
        // await alert(DISCONNECTED_ALERT)
        return;
      } else if (getStorageTask()) {
        saveFromLocalStorage();
      }
      let data = await sortTheOrderField(task);
      data.id ? saveUpdatedTask(data, key, clicked) : saveNewTask(data, clicked);
    }
  }

  function saveUpdatedTask(data, key, clicked) {
    clicked && dispatch(spinnerOn(true));
    putTaskById(data, data.id)
      .then(async res => {
        let updatedTask = await res.data.data;
        if (key) {
          if (key === 'work_process') {
            const newRow = findNewRowOrder(data)
            let setIsOpen = initialWorkProcessIsOpen(updatedTask.work_process, data, newRow);
            setNewRowIsCurrent(updatedTask.work_process, newRow);
            setCurrentTask({ ...currentTask, work_process: setIsOpen });
          } else {
            setCurrentTask({ ...currentTask, [key]: updatedTask[key] });
            if (key === 'status_name') {
              dispatch(updateSelectedGroup(menuItems.GROUP_ID + currentTask.group_id));
            }
          }
        }
        if (clicked) {
          await setEditMode(false);
          await props.newTask ? setShow(true) : setSaved(true);
          await dispatch(spinnerOn(false));
          setTimeout(() => {
            if (props.newTask) {
              dispatch(taskCreation(true));
              setIsCreated(true);
            } else {
              setSaved(false);
            }
          }, 2000);
          // let resEditable = await getIsTaskEditable(currentTask.id);
          // let editable = resEditable.data.data.editable
        }
        await dispatch(updateCurrentTaskInArray({ data: { ...updatedTask }, id: updatedTask.id }));
      }).catch(err => {
        dispatch(spinnerOn(false));
        console.log(err);
      });
  }

  function saveNewTask(data, clicked) {
    clicked && dispatch(spinnerOn(true));
    postTask(data)
      .then(async res => {
        let resData = res.data.data;
        setCurrentTask(resData);
        setNewTask(false);
        await dispatch(insertNewTaskToTasksArray(res.data.data));
        await dispatch(updateCurrentTaskInArray({ data: res.data.data, id: res.data.data.id }));
        await dispatch(updateCreatedTask(res.data.data));
        await dispatch(restartTasksRequest(true));
        await dispatch(updateSelectedGroup(menuItems.USER_AREA));
        clicked && await dispatch(spinnerOn(false));
        clicked && setShow(true);
        clicked && setTimeout(() => {
          dispatch(taskCreation(true));
          setIsCreated(true);
        }, 2000);
      })
      .catch(e => {
        handleErrors(e);
      });
  }

  function handleErrors(e) {
    dispatch(spinnerOn(false));
    let error = e.response;
    if (error && error.status === 404) {
      throw new Error(`${e.config.url} not found`);
    }
    if (error && error.status === 400) {
      switch (error.data.errors[0].field) {
        case 'title':
          return alert(TASK_TITLE_IS_REQUIRED_FIELD);
        default:
          return alert(`${error.data.errors[0].field}`);
      }
    }
  }

  // function handleDescriptionsChanges(parentId, id, field, value) {
  //   let task = { ...currentTask };
  //   if (newTask) {
  //     parentId === null
  //       ? task.work_process[id].description = value
  //       : task.work_process[parentId].children[id].description = value;
  //   } else {
  //     task.work_process.forEach(t => parentId === null && t.id == id
  //       ? t.description = value
  //       : parentId === t.id
  //         ? t.children.forEach(c => c.id == id
  //           ? c.description = value
  //           : c)
  //         : t);
  //   }

  //   if (field === 'description') {
  //     task.description = value;
  //   }
  //   if (field === 'comments') {
  //     task.comments = value;
  //   }

  //   // setCurrentTask({...currentTask, task})
  // }

  function handleTitleAndTaskDescription(field, e) {
    currentTask[field] = e;
  }

  function handleEventId(data) {
    setCurrentTask({ ...currentTask, 'google_calendar_events': [data] });
  }

  function tagsHandler(tag, status) {
    let taskTags = { ...currentTask };
    status
      ? taskTags.tags.push(tags.find(t => t.id === tag))
      : taskTags.tags = currentTask.tags.filter(t => t.id !== tag);
    setCurrentTask({ ...currentTask, 'tags': taskTags.tags });
  }

  const deleteRow = async (details) => {
    let task = currentTask;
    if (details.parentIndex === null && isInt(details.index)) {
      task.work_process.splice(details.index, 1);
    } else if (isInt(details.parentIndex) && isInt(details.index)) {
      task.work_process[details.parentIndex].children.splice(details.index, 1);
    }
    setCurrentTask({ ...task });
  };

  /* row creator by content menu choose */
  const addProcessStep = async (details) => {
    let [pIndex, cIndex, where] = details;
    let isChild = pIndex !== null;
    // let task = { ...currentTask };
    let task = deepCopyIntoObject(currentTask);
    let array = task.work_process;
    switch (where) {
      case 'down':
        cIndex = cIndex + 1;
        if (isChild) {
          array = array[pIndex].children;
        }
        break;
      case 'up':
        if (isChild) {
          array = array[pIndex].children;
        }
        break;
      case 'child':
        array = array[cIndex].children;
        // cIndex = array.length
        break;
      default:
        break;
    }
    let newItem = {
      id: null,
      order: isChild ? cIndex : pIndex,
      title: '<p></p>',
      description: '<p></p>',
      role: null,
      task_id: null,
      parent_id: isChild ? task.work_process[pIndex].id : null,
      checked: false,
      children: [],
      end_date: null,
      performer: null,
    };
    array.splice(where !== 'child' ? cIndex : array.length, 0, newItem);
    array.forEach((t, i) => t.order = i);
    if (where === 'child') {
      task.work_process[cIndex].children = array;
    } else {
      if (isChild) {
        task.work_process[pIndex].children = array;
      } else {
        task.work_process = array;
      }
    }
    if (newTask) {
      setCurrentTask({ ...currentTask, 'work_process': task.work_process });
    } else {
      saveChanged(task, 'work_process');
    }
  };

  function setOnSelectSection(index) {
    setOnSection(index);
  }

  /* send comment to server and refresh the object*/
  function sendMessage(e) {
    addComment(e)
      .then((res) => {
        setCurrentTask({ ...currentTask, comments: [...currentTask.comments, e] });
      })
      .catch(e => {
        alert(e);
      });
  }

  function setParents(data) {
    setCurrentTask({ ...currentTask, 'work_process': data });
  }

  const setTaskLeader = (parent_id = null, id, key, value) => {
    let task = { ...currentTask };
    task.leading_id = value.id;
    task.leading_name = `${value.first_name} ${value.last_name}`;
    setCurrentTask({ ...currentTask, 'leading_name': task.leading_name, 'leading_id': task.leading_id });
  };

  //*======================================create task==============================================*//

  async function isInternetConnection() {
    let promise = new Promise((resolve, reject) => {
      axios.get(`${basePath}isConnection`)
        .catch(e => {
          if (e.toJSON().message === 'Network Error') {
            resolve(false);
            // alert('no internet connection')
          } else if (e.response.status == (404 || 502)) {
            resolve(true);
            setIsConnection(true);
          } else {
            resolve(false);
            setIsConnection(false);
            // alert(e.toJSON().message)
          }
        });
    });
    return promise;
  };

  const updatingStoreTask = (key, value) => {
    let receivedTaskData = { ...currentTask }; // not sure if without this action does deep copy??
    receivedTaskData[key] = value;
    updateCurrentTask(receivedTaskData);
  };

  usePrompt(location => THE_CHANGES_NOT_SAVED, editMode && !isTaskDeleted && !props.newTask);

  return (
    show && isCreated ? <Redirect to='/dashboard' /> : show ? successAlert() :
      <React.Fragment>

        {saved ? successSavedAlert() : <span />}
        <div className='container-fluid' style={{ overflowY: 'auto', marginBottom: '20px' }}>
          <div className='main-container'>
            {/*<SelectSearch options={users} value="sv" name="language" placeholder="Choose your language" />*/}
            {/*<SelectSearch*/}
            {/*  options={users}*/}
            {/*  search*/}
            {/*  filterOptions={fuzzySearch}*/}
            {/*  emptyMessage="Not found"*/}
            {/*  placeholder="Select your country"*/}
            {/*/>*/}
            {header()}
            <Description
              onClicked={setOnSelectSection}
              groupId={currentTask.group_id}
              editMode={editMode}
              onSection={onSection === 0}
              description={currentTask.description}
              title={currentTask.title}
              onChange={handleTitleAndTaskDescription}
            // editable={currentTask.editable}
            />
            <Section
              id={'1'}
              title={`תהליך `}
              progress={(currentTask.group_id !== 2 || currentTask.status_name === "draft") && <ProgressItem />}
              section={onSection}
              setOnSection={setOnSelectSection}
              disabled={false}
              isOpen={true}
              count={currentTask.work_process.length}
              body={
                <span> <Process
                  task={currentTask}
                  parents={currentTask.work_process}
                  setParents={setParents}
                  isDone={calculatesTaskStatus(currentTask.work_process)}
                  editMode={editMode}
                  isYesodTask={currentTask.group_id === 2}
                  newTask={newTask || false}
                  onSection={onSection === 1}
                  setOnSection={setOnSelectSection}
                  setDoneClicked={setDoneClicked}
                  setDoneStatus={(e) => setDoneStatus(calculatesTaskStatus(e))}
                  setCurrentTask={setCurrentTask}
                  save={saveChanged}
                  addProcessStep={addProcessStep}
                  deleteRow={deleteRow}
                  isAllowed={isSuperuser || isStaff}
                  leftClickIndex={leftClickIndex}
                  setLeftClickIndex={setLeftClickIndex}
                  showAll={showAll}
                  setShowAll={setShowAll}
                />
                  {/*  <ProgressTable*/}
                  {/*  section={onSection}*/}
                  {/*  setOnSection={setOnSelectSection}*/}
                  {/*  currentTask={currentTask}*/}
                  {/*  isYesodTask={currentTask.group_id === 2}*/}
                  {/*  editMode={editMode}*/}
                  {/*  onSection={onSection === 1}*/}
                  {/*  // users={users}*/}
                  {/*  newTask={newTask || false}*/}
                  {/*  isAllowed={isSuperuser || isStaff}*/}
                  {/*  handleDescriptionsChanges={handleDescriptionsChanges}*/}
                  {/*  onClick={setCurrentTask}*/}
                  {/*  deleteRow={deleteRow}*/}
                  {/*  addProcessStep={addProcessStep}*/}
                  {/*  doneStatus={calculatesTaskStatus(currentTask.work_process)}*/}
                  {/*  setDoneStatus={() => setDoneStatus(calculatesTaskStatus(currentTask.work_process))}*/}
                  {/*  sendMessage={(e) => sendMessage(e)}*/}
                  {/*/>*/}
                </span>
              }
            />
            <Section id={'2'}
              title={`חומרים לשימוש / הרחבות `}
              section={onSection}
              setOnSection={setOnSelectSection}
              count={currentTask.files_count}
              disabled={false}
              body={
                <FilesAndLinks
                  task={currentTask}
                  editMode={editMode}
                  onChange={setCurrentTask}
                />} />
            {editMode && isSuperuser &&
              <Section
                id={'3'}
                section={onSection}
                setOnSection={setOnSelectSection}
                title={`תגיות `}
                disabled={false}
                body={
                  <TagsItems
                    taskTags={currentTask.tags}
                    allTags={tags}
                    tagsHandler={tagsHandler} />
                } />
            }
            {!props.newTask && <span>{((isSuperuser && currentTask.group_id == 2) ||
              (currentTask.leading_id == getUserInfo('userId') && currentTask.group_id == getUserInfo('groupId'))) ?
              <Section id={'4'}
                title={`כלי ניהול `}
                section={onSection}
                setOnSection={setOnSelectSection}
                disabled={false}
                body={
                  <span>
                    <Section
                      title={`מתקדם `}
                      section={onSection}
                      setOnSection={setOnSelectSection}
                      body={
                        <div className='row mb-3'>
                          {isSuperuser && <div className='col-6'>
                            <Aliases taskId={currentTask.id} editMode={editMode} />
                          </div>}
                          <div className={isSuperuser ? 'col-6' : 'col-12'}>
                            <TaskMissionHistory
                              created={currentTask.created}
                              taskId={currentTask.id}
                              creator={users.find(u => u.id === currentTask.created_by_id)}
                            />
                          </div>
                        </div>}
                    />
                    {isSuperuser && currentTask.group_id == 2 &&
                      <span>
                        <Section
                          title={`הערות `}
                          section={onSection}
                          setOnSection={setOnSelectSection}
                          disabled={false}
                          body={
                            <GetChildrenComment
                              taskId={currentTask.id}
                              users={users}
                            />}
                        />
                        <Section
                          title={`סטטיסטיקה `}
                          section={onSection}
                          setOnSection={setOnSelectSection}
                          disabled={false}
                          body={
                            <Statistics
                              taskId={currentTask.id}
                              taskDescription={currentTask.description}
                            />}
                        />
                      </span>
                    }
                  </span>
                } /> :

              ((isStaff || isSuperuser || (currentTask.leading_id == getUserInfo('userId') && currentTask.group_id == getUserInfo('groupId'))) && editMode) ?
                <Section id={'4'}
                  title={`הסטוריית משימה `}
                  section={onSection}
                  setOnSection={setOnSelectSection}
                  disabled={false}
                  body={
                    <TaskMissionHistory
                      created={currentTask.created}
                      taskId={currentTask.id}
                      creator={users.find(u => u.id === currentTask.created_by_id)}
                    />
                  } /> : <span />}</span>}

            {/*<MoreInfo*/}
            {/*  data={props.nextToCreateTask ? undefined : currentTask}*/}
            {/*  id={currentTask.id}*/}
            {/*  nextToCreateTask={props.nextToCreateTask}*/}
            {/*/>*/}

          </div>
        </div>
      </React.Fragment>);
};

// class ViewTask extends Component {
//
//   /**
//    *
//    *
//    *
//    */
//   constructor(props) {
//
//     super(props)
//     this.state = {
//       show: false,
//       toEdit: false,
//       myIndex: 0,
//       overOnEditBtn: false,
//       task: this.props.tasks.find(task => task.id == this.props.id)
//     }
//     this.isStaff = isUserStaff();
//     this.isSuperUser = isSuperUser();
//   }
//
//   findMe() {
//     let myIndex = this.props.tasks.findIndex(task => task.id == this.props.id)
//     this.setState({myIndex: myIndex})
//   }
//
//   componentDidMount = () => {
//     this.findMe();
//     let myGroupId = this.getUserInformation('groupId');
//     this.setState({myGroupId: myGroupId});
//
//     // setInterval(() => {
//     //     this.setState({ "show": true });
//     // }, 2000);
//     // setTimeout(() => this.setState({ "show": true }), 4000);
//   }
//
//   getUserInformation(title) {
//     return localStorage.getItem(title)
//   }
//
//   editTask = async () => {
//     let access = await isAllowedAccess();
//     if (access) {
//       this.props.updateCurrentTask(this.state.task);
//       this.props.updateIsEdit(true);
//     } else window.location.reload();
//   }
//
//   displayLinks() {
//
//     if (this.state.task.links && this.state.task.links.length > 0) {
//       let links = this.state.task.links.map((e, index) => {
//         return <a style={{marginRight: '70px'}} key={index} className="a" href={e.url}
//                   style={{'textDecoration': 'underline'}} target="_blank">{e.text}</a>
//       })
//       return <div style={{marginRight: '70px'}} className="linksToDisplay">{links}</div>
//     }
//   }
//
//   onCheckedWorkProcess = async (value, selfIndex, fatherIndex) => {
//     let allTasks = Array.from(this.props.tasks)
//     let myTask = this.state.task
//     let updatingWorkProcess = Array.from(myTask.work_process);
//
//     this.updateWorkProcess(updatingWorkProcess, value, selfIndex, fatherIndex)
//
//     myTask.work_process = updatingWorkProcess
//     allTasks[this.state.myIndex] = myTask;
//     this.props.updateTaskArray(allTasks)
//     let ansFromServer = await putTaskById(myTask, this.props.id)
//     if (ansFromServer == null) {
//       return alert('try later')
//     }
//     if (!ansFromServer.data.success) {  //if not TODO ...
//       this.updateWorkProcess(updatingWorkProcess, !value, selfIndex, fatherIndex)
//       myTask.work_process = updatingWorkProcess
//       allTasks[this.state.myIndex] = myTask
//       this.props.updateTaskArray(allTasks)
//       alert('updating work process line not succeed')
//     }
//   }
//
//   updateWorkProcess(workArray, value, selfIndex, fatherIndex) {
//     if (fatherIndex != null) {
//       //check all childs
//       if (workArray[fatherIndex].checked == true && !value == false) {
//         workArray[fatherIndex].checked = !value
//         // workArray[fatherIndex].children[selfIndex].checked =!value
//       } else if (workArray[fatherIndex].checked == false && !value == true) {
//         let fatherChecked = true;
//         for (let i = 0; i < workArray[fatherIndex].children.length; i++) {
//           if (workArray[fatherIndex].children[i].checked == false && i != selfIndex) {
//             fatherChecked = false
//           }
//         }
//         if (fatherChecked) workArray[fatherIndex].checked = !value
//       }
//       workArray[fatherIndex].children[selfIndex].checked = !value
//     } else {
//       if (workArray[selfIndex].children.length > 0) {
//         // All childs like father
//         for (let i = 0; i < workArray[selfIndex].children.length; i++) {
//           workArray[selfIndex].children[i].checked = !value
//         }
//         workArray[selfIndex].checked = !value
//       } else {
//         workArray[selfIndex].checked = !value
//
//       }
//     }
//   }
//
//   displayWorkProcessLines() {
//     let lines = [];
//     for (let i = 0; i < this.state.task.work_process.length; i++) {
//       let line = this.state.task.work_process[i];
//       let children = [];
//       if (line.children.length > 0) {
//         children = line.children.map((el, indexChild) => {
//           return <ShowTask
//             className={"processLineChildForShowTask"}
//             htmlText={el.description}
//             isChecked={el.checked}
//             onChecked={this.onCheckedWorkProcess}
//             selfIndex={indexChild}
//             fatherIndex={i}/>
//         })
//       }
//       if (children.length > 0) {
//         lines.push(
//           <ShowTask
//             className={"processLineInWiewTask"}
//             htmlText={line.description}
//             isChecked={line.checked}
//             onChecked={this.onCheckedWorkProcess}
//             selfIndex={i}
//             fatherIndex={null}/>
//         )
//         lines = [...lines, ...children]
//       } else {
//         lines.push(
//           <ShowTask
//             className={"processLineInWiewTask"}
//             htmlText={line.description}
//             isChecked={line.checked}
//             onChecked={this.onCheckedWorkProcess}
//             selfIndex={i}
//             fatherIndex={null}/>
//         )
//       }
//
//     }
//
//     return lines
//   }
//
//   updateTaskChanged(task) {
//     this.setState({task})
//   }
//
//   addEventToCalender() {
//
//   }
//
//   render() {
//     let task = this.props.tasks.find(t => t.id == this.props.id)
//
//     let folderLogo;
//     if (task.category === 1) {
//       folderLogo = blueFolderLogo;
//     }
//     if (task.category === 2) {
//       folderLogo = purpleFolderLogo;
//     }
//     if (task.category === 3) {
//       folderLogo = greenFolderLogo;
//     }
//     if (task.category === 4) {
//       folderLogo = orangeFolderLogo;
//     }
//     let categoryLogo;
//     let myCategory = this.props.categories.find(c => c.id === task.group_id);
//     let libraryManager = isManagerOfTheCurrentLibraryToCopy(myCategory, this.state.myGroupId, this.isStaff, this.isSuperUser);
//     let libraryManagerToEditEndDelete = isManagerOfTheCurrentLibraryToEditEndDelete(myCategory, this.state.myGroupId, this.isStaff, this.isSuperUser)
//     // console.log('libraryManager, ', libraryManager);
//
//     if (task.group_id !== 2) {
//       // console.log('myCategory, ', myCategory);
//       categoryLogo = myCategory.logo !== '' && myCategory.logo !== null ? myCategory.logo : '';
//     }
//     // console.log('categoryLogo, ', categoryLogo);
//
//     let categoryName;
//     this.props.categories[0].categories.forEach(elem => {
//       if (elem.id == task.category) {
//         categoryName = elem.name;
//       }
//     });
//
//     let tags = []
//     task.tags.forEach((element) => {//TODO-- Real data needs to be connected
//       tags.push(<Tag
//         selected={
//           true
//         }
//         name={element.name}/>)
//     });
//
//     let linksToDisplay = [];
//     task.links.forEach((element, index) => {
//       linksToDisplay.push(
//         <LinkManager isCreateLink={true} index={index} url={element.url} link={element.text}/>
//       );
//     });
//     let cards = [];
//     task.files.forEach((element) => {//TODO -- Real data needs to be connected
//
//       let substringName = element.name.substring(element.name.indexOf(".") + 1);
//
//       cards.push(<Card file={element} name={element.name} type={element.type || substringName} url={element.url}/>)
//     })
//
//     // var inputs = []
//     // this.props.data.work_process.forEach((element, index) => {
//
//     //     let className;
//     //     if (element.parent_id) { className = "processLineChildForShowTask" }
//     //     else if (!element.parent_id) { className = "processLineInWiewTask" }
//
//
//     //     inputs.push(<ProcessLine
//     //         className={className}
//     //         freeText={element.description}
//     //         key={index}
//     //     />)
//     // });
//
//     return (
//       this.props.isEdit ? <CreateTaskPage toEdit="true" updateParent={() => this.updateTaskChanged(task)}/> :
//         <div className="CreateTaskPage">
//           {/* {!this.state.show && this.props.new ?
//                     <Success
//                      name={this.props.tasks[this.state.myIndex].title}
//                      line_1={"מערך משימה" + ' " ' + this.props.tasks[this.state.myIndex].title + ' " '}
//                      line_2={'נוצר בהצלחה !'}
//                      line_3={'מיד תועבר/י למשימה...'}
//                      /> : <React.Fragment />} */}
//           <CancellationAndReturn viewTask={true}/>
//           {/*<div className="headerDiv">*/}
//           {/*  <div className='rightDiv'>*/}
//           {/*    <div className="createHeader">{task.title}</div>*/}
//           {/*    <div className="category"><img src={folderLogo} /> {categoryName}</div>*/}
//           {/*  </div>*/}
//           {/*  <span style={{ display: 'flex' }}>*/}
//           {/*                  <CopyTaskButton isAllowed={libraryManager || this.isSuperUser} handleCopyTaskClick={this.props.handleCopyTaskClick} />*/}
//           {/*                  <button style={{justifyContent: 'center', alignItems: 'center'}}*/}
//           {/*                          disabled={!libraryManagerToEditEndDelete && !this.isSuperUser}*/}
//           {/*                          className={libraryManagerToEditEndDelete || this.isSuperUser ? 'editListButtonForShow' : 'editListButtonForShowOver'}*/}
//           {/*                          onClick={this.editTask}*/}
//           {/*                  >*/}
//           {/*                      <EditLogo />*/}
//           {/*                      <span style={{padding: '3px'}}>עריכת מערך</span>*/}
//           {/*                  </button >*/}
//           {/*              </span>*/}
//           {/*  <img className='mainLogo' src={categoryLogo === undefined ? mainLogo : categoryLogo} />*/}
//           {/*</div>*/}
//           <Container className="whiteContainerForViewTask">
//             <div className='logos'>
//               <img className='frameForLogoForShoe' src={CalendarForShow} onClick={() => this.addEventToCalender()}/>
//               <img className='frameForLogoForShoe' src={ShareForShow}/>
//               <img className='frameForLogoForShoe' src={CloudDownloadForShow}/>
//               <img className='frameForLogoForShoe' src={PrintForShow}/>
//               <img
//                 onClick={(e) => this.props.deleteArrayOnClick(
//
//                 )}
//                 className={this.isSuperUser || libraryManagerToEditEndDelete ? 'frameForLogoForShoe' : 'frameForLogoForShoe-is-not-isStaff'}
//                 src={DeleteForShow}/>
//             </div>
//             <p className="tagsHeaderForShowTask">מהות המשימה</p>
//             <ShowDescription className="essence" htmlText={task.description}/>
//             {/* <ShowTask className="essence" htmlText={this.props.data.description} /> */}
//             <Row className="workProcess">
//               <Col>
//                 <p>תהליך עבודה </p>
//               </Col>
//             </Row>
//             {this.displayWorkProcessLines()}
//
//             <p className="linksHeader mb-3">קישורים</p>
//             {this.displayLinks()}
//
//             <p className="filesHeader mb-3">קבצים</p>
//             {cards.length > 0 ? <Row style={{marginRight: '70px'}} className="cards">
//               {cards}
//             </Row> : <span/>}
//             <p className="tagsHeaderForShow">תגיות</p>
//             {tags.length > 0 ?
//               <div style={{marginRight: '70px'}} className='tags'>
//                 {tags}
//               </div> : <span/>}
//           </Container>
//         </div>
//     )
//   }
// }

const mapStateToProps = (state) => {
  return {
    tagsServer: state.dashboard.tags,
    categories: state.dashboard.categories,
    selectedTags: state.task.task.selectedTags,
    isEdit: state.task.task.isEdit,
    tasks: state.dashboard.tasks,
    currentTaskData: state.task.task_undo.present.currentTaskData,
    // isTaskCreated: useSelector(state => state.task.task.isTaskCreated)
  };
};


const mapDispatchToProps = dispatch => {
  return {
    updateCurrentTask: () => dispatch(updateCurrentTask),
    updateCurrentTaskInArray: () => dispatch(updateCurrentTaskInArray),
    updateCreatedTask: () => dispatch(updateCreatedTask),
    restartTasksRequest: () => dispatch(restartTasksRequest),
    taskCreation: () => dispatch(taskCreation),
    updateIsEdit: () => dispatch(updateIsEdit),
  };
};
// ReactPDF.renderToStream(<MyDocument />);
connect(mapStateToProps, mapDispatchToProps, spinnerOn)(TaskPage);
export default TaskPage;
