import React, { useEffect, useRef } from "react";
import PdfPageContent from "./pdfPageContent";
import logo from "../../ui/images/Yesod logo full.svg"

const PdfPageLayout = (props) => {
   

    return (
        <div className="config-page" >
            <table className="config-page">
                <thead><tr><td >
                    <div className="mein-header-space"></div>
                </td></tr></thead>
                <tbody><tr><td  className ="p-0">
                    <PdfPageContent task={props.task} />
                </td></tr></tbody>
                <tfoot><tr><td >
                    <div className="mein-footer-space"></div>
                </td></tr></tfoot>
            </table>
            <div class="mein-header">
                <div className="div-img-config">
                    <img className="img-config" src={logo} height = "50px"/></div>
            </div>
            <div className="mein-footer date-text" >
                <div id="content-footer">

                    {/* <div id="page-numbar"> עמוד </div> */}
                    <div style={{ height: "20px" }} >
                        <span> <img src={logo} style={{ display: "inline-block", height: "98%", padding: "2px", color: "#6C7582" }} />הופק ע"י מערכת יסוד
                        </span> | Lorem@ipsum.com | Yesod.gov.il
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PdfPageLayout;
