import React, {useEffect, useState} from "react"
import '../taskPage.css'
import {deleteGoogleEvent, insertGoogleEvent} from "../../../apis/calendarEvent";


let config = {
  "clientID": "1091921654164-0d231d0b9f6iojqb9fn653odig2eq2gk.apps.googleusercontent.com",
  "ClientSecret": "YKvnrXut1Txu-tZxW5vMom4c",
  "apiKey": "AIzaSyCGo1QlWuopeXVxzpJAJqtt2XUO7bYh6Vw",
  "SCOPES": "https://www.googleapis.com/auth/calendar",
  "DISCOVERY_DOCS": ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
}

let gapi = window.gapi

const AddGoogleCalendarEvent = (props) => {

  const [firstTime, setFirstTime] = useState(true)

  useEffect(() => {
    if (firstTime){
      setFirstTime(false)
    } else if(props.eventDetails){
       handleClick('update')
    }
  }, [props.processValue])

  const registeration = () => {
try {
  gapi.load('client:auth2', () => {
    try {
      gapi.client.init({
        client_id: config.clientID,
        apiKey: config.apiKey,
        discoveryDocs: config.DISCOVERY_DOCS,
        scope: config.SCOPES,
      }).then(() => {
        gapi.auth2.getAuthInstance().signIn().then(()=>
          props.eventDetails ? updateEvent() : addEvent()
        )
      })
    } catch (error) {
      console.log('ERROR: ', error);
    }
  })
}catch (e) {
  console.log(e)
}

  }

  const getCalendarsList = () => {
    try {

      const request = gapi.client.discovery
      console.log(request);
      const response = JSON.parse(request)
      console.log(response);

    } catch (error) {
      console.log(error);

    }
  }

  const addCalendarForTheUser = () => {

    gapi.client.load('calendar', 'v3', () => {

      let request = gapi.client.calendar.calendarList.insert
      ({
        id: 'c_e1dd26kafjf40kg4mub1p4ur80@group.calendar.google.com',
      });
      request.execute(event => {
        console.log('Event created: ', event);
        // setEventId(event.id)
      });
    })
  }

  const addEvent = () => {

    gapi.client.load('calendar', 'v3', () => {

      let request = gapi.client.calendar.events.insert({
        'calendarId': 'primary',
        // 'calendarId': 'c_e1dd26kafjf40kg4mub1p4ur80@group.calendar.google.com',
        'resource': props.event
      });

      request.execute(event => {
        console.log('Event created: ', event);
        let url = event.htmlLink.replace('event?eid=', 'r/eventedit/')
        console.log(url);
        window.open(url)
        // setEventId(event.id)
        insertGoogleEvent({task: props.taskId, event_id: event.id, calendar_id: 'google'})
          .then(result => {
            props.addEventId(result.data.data)
            console.log(result)
          })
          .catch(e => {
            console.log(e)
          })
      });
    })
  }

    const updateEvent =  () => {

      gapi.client.load('calendar', 'v3', async () => {

        try {
         let get = gapi.client.calendar.events.get({
            'calendarId': 'primary',
            // 'calendarId': 'c_e1dd26kafjf40kg4mub1p4ur80@group.calendar.google.com',
            'eventId': props.eventDetails.event_id,
            'resource': props.event
          })
           get.execute(result => {

             let event = result

             if (event.status === 'cancelled'){
                deleteGoogleEvent(props.eventDetails.id)
                 .then((res)=>{
                   props.addEventId(null)
                   console.log('deleted: ',res)
                 }).catch(e=>{
                 console.log('deleted failed: ',e)
               })
               return
             } else {
             result.summary = props.event.summary
             let update = gapi.client.calendar.events.update({
               'calendarId': 'primary',
               // 'calendarId': 'c_e1dd26kafjf40kg4mub1p4ur80@group.calendar.google.com',
               'eventId': props.eventDetails.event_id,
               'resource': event
             });

             update.execute(event => {
               console.log('EVENT UPDATED: ', event)
               // window.open(event.htmlLink)
             });
             }
          })
        } catch (error) {
          console.log(error);
        }
      })
    }

    const handleClick = async (mode) => {
      if (!(window.gapi && window.gapi.auth2 && window.gapi.auth2.getAuthInstance().isSignedIn.get())) {
        registeration()
      } else {

        switch (mode) {
          case 'insert':
            return addEvent()

          case 'update':
            return updateEvent()

          case 'getCalendars':
            return getCalendarsList()

          case 'addCalendar':
            return addCalendarForTheUser()

          default:
            return
        }
      }
    }

    return <div onClick={() => handleClick(props.eventDetails ? 'update' : 'insert')}>Google</div>

  }

  export default AddGoogleCalendarEvent
