import React, {useEffect, useState} from "react";
import {getComment} from "../../../apis/restApi/taskApi";
import {format} from "date-fns";
import {COMMENTS_FROM_DUPLICATE_TASKS} from "../../../../ui/strings";

const GetChildrenComment = (props) => {

  const [comments, setComments] = useState(null)

  useEffect(() => {
    if (comments === null) {
      getComment(props.taskId)
        .then(res => {
          let response = res.data
          response.data && response.data.length > 0 && setComments(res.data.data)
        })
        .catch(e => console.log(e))
    }
  }, [comments])


  return (
    comments !== null && <div className='pr-3 pl-3 pt-2'>
      <div className='pb-2'><b>{COMMENTS_FROM_DUPLICATE_TASKS}</b></div>
      <table className='table table-bordered'>
        <thead className='text-center'>
        <th style={{width: '60%'}}>הערה</th>
        <th style={{width: '10%'}}>ארגון</th>
        <th style={{width: '15%'}}>נכתב ע"י</th>
        <th style={{width: '15%'}}>בתאריך</th>
        </thead>
        {comments.map(comm => {
          return <tbody className='pt-4'>
          {
            comm.comments.map(co => {
              let user = props.users && props.users.find(u => u.id === co.user ? u : '')
              return <tr>
                <td style={{textAlign: 'start'}} dangerouslySetInnerHTML={{__html: co.description}}></td>
                <td style={{textAlign: 'center'}}>{`${comm && comm.group_name ? comm.group_name : ''}`}</td>
                <td style={{textAlign: 'center'}}>
                  {`${user && user.first_name && user.first_name} ${user && user.last_name && user.last_name}`}
                </td>
                <td style={{
                  textAlign: 'center',
                  wordBreak: 'break-word'
                }}>{format(new Date(comm.created), 'dd/MM/yyyy')}</td>
              </tr>
            })
          }
          </tbody>
        })}</table>
    </div>
  )
}
export default GetChildrenComment





