import {format} from "date-fns";
import React from "react";

export function calculatesTaskStatus(data) {
    let allProcessLineLength = data.length;
    let totalCheckedCount = 0;
    let checkedCountChildrenArr = [];
      data.map(w => {
        if (w.checked) {
          totalCheckedCount++;
        }
        if (w.children.length > 0) {
          let checkedCountChildren = 0;
          w.children.map(c => {
            if (c.checked && !w.checked) {
              checkedCountChildren++;
            }
          });
          let childrenObj = {
            fatherId : w.id,
            checkedCount: checkedCountChildren,
            allChildren: w.children.length
          }
          checkedCountChildrenArr.push(childrenObj);
        }
      });

    let total = totalCheckedCount / allProcessLineLength * 100;
    let onePartOfAll = 1 / allProcessLineLength * 100;
    checkedCountChildrenArr.map( children => {
      let totalChildren = onePartOfAll * children.checkedCount / children.allChildren;
      total = total + totalChildren;
    });
    return isInt(total) ? total : isFloat(total) ? total.toFixed(1) : 0;

  return 0;
}

export function isInt(n){
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n){
  return Number(n) === n && n % 1 !== 0;
}

export function getUserInfo(title) {
  return localStorage.getItem(title)
}
