import React, { Component } from 'react';
import TaskPage from "../../CreateTaskPage/screens/taskPage";
import DeleteTaskModal from '../../../ui/modal/deleteTaskModal';
import { updateCurrentTask } from "../../store/taskStore/taskActions";
import { connect } from "react-redux";
import { getUserInfo } from "../../CreateTaskPage/screens/auxiliaryFunctions";
import { TASK_DEFAULT_TITLE } from "../../../ui/strings";

import { isSuperUser } from '../../apis/middleWareToken';
import ThemeProvider from '@material-ui/styles/ThemeProvider';

class TaskPageAndForum extends Component {

  constructor(props) {
    super(props);
    this.isSuperUser = isSuperUser();
    this.state = { data: {} }
  }

  deleteArrayOnClick = (e, libraryManagerToEditEndDelete, data) => {
    this.setState({ data: data })

    if (this.isSuperUser || libraryManagerToEditEndDelete) {
      this.deleteModal.toggle();
    }
  }

  getTaskPage() {
    let cleanData = {
      group_id: parseInt(getUserInfo('groupId')) || 2,
      category: 3,
      title: TASK_DEFAULT_TITLE,
      description: "<p><br></p>",
      status_name: "draft",
      files: [],
      tags: [],
      area_id:null,
      links: [],
      comments: [],
      work_process: [],
      google_calendar_events: [],
      editable: true
    }
    return (
      <TaskPage
        task={cleanData}
        deleteArrayOnClick={this.deleteArrayOnClick}
        handleCopyTaskClick={this.props.handleCopyTaskClick}
        nextToCreateTask={true}
        newTask={true}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        {/*<CreateTaskPage/>*/}
        {this.getTaskPage()}
        {this.state.data && <DeleteTaskModal ref={ref => this.deleteModal = ref} data={this.state.data} />}

      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    task: state.task.task.currentTaskData,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    updateCurrentTask,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskPageAndForum)

