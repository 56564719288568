import {
    IS_ALLOWED_ACCESS,
    UPDATE_USER_DETAILS,
    IS_ALLOWED_ACCESS_LOGIN
} from './loginActionTypes';
import { loginInitData } from './loginInitData';
import { localeData } from 'moment';

export const loginReducer =  function(state = loginInitData, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case IS_ALLOWED_ACCESS: {
            return Object.assign(newState, { isAllowedAccess: action.payload });
        }
        case IS_ALLOWED_ACCESS_LOGIN: {
            return Object.assign(newState, { isAllowedAccessLogin: action.payload });
        }
        case UPDATE_USER_DETAILS: {
            return Object.assign(newState, { user: action.payload });
        }

        default:
            return state
    }
}