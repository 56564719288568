import React, { Component } from 'react';
import './CreateTaskPage.css'

class ShowTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked:false
        }
    }

    render() {

        return (
            <div className={this.props.className}>
                        <div style={{marginRight:'70px'}}
                            className={this.props.isChecked? "CheckBoxForUser":"CreateProcessCheckBox"}
                            onClick={()=>this.props.onChecked(this.props.isChecked, this.props.selfIndex, this.props.fatherIndex)}
                        />
                        <div className={"showProcess"} dangerouslySetInnerHTML={{__html: this.props.htmlText}}></div>
                    </div>
        )
    }
}
export default ShowTask;
