import {makeRequest} from "../middleWareToken";
import {basePath, groups, singleFile, tasks} from "../paths";

export async function addGroup(data) {
  let formData = new FormData();
  formData.append('name', data.name)
  formData.append('logo', data.logo)
  // formData.append('email', data.email)
  let ans = await makeRequest(basePath+'groups/', 'POST', formData)
  return ans
}

export async function editGroup(data) {
  let formData = new FormData();
  formData.append('name', data.name)
  formData.append('logo', data.logo)
  let ans = await makeRequest(`${basePath}${groups}${data.groupId}` , 'PUT', formData)
  return ans
}
