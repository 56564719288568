import React, { Component } from 'react';
import {
  getAllData,
  getCategories,
  getTags,
  dataReceived,
  updateIsRoutesBounded,
  taskDeleted,
  setGroupUsers, spinnerOn
} from '../../store/dashBoardStore/dashboardActions'
import { getCategoriesAndTags } from '../../apis/getCategoriesAndTags';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import CreateUser from '../../../ui/users/createUser';
import { getAllTasks } from '../../apis/getAllTasks';
import TaskPageAndForum from './taskPageAndForum';
import ViewTaskAndForum from './viewTaskAndForum';
import { connect } from 'react-redux';
import Dashboard from './dashboard';
import { isUserStaff, isSuperUser } from '../../apis/middleWareToken';
import Modal from '../../../ui/modal/modal';
import './main.css';
import Sidebar from "../sideBar/sideBar";
import { getUsers } from "../../apis/restApi/userApi";
import { getAllFolders } from '../../apis/foldersHandling';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: false,
      toggle: false,
      taskLoaded: false
    }
    this.isStaff = isUserStaff() || isSuperUser();
  }


  getUserInformation(title) {
    return localStorage.getItem(title)
  }

  async getTasks() {

    let dataObj = {
      tasks: [],
      categories: [],
      tags: [],
      areas: [],
      folders: [],
      isTasksReceived: true
    }
    this.props.spinnerOn(true)
    await getAllTasks()
      .then(taskRes => {
        let tasks = taskRes.data.data
        dataObj.tasks = tasks
      })
      .catch(e => {
        console.log(e)
      })

    await getAllFolders()
      .then(foldersRes => {
        dataObj.folders = Object.keys(foldersRes.data.data).length ? foldersRes.data.data : []
      })
      .catch(e => {
        console.log(e);
      })

    await getCategoriesAndTags()
      .then(categoriesRes => {
        let categoriesAndTags = categoriesRes.data.data
        dataObj.categories = categoriesAndTags.sets
        dataObj.tags = categoriesAndTags.tags
        dataObj.areas = categoriesAndTags.areas
      }).catch(e => {
        console.log(e)
      })

    await this.props.getAllData(dataObj)
    await this.props.dataReceived(true);
    this.props.spinnerOn(false);
  }

  getUsers() {
    let groupId = this.getUserInformation('groupId')
    getUsers(groupId)
      .then(res => {
        this.props.setGroupUsers(res.data.data)
      })
      .catch(e => console.log(e))
  }

  componentDidMount() {
    this.loader = true;
    this.getTasks()
    this.getUsers()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isTaskDeleted !== prevProps.isTaskDeleted && this.props.isTaskDeleted) {
      this.getUpdatedTasks(this.props.isTaskDeleted)
    }
  }

  getUpdatedTasks(when) {
    if (when) {
      this.getTasks().then(res => {
        this.props.taskDeleted(false)
      })
    }
  }

  buildTasksRoutes() {
    if (this.props.tasks && this.props.tasks.length > 0) {
      let routes = [];
      this.props.tasks.map((e, i) => {
        routes.push(<Route key={'viewTaskAndForumRoute ' + i} path={`/dashboard/task/${e.id}`}
          render={() =>
            <ViewTaskAndForum key={'viewTaskAndForum ' + i} id={e.id} data={e} dataToPdf={this.transforemDataToPdf} />
          } />)
      })
      if (!this.props.isRoutesBounded) this.props.updateIsRoutesBounded(true)
      return routes
    }
  }

  toggle() {
    this.modal.toggle();
  }

  dataToPdf = {}
  transforemDataToPdf = (data) => {
    this.dataToPdf = data
  }

  render() {
    return (
      <BrowserRouter>
        <div className='main-page'>
          {/*<Navbar />*/}
          <Sidebar toggle={this.toggle} handleCreateNewFolderClick={() => this.toggle()} />
          {/*<div className='sidebar-line'>*/}
          {/*    {*/}
          {/*        <UserNameLogo*/}
          {/*            userFirstName={this.getUserInformation('userFirstName')}*/}
          {/*            userLastName={this.getUserInformation('userLastName')}*/}
          {/*            role={this.getUserInformation('userRole')}*/}
          {/*        />*/}
          {/*    }*/}
          {/*    <div className='class-all-category-folders'>*/}
          {/*        <Folders isStaff={this.isStaff} handleCreateNewFolderClick={() => this.toggle()} />*/}
          {/*    </div>*/}
          {/*</div>*/}
          {/* ****************************** */}
          <Switch>
            <Route exact path='/dashboard'><Dashboard /></Route>
            <Route path='/dashboard/userArea'><Dashboard/></Route>
            {this.buildTasksRoutes()}
            <Route path='/dashboard/createtask'><TaskPageAndForum /></Route>
            <Route path='/dashboard/createuser'><CreateUser /></Route>
          </Switch>
        </div>
        <Modal ref={obj => this.modal = obj} />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    tasks: state.dashboard.tasks,
    isTasksReceived: state.dashboard.isTasksReceived,
    categories: state.dashboard.categories,
    tags: state.dashboard.tags,
    isRoutesBounded: state.dashboard.isRoutesBounded,
    isTaskCreated: state.task.task.isTaskCreated,
    isTaskDeleted: state.dashboard.isTaskDeleted


  }
}

export default connect(
  mapStateToProps,
  { spinnerOn, getAllData, getCategories, getTags, setGroupUsers, dataReceived, updateIsRoutesBounded, taskDeleted }
)(Main);
