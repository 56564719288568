import { makeRequest } from "./middleWareToken";
import { specificTask, basePath, updateTasklocation, tasks } from "./paths";

export async function updateTasksParentFolder(id, data) {
  return await makeRequest(basePath + specificTask + id + updateTasklocation, 'PUT', data);
}

export async function updateTasksOrder(id, data) {
  return await makeRequest(basePath + specificTask + id + updateTasklocation, 'PUT', data);
}

export async function getTask(id) {
  return await makeRequest(basePath + tasks + "/" + id)
}