import React, { useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch, useSelector } from "react-redux"
import { deleteFolder, updateFolder } from "../../../apis/foldersHandling"
import { removeFolderFromAllFolders, updateCurrentTaskInArray, updateFolderInAllFoldersArr } from "../../../store/dashBoardStore/dashboardActions"
import { dndTypes } from "./dndTypes"
import { Popover, PopoverBody } from "reactstrap";
import { CHANGE_NAME, DELETE, USER_AREA } from "../../../../ui/strings"
import { isAdminUser, isAreaUser, isSuperUser, isUserStaff } from "../../../apis/middleWareToken"
import PopupAction from "./popupAction"
import BetweenCards from "./betweenCards"
import { updateTasksParentFolder } from "../../../apis/taskHandling"


const FolderCard = ({
  folder,
  currentFolder,
  index,
  selectFolder,
  myGroup,
  folderIdOpen,
  setFolderIdOpen,
  newFolderCreated,
  setNewFolderCreated,
  aroundedFolders
}) => {

  const allFolders = useSelector(state => state.dashboard.folders)
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  const [isInputOpen, setIsInputOpen] = useState(false)
  const [folderName, setFolderName] = useState(folder.title)
  const [isThereItemAction, setIsThereItemAction] = useState(false)

  const ref = useRef(null)

  useEffect(() => {
    return () => { setFolderIdOpen(null) }
  }, [])

  useEffect(() => {
    folder.id === folderIdOpen && setIsOpen(true)
  }, [folderIdOpen])

  useEffect(() => {
    if (newFolderCreated && newFolderCreated.id === folder.id) {
      setIsInputOpen(true)
    }
  }, [newFolderCreated])

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [dndTypes.TASK, dndTypes.FOLDER],
    drop: (item, monitor) => {
      if (monitor.getItemType() == dndTypes.FOLDER && item.id !== folder.id) {
        updateFolderParent(item)
      }
      if (monitor.getItemType() == dndTypes.TASK) {
        updateTaskParent(item)
      }
      return folder
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
      }
    }
  }), [])

  const [{ item, itemType, isDragging }, drag] = useDrag(() => ({
    type: dndTypes.FOLDER,
    item: { ...folder, index },
    canDrag: () => (getIsAllowedToUpdateFolder() && !isInputOpen),
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
        item: monitor.getItem(),
        itemType: monitor.getItemType()
      }
    }
  }), [isInputOpen])

  drag(drop(ref))

  const updateFolderParent = async (item) => {
    const data = await updateFolder(item.id, { parent: folder ? folder.id : null })
    data && data.data && data.data.data && dispatch(updateFolderInAllFoldersArr(data.data.data))
  }

  const updateTaskParent = async (item) => {
    const details = {
      folder: folder ? folder.id : null,
      update_folder: true,
      old_folder: currentFolder ? currentFolder.id : null
    }
    let data = await updateTasksParentFolder(item.id, details)
    data = data && data.data && data.data.data ? data.data.data : null;
    data && dispatch(updateCurrentTaskInArray({ data: data, id: data.id }))
  }

  const updateFolderOrder = async (folderToUpdate, order) => {
    const data = await updateFolder(folderToUpdate.id, order)
    data && data.data && data.data.data && dispatch(updateFolderInAllFoldersArr(data.data.data))
  }

  const updateFolderTitle = async (e) => {
    if (e.target.value !== folder.title) {
      const data = await updateFolder(folder.id, { title: e.target.value })
      if (data && data.data && data.data.data) {
        setFolderName(data.data.data.title)
        allFolders[allFolders.findIndex(fold => fold.id === folder.id)].title = data.data.data.title
        newFolderCreated && setNewFolderCreated(false)
      }
    }
    setIsInputOpen(false)
  }

  const handleInputFocus = (e) => {
    e.target.select()
  }

  const handleDeleteFolder = async () => {
    const confirem = window.confirm("האם אתה בטוח שברצונך למחוק את תיקית " + folder.title + " עם כל התוכן שבתוכו")
    if (confirem) {
      const data = await deleteFolder(folder.id)
      data.status === 200 && dispatch(removeFolderFromAllFolders(folder.id));
    }
    setIsOpen(false)
  }

  const getIsAllowedToUpdateFolder = () => {
    if (
      isSuperUser() ||
      isAreaUser() ||
      (isAdminUser() && myGroup.id !== 2) ||
      (isUserStaff() && myGroup.name === USER_AREA)
    ) {
      return true
    }
    return false
  }

  const getIsActineWhenOver = (isFirst) => {
    if (!item || (item && item.id === folder.id) || (aroundedFolders.after && item.id === aroundedFolders.after.id && !isFirst)) {
      return false
    } else if (itemType === dndTypes.FOLDER) {
      return true
    }
    return false
  }


  const getPopupBody = () => {
    const addItemContent = [
      {
        text: CHANGE_NAME,
        class: "pen-icon",
        onClick: () => { setIsInputOpen(true); setIsOpen(false) },
        isAllowed: getIsAllowedToUpdateFolder()
      },
      {
        text: DELETE,
        class: "delete-icon",
        onClick: () => { handleDeleteFolder() },
        isAllowed: getIsAllowedToUpdateFolder(),
      },
    ]
    return addItemContent.map((item, i) => {
      !isThereItemAction && item.isAllowed && setIsThereItemAction(true)
      return item.isAllowed &&
        <PopupAction key={i} item={item} />
    })
  }


  return (
    <span>
      <div id={`Popup${folder.id}`} className="d-flex">
        {aroundedFolders.before === null &&
          <BetweenCards
            className={"first"}
            itemInfo={folder}
            aroundedItem={{ ...aroundedFolders }}
            isActineWhenOver={() => getIsActineWhenOver(true)}
            typeOfItem={dndTypes.FOLDER}
            isFirst={true}
            updateOrder={updateFolderOrder}
          />}
        <div
          ref={ref}
          className={`
          ${isOver && ((itemType === dndTypes.FOLDER && item.id !== folder.id) || itemType === dndTypes.TASK) ? 'folder-drag-over' : "folder-no-over"} 
          ${isDragging ? "is-dragging" : ""}
          `}
        >
          <div
            onClick={() => { selectFolder() }}
            className="folder-card">
            <div className="folder-right-part">
              <div className="folder-icon-wraper">
                <span className="folder-icon" />
              </div>
              {!isInputOpen
                ? <div className="folder-name">{folderName}</div>
                : <input
                  autoFocus={true}
                  onFocus={(e) => { handleInputFocus(e) }}
                  defaultValue={folderName}
                  type="text"
                  className="folder-name-input"
                  onClick={(e) => { e.stopPropagation() }}
                  onDoubleClick={(e) => { e.stopPropagation() }}
                  onKeyUp={(e) => { e.key === "Enter" && updateFolderTitle(e) }}
                  onBlur={(e) => { updateFolderTitle(e) }}
                />}
            </div>
            <div className="folder-left-part">
              <div className="action-icon-wraper" onClick={(e) => {
                e.stopPropagation()
                setIsOpen(!isOpen);
                setFolderIdOpen(folder.id)
              }}>
                <span className="action-icon" />
              </div>
            </div>
          </div>
        </div>
        <BetweenCards
          itemInfo={folder}
          aroundedItem={{ ...aroundedFolders }}
          isActineWhenOver={getIsActineWhenOver}
          typeOfItem={dndTypes.FOLDER}
          updateOrder={updateFolderOrder}
        />
      </div>
      <Popover
        popperClassName={"folder-actions-popover"}
        offset="35px, 0"
        hideArrow
        placement="bottom-start"
        isOpen={isOpen && folderIdOpen == folder.id}
        target={`Popup${folder.id}`}
        innerClassName={!isThereItemAction ? "display-none" : ""}
        modifiers={
          {
            offset: {
              fn: (data, options) => {
                data.instance.popper.style.top = "-24px"
                data.instance.popper.style.left = "30px"
                return data
              },
            }
          }
        }      >
        <PopoverBody>
          {getPopupBody()}
        </PopoverBody>
      </Popover>
    </span>
  )
}

export default FolderCard