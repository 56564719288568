import {
    IS_ALLOWED_ACCESS,
    UPDATE_USER_DETAILS,
    IS_ALLOWED_ACCESS_LOGIN
} from './loginActionTypes'

export function updateIsAllowedAccess(data) {
    return { type: IS_ALLOWED_ACCESS, payload: data }
}
export function updateIsAllowedAccessLogin(data) {
    return { type: IS_ALLOWED_ACCESS_LOGIN, payload: data }
}
export function updateUserDetails(data) {
    return { type: UPDATE_USER_DETAILS, payload: data }
}