import React, { Component } from 'react';
import { connect } from 'react-redux';
import { } from '../../components/store/dashBoardStore/dashboardActions'
import FindInSearchInput from './findInSearchInput';
import './allFindInSearchInput.css';
import tempLogo from "../images/input_search_logo.svg";
import {
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_1,
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_2,
  NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_3
} from "../strings";
import yesod_find_logo from "../images/yesod_logo_find.svg";
import { Link } from "react-router-dom";

class AllFindInSearchInput extends Component {

  constructor(props) {
    super(props);
  }

  parseResponse() {
    if (this.props.searchResults.length > 0) {
      let strToShow = []

      // console.log("this.props.searchResults", this.props.searchResults);
      this.props.searchResults.forEach(element => {
        let highlightKeys = Object.keys(element.highlight);
        if (highlightKeys) {
          highlightKeys.forEach(key => {
            element.highlight[key].forEach((el, i) => {
              if (key == 'title') {
                strToShow.unshift(<FindInSearchInput
                  tabindex={i}
                  findHeader={el}
                  taskId={element.id}
                  category_name={element.category_name}
                  groupId={element.group}
                  status ={element.status}
                />)
              }
              else {
                strToShow.push(<FindInSearchInput
                  tabindex={i}
                  findHeader={el}
                  taskId={element.id}
                  category_name={element.category_name}
                  groupId={element.group}
                  status ={element.status}
                />)
              }
            })


          })
        }


      });
      // strToShow.push(
      //   <button
      //     onClick={() => ''}
      //     className='div-line-find'
      //   >
      //     <img src={tempLogo} alt="" />
      //     <span className='text-find-header'>{NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_1}{' '}<a href="#">{NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_2}</a>
      //       {' '}{NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_3}</span>
      //   </button>
      // )
      return strToShow
    }
  }

  render() {

    return (
      <div className='div-all-find'>
        <div className='all-find'>
          {this.parseResponse()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    inputSearch: state.dashboard.inputSearch,
    searchResults: state.dashboard.searchResults
  }

}


export default connect(
  mapStateToProps,
  {}
)(AllFindInSearchInput);
