import {tasks, basePath, singleFile, specificTask} from './paths';
import {createRequest} from './middleWareToken';
import {TASK_TITLE_IS_REQUIRED_FIELD} from "../../ui/strings";

function handleErrors(e) {

  let error = e.response
  if (error.status === 404) {
    throw new Error(`${e.config.url} not found`);
  }
  if (error.status === 400) {
    switch (error.data.errors[0].field) {
      case 'title':
        return alert(TASK_TITLE_IS_REQUIRED_FIELD)
      default:
        return alert(`${error.data.errors[0].field}`)
    }
  }
}

export async function postTask(data) {

  // if there are files added to task
  if (data.files && data.files.length > 0) {
    createRequest(basePath + tasks, 'post', data)
      .then(res => {
        let formData = new FormData();
        for (let i = 0; i < data.files.length; i++) {
          formData.append('file', data.files[i], data.files[i].name, data.files[i].name)
          return createRequest(basePath + singleFile, 'post', formData)
        }
      }).catch(e=>{
       handleErrors(e)
     })
  } else {
    // without files
   return createRequest(basePath + tasks, 'post', data)
  }
}

