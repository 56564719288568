import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Container, Col } from 'reactstrap';
import './CreateTaskPage.css';
import Tag from './Tags';
import SimpleProcessLine from './simpleProcessLine';
import Card from './Card';
import LinkLogo from '../../ui/images/link.png';
import deleteLogo from '../../ui/images/delete.jpg';
import share from '../../ui/images/share.jpg';
import printer from '../../ui/images/printer.jpg';
import mainLogo from '../../ui/images/mainLogo.svg';
import CancellationAndReturn from './CancellationAndReturn';
import plus from '../../ui/images/plus.svg';
import WhitePlus from '../../ui/images/whitePlus.svg';
import editing from '../../ui/images/Editing.svg';
import Success from './Success';
import path from '../../ui/images/Path.svg';
import RechEditor from './RechEditor';
import CalendarForShow from '../../ui/images/CalendarForShow.svg';
import LinkManager from './LinkMeaneger';
import {restartTasksRequest, updateCurrentTaskInArray} from '../store/dashBoardStore/dashboardActions';
import { postTask } from '../apis/postTask';
import { putTaskById } from '../apis/putSpecificTask';
import { deleteFileFromTask } from '../apis/deleteFromTask';
import { getSpecificTask } from '../apis/getSpecificTask';
import {
  updateTaskTags,
  taskCreation,
  updateCurrentTask,
  updateCreatedTask,
  updateIsEdit,
  updateIsCreate,
  updateNewLine,
  addOrderToWorkProcess,
  changeAdvertisingOnClickToTrue,
  changeSaveDraftToTrue,
} from '../store/taskStore/taskActions';
import {
  TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK,
  TEXT_GO_BACK_IN_VIEW_TASK,
  TEXT_TO_ALERT_OF_MAKE_SURE_DELETE_WORK_PROCESS
} from '../../ui/strings';
import {
  prepareData,
  checkDataToSend,
  checkDataToSendWithOutAlert,
  handleDeleteClick,
} from './prepareDataToSend';
import { addNewWorkProcessLine } from './commonFunctions';
import LittlePlus from './littlePlus';
import { isAllowedAccess } from '../apis/middleWareToken';
import ClearIcon from '@material-ui/icons/Clear';
import {
  CREATE_TASK_ESSENCE_PLACEHOLDER,
  CREATE_TASK_ARRAY_NAME,
  CREATE_TASK_ALERT_PLEASE_GIVE_A_HEADLINE,
  CREATE_TASK_ALERT_PLEASE_CHOSE_A_CATEGORY,
  WINDOW_CONFIRM_INSERT_AGAIN,
  TEXT_TASK_ARRAY,
  TEXT_DRAFT,
  TEXT_SUCCESSFULLY_CREATED_MALE,
  TEXT_SUCCESSFULLY_CREATED_FEMALE,
  YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_TASK,
  TEXT_CREATE_TASK_ARRAY,
  TEXT_CREATE_ARRAY,
  INTERNET_CONNECTION,
  GENERAL_INTERNAL_ERROR,
} from '../../ui/strings';
import TaskLink from './taskLink';
import axios from 'axios';
import {basePath, tasks, users} from '../apis/paths';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import UndoRedo from './undoRedo'
import {ActionCreators as UndoActionCreators} from "redux-undo";


class CreateTaskPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      SaveDraft: false,
      SavedAsADraft: false,
      value: this.props.currentTaskData && this.props.currentTaskData.title
        ? this.props.currentTaskData.title
        : '',
      header: '',
      showV: false,
      AdvertisingOnClick: false,
      show: false,
      fakeInput: [],
      isReady: false,
      counterOfCreateProcesses: [''],
      linksCounter: [],
      isLinkCreate: false,
      removedWorkProcess: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.toEdit) {
      this.cleaningStoreTask();
    } else {
      setInterval(this.handleAutoSaving, 10000)
    }
  };

  updatingStoreTask = (key, value) => {
    let receivedTaskData = Object.assign({}, this.props.currentTaskData); // not sure if without this action does deep copy??
    let updatedObject = {};
    updatedObject[key] = value;
    let updatedTaskData = Object.assign(receivedTaskData, updatedObject);
    this.props.updateCurrentTask(updatedTaskData);
  };

  cleaningStoreTask() {
    this.props.updateCurrentTask({
      title: '',
      group_id: 2,
      description: '',
      status_name: 'publish',
      work_process: [],
      links: [],
      files: [],
      category: null,
    });
  }

  linkCreateToggle = () => {
    this.setState({isLinkCreate: !this.state.isLinkCreate});
  };

  listTitleInputFanc = (e) => {
    if (e.target.value.length > 0) {
      this.setState({header: CREATE_TASK_ARRAY_NAME, value: e.target.value});
      // for store
      this.updatingStoreTask('title', e.target.value);
    } else if (e.target.value.length == 0) {
      this.updatingStoreTask('title', e.target.value);
      this.setState({header: '', isReady: false});
    }
  };

  uploadFile = () => {
    this.upload.click();
  };

  categorySelect = (e) => {
    let id;
    this.props.categories[0].categories.forEach((elem) => {
      let lookFor = e.target.value.split('🗀');
      if (lookFor && lookFor.length > 1 && elem.name == lookFor[1].trim()) {
        id = elem.id;
      }
    });
    //for store
    this.updatingStoreTask('category', id);
  };

  newFile = (e) => {
    if (e.target.files[0]) {
      if (!this.props.currentTaskData.files) {
        this.props.currentTaskData.files = [];
      }
      let files = this.props.currentTaskData.files.slice();
      files.push(e.target.files[0]);
      this.updatingStoreTask('files', files);
    }
  };

  tagsHandler = (tag, status) => {
    if (!this.props.currentTaskData.tags_ids) {
      this.props.currentTaskData.tags_ids = [];
    }
    let tags_ids = this.props.currentTaskData.tags_ids.slice();
    status
      ? tags_ids.push(tag)
      : (tags_ids = tags_ids.filter((item) => item !== tag));
    this.updatingStoreTask('tags_ids', tags_ids);
  };
  essenceHandleChange = (content) => {
    // for store
    this.updatingStoreTask('description', content);
  };

  saveTask = async () => {
    let access = await isAllowedAccess();
    if (access) {
      this.setState({SaveDraft: true, SavedAsADraft: true});
      this.updatingStoreTask('status_name', 'publish');
      // this.updatingStoreTask('status_name', 'draft');

      if (this.props.currentTaskData.title === '' || null) {
        alert(CREATE_TASK_ALERT_PLEASE_GIVE_A_HEADLINE);
        return;
      } else if (!this.props.currentTaskData.category > 0) {
        alert(CREATE_TASK_ALERT_PLEASE_CHOSE_A_CATEGORY);
        return;
      }
      await this.advertising(this.props.currentTaskData);
    } else window.location.reload();
  };

  // checkIfRemoveWorkProcess(task) {
  //     let originalWorkProcessArr = this.props.currentTaskData.work_process.length;
  //     let workProcessAfterRemoveArr = task.work_process.length;
  //     if (workProcessAfterRemoveArr < originalWorkProcessArr) {
  //         return true;
  //     }
  //     for (let i = 0; i < task.work_process.length; i++) {
  //         let originalChildrenArr = this.props.currentTaskData.work_process[i].children.length;
  //         let childrenAfterRemoveArr = task.work_process[i].children.length;
  //         if (childrenAfterRemoveArr < originalChildrenArr) {
  //             return true;
  //         }
  //     }
  //     return false;
  // }

  advertisingOnClick = async () => {
    let access = await isAllowedAccess();
    if (access) {
      let data = prepareData(
        this.props.currentTaskData,
        this.props.newLine,
        this.props.updateNewLine,
      );
      if (data) {
        this.setState({AdvertisingOnClick: true, SaveDraft: false}, () => {
          this.advertising(data);
        });
      }
    } else window.location.reload();

    // let access = await isAllowedAccess();
    // if (access) {
    //     let data = prepareData(this.props.currentTaskData, this.props.newLine, this.props.updateNewLine);
    //     if (data) {
    //         let checkIfRemoveWorkProcess = this.checkIfRemoveWorkProcess(data);
    //         if (checkIfRemoveWorkProcess || this.state.removedWorkProcess) {
    //             if (window.confirm(TEXT_TO_ALERT_OF_MAKE_SURE_DELETE_WORK_PROCESS)) {
    //                 // this.props.addOrderToWorkProcess(this.props.currentTaskData)
    //                 // this.props.changeAdvertisingOnClickToTrue();
    //                 // this.props.changeSaveDraftToTrue();
    //                 this.setState({ AdvertisingOnClick: true, SaveDraft: false, removedWorkProcess: false }, () => {
    //                     this.advertising(data);
    //                 });
    //             }
    //             else {
    //                 this.updateSpecificTaskFromDB();
    //                 this.setState({ removedWorkProcess: false });
    //             }
    //         }
    //         else if (!checkIfRemoveWorkProcess || !this.state.removedWorkProcess) {
    //             this.setState({ AdvertisingOnClick: true, SaveDraft: false }, () => {
    //                 this.advertising(data);
    //             });
    //         }
    //     }
    // }
    // else window.location.reload();
  };

  advertising = async (data) => {
    if (
      this.props.currentTaskData.status_name == 'draft' &&
      this.state.AdvertisingOnClick
    ) {
      this.updatingStoreTask('status_name', 'publish');
      data.status_name = 'publish';
    }
    if (!this.checkInternetConnection()) {
      return
    }

    function sendToServer(mode, data, id) {
      return mode ? putTaskById(data, id) : postTask(data)
    }

    let status = this.props.isEdit || (this.state.SavedAsADraft && !this.props.currentTaskData.status_name == 'draft')
    sendToServer(status, data, this.props.currentTaskData.id)
      .then(res => {
        this.props.updateCurrentTaskInArray({data: res.data.data, id: res.data.data.id})
        this.props.updateCreatedTask(res.data.data);
        // this.props.updateParent(res.data.data)
        this.props.restartTasksRequest(true);
        this.setState({show: true});
        setTimeout(() => {
          this.props.taskCreation(true);
        }, 2000);
      })
      .catch(e => {
        alert(e)
      })


    // if (ans && ans.data && ans.data.success) {

    // } else {
    //   (async () => {
    //     await this.checkInternetConnection()
    //       .then(function (response) { })
    //       .catch((error) => {
    //         // console.log(error.response);
    //         if (error.response) window.confirm(GENERAL_INTERNAL_ERROR);
    //         else window.confirm(INTERNET_CONNECTION);
    //       });
    //   })();

    //window.location.reload();
    // }
    // this.setState({ showV: true })
  };
//===============================AVI========================================
  saveTaskInLocalStorage() {
    localStorage.setItem("task", JSON.stringify(tasks))
  }

  getDataFromLocalStorage() {
    return JSON.parse(localStorage.getItem("task"))
  }

  cleanDataFromLocalStorage(){
    localStorage.removeItem("task")
  }

  handleAutoSaving = () => {

  }


  //==========================================================================


  checkInternetConnection = async () => {
    axios.get(`${basePath}ggg`)
      .catch(e=>{
        if(e.toJSON().message === 'Network Error'){
          alert('no internet connection')
          return false
        } else if(e.response.status == 404) {
          return true
        } else {
          alert(e.toJSON().message)
          return false
        }
    });
  };

  handleDeleteLinkOnClick = async (elm, index) => {
    let task = Object.assign({}, this.props.currentTaskData);
    task.links.splice(index, 1);
    this.props.updateCurrentTask(task);
    // if (elm.id === undefined) {
    //     let task = Object.assign({}, this.props.currentTaskData);
    //     task.links.splice(index, 1);
    //     this.props.updateCurrentTask(task);
    // }
    // else if (elm.id !== undefined) {
    //     let task = Object.assign({}, this.props.currentTaskData);
    //     task.links.splice(index, 1);
    //     let answer = await putTaskById(task, this.props.currentTaskData.id);
    //     if (answer && answer.data && answer.data.success) {
    //         this.props.updateCurrentTask(answer.data.data);
    //     }
    // }
  };

  displayLinks() {
    if (
      this.props.currentTaskData &&
      this.props.currentTaskData.links &&
      this.props.currentTaskData.links.length > 0
    ) {
      let links = [];
      this.props.currentTaskData.links.map((e, index) => {
        links.push(
          <TaskLink
            handleDeleteLinkOnClick={this.handleDeleteLinkOnClick}
            e={e}
            index={index}
          />,
        );
      });
      return links;
    }
  }

  addNewLine = (index, parent_id, childIndex) => {
    this.props.updateCurrentTask(
      addNewWorkProcessLine(
        this.props.currentTaskData,
        '<p></p>',
        true,
        index,
        childIndex,
        this.props.currentTaskData.id ? this.props.currentTaskData.id : null,
        parent_id,
      ),
    );
  };

  displayWorkProcess() {
    if (
      this.props.currentTaskData &&
      this.props.currentTaskData.work_process.length > 0
    ) {
      let workProcessArray = [];
      for (let i = 0; i < this.props.currentTaskData.work_process.length; i++) {
        let line = this.props.currentTaskData.work_process[i];
        let children = [];

        if (line.children.length > 0) {
          // new line in children.
          line.children.map((el, indexChild) => {
            children.push(
              <SimpleProcessLine
                isChild={true}
                parent_id={el.parent_id ? el.parent_id : null}
                task_id={
                  this.props.currentTaskData.id
                    ? this.props.currentTaskData.id
                    : null
                }
                id={el.id ? el.id : null}
                checked={el.checked ? el.checked : false}
                self_index={indexChild}
                father_index={i}
                is_new={false}
                description={el.description}
                children={[]}
                handleDeleteClick={() => {
                  handleDeleteClick(
                    true,
                    i,
                    indexChild,
                    this.props.currentTaskData,
                    this.props.updateCurrentTask,
                  );
                  // this.setState({ removedWorkProcess: true });
                }}
              />,
            );
            children.push(
              <LittlePlus
                fatherIndex={i}
                parent_id={line.id ? line.id : null}
                addNewChild={this.addNewLine}
                propsStyle={{ marginRight: '46px' }}
                selfIndex={indexChild}
              />,
            );
          });
        }
        // if user press on plus button it's added to the next work process and.
        if (children.length > 0) {
          workProcessArray.push(
            <SimpleProcessLine
              isChild={false}
              parent_id={line.parent_id ? line.parent_id : null}
              task_id={
                this.props.currentTaskData.id
                  ? this.props.currentTaskData.id
                  : null
              }
              id={line.id ? line.id : null}
              checked={line.checked ? line.checked : false}
              self_index={i}
              father_index={i}
              is_new={false}
              description={line.description}
              children={line.children}
              handleDeleteClick={() => {
                handleDeleteClick(
                  false,
                  i,
                  null,
                  this.props.currentTaskData,
                  this.props.updateCurrentTask,
                );
                // this.setState({ removedWorkProcess: true });
              }}
            />,
          );
          // moshe b added it.
          workProcessArray.push(
            <LittlePlus
              fatherIndex={i}
              parent_id={line.id ? line.id : null}
              addNewChild={this.addNewLine}
              propsStyle={{ marginRight: '46px' }}
              // Each time you press the plus button, a work work process is added to the next location (current location +1)
              // and in this case it should be in position 0 so I bring the number -1 to reach position 0.
              selfIndex={-1}
            />,
          );
          // moshe b added it.
          workProcessArray = [...workProcessArray, ...children];
          // ?
        } else {
          workProcessArray.push(
            <SimpleProcessLine
              isChild={false}
              parent_id={line.parent_id ? line.parent_id : null}
              task_id={
                this.props.currentTaskData.id
                  ? this.props.currentTaskData.id
                  : null
              }
              id={line.id ? line.id : null}
              checked={line.checked ? line.checked : false}
              self_index={i}
              is_new={false}
              description={line.description}
              children={line.children}
              father_index={i}
              handleDeleteClick={() => {
                handleDeleteClick(
                  false,
                  i,
                  null,
                  this.props.currentTaskData,
                  this.props.updateCurrentTask,
                );
                // this.setState({ removedWorkProcess: true });
              }}
            />,
          );
          workProcessArray.push(
            <LittlePlus
              fatherIndex={i}
              parent_id={line.id ? line.id : null}
              addNewChild={this.addNewLine}
              propsStyle={{ marginRight: '46px' }}
            />,
          );
        }
      }

      return workProcessArray;
    }
  }

  displayCardsFiles() {
    let cards = [];
    if (
      this.props.currentTaskData &&
      this.props.currentTaskData.files &&
      this.props.currentTaskData.files.length > 0
    ) {
      this.props.currentTaskData.files.map((file, i) => {
        let substringName = file.name.substring(file.name.indexOf('.') + 1);
        cards.push(
          <Card
            handleDeleteFile={() => this.handleDeleteFile(file, i)}
            file={file}
            name={file.name}
            type={file.type || substringName}
          />,
        );
      });
      return cards;
    }
  }

  async handleDeleteFile(file, i) {
    let task = Object.assign({}, this.props.currentTaskData);
    task.files.splice(i, 1);
    this.props.updateCurrentTask(task);
    // if (file.id === undefined) {
    //     let task = Object.assign({}, this.props.currentTaskData);
    //     task.files.splice(i, 1);
    //     this.props.updateCurrentTask(task);
    // }
    // else if (file.id !== undefined) {
    //     let answer = await deleteFileFromTask(file.id);
    //     if (answer && answer.data && answer.data.success) {
    //         let task = Object.assign({}, this.props.currentTaskData);
    //         task.files.splice(i, 1);
    //         this.props.updateCurrentTask(task);
    //     }
    // }
  }

  // cancelAndReturnOnClick = () => {
  //   this.updateSpecificTaskFromDB();
  // };

  async updateSpecificTaskFromDB() {
    let oldTask = await getSpecificTask(this.props.currentTaskData.id);
    if (oldTask && oldTask.data && oldTask.data.success) {
      this.props.updateCurrentTask(oldTask.data.data);
      this.props.restartTasksRequest(false);
    }
  }

  render() {
    let requiredFieldChecker = checkDataToSendWithOutAlert(this.props.currentTaskData);
    // workspace!
    // let banner = document.getElementsByClassName('tox tox-tinymce tox-tinymce-inline tox-tinymce--toolbar-sticky-off');
    // if (banner[0] !== undefined) {
    //     for (let i = 0; i < banner.length; i++) {
    //         document.getElementsByClassName('whiteContainer container')[0].addEventListener("scroll",() => {
    //             if (banner[i] !== undefined) {
    //                 banner[i].style.display = 'none';
    //             }
    //         });
    //     }
    // }
    // workspace!

    if (!this.props.isEdit && !this.props.isCreate) {
      // this.cleaningStoreTask()
      this.props.updateIsCreate(true);
    }

    let tags = [];
    this.props.tags.forEach((element, index) => {
      let selected;
      if (this.props.currentTaskData && this.props.currentTaskData.tags) {
        this.props.currentTaskData.tags.forEach((tag, index) => {
          if (tag.id == element.id) {
            selected = true;
          }
        });
      }

      tags.push(
        <Tag
          key={index}
          selected={selected}
          tagsHandler={this.tagsHandler}
          id={element.id}
          name={element.name}
        />,
      );
    });

    var options = [];
    if (this.props.categories[0]) {
      this.props.categories[0].categories.forEach((element, index) => {
        options.push(
          <option
            element={element}
            key={index}
            selected={ this.props.currentTaskData.category == element.id}
          >
            &#x1F5C0;{/* folder in html */}
            {'  '}
            {element.name}
          </option>,
        );
      });
    }

    if (this.props.isTaskCreated) {
      return <Redirect to="/dashboard"></Redirect>;
    } else {
      return (
        <div className="CreateTaskPage">
          {this.state.show && (
            <Success
              name={this.props.currentTaskData.title}
              line_1={
                !this.state.SaveDraft
                  ? TEXT_TASK_ARRAY +
                  ' " ' +
                  this.props.currentTaskData.title +
                  ' " '
                  : TEXT_DRAFT
              }
              line_2={
                !this.state.SaveDraft
                  ? TEXT_SUCCESSFULLY_CREATED_MALE
                  : TEXT_SUCCESSFULLY_CREATED_FEMALE
              }
              line_3={YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_TASK}
            />
          )}
          <div>
            {/*{ UndoRedo(*/}
            {/*  {*/}
            {/*  canUndo: this.props.canUndo,*/}
            {/*  canRedo: this.props.canRedo,*/}
            {/*  onUndo: this.props.onUndo,*/}
            {/*  onRedo: this.props.onRedo})*/}
            {/*}*/}
          </div>
          <CancellationAndReturn
            cancelAndReturnOnClick={this.cancelAndReturnOnClick}
            editTask={true}
          />
          <Row className="headerDiv">
            <div className="createHeader">{TEXT_CREATE_TASK_ARRAY}</div>
            <div className="dubleHeader">
              <button className="editListButton">
                <img src={editing} style={{ marginLeft: '7px' }} />
                <span className="editList">{TEXT_CREATE_ARRAY}</span>
              </button>
              <button className="draftSave" sm="3" onClick={this.saveTask}>
                שמירת מערך כטיוטא
              </button>
              <div>
                <button
                  className="buttonAdvertising"
                  onClick={this.advertisingOnClick}
                >
                  <img
                    src={requiredFieldChecker ? WhitePlus : plus}
                    style={{ marginLeft: '5px' }}
                    className="imgPlus"
                  />
                  <span
                    className={
                      requiredFieldChecker
                        ? 'ArrayAdvertisingReady'
                        : 'ArrayAdvertising'
                    }
                  >
                    פרסום מערך
                  </span>
                </button>
              </div>
              <img className="mainLogo" src={mainLogo} />
            </div>
          </Row>
          <Container className="whiteContainer">
            <div className="logos">
              <div className="frameForLogo">
                <img className="whiteImg" src={deleteLogo} />
              </div>
              <div className="frameForLogo">
                <img className="whiteImg" src={printer} />
              </div>
              <div className="frameForLogo">
                <img className="whiteImg" src={share} />
              </div>
              <div className="frameForLogo">
                <img className="whiteImg" src={printer} />
              </div>
              <img className="frameForLogo" src={CalendarForShow} />
            </div>
            <p className="header">{this.state.header}</p>
            <div className="titleAndCategory">
              <input
                onChange={(e) => this.listTitleInputFanc(e)}
                type="text"
                id="listTitle"
                placeholder={!this.props.isEdit ? CREATE_TASK_ARRAY_NAME : ''}
                value={this.props.currentTaskData ? this.props.currentTaskData.title:''}
              ></input>
            </div>
            <div id="maintaskEssence">
              <div className="taskEssence">מהות המשימה</div>
              <RechEditor
                toEdit={this.props.isEdit}
                onChange={this.essenceHandleChange}
                inline={false}
                toolbar=" bold "
                value={
                  !this.props.isEdit
                    ? CREATE_TASK_ESSENCE_PLACEHOLDER
                    : this.props.currentTaskData
                      ? this.props.currentTaskData.description
                      : ''
                }
              />
            </div>
            <div className="workProcess">
              <p>תהליך עבודה </p>
            </div>
            {this.displayWorkProcess()}
            <SimpleProcessLine isNew={true} children={[]} isChild={false} />
            <p className="linksHeader mt-3">קישורים</p>
            <div className="linksToDisplay">
              {this.displayLinks()}
              {this.state.isLinkCreate && (
                <LinkManager linkCreateToggle={this.linkCreateToggle} />
              )}
            </div>
            <Row>
              <Col>
                <button className="morLinx" onClick={this.linkCreateToggle}>
                  <img src={LinkLogo} style={{ marginLeft: '3px' }} />
                  <span className="addLinksText">הוספת קישורים </span>
                </button>
              </Col>
            </Row>
            <p className="filesHeader mb-0 mt-3">קבצים</p>
            <div className="cards">{this.displayCardsFiles()}</div>
            <button className="addInput" onClick={this.uploadFile}>
              <img src={path} style={{ marginLeft: '3px', marginTop: '3px' }} />
              <p id="addFile">הוספת קובץ</p>
            </button>
            <input
              ref={(ref) => (this.upload = ref)}
              style={{ display: 'none' }}
              type="file"
              onChange={(e) => this.newFile(e)}
            />

            <div>
              <div className="tagsHeaderCategory">קטגוריה*</div>

              <select
                className="selectcategory"
                onChange={(e) => this.categorySelect(e)}
                id="category"
              >
                <option>בחר</option>
                {options}
              </select>
            </div>
            <p className="tagsiHeader">תגיות</p>
            <div className="tags">{tags}</div>
          </Container>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    tags: state.dashboard.tags,
    categories: state.dashboard.categories,
    selectedTags: state.task.task.selectedTags,
    isTaskCreated: state.task.task.isTaskCreated,
    currentTaskData: state.task.task_undo.present.currentTaskData,
    isRoutesBounded: state.dashboard.isRoutesBounded,
    isEdit: state.task.task.isEdit,
    isCreate: state.task.task.isCreate,
    newLine: state.task.task.newLine,
    advertisingOnClick: state.task.task.advertisingOnClick,
    saveDraft: state.task.task.saveDraft,
    tasks: state.dashboard.tasks,
    // dataChangesHistory: state.task.task.dataChangesHistory,
    canUndo: state.task.task_undo.past.length > 0,
    canRedo: state.task.task_undo.future.length > 0
  };
};


export default connect(mapStateToProps, {
  updateTaskTags,
  restartTasksRequest,
  taskCreation,
  updateCurrentTask,
  updateCreatedTask,
  updateIsCreate,
  updateNewLine,
  addOrderToWorkProcess,
  updateIsEdit,
  updateCurrentTaskInArray,
  // onUndo: () => UndoActionCreators.undo(),
  // onRedo: () => UndoActionCreators.redo()
  // changeAdvertisingOnClickToTrue,
  // changeSaveDraftToTrue
})(CreateTaskPage);
