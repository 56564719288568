import React, {useState} from 'react'
import Shape from '../../../ui/images/Shape.svg'
import copy from '../../../ui/images/copy.svg'
import calender from '../../../ui/images/calendar.svg'
import del from '../../../ui/images/delete.svg'
import add_step_down from '../../../ui/images/add_step_down.svg'
import add_step_up from '../../../ui/images/add_step_up.svg'
import add_sub_step from '../../../ui/images/add_sub_step.svg'
import whatsApp from '../../../ui/images/whatsApp.svg'
import './advancedFeatures.css'
import {COPY_LINK, SEND_LINK, EMBED_IN_THE_LOG, DELETE, ADD_ROW_UP, ADD_ROW_DOWN, ADD_CHILD} from '../../../ui/strings'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {copyToClipboard} from "./auxiliaryFunctions";
import {handleClick} from "../../apis/google/addCalendarEvent";

const AdvancedFeatures = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  function getDetails(where) {
    if (props.child) {
      return [props.details.parentIndex, props.details.index, where]
    }
    return [null, props.details.index, where]
  }

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
      <DropdownToggle className={'dropdown-btn show'}>
        <img src={Shape} alt="" className=" m-2"/>
      </DropdownToggle>
    <DropdownMenu className='box-shadow' style={{textAlign:'start', zIndex: '99999'}}>

      {props.editMode ? <span>

        <DropdownItem toggle={() => toggle()} onClick={() => props.onClick(getDetails('down'))}>
          <img src={add_step_down} alt="" className="ml-2" width='22' height='22'/>
          {ADD_ROW_DOWN}
        </DropdownItem>

        {!props.child ?
          <DropdownItem toggle={() => toggle()} onClick={() => props.onClick(getDetails('child'))}>
            <img src={add_sub_step} alt="" className="ml-2" width='22' height='22'/>
            {ADD_CHILD}
          </DropdownItem>
          : <span/>}
        {/*<DropdownItem divider/>*/}
        <DropdownItem toggle={() => toggle()} onClick={() => props.onClick(getDetails('up'))}>
          <img src={add_step_up} alt="" className="ml-2" width='22' height='22'/>
          {ADD_ROW_UP}
        </DropdownItem>
      </span> : <span/>}

      <DropdownItem className='dropdown-item' onClick={()=>navigator.clipboard.writeText(`${window.location.href}`)}>
        <img src={copy} alt="" className="ml-2" width='22' height='22'/>
        {COPY_LINK}
      </DropdownItem>

      <DropdownItem toggle={() => toggle()} disabled>
        <img src={whatsApp} alt="" className="ml-2" width='22' height='22'/>
        {SEND_LINK}
      </DropdownItem>

      <DropdownItem toggle={() => toggle()} disabled={true/*props.event.user === null*/} onClick={()=>handleClick(props.event.user)}>
        <img src={calender} alt="" className="ml-2" width='22' height='22'/>
        {EMBED_IN_THE_LOG}
      </DropdownItem>

      {props.editMode ?

        <DropdownItem toggle={() => toggle()} onClick={() => {
          props.delete(props.details)
        }}>
          <img src={del} alt="" className="ml-2" width='22' height='22'/>
          {DELETE}
        </DropdownItem>

        : <span/>}

    </DropdownMenu>
  </Dropdown>)
}
export default AdvancedFeatures
