import React from 'react';
import { TEXT_COPY_TASK_BUTTON } from '../../ui/strings';
import './copyTaskButton.css';

function CopyTaskButton(props) {
    return (
        <button
            disabled={!props.isAllowed}
            className={props.isAllowed ? 'copy-task-btn' : 'copy-task-btn-is-not-isStaff'}
            onClick={props.handleCopyTaskClick}
        >
            {TEXT_COPY_TASK_BUTTON}
        </button>
    );
}

export default CopyTaskButton;