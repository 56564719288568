import React from "react"

const PopupAction = ({item}) => {
  return (
    <div
      className={"add-item add-item-icon" + " " + item.class}
      onClick={item.onClick}
    >
      {item.text}
    </div>
  )
}

export default PopupAction