import Editor from "../editor/quill";
import UsersDropDown from "../usersDropDown";
import {DateIcon, SendMessage} from "../statusMode";
import Datepicker from "../datepicker";
import AdvancedFeatures from "../advancedFeatures";
import React, {Component} from "react";
import {format} from 'date-fns'
import '../taskPage.css'
import {CHOOSE_A_DATE} from "../../../../ui/strings";

class TableRowLast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      task: this.props.task,
      index: this.props.index,
      child: this.props.child,
      parentIndex: this.props.parentIndex,
      hover: false,
      selected: false,
      description:''

    }
  }

  handleState(field, value) {
    this.setState({[field]: value})
  }

  handleHover(mode) {
    this.props.handleHover
      ? this.props.handleHover(mode ? this.props.index : null)
      : this.handleState('hover', mode)
  }

  getDetails(field = '') {
    return this.props.newTask
      ? {parent: this.props.parentIndex, self: this.props.index, field: field}
      : {parent: this.props.task.parent_id, self: this.props.task.id, field: field}
  }

  render() {

    let description = ''

    function onTextChange(e){
      description = e
  }

    return (
      <span style={{display:'contents'}}>
      <tr
        style={{backgroundColor: this.state.hover || this.props.onHover ? '#f8f9fa' : ''}}
          className={this.props.onFocus ? 'choose box-shadow fs-16' : 'fs-16'}
          onMouseOver={() => !this.props.editMode ? this.handleHover(true) : null}
          onMouseLeave={() => !this.props.editMode ? this.handleHover(false) : null}
          onClick={(e) => {
            if (this.props.editMode) {
              this.props.newTask
                ? this.props.handleClick(e, {
                  parentIndex: this.props.parentIndex,
                  index: this.props.index})
                : this.props.handleClick(e, {
                  parentId: null,
                  id: this.props.id,
                  index: this.props.index
                })
            }
          }}
      >
        <td colspan={"2"} id='forEditor'>
          <Editor
            id={this.props.id}
            readOnly={!this.props.onFocus}
            displayToolbar={this.props.onFocus}
            details={{parentId: null, id: null, field: "comments"}}
            value={''}
            edit={this.props.editMode}
            newTask={this.props.newTask}
            onChange={onTextChange}
          />
        </td>
        <td>{this.props.task.leading_name}</td>
        <td>{''}</td>
        <td onClick={() =>this.props.sendMessage({task: this.props.task.id, description: description, user: parseInt(localStorage.getItem('userId'))}) }>
          <SendMessage/>
        </td>
        <td>{''}</td>
      </tr>
      </span>
    )
  }
}

export default TableRowLast
