import React, { Component } from 'react';
import PasswordInput from '../../../ui/form/passwordInput';
import logoUp from '../../../ui/images/yesode_logo_up.svg';
import { registerNewUser } from '../../apis/register';
import TextInput from '../../../ui/form/textInput';
import Success from '../../CreateTaskPage/Success';
import MyYesodLogo from '../../../ui/myLogos/myYesodLogo';
import { Redirect } from 'react-router-dom';
import { Row, Col } from 'antd';
import {
    REGISTER_TO_SYSTEM_HEADER, REGISTER_DETAILS_HEADER, REGISTER_PERSONAL_INFO_HEADER,
    TEXT_INPUT_MAIL_PLACEHOLDER_WITH_ASTERISK, TEXT_INPUT_PRIVATE_NAME_PLACEHOLDER_WITH_ASTERISK,
    TEXT_INPUT_LIMIT_PASSWORD_LABEL, TEXT_INPUT_FAMILY_NAME_PLACEHOLDER, TEXT_INPUT_CONFIRM_PASSWORD_PLACEHOLDER,
    TEXT_INPUT_PASSWORD_DO_NOT_MATCH_LABEL, TEXT_INPUT_ROLE_PLACEHOLDER, FINISH_REGISTER_BTN,
    REGISTER_TO_SYSTEM_AGREE_LINK_PART_1, REGISTER_TO_SYSTEM_AGREE_LINK_PART_2, REGISTER_TO_SYSTEM_AGREE_LINK_PART_3,
    REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_2, REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_3,
} from '../../../ui/strings';
import './registerToTheSystem.css';
import 'antd/dist/antd.css';


class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            firstName: "",
            lastName: "",
            role: "",
            email: "",
            password: "",
            validatePassword: "",
            isAgree: false,
            message: "",
            isRedirect: false,
            isRequest: false,
            incompatiblePasswords: false,
            showSuccessAlert: false

        };
        this.toggle = this.toggle.bind(this);
    }
    timeOut = 5000;


    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    updateState = (category, value) => {
        let obj = {}
        if (category == 'isAgree') {
            obj[category] = !this.state.isAgree

        } else {

            obj[category] = value
        }
        this.setState(obj)
    }

    async componentDidMount() {

        if (this.state.isRequest) {
            let ans;
            if (this.validateRegistration()) {
                registerNewUser(this.buildJson())
                    .then(() => {
                        this.setState({
                            open: !this.state.open,
                            isRedirect: true
                        })

                    });
            }
        }
    }

    async finishSignUp() {
        this.myPasswordTest();
        let ans;
        if (this.validateRegistration()) {
            (async () => {
                registerNewUser(this.buildJson())
                    .then(() => {
                        this.setState({
                            showSuccessAlert: true,
                            open: !this.state.open,
                            isRedirect: true
                        });
                    })
                    .catch(error => {
                        console.log(error);

                    })



            })();
        }

    }

    buildJson() {
        return {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            title: this.state.role,
            email: this.state.email,
            password: this.state.password,
            confirm_password: this.state.validatePassword,
            agree_the_conditions_of_use: true
        }
    }

    validateRegistration() {
        // first names
        if (!this.validateFirstOrLastNames('firstName')) {
            this.setState({ firstNameValidation: true });
            setTimeout(() => this.setState({ firstNameValidation: false }), this.timeOut);
            return false
        }
        // last name
        if (!this.validateFirstOrLastNames('lastName')) {
            this.setState({ lastNameValidation: true });
            setTimeout(() => this.setState({ lastNameValidation: false }), this.timeOut);
            return false
        }
        //email
        if (!this.validateEmail()) {
            this.setState({ emailValidation: true });
            setTimeout(() => this.setState({ emailValidation: false }), this.timeOut);
            return false
        }
        // password
        if (!this.validatePassword2()) {
            this.setState({ passwordValidation: true });
            setTimeout(() => this.setState({ passwordValidation: false }), this.timeOut);
            return false;
        }
        // password Authentication
        if (!this.passwordAuthentication()) {
            this.setState({ passwordAuthentication: true });
            setTimeout(() => this.setState({ passwordAuthentication: false }), this.timeOut);
            return false;
        }
        //isAgree
        if (!this.state.isAgree) {
            this.setState({ agreeValidation: true });
            setTimeout(() => this.setState({ agreeValidation: false }), this.timeOut);
            return false
        }
        this.setState({ message: "", isRequest: true });
        return true

    }

    validateFirstOrLastNames(kyeName) {
        if (!this.state[kyeName]) {
            return false
        } else {
            return true
        }
    }


    validateEmail() {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(this.state.email);
    }


    validatePassword2() {
        if (!this.state.password || this.state.password.length < 5) {
            return false
        } else return true
    }

    validatePassword() {
        if (this.state.password !== this.state.validatePassword || this.state.password.length < 5) {
            this.setState({ incompatiblePasswords: true })
            return false
        } else return true
    }

    myPasswordTest() {
        if (this.state.password !== this.state.validatePassword &&
            this.state.validatePassword.length > 0 && this.state.password.length >= 5) {
            this.setState({ incompatiblePasswords: true });
            setTimeout(() => this.setState({ incompatiblePasswords: false }), this.timeOut)
            return false
        }
        else return true
    }

    passwordAuthentication() {
        if (!this.state.validatePassword) {
            return false
        } else return true
    }

    keyPress(e) {
        if (e.keyCode == 13) {
            this.finishSignUp();
        }
    }

    render() {

        return (this.state.isRedirect ? <Redirect to='/dashboard'></Redirect> :
            this.state.open ?
                <div className='yesod-modal-layer'>
                    <div className='modal-logoUp'><img alt='' src={logoUp} /></div>
                    <div className='yesod-modal' >
                        <div className='modal-logo'>
                            {/* <img alt='' src={logo} /> */}
                            <MyYesodLogo
                                transparency={0.2}
                            />
                        </div>
                        <div className='modal-to-system-text'>{REGISTER_TO_SYSTEM_HEADER}</div>
                        <div className='around-rgister-system-inputs'>
                            <div className="rgister-system-inputs-header">
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <div className="modal-details-header">{REGISTER_DETAILS_HEADER}</div>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <div className="modal-details-header">{REGISTER_PERSONAL_INFO_HEADER}</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <TextInput
                                                placeholder={TEXT_INPUT_MAIL_PLACEHOLDER_WITH_ASTERISK}
                                                width={'418px'}
                                                onChange={this.updateState}
                                                category={'email'}
                                                validation={this.state.emailValidation}
                                                tabIndex={4}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <TextInput
                                                placeholder={TEXT_INPUT_PRIVATE_NAME_PLACEHOLDER_WITH_ASTERISK}
                                                width={'418px'}
                                                onChange={this.updateState}
                                                category={'firstName'}
                                                validation={this.state.firstNameValidation}
                                                tabIndex={1}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div >
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <PasswordInput
                                                placeholder={TEXT_INPUT_LIMIT_PASSWORD_LABEL}
                                                width={'418px'}
                                                asCheckLogo={true}
                                                minimumOfCharacters={5}
                                                onChange={this.updateState}
                                                category={'password'}
                                                type='check'
                                                checkLine={true}
                                                validation={this.state.passwordValidation}
                                                tabIndex={5}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <TextInput
                                                placeholder={TEXT_INPUT_FAMILY_NAME_PLACEHOLDER}
                                                width={'418px'}
                                                onChange={this.updateState}
                                                category={'lastName'}
                                                validation={this.state.lastNameValidation}
                                                tabIndex={2}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <PasswordInput
                                                placeholder={TEXT_INPUT_CONFIRM_PASSWORD_PLACEHOLDER}
                                                width={'418px'}
                                                downLabelText={TEXT_INPUT_PASSWORD_DO_NOT_MATCH_LABEL}
                                                onChange={this.updateState}
                                                category={'validatePassword'}
                                                confirmPassword={this.state.incompatiblePasswords}
                                                type={'confirm Password'}
                                                validation={this.state.passwordAuthentication}
                                                tabIndex={6}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <TextInput
                                                placeholder={TEXT_INPUT_ROLE_PLACEHOLDER}
                                                width={'418px'}
                                                onChange={this.updateState}
                                                category={'role'}
                                                tabIndex={3}
                                                keyPress={(e) => this.keyPress(e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ marginTop: '40px' }}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <button className='modal-btn' onClick={() => this.finishSignUp()}>{FINISH_REGISTER_BTN}</button>
                                        </div>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <div className="gutter-box">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customCheck1"
                                                    value="on"
                                                    onClick={(e) => this.updateState('isAgree', e.target.value)}
                                                    onKeyDown={(e) => this.keyPress(e)}
                                                    tabIndex={7}
                                                />
                                                <label
                                                    style={this.state.agreeValidation ? { borderBottom: '2px solid #FF8B90' } : {}}
                                                    className="custom-control-label"
                                                    htmlFor="customCheck1"
                                                >
                                                    {REGISTER_TO_SYSTEM_AGREE_LINK_PART_1}
                                                    <a style={{ textDecoration: 'underline' }} href='#'>{REGISTER_TO_SYSTEM_AGREE_LINK_PART_2}</a>
                                                    {REGISTER_TO_SYSTEM_AGREE_LINK_PART_3}
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {this.state.showSuccessAlert ?
                        <Success
                            line_1={this.state.firstName + ' ' + this.state.lastName}
                            line_2={REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_2}
                            line_3={REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_3}
                        />
                        : <React.Fragment/>}
                </div> : <React.Fragment/>
        );
    }
}

export default Modal;
