import React, { useEffect, useState } from "react";
import FolderCard from './ui/folderCard'
import { FOLDERS } from "../../../ui/strings";
import './foldersBuilder.css'
import { useHistory, useLocation } from "react-router-dom";

const FoldersBuilder = ({ 
  filteredFolders,
  currentFolder, 
  setCurrentFolder, 
  myGroup, 
  newFolderCreated, 
  setNewFolderCreated }) => {

  const location = useLocation()
  const history = useHistory()
  const [folderIdOpen, setFolderIdOpen] = useState(null)

  const handleSelectFolder = (folder) => {
    let urlParams = new URLSearchParams(location.search);
    urlParams.get("folder") ? urlParams.set("folder", folder.id) : urlParams.append("folder", folder.id)
    location.search = "?" + urlParams.toString()
    setCurrentFolder(folder)
    history.push(location.pathname + location.search)
  }

  const getFoldersCards = () => {
    const getAroundedFolders = (index) => {
      return { 
        before: filteredFolders[index-1]? filteredFolders[index-1]: null,
        after: filteredFolders[index+1]? filteredFolders[index+1]: null,
      }
    }
    return filteredFolders.map((folder, i) => {
      return <FolderCard
        key={folder.id * folder.order * ((i + 1) * 17)}
        folder={folder}
        currentFolder={currentFolder}
        index={i}
        selectFolder={() => { handleSelectFolder(folder) }}
        setFolderIdOpen={setFolderIdOpen}
        folderIdOpen={folderIdOpen}
        myGroup={myGroup}
        newFolderCreated={newFolderCreated}
        setNewFolderCreated={setNewFolderCreated}
        aroundedFolders = {getAroundedFolders(i)}
      />
    })
  }
  return (
    <div className="folder-area">
      <div className="folders-header">{FOLDERS}</div>
      <div className="folder-builder-wraper">
        {getFoldersCards()}
      </div>
    </div>
  )
}

export default FoldersBuilder