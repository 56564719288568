import React, { Component } from 'react';
import './listLeads.css';


class ListLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            highlightedLine: false
        }
    }
    onMouseOverLeads() {
        this.setState({highlightedLine:true});
    }
    onMouseLeaveLeads() {
        this.setState({highlightedLine:false});
    }
    
    render() {
        return (
            <option
                // onMouseOver={() => { this.onMouseOverLeads() }}
                // onMouseLeave={() => { this.onMouseLeaveLeads() }}
                // onClick={this.props.leadsOnClick}
                // style={this.state.highlightedLine ? {backgroundColor: 'rgb(220, 222, 231)'} : {}}
                // className="div-all-leads-names"
                value={this.props.item.id}>
                {this.props.formatText ? this.props.formatText(this.props.item) : this.props.item.name}
            </option>
        );
    }
}

export default ListLeads;