import React, { Component } from 'react';
import { Row, Container, Col } from 'reactstrap';
import './CreateTaskPage.css';
import bag from '../../ui/images/Bag.svg';
import CancellationAndReturn from './CancellationAndReturn';
import CreateTaskPage from './CreateTaskPage';
import ShowTask from './ShowTask';
import ShowDescription from './showDescription'
import Tag from './Tags';
import Card from './Card';
import CalendarForShow from '../../ui/images/CalendarForShow.svg';
import { connect } from 'react-redux';
import DeleteForShow from '../../ui/images/DeleteForShow.svg';
import ShareForShow from '../../ui/images/ShareForShow.svg';
import PrintForShow from '../../ui/images/PrintForShow.svg';
import mainLogo from '../../ui/images/mainLogo.svg';
// import purple_icon_to_search from '../../ui/images/purple_icon_to_search.svg';
// import blue_icon_to_search from '../../ui/images/blue_icon_to_search.svg';
// import green_icon_to_search from '../../ui/images/green_icon_to_search.svg';
// import orange_icon_to_search from '../../ui/images/orange_icon_to_search.svg';
import blueFolderLogo from '../../ui/images/single_array_folder.svg';
import purpleFolderLogo from '../../ui/images/purple_folder.svg';
import greenFolderLogo from '../../ui/images/green_folder.svg';
import orangeFolderLogo from '../../ui/images/orange_folder.svg';
import ClouDownloadForShow from '../../ui/images/ClouDownloadForShow.svg';
import LinkManager from './LinkMeaneger';
import { saveTaskToEdit, updateCurrentTask, updateCreatedTask, updateIsEdit } from '../store/taskStore/taskActions'
import { updateTaskArray } from '../store/dashBoardStore/dashboardActions'
import Success from './Success';
import { amazon } from '../apis/paths';
import EditLogo from './LogoEdit';
import { isUserStaff, isSuperUser } from '../apis/middleWareToken';
import { isAllowedAccess } from '../apis/middleWareToken';
import { putTaskById } from '../apis/putSpecificTask';
import CopyTaskButton from './copyTaskButton';
import { isManagerOfTheCurrentLibraryToCopy, isManagerOfTheCurrentLibraryToEditEndDelete } from './commonFunctions';
import TaskPage from "./screens/taskPage";
import { UPDATE_CURRENT_TASK_DATA } from "../store/taskStore/taskActionType";

import PrintPage from '../../utils/pdf/printPage';

class ViewTask extends Component {

    constructor(props) {

        super(props)
        this.state = {
            show: false,
            toEdit: false,
            myIndex: 0,
            overOnEditBtn: false,
            task: this.props.tasks.find(task => task.id == this.props.id)
        }
        this.isStaff = isUserStaff();
        this.isSuperUser = isSuperUser();
    }

    findMe() {
        let myIndex = this.props.tasks.findIndex(task => task.id == this.props.id)
        this.setState({ myIndex: myIndex })
    }

    componentDidMount = () => {
        this.findMe();
        let myGroupId = this.getUserInformation('groupId');
        this.setState({ myGroupId: myGroupId });

        // setInterval(() => {
        //     this.setState({ "show": true });
        // }, 2000);
        // setTimeout(() => this.setState({ "show": true }), 4000);
        this.props.dataToPdf(this.state.task)
    }

    getUserInformation(title) {
        return localStorage.getItem(title)
    }

    // editTask = async () => {
    //     let access = await isAllowedAccess();
    //     if (access) {
    //         this.props.updateCurrentTask(this.state.task);
    //         this.props.updateIsEdit(true);
    //     }
    //     else window.location.reload();
    // }

    // displayLinks() {

    //     if (this.state.task.links && this.state.task.links.length > 0) {
    //         let links = this.state.task.links.map((e, index) => {
    //             return <a style={{ marginRight: '70px', 'textDecoration': 'underline' }} key={index} className="a" href={e.url} target="_blank">{e.text}</a>
    //         })
    //         return <div style={{ marginRight: '70px' }} className="linksToDisplay">{links}</div>
    //     }
    // }

    // onCheckedWorkProcess = async (value, selfIndex, fatherIndex) => {
    //     let allTasks = Array.from(this.props.tasks)
    //     let myTask = this.state.task
    //     let updatingWorkProcess = Array.from(myTask.work_process);

    //     this.updateWorkProcess(updatingWorkProcess, value, selfIndex, fatherIndex)

    //     myTask.work_process = updatingWorkProcess
    //     allTasks[this.state.myIndex] = myTask;
    //     this.props.updateTaskArray(allTasks)
    //     let ansFromServer = await putTaskById(myTask, this.props.id)
    //     if (!ansFromServer.data.success) {  //if not TODO ...
    //         this.updateWorkProcess(updatingWorkProcess, !value, selfIndex, fatherIndex)
    //         myTask.work_process = updatingWorkProcess
    //         allTasks[this.state.myIndex] = myTask
    //         this.props.updateTaskArray(allTasks)
    //         alert('updating work process line not succeed')
    //     }
    // }
    // updateWorkProcess(workArray, value, selfIndex, fatherIndex) {
    //     if (fatherIndex != null) {
    //         //check all childs
    //         if (workArray[fatherIndex].checked == true && !value == false) {
    //             workArray[fatherIndex].checked = !value
    //             // workArray[fatherIndex].children[selfIndex].checked =!value
    //         } else if (workArray[fatherIndex].checked == false && !value == true) {
    //             let fatherChecked = true;
    //             for (let i = 0; i < workArray[fatherIndex].children.length; i++) {
    //                 if (workArray[fatherIndex].children[i].checked == false && i != selfIndex) {
    //                     fatherChecked = false
    //                 }
    //             }
    //             if (fatherChecked) workArray[fatherIndex].checked = !value
    //         }
    //         workArray[fatherIndex].children[selfIndex].checked = !value
    //     } else {
    //         if (workArray[selfIndex].children.length > 0) {
    //             // All childs like father
    //             for (let i = 0; i < workArray[selfIndex].children.length; i++) {
    //                 workArray[selfIndex].children[i].checked = !value
    //             }
    //             workArray[selfIndex].checked = !value
    //         } else {
    //             workArray[selfIndex].checked = !value

    //         }
    //     }
    // }

    // displayWorkProcessLines() {
    //     let lines = [];
    //     for (let i = 0; i < this.state.task.work_process.length; i++) {
    //         let line = this.state.task.work_process[i];
    //         let children = [];
    //         if (line.children.length > 0) {
    //             children = line.children.map((el, indexChild) => {
    //                 return <ShowTask
    //                     className={"processLineChildForShowTask"}
    //                     htmlText={el.description}
    //                     isChecked={el.checked}
    //                     onChecked={this.onCheckedWorkProcess}
    //                     selfIndex={indexChild}
    //                     fatherIndex={i} />
    //             })
    //         }
    //         if (children.length > 0) {
    //             lines.push(
    //                 <ShowTask
    //                     className={"processLineInWiewTask"}
    //                     htmlText={line.description}
    //                     isChecked={line.checked}
    //                     onChecked={this.onCheckedWorkProcess}
    //                     selfIndex={i}
    //                     fatherIndex={null} />
    //             )
    //             lines = [...lines, ...children]
    //         } else {
    //             lines.push(
    //                 <ShowTask
    //                     className={"processLineInWiewTask"}
    //                     htmlText={line.description}
    //                     isChecked={line.checked}
    //                     onChecked={this.onCheckedWorkProcess}
    //                     selfIndex={i}
    //                     fatherIndex={null} />
    //             )
    //         }

    //     }

    //     return lines
    // }

    updateTaskChanged(task) {
        this.setState({ task })
    }

    // addEventToCalender() {

    // }
    
    render() {
        let task = this.props.tasks.find(t => t.id == this.props.id)
        this.props.updateCurrentTask(task)

        // let folderLogo;
        // if (task.category === 1) {
        //     folderLogo = blueFolderLogo;
        // }
        // if (task.category === 2) {
        //     folderLogo = purpleFolderLogo;
        // }
        // if (task.category === 3) {
        //     folderLogo = greenFolderLogo;
        // }
        // if (task.category === 4) {
        //     folderLogo = orangeFolderLogo;
        // }
        // let categoryLogo;
        // let myCategory = this.props.categories.find(c => c.id === task.group_id);
        // let libraryManager = isManagerOfTheCurrentLibraryToCopy(myCategory, this.state.myGroupId, this.isStaff, this.isSuperUser);
        // let libraryManagerToEditEndDelete = isManagerOfTheCurrentLibraryToEditEndDelete(myCategory, this.state.myGroupId, this.isStaff, this.isSuperUser)
        // // console.log('libraryManager, ', libraryManager);

        // if (task.group_id !== 2) {
        //     // console.log('myCategory, ', myCategory);
        //     categoryLogo = myCategory.logo !== '' && myCategory.logo !== null ? myCategory.logo : '';
        // }
        // // console.log('categoryLogo, ', categoryLogo);

        // let categoryName;
        // this.props.categories[0].categories.forEach(elem => {
        //     if (elem.id == task.category) {
        //         categoryName = elem.name;
        //     }
        // });

        // let tags = []
        // task.tags.forEach((element) => {//TODO-- Real data needs to be connected
        //     tags.push(<Tag
        //         selected={
        //             true
        //         }
        //         name={element.name} />)
        // });

        // let linksToDisplay = [];
        // task.links.forEach((element, index) => {
        //     linksToDisplay.push(
        //         <LinkManager isCreateLink={true} index={index} url={element.url} link={element.text} />
        //     );
        // });
        // let cards = [];
        // task.files.forEach((element) => {//TODO -- Real data needs to be connected

        //     let substringName = element.name.substring(element.name.indexOf(".") + 1);

        //     cards.push(<Card file={element} name={element.name} type={element.type || substringName} url={element.url} />)
        // })

        // var inputs = []
        // this.props.data.work_process.forEach((element, index) => {

        //     let className;
        //     if (element.parent_id) { className = "processLineChildForShowTask" }
        //     else if (!element.parent_id) { className = "processLineInWiewTask" }


        //     inputs.push(<ProcessLine
        //         className={className}
        //         freeText={element.description}
        //         key={index}
        //     />)
        // });

        return (
            <React.Fragment>
                {/* <div className ="row">
            <div className="col-12">

                <PrintPage task = {task}></PrintPage>
                
            </div>
                </div> */}
            {/* {this.props.isEdit ?
                <CreateTaskPage
                    toEdit="true"
                    updateParent={() => this.updateTaskChanged(task)}
                />
                : */}
                <TaskPage
                    task={task}
                    newTask={false}
                    deleteArrayOnClick={this.props.deleteArrayOnClick}
                    handleCopyTaskClick={this.props.handleCopyTaskClick}
                    nextToCreateTask={false}
                    print = {this.props.print}
                />
                {/* } */}
            </React.Fragment>
            // <div className="CreateTaskPage">
            //     {/* {!this.state.show && this.props.new ?
            //     <Success
            //      name={this.props.tasks[this.state.myIndex].title}
            //      line_1={"מערך משימה" + ' " ' + this.props.tasks[this.state.myIndex].title + ' " '}
            //      line_2={'נוצר בהצלחה !'}
            //      line_3={'מיד תועבר/י למשימה...'}
            //      /> : <React.Fragment />} */}
            //     <CancellationAndReturn viewTask={true} />
            //     <div className="headerDiv">
            //         <div className='rightDiv'>
            //             <div className="createHeader">{task.title}</div>
            //             <div className="category"><img src={folderLogo} /> {categoryName}</div>
            //         </div>
            //         <span style={{ display: 'flex' }}>
            //             <CopyTaskButton isAllowed={libraryManager || this.isSuperUser} handleCopyTaskClick={this.props.handleCopyTaskClick} />
            //             <button style={{justifyContent: 'center', alignItems: 'center'}}
            //                 disabled={!libraryManagerToEditEndDelete && !this.isSuperUser}
            //                 className={libraryManagerToEditEndDelete || this.isSuperUser ? 'editListButtonForShow' : 'editListButtonForShowOver'}
            //                 onClick={this.editTask}
            //             >
            //                 <EditLogo />
            //                 <span style={{padding: '3px'}}>עריכת מערך</span>
            //             </button >
            //         </span>
            //         <img className='mainLogo' src={categoryLogo === undefined ? mainLogo : categoryLogo} />
            //     </div>
            //     <Container className="whiteContainerForViewTask">
            //         <div className='logos'>
            //             <img className='frameForLogoForShoe' src={CalendarForShow} onClick={()=>this.addEventToCalender()}/>
            //             <img className='frameForLogoForShoe' src={ShareForShow} />
            //             <img className='frameForLogoForShoe' src={ClouDownloadForShow} />
            //             <img className='frameForLogoForShoe' src={PrintForShow} />
            //             <img
            //                 onClick={(e) => this.props.deleteArrayOnClick(e,libraryManagerToEditEndDelete)}
            //                 className={this.isSuperUser || libraryManagerToEditEndDelete ? 'frameForLogoForShoe' : 'frameForLogoForShoe-is-not-isStaff'}
            //                 src={DeleteForShow} />
            //         </div>
            //         <p className="tagsHeaderForShowTask">מהות המשימה</p>
            //         <ShowDescription className="essence" htmlText={task.description} />
            //         {/* <ShowTask className="essence" htmlText={this.props.data.description} /> */}
            //         <Row className="workProcess" >
            //             <Col>
            //                 <p >תהליך עבודה </p>
            //             </Col>
            //         </Row>
            //         {this.displayWorkProcessLines()}
            //
            //         <p className="linksHeader mb-3">קישורים</p>
            //         {this.displayLinks()}
            //
            //         <p className="filesHeader mb-3" >קבצים</p>
            //         {cards.length > 0 ? <Row style={{marginRight:'70px'}} className="cards">
            //             {cards}
            //         </Row> : <></>}
            //         <p className="tagsHeaderForShow">תגיות</p>
            //         {tags.length > 0 ?
            //             <div style={{marginRight:'70px'}} className='tags'>
            //                 {tags}
            //             </div> : <></>}
            //     </Container>
            // </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tagsServer: state.dashboard.tags,
        categories: state.dashboard.categories,
        selectedTags: state.task.task.selectedTags,
        isEdit: state.task.task.isEdit,
        tasks: state.dashboard.tasks,
        task: state.task.task_undo.present.currentTaskData

    }
}


export default connect(
    mapStateToProps,
    { saveTaskToEdit, updateCurrentTask, updateCreatedTask, updateIsEdit, updateTaskArray }
)(ViewTask);
