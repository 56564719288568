import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, } from 'react-router-dom';
import SearchInput from '../../../ui/form/searchInput';
import AllTags from '../../../ui/mainPage/allTags';
import upImg from '../../../ui/images/Group 24.svg';
import { taskCreation } from '../../store/taskStore/taskActions';
import { updateIsRoutesBounded, updateCurrentTaskArrayFiltered } from '../../store/dashBoardStore/dashboardActions';
import { updateIsEdit, updateIsCreate, updateCurrentTask, updateNewLine } from '../../store/taskStore/taskActions';
import { cleanedTaskData } from '../../store/taskStore/initTaskData';
import { isUserStaff, isSuperUser, isAllowedAccess } from '../../apis/middleWareToken';
import { HI, SEARCH_WORDS} from '../../../ui/strings';
import './main.css';
import { getUserInfo } from "../../CreateTaskPage/screens/auxiliaryFunctions";
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import DashboardV3 from './dashboard-v3';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      taskResultsLength: 0,
    }
    this.isStaff = isUserStaff();
  }

  cleanData() {
    this.props.updateIsCreate(false)
    this.props.updateIsEdit(false)
    this.props.taskCreation(false)
    this.props.updateNewLine('')
    this.props.updateCurrentTask(cleanedTaskData)
  }

  componentDidMount() {
    this.props.updateIsEdit(false)
    this.setState({ userName: getUserInfo('userFirstName') })
  }

  async handleLinkClick() {
    if (!await isAllowedAccess()) {
      window.location.reload()
    }
  }

  render() {

    if (this.props.isTaskCreated && this.props.isRoutesBounded && this.props.createdTask) {
      this.props.taskCreation(false)
      this.props.updateIsRoutesBounded(false)
      this.props.updateCurrentTaskArrayFiltered(false)
      this.props.updateNewLine('')
      return <Redirect to={`/dashboard/task/${this.props.createdTask.id}`} />
    } else {
      if (this.props.isEdit || this.props.isCreate) {
        this.cleanData()
      }

      return (
        <div className="div-main">
          <div className={`justify-content-between ${!this.props.isStartFilter ? 'd-flex top-img' : 'search-up'}`}>
            <div>
              {!this.props.isStartFilter ? <img src={upImg} alt='' width='' height='' /> : <SearchInput />}
            </div>
          </div>

          {
            !this.props.isStartFilter ?
              <div className='welcome'>
                <div className='welcome-title'>{`${HI}${this.state.userName}`}</div>
                <div className='welcome-description'>{SEARCH_WORDS}</div>
              </div> : <React.Fragment />
          }

          {/* <div> */}
            {!this.props.isStartFilter ? <SearchInput /> : <React.Fragment />}
            {/* <div className='div-navber-header'>
              <AllTags />
            </div> */}
            {/* <div
              className={this.props.isStartFilter ? 'div-all-cards-vertical ' : 'div-all-cards-horizontal  d-flex'}
            > */}
              <DndProvider backend={HTML5Backend}>
                <DashboardV3/>
              </DndProvider>
            {/* </div> */}
          {/* </div> */}
          {/*<div className="mainFooter">*/}
          {/*  <div className='main-footer-right'>*/}
          {/*    <img className='footer-search-logo' src={tempLogo} alt=""/>*/}
          {/*    {NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_1}{' '}<a href="#">{NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_2}</a>*/}
          {/*    {' '}{NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_3}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      );
    }
  }
}


const mapStateToProps = (state) => {
  return {
    tasks: state.dashboard.tasks,
    isTasksReceived: state.dashboard.isTasksReceived,
    isTaskCreated: state.task.task.isTaskCreated,
    createdTask: state.task.task.createdTask,
    isRoutesBounded: state.dashboard.isRoutesBounded,
    isEdit: state.task.task.isEdit,
    isCreate: state.task.task.isCreate,
    currentTasks: state.dashboard.currentTasks,
    currentTasksFiltered: state.dashboard.currentTasksFiltered,
    //* for changed the page style (image at top) when tag all selected. turn on the comment*//
    // isStartFilter:state.dashboard.isStartFilter,
    isStartFilter: true,
  }
}

export default connect(
  mapStateToProps,
  {
    taskCreation,
    updateIsRoutesBounded,
    updateIsEdit,
    updateIsCreate,
    updateCurrentTask,
    updateNewLine,
    updateCurrentTaskArrayFiltered
  }
)(Dashboard);
