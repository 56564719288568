import {
    UPDATE_TASK_TAGS,
    UPDATE_CURRENT_TASK_DATA,
    TASK_CREATION,
    SAVE_CURRENT_TASK_DATA,
    UPDATE_CREATED_TASK,
    UPDATE_IS_EDIT,
    UPDATE_IS_CREATE,
    UPDATE_NEW_LINE,
    ADD_ORDER_TO_WORK_PROCESS,
    CHANGE_SAVE_DRAFT_TO_TRUE,
    CHANGE_ADVERTISING_ON_CLICK_TO_TRUE
} from './taskActionType'

export function updateTaskTags(data) {
    return { type: UPDATE_TASK_TAGS, payload: data }
}

export function taskCreation(data) {
    return { type: TASK_CREATION, payload: data }
}

export function updateCurrentTask(data) {
    return { type: UPDATE_CURRENT_TASK_DATA, payload: data }
}

export function saveTaskToEdit(data) {
    return { type: SAVE_CURRENT_TASK_DATA, payload: data }
}

export function updateCreatedTask(data) {
    return { type: UPDATE_CREATED_TASK, payload: data }
}

export function updateIsEdit(data) {
    return { type: UPDATE_IS_EDIT, payload: data }
}

export function updateIsCreate(data) {
    return { type: UPDATE_IS_CREATE, payload: data }
}

export function updateNewLine(data) {
    return { type: UPDATE_NEW_LINE, payload: data }
}

export function addOrderToWorkProcess(data) {
    return { type: ADD_ORDER_TO_WORK_PROCESS, payload: data }
}

export function changeSaveDraftToTrue() {
    return { type: CHANGE_SAVE_DRAFT_TO_TRUE, payload: true }
}

export function changeAdvertisingOnClickToTrue() {
    return { type: CHANGE_ADVERTISING_ON_CLICK_TO_TRUE, payload: true }
}
