import React, { Component } from 'react';
import delete_x  from '../../ui/images/delete_x.svg';
import './deleteButton.css';

class DeleteButton extends Component {


    render() {
        return (
            <button 
             className='delete-button'
             onClick={() => this.props.handleDeleteClick()}
             >
                <img src={delete_x} alt=""/>
            </button>
        );
    }
}

export default DeleteButton;