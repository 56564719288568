import TableRow from "./tableRow";
import UsersDropDown from "../usersDropDown";
import RectangleButton from "../rectangleButton";
import {ADD, TASK_LEADING} from "../../../../ui/strings";
import React, {Component} from "react";
import TableRowLast from "./tableRowLast";
import {connect} from "react-redux";
import {updateCurrentTask} from "../../../store/taskStore/taskActions";


class ProgressTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftClickIndex: null,
      editMode: this.props.editMode,
      hover: null
    }
  }

  handleState(field, value) {
    this.setState({[field]: value})
  }

  /* return true if stand on specific row in table*/
  isCurrentRow = (parent, self, index) => {
    if (!this.props.newTask && this.state.leftClickIndex !== null
      && this.state.leftClickIndex.parentId === parent
      && this.state.leftClickIndex.id === self
      && this.state.leftClickIndex.index === index) {
      return true
    }
    if (this.props.newTask && this.state.leftClickIndex !== null
      && this.state.leftClickIndex.parentIndex === parent
      && this.state.leftClickIndex.index === index) {
      return true
    }
    return false
  }

  /* this function will updated [checkboxes, end_date] change in this task */
  handleTaskChanges = async (parent_id = null, id, key, value) => {
    let parentChecked = true
    if ((parent_id || id) !== null) {
      let task = [...this.props.currentTask.work_process]
      if(this.props.newTask){
        let allSelected = true
        if(parent_id !== null){
            task[parent_id].children[id][key] = value
          if(key === 'checked'){
            task[parent_id].children.forEach(c=> !c.checked? allSelected = false : null)
            task[parent_id].checked = allSelected
          }
        } else {
          task[id][key] = value
          if (key === 'checked' && value){
            task[id].children.forEach(c=>c.checked = true)
          }
        }
      } else {
        await task.forEach(t => t.id === parent_id ?
          (t.children.forEach(c => c.id === id ?
            (c[key] = value,
                key === 'checked' && !value ?
                  parentChecked = false : c
            ) : !c.checked ? parentChecked = false : c), t.checked = parentChecked)
          : t.id === id ?
            (t[key] = value,
              key === 'checked' && value ?
                t.children.forEach(c => c.checked = true)
                : t ,
              key === 'end_date' && value ?
                t.children.forEach(c => !c.end_date ? c.end_date = value :'')
                : t
            )
            : t)
      }
      this.props.onClick({...this.props.currentTask, 'work_process': task})
      if (key === 'checked'){
        this.props.setDoneStatus()
      }
    } else if (parent_id === null && id === null) {
        let task = {...this.props.currentTask}
        task[key] = value.id
        task.leading_name = `${value.first_name} ${value.last_name}`
        this.props.onClick({...this.props.currentTask, 'leading_name': task.leading_name, 'leading_id': task[key]})
    }
  }

  handleClick = (e, details = 0) => {
    if (e.type === 'click') {
      // setRightClickIndex(null)
      if (JSON.stringify(this.state.leftClickIndex) !== JSON.stringify(details)) {
        this.props.newTask
        ? this.handleState('leftClickIndex', { parentIndex: details.parentIndex, index: details.index})
        : this.handleState('leftClickIndex', { parentId: details.parentId, id: details.id, index: details.index})
      }
    } else if (e.type === 'contextmenu') {
      // this.handleState({parentId: index.parentId, index: index.index})
    }
  }

  handleHover = (index) => {
    this.setState({'hover': index})
  }

  tableHeaders(){
    let yesod = this.props.currentTask.group_id == 2
   return (<tr>
      <th style={{width: '4%'}}></th>
      <th style={{width: '46%'}}>{!this.props.isYesodTask ? this.props.editMode ? '' : `${TASK_LEADING}${this.props.currentTask.leading_name}`:''}</th>
      {!yesod && <th style={{width: '14%'}} className='text-center'>אחראי/ת</th>}
      {!yesod && <th style={{width: '13%'}} className='text-center'>תאריך יעד</th>}
      {!yesod && <th style={{width: '12%'}} className='text-center'>סטטוס</th>}
      <th style={{width: !yesod ? '7%':'4%' }} className='text-center'>{!yesod && 'פעולות'}</th>
    </tr>)
  }

  tableRows() {
    let tableLines = []
    // let leaderName = props.task.leading_name.split(' ');
    this.props.currentTask.work_process.map((process, i) => {
      tableLines.push(
        <TableRow
          key={process.id}
          index={i}
          onSection={this.props.onSection}
          onFocus={
            this.isCurrentRow(
              null,
              this.props.newTask? i :process.id, i
            ) && this.props.editMode && this.props.onSection}
          parent={null}
          isYesodTask={this.props.isYesodTask}
          parentIndex={null}
          title={this.props.currentTask.title}
          task={process}
          editMode={this.props.editMode}
          users={this.props.users}
          handleClick={this.handleClick}
          handleDescriptionsChanges={this.props.handleDescriptionsChanges}
          handleTaskChanges={this.handleTaskChanges}
          addProcessStep={this.props.addProcessStep}
          deleteRow={this.props.deleteRow}
          handleHover={this.handleHover}
          onHover={this.state.hover === i}
          newTask={this.props.newTask || false}
        />
      )
      process.children.map((child, j) => {
        tableLines.push(
          <TableRow
            key={child.id}
            index={j}
            onSection={this.props.onSection}
            onFocus={
              this.isCurrentRow(
                this.props.newTask? i :child.parent_id,
                this.props.newTask? j :child.id, j
              ) && this.props.editMode && this.props.onSection}
            child={true}
            parent={process}
            isYesodTask={this.props.isYesodTask}
            parentIndex={i}
            title={this.props.currentTask.title}
            task={child}
            editMode={this.props.editMode}
            users={this.props.users}
            handleClick={this.handleClick}
            handleDescriptionsChanges={this.props.handleDescriptionsChanges}
            handleTaskChanges={this.handleTaskChanges}
            addProcessStep={this.props.addProcessStep}
            deleteRow={this.props.deleteRow}
            onHover={this.state.hover === i}
            newTask={this.props.newTask || false}
          />
        )
      })
    })
    return tableLines
  }

  lastTableRow(){
    if(this.props.newTask){
      return
    }
    let lastIndex = this.props.currentTask.work_process.length;
    return(
      <TableRowLast
        id={'last'}
        task={this.props.currentTask}
        sendMessage={this.props.sendMessage}
        editMode={this.props.editMode}
        key={'last'}
        index={lastIndex}
        onSection={this.props.onSection}
        onFocus={this.isCurrentRow(null,'last' , lastIndex ) && this.props.editMode && this.props.onSection}
        parent={null}
        isYesodTask={this.props.isYesodTask}
        parentIndex={null}
        title={'title'}
        handleClick={this.handleClick}
        handleTaskChanges={this.handleTaskChanges}
        handleHover={this.handleHover}
        onHover={this.state.hover === lastIndex }
        newTask={this.props.newTask || false}
      />
    )
  }

  render() {
    if (!this.props.currentTask) {
      return <span/>
    }
    return <span>
    {!this.props.isYesodTask && this.props.editMode ?
      <UsersDropDown
        id={null}
        parent_id={null}
        editMode={this.props.editMode}
        disabled={false}
        multiple={false}
        options={this.props.users}
        value={this.props.currentTask.leading_id}
        onChange={this.handleTaskChanges}
        field='leading_id'
      /> : <span/>}
      <table className="table table-bordered"
      onClick={this.props.onSection !== 1 ? () => this.props.setOnSection(1) : null} cellPadding='0px'>
      <thead>
      {this.tableHeaders()}
      </thead>
      <tbody>
      {this.tableRows()}
      {this.props.currentTask.group_id!== 2 && this.lastTableRow()}
      </tbody>
    </table>
      <div style={{display:'block'}}>
        {this.props.currentTask.group_id!== 2 &&<tr>
          <ul>{this.props.currentTask.comments.map(comment => <li><span
            dangerouslySetInnerHTML={{__html: comment.description}}/></li>)}</ul>
        </tr>}

      {this.props.editMode ? <RectangleButton
        style={{marginRight: 'auto'}}
        text={ADD}
        icon={''}
        onClick={() => this.props.addProcessStep([null, this.props.currentTask.work_process.length - 1, 'down'])}
        isAllowed={this.props.isAllowed}
        isEdit={this.props.editMode}/> : <span/>}
      </div>
</span>
  }
}
const mapStateToProps = (state) => {

  return {
    users: state.dashboard.users
  }
}

export default connect(mapStateToProps)(ProgressTable);
