import React, {useEffect, useState} from 'react'
import config from './config.json'

// export const CreateCalendarEvent = (props) => {

    let gapi = window.gapi
export const handleClick = (props) => {

  gapi.load('client:auth2', () => {
    gapi.client.load('calendar', 'v3', () => {
      console.log('loaded client')

      gapi.client.init({
        client_id: config.clientID,
        apiKey: config.apiKey,
        discoveryDocs: config.DISCOVERY_DOCS,
        scope: config.SCOPES
      }).then(() => {
        console.log(gapi);

        gapi.auth2.getAuthInstance().signIn().then(()=>{
          let event = {
            'summary': `% ${Math.round(props.doneStatus)} - ${props.title} `,
            'location': ``,
            'description': `${props.description}`,
            'start': {
              'dateTime': '2021-05-07T09:00:00-07:00',
              // 'timeZone': 'America/Los_Angeles'
            },

            'end': {
              'dateTime': '2021-05-07T09:00:00-07:00',
              // 'timeZone': 'America/Los_Angeles'
            },
            'recurrence': [
              // 'RRULE:FREQ=DAILY;COUNT=2'
            ],
            'attendees': [
              {"email": `${props.user.email}`},
              // {"email": "itaydahan79@gmail.com"},
              // { "email": "avrahamd@ravtech.co.il" },
              // {"email": "davidd@ravtech.co.il"},
            ],
            'reminders': {
              'useDefault': false,
              'overrides': [
                // {'method': 'email', 'minutes': 24 * 60},
                {'method': 'popup', 'minutes': 10}
              ]
            }
          };

          let request = gapi.client.calendar.events[props.mode]({
            "calendarId": "primary",
            "resource": event
          });

          request.execute(event => {
            console.log('Event created: ' , event);
          });

        })

      })

    })
    console.log('ok!!')

    // })
  })
  }

    // return <button onClick={()=>handleClick()}>Crate event</button>
// }
