import React, { Component } from 'react';

export default class LogoEdit extends Component {
    render() {
        return <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" >
            <title>4A076932-574D-412F-A703-3B5FECD63495</title>
            <desc>Created with sketchtool.</desc>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Yesod/New-task-(admin)-2" transform="translate(-482.000000, -106.000000)" fill="#FFFF" fillRule="nonzero">
                    <g id="Group-27" transform="translate(385.000000, 95.000000)">
                        <g id="Group-8-Copy-8">
                            <path d="M103.777506,16.1917199 L99.3515694,20.6160791 C99.1460148,20.7978812 99.0188506,21.0522218 99,21.2452197 L99,23.0024825 L100.685783,23.0040495 C100.953689,22.9850834 101.203068,22.8603998 101.427493,22.6053795 L105.80933,18.2235432 L103.777506,16.1917199 Z M105.191972,14.7777585 L107.223543,16.8093296 L108.989486,15.0433863 L106.958236,13.0121246 L105.191972,14.7777585 Z M107,11 C107.528789,11 107.986927,11.2094938 108.35444,11.5799129 L110.421706,13.6471787 C110.79239,14.0178619 111,14.4727409 111,15 C111,15.5238514 110.79402,16.0672807 110.421707,16.4395931 L102.890207,23.9675114 C102.366606,24.571514 101.624382,24.9426079 100.7564,25.001553 L98,25.001553 L97,25.001553 L97,24.001553 L97.0032477,21.1646917 C97.0669805,20.3758249 97.4337305,19.6422894 97.981963,19.1599098 L105.560931,11.5810082 C105.932846,11.2071807 106.475385,11 107,11 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    }
}
