import React, { forwardRef } from 'react'

import './rectangleButton.css';
import '../../../components/CreateTaskPage/screens/sharingModal/sharingModal.css'

import { IconMouseOverPopover, Popup } from "./popover/popover";


const RectangleButton = (props) => {
  let butten = props.isAllowed
    ?
    <button
      style={props.style}
      className={`${props.className ? props.className : ""} rectangle-btn`}
      onClick={() => props.onClick()}
    >
      <span>
        {!props.isEdit ? props.icon : ''}
      </span>
      <span className={"button-text"}>
        {!props.isEdit ? ` ${props.text}` : `${props.text}`}
      </span>
    </button>
    : <span />


  if (!props.withPopover && !props.withPopup) return butten;

  if (props.withPopup){
    return (
       <Popup 
         id={props.id}
         trigger={butten}
         popupBody={props.popupBody}
         popperClass = {props.popperClass}
       ></Popup>
     )

  }

  return (
    <IconMouseOverPopover
      id={props.id}
      title={butten}
      popoverData={props.textOver} />
  );
}

export default RectangleButton;


