export const deepCopyIntoArray = (arrry) => {
  const arr = [...arrry]
  for (let i = 0; i < arr.length; i++) {
    if (Array.isArray(arr[i])) {
        arr[i] = deepCopyIntoArray(arr[i])
    }
    if (arr[i] instanceof Object && !Array.isArray(arr[i])) {
        arr[i] = deepCopyIntoObject(arr[i])
    }
  }
  return arr
}

export const deepCopyIntoObject = (object) => {
  const obj = {...object}
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
        obj[key] = deepCopyIntoArray(obj[key])
    }
    if (obj[key] instanceof Object && !Array.isArray(obj[key])) {
        if(obj[key] instanceof Date){
          obj[key] = new Date(obj[key].getTime())
        } else {
          obj[key] = deepCopyIntoObject(obj[key])
        }
      }
  }
  return obj
}