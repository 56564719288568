/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useEffect, useRef, useState } from 'react';
import { Popover, PopoverBody } from "reactstrap";
import './popover.css'


export const TextMouseOverPopover = (props) => {

  return (
    <div>
      <div id={`Popover${props.id}`}>{props.title}</div>
      <Popover className='po-white' positionFixed placement="top-end" isOpen={props.open} target={`Popover${props.id}`}>
        <PopoverBody style={{ textAlign: 'right' }}>{props.popoverData}</PopoverBody>
      </Popover>
    </div>
  );
}

export const IconMouseOverPopover = (props) => {
  const [onHover, setOnHover] = useState(false)
  return (
    <span
      onMouseOver={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <span id={`Popover${props.id}`} style={{ color: '#6178FF' }}
      >{props.title}</span>
      <Popover  placement="top" isOpen={onHover} target={`Popover${props.id}`}>
        <PopoverBody className='d-inline-flex sharingBody' >
          {props.onDelete}
          {props.onDelete && <div className='divider' />}
          {props.popoverData}
        </PopoverBody>
      </Popover>
    </span>
  );
}

export const Popup = ({id, trigger, popupBody, popperClass}) => {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <span onClick={() => setIsOpen(!isOpen)} onBlur = {() => {setIsOpen(false)}}>
      <span id={`Popup${id}`} style={{ color: '#6178FF' }}
      >{trigger}</span>
        <Popover
          popperClassName={popperClass} 
          offset="[0,10]" 
          hideArrow 
          placement="bottom-start" 
          isOpen={isOpen} 
          target={`Popup${id}`}
        >
          <PopoverBody onClick={(e)=>{ e.stopPropagation()}}>
            {popupBody}
          </PopoverBody>
        </Popover>
    </span>
  );
}
