import React, { Component } from 'react';
import './textInput.css';

class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fontSizeLabel: false
        }
    }

    handleChangeLocal(e) {
        this.setState({ value: e.target.value })
    }

    render() {
        let validationBorder = { borderBottom: '2px solid #FF8B90', width: this.props.width };
        return (
            <div className='class-text-main'>
                {this.state.value ? 
                    <label className='class-text-label-login-sm' htmlFor="mail">{this.props.placeholder}</label> :
                    <React.Fragment />}
                <input
                    style={this.state.value && !this.props.validation ? { borderBottom: '2px solid #6178FF', width: this.props.width } :
                        this.props.validation ? validationBorder : { width: this.props.width }}
                    placeholder={!this.state.value ? this.props.placeholder : ''}
                    className='login-text-input'
                    type='text'
                    onChange={(e) => { this.props.onChange(this.props.category, e.target.value); this.handleChangeLocal(e) }}
                    tabIndex={this.props.tabIndex}
                    onKeyDown={this.props.keyPress}
                    autoComplete="off"
                />
            </div>
        );
    }
}

export default TextInput;