import React, { Component } from 'react';
import './passwordInput.css';
import eyeLogo from '../../ui/images/eyeLogo.svg';
import checkAllTurquoise from '../../ui/images/check-all-turquoise.svg';

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // need
            passwordIncorrect: false,
            hidden: true,
            value: '',
            goodPassword: false,
            confirmPassword: false,
        }
    }
    handleChangeLocal(e) {
        this.setState({ value: e.target.value });
    }

    render() {

        let validationBorder = {borderBottom: '2px solid #FF8B90'};
        this.state.value.length > 6 ? this.state.confirmPassword = true : this.state.confirmPassword = false;
        let goodPassword = this.state.value.length >= this.props.minimumOfCharacters ? true : false;
        let styleObj;
        if (this.props.type === 'regular') {
            // styleObj = this.props.redLine && this.state.value.length > 0 ?
            styleObj = this.props.redLine ?
            { borderBottom: '2px solid #FF8B90' } :
             (this.state.value ? { borderBottom: '2px solid #6178FF' } : {})
        }
        if (this.props.type === 'confirm Password') {
            // styleObj = this.state.confirmPassword && this.props.redLine ?
            styleObj = this.props.confirmPassword ?
            { borderBottom: '2px solid #FF8B90' } :
             (this.state.value ? { borderBottom: '2px solid #6178FF' } : {})
        }
        if (this.props.type === 'check') {
            styleObj = this.props.checkLine && goodPassword ?
            { borderBottom: '2px solid #07DCCD' } :
             (this.state.value ? { borderBottom: '2px solid #6178FF' } : {})
        }
        if (this.props.validation) {
            styleObj = validationBorder;
        }

        return (
            <div>
                <div className='class-password-main'>
                    {this.state.value ? <label className='class-password-label-login-sm' htmlFor="mail">{this.props.placeholder}</label> : <React.Fragment />}
                    <input
                        className='login-password-input'
                        style={styleObj}
                        type={this.state.hidden ? "password" : "text"}
                        placeholder={!this.state.value ? this.props.placeholder : ''}
                        onChange={(e) => {this.props.onChange(this.props.category, e.target.value); this.handleChangeLocal(e)}}
                        tabIndex={this.props.tabIndex}
                        onKeyDown={this.props.keyPress}
                    />
                    <div
                        className='class-input-images d-flex'
                        style={styleObj}
                    >
                        {this.state.value ?
                            <img
                            style={{ marginBottom: '7px' }}
                                alt=''
                                src={eyeLogo}
                                onMouseDown={() => this.setState({ hidden: false })}
                                onMouseUp={() => this.setState({ hidden: true })}
                            /> : <React.Fragment/>}
                        {this.props.asCheckLogo && goodPassword ?
                            <img
                                style={{ marginRight: '5px', marginBottom: '7px', cursor: 'pointer' }}
                                src={checkAllTurquoise}
                                alt=""
                            /> : <React.Fragment/>}
                    </div>
                </div>
                {/* {this.props.redLine && this.state.value.length > 0 && this.props.type === 'regular' ? <div className='class-password-incorrect'>{this.props.downLabelText}</div> : <React.Fragment />} */}
                {this.props.redLine && this.props.type === 'regular' ? <div className='class-password-incorrect'>{this.props.downLabelText}</div> : <React.Fragment />}
                {this.props.confirmPassword && this.props.type === 'confirm Password' ?
                 <div className='class-password-incorrect'>{this.props.downLabelText}</div> : <React.Fragment />}
            </div>
        );
    }
}

export default PasswordInput;
