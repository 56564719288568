import React from "react"
import { PARTICIPANTS } from "../../../../ui/strings"
import UserLogo from "./sharing-userLogo"

const SharedUsersOverview = ({users, owner, sharedUsers,openSharingModal, setOpenSharingModal }) => {

  const getOwner = () => {
    return <UserLogo key={"owner"} user={users.find(u => u.id == owner)} usegeFor={"overview"} className = "owner" onClick={() => { }}/>
  }

  const getSharedUserList = () => {
    return sharedUsers.length && sharedUsers.map((sharedUser, i) => {
      const curUser = users.find(user => user.id === sharedUser.id)
      return curUser 
      ? <UserLogo key={i} user={curUser} usegeFor={"overview"} onClick={() => { }}/>
      :<span key={i}/>
    })
  }

  return (
    <span className="wrap-overview" style={{display:"inline-flex", height:"40px"}}>
      <span className="overview-text">{`${PARTICIPANTS} (${sharedUsers.length + 1})`}</span>
      {getOwner()}
      <span className="owner-separator"/>
      {getSharedUserList()}
      <div style={{alignSelf:"center", marginRight:"9px"}} className="user-plus" onClick={() => setOpenSharingModal(!openSharingModal)}>
        <div className="into-user-plus"></div>
        <div className="plus-icon"></div>
      </div>
      <span className="line"/>
    </span>
  )
}

export default SharedUsersOverview