import axios from 'axios';
import { login, basePath } from './paths';
import { setTokenToLocalStorage } from './middleWareToken';

export async function userLogin(data) {
  // axios.defaults.baseURL = basePath;
  // let answer;
  await axios.post(basePath + login, data
  )
    .then(async function (response) {

      setTokenToLocalStorage('accessToken', response.data.data.access);
      setTokenToLocalStorage('refreshToken', response.data.data.refresh);
      localStorage.setItem('userEmail', response.data.data.user.email);
      localStorage.setItem('userFirstName', response.data.data.user.first_name);
      localStorage.setItem('userLastName', response.data.data.user.last_name);
      localStorage.setItem('userRole', response.data.data.user.title);
      localStorage.setItem('userId', response.data.data.user.id);
      localStorage.setItem('groupId', response.data.data.user.group_id);
      
      return (response.data.data.user);
    })
    .catch(function (error) {

      console.log(error);
      return Promise.reject(error);
    });

}
