import React, {Component, useState} from 'react';
import {Button, Input} from 'reactstrap';
import RectangleButton from "../rectangleButton";
import {ADD_LINK, TEXT_DELETE_TASK_1} from "../../../../ui/strings";

const AddLinkBtn = props => {
  const [url, setUrl] = useState('')
  const [link, setLink] = useState('')
  const [toggle, setToggle] = useState(false)

  function createLink() {
    if (url.length > 5 ) {
      let task = {...props.task}
      task.links.push({
        task_id: props.task.id,
        text: link,
        url: url
      })
      // props.onChange({...props.task, 'links': task.links})
      props.onChange({...props.task, 'links': task.links, 'files_count': task.files_count + 1})
    }
    setToggle(false)
    setUrl('')
    setLink('')
  }

  return (
    <span>{!toggle ?
      <RectangleButton
        style={{backgroundColor: '#6178ff', color: '#FFFFFF', marginRight: '10px'}}
        text={ADD_LINK}
        icon={''}
        onClick={(e) => setToggle(!toggle)}
        isAllowed={true}
        isEdit={true}
      /> :
      <div className="linksHeader">
        <Input value={url} onChange={(e) => setUrl(e.target.value)} className="linkLine col-3"
               placeholder={"אנא הכנס URL"}/>
        <Input value={link} onChange={(e) => setLink(e.target.value)} className="linkLine col-3"
               placeholder={"הכנס טקסט ליצירת קישור"}/>
        <RectangleButton
          style={{backgroundColor: '#6178ff', color: '#FFFFFF', marginRight: '10px'}}
          text={'צור קישור'}
          icon={''}
          onClick={(e) => createLink()}
          isAllowed={true}
          isEdit={true}/>
      </div>}
    </span>


  )
}
export default AddLinkBtn
