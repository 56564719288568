import React, { Component } from 'react';
import {
  TEXT_DELETE_TASK_1,
  TEXT_DELETE_BTN,
  TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK,
  SUCCESS_SAVED, SUCCESS_DELETED
} from '../../ui/strings';
import {
  restartTasksRequest,
  taskDeleted,
  updateCurrentTasksArray,
  updateSelectedGroup
} from '../../components/store/dashBoardStore/dashboardActions';
import { deleteSpecificTask } from '../../components/apis/deleteTask';
import delete_task_icon from '../../ui/images/delete_task_icon.jpg';
import { Redirect, withRouter } from 'react-router';
import { connect } from 'react-redux';
import './deleteTaskModal.css';
import Success from "../../components/CreateTaskPage/Success";

import { menuItems } from "../enums/sidebar"
import RectangleButton from '../../components/CreateTaskPage/screens/rectangleButton';

const withRouterAndRef = (WrappedComponent) => {

  class InnerComponentWithRef extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }
  const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });

  return React.forwardRef((props, ref) => {
    return <ComponentWithRouter {...props} forwardRef={ref} />;
  });
}

class DeleteTaskModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      isRedirect: false,
      successDeletedModal: false,
      folder: null,
    }
  }

  componentDidMount() {
    const locationParams = this.getParamsFromURl()
    this.setState({
      folder: locationParams
    })
  }

  toggle() {
    this.setState({
      open: !this.state.open
    });
  }

  getParamsFromURl = () => {
    const rawParams = this.props.location.search.split(/['?','&']/)

    const params = {}
    rawParams.forEach(param => {
      if (param) {
        const subParam = param.split("=")
        params[subParam[0]] = subParam[1] === "null" ? null : subParam[1]
      }
    })
    return params
  }

  deleteTaskOnClick = async () => {
    await deleteSpecificTask(this.props.data.id, this.state.folder)
      .then(async res => {
        let updateTasks = this.props.tasks.filter(task => task.id !== this.props.data.id && task.group_id == this.props.data.group_id)
        this.props.updateCurrentTasksArray(updateTasks);
        this.setState({ open: !this.state.open, successDeletedModal: true });
        setTimeout(() => {
          this.props.taskDeleted(true)
          this.setState({ successDeletedModal: false, isRedirect: true });
          this.props.updateSelectedGroup(menuItems.GROUP_ID + this.props.data.group_id + (this.props.data.group_id == 2 ? menuItems.AREA_ID + this.props.data.area_id : ""))
          this.props.history.goBack()
        }, 2000);
      }).catch(res => {
        alert(res.massage)
      })
  }

  render() {
    return (
      this.state.isRedirect ? <span /> :
        this.state.open ?
          <div className='modal-background'>
            <div className="main-div-delete-task-modal">
              <div className="big-circle">
                <div className="small-circle">
                  <img className='delete-img' src={delete_task_icon} alt="" />
                </div>
              </div>
              <span className="delete-modal-text">
                {TEXT_DELETE_TASK_1}
              </span>
              <span className="delete-modal-text">
                {`"${this.props.data.title}"`}
              </span>
              <div className='delete-button-wrap'>
                <RectangleButton
                  style={{
                    background: "#E7EBFF",
                    color: "#6178FF",
                  }}
                  onClick={() => this.setState({ open: !this.state.open })}
                  text={TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK}
                  isAllowed={true}
                />
                <RectangleButton
                  onClick={() => this.deleteTaskOnClick()}
                  text={TEXT_DELETE_BTN}
                  isAllowed={true}
                />
              </div>
            </div>
          </div>
          : this.state.successDeletedModal
            ? <Success
              name={this.props.data.title}
              line_1={SUCCESS_DELETED}
              line_2={''}
              line_3={''}
            />
            : <React.Fragment />
    );
  }
}

const mapStateToProps = (state) => {

  return {
    state: state,
    tags: state.dashboard.tags,
    categories: state.dashboard.categories,
    selectedTags: state.task.selectedTags,
    isTaskCreated: state.task.isTaskCreated,
    isTasksReceived: state.dashboard.isTasksReceived,
    dataToEdit: state.task.dataToEdit,
    currentTaskData: state.task.currentTaskData,
    isRoutesBounded: state.dashboard.isRoutesBounded,
    isEdit: state.task.isEdit,
    isCreate: state.task.isCreate,
    newLine: state.task.newLine,
    advertisingOnClick: state.task.advertisingOnClick,
    saveDraft: state.task.saveDraft,
    tasks: state.dashboard.tasks
  }

}

// const deleteTaskModal = withRouter(DeleteTaskModal)
// export default  connect(mapStateToProps, {
//   restartTasksRequest,
//   updateCurrentTasksArray,
//   taskDeleted
// }, null, {forwardRef: true})(DeleteTaskModal)




export default withRouterAndRef(connect(mapStateToProps, {
  restartTasksRequest,
  updateCurrentTasksArray,
  taskDeleted,
  updateSelectedGroup
}, null, { forwardRef: true })(DeleteTaskModal))
