import { offset } from "@popperjs/core";
import React, { useMemo, useRef, useState } from "react"
import { Popover, PopoverBody } from "reactstrap";
import { options } from "sanitize-html";

const UserLogo = ({ user, usegeFor, className, onClick, isOneRemoveLogoActive, setIsOneRemoveLogoActive }) => {

  const [isMouseOn, setIsMouseOn] = useState(false)

  const invertColor = (hex) => {
    const r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);

    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return L > 0.179 ? '#000000' : '#FFFFFF';

    // https://stackoverflow.com/a/3943023/112731
  }

  const { bgColor, color } = useMemo(() => {
    const bgColor = Math.random().toString(16).substring(9)
    const color = invertColor(bgColor)

    return { bgColor: bgColor, color: color }
  }, [])



  return (
    <div
      id={usegeFor + user.id}
      onMouseEnter={() => { usegeFor !== "groupUser" && !isMouseOn && !isOneRemoveLogoActive && setIsMouseOn(true) }}
      onMouseLeave={() => { usegeFor !== "groupUser" && isMouseOn && setIsMouseOn(false) }}
      onMouseOver={() => { usegeFor !== "groupUser" && !isMouseOn && setIsMouseOn(true) }}
      className={`
          ${usegeFor == "groupUser" ? 'user-logo' : 'shared-user-logo'}
          ${className ? className : ""}
          `}
      style={{ backgroundColor: "#" + bgColor, display: "flex" }}
    >
      {usegeFor === "sharedUser" && isMouseOn &&
        <div className="remove-user-icon-wrap" >
          <div
            className="remove-user-circle"
            onClick={() => { onClick() }}
            onMouseEnter={() => {
              !isMouseOn && setIsMouseOn(true)
              !isOneRemoveLogoActive && setIsOneRemoveLogoActive(true)
            }}
            onMouseOver={() => { !isMouseOn && setIsMouseOn(true) }}
            onMouseLeave={() => {
              isMouseOn && setIsMouseOn(false)
              setIsOneRemoveLogoActive(false)
            }}
          >
            <span className="remove-user-icon"></span>
          </div>
        </div>
      }
      <div
        className='user-logo-text text-uppercase'
        style={{ color: color }}
      >{`${user.first_name[0]}${user.last_name[0]}`}</div>
      <Popover
        target={usegeFor + user.id}
        isOpen={usegeFor !== "groupUser" && isMouseOn}
        hideArrow
        placement="bottom-end"
        className="logo-text-popup"
        modifiers={
          {
            offset: {
              fn: (data, options) => {
                if (usegeFor === "sharedUser") {
                  data.instance.popper.style.top = "45px"
                }
                return data
              },
            }
          }
        }
      >
        <PopoverBody>
          <div>{`${user.first_name} ${user.last_name}`}</div>
        </PopoverBody>
      </Popover>
    </div >
  )
}

export default UserLogo