import React, { Component } from 'react';
import './CreateTaskPage.css'
import plus from '../../ui/images/plus.svg';


class LittlePlus extends Component {

    constructor(props) {
        super(props);
    }
    
    
    render() {
        return (
            <div style={this.props.propsStyle ? this.props.propsStyle : {}} className="CreateProcessEmptyForPlusChildRow d-flex pointer"
                onClick={() => this.props.addNewChild( this.props.fatherIndex, this.props.parent_id, this.props.selfIndex )}>
                <img id="plus" className='m-auto' src={plus} style={{ marginLeft: "3px" }} />
            </div>
        )
    }
}

export default LittlePlus;