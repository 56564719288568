import React from "react";

/* general words*/
export const GROUP = 'ארגון'

export const TEXT_TO_ALERT_OF_MAKE_SURE_DELETE_WORK_PROCESS =
  'האם אתה בטוח למחוק תהליך עבודה ?';
export const LOG_IN_TO_SYSTEM_HEADER = 'התחברות למערכת';
export const TEXT_MAIL_TO_PLACEHOLDER = 'דוא"ל';
export const TEXT_PASSWORD_TO_PLACEHOLDER = 'סיסמא';
export const DOWN_LABEL_TEXT_TO_WRONG_PASSWORD =
  'הסיסמא או שם המשתמש שהקלדו אינם נכונים';
export const LOGIN_BUTTON_TEXT = 'התחבר/י למערכת';
export const LINK_REGISTER_TO_SYSTEM_PART_1 = 'פעם ראשונה כאן?';
export const LINK_REGISTER_TO_SYSTEM_PART_2 = 'הרשמ/י עכשיו';
export const NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_1 = 'לא מצאת את מה שחיפשת?';
export const NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_2 = 'כתוב/כתבי לנו';
export const NOT_FOUND_WHAT_YOU_LOOKING_FOR_PART_3 =
  'ונדאג לייצר משימה שתתאים בול בשבילך';
export const CREATE_NEW_FOLDER_TEXT = '+ יצירת ספרייה חדשה';
export const REGISTER_TO_SYSTEM_HEADER = 'הרשמה למערכת';
export const REGISTER_DETAILS_HEADER = 'פרטי התחברות';
export const REGISTER_PERSONAL_INFO_HEADER = 'פרטים אישיים';
export const TEXT_INPUT_MAIL_PLACEHOLDER_WITH_ASTERISK = 'דוא"ל' + '*';
export const TEXT_INPUT_PRIVATE_NAME_PLACEHOLDER_WITH_ASTERISK =
  'שם פרטי' + '*';
export const TEXT_INPUT_LIMIT_PASSWORD_LABEL =
  'סיסמא* (אותיות אנגלית או/ו מספרים. 5 תווים לפחות)';
export const TEXT_INPUT_FAMILY_NAME_PLACEHOLDER = 'שם משפחה' + '*';
export const TEXT_INPUT_CONFIRM_PASSWORD_PLACEHOLDER = 'אימות סיסמא*';
export const TEXT_INPUT_PASSWORD_DO_NOT_MATCH_LABEL = 'הסיסמאות אינן תואמות';
export const TEXT_INPUT_ROLE_PLACEHOLDER = 'תפקיד';
export const FINISH_REGISTER_BTN = 'סיום הרשמה';
export const REGISTER_TO_SYSTEM_AGREE_LINK_PART_1 = 'אני מסכימ/ה ';
export const REGISTER_TO_SYSTEM_AGREE_LINK_PART_2 = 'לתנאי השימוש והפרטיות ';
export const REGISTER_TO_SYSTEM_AGREE_LINK_PART_3 = 'של מערכת יסוד';
export const REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_2 =
  'נרשמת בהצלחה למערכת יסוד';
export const REGISTER_TO_SYSTEM_SUCCESS_ALERT_LINE_3 = 'מיד תתחבר למערכת';
export const CANCEL_TEXT_BTN = 'ביטול וחזרה';
export const CREATE_LIBRARY_HEADER = 'יצירת ספריה חדשה';
export const CREATE_LIBRARY_PLACEHOLDER_INPUT = 'שם המוסד הלימודי*';
export const CREATE_LIBRARY_LABEL = 'ספריה*';
export const ADD_FILE_TEXT = 'צירוף תמונה / סמל*';
export const ADD_FILE_BTN_IN_CREATE_LIBRARY_MODAL_TEXT = 'הוספת קובץ';
export const ADD_LINK = 'הוספת קישור';
export const CREATE_LIBRARY_ACTION_TEXT = 'צור ספריה';
export const CREATE_TASK_ESSENCE_PLACEHOLDER = 'הקלד/י כאן את מהות המשימה...';
export const CREATE_TASK_ARRAY_NAME = 'שם המשימה *';
export const CREATE_TASK_ALERT_PLEASE_GIVE_A_HEADLINE = 'אנא תן כותרת';
export const CREATE_TASK_ALERT_PLEASE_CHOSE_A_CATEGORY = 'אנא בחר קטגוריה';
export const WINDOW_CONFIRM_INSERT_AGAIN = 'עליך להיכנס לחשבון שוב !';
export const INTERNET_CONNECTION = 'אנא התחבר לרשת האינטרנט לטובת שמירת הנתונים';
export const NO_INTERNET_CONNECTION = 'אנא התחבר לרשת האינטרנט';
export const TEXT_TASK_ARRAY = 'משימה';
export const TEXT_DRAFT = 'טיוטא';
export const TEXT_SUCCESSFULLY_CREATED_MALE = 'נוצר בהצלחה !';
export const TEXT_SUCCESSFULLY_CREATED_FEMALE = 'נוצרה בהצלחה !';
export const YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_TASK =
  'מיד תועבר/י למשימה';
export const TEXT_CREATE_TASK_ARRAY = 'יצירת משימה';
export const TEXT_CREATE_ARRAY = 'עריכת משימה';
export const TEXT_GO_BACK_IN_VIEW_TASK = 'חזרה';
export const TEXT_CANCEL_AND_GO_BACK_IN_EDIT_TASK = 'ביטול וחזרה';
export const TEXT_COPY_TASK_BUTTON = 'העתקה';
export const EDIT = 'עריכה';
export const SAVE = 'שמירה';
export const ENABLE_EDITING = "אפשר עריכה"
export const DISABLE_EDITING = "נעל לעריכה"
export const SAVE_AS_DRAFT = 'שמירה כטיוטא';
export const publish = 'פרסום';
export const SHARE = 'שיתוף';
export const UPDATE = 'עדכן';
export const SHARE_TASK_WITH_YOUR_TEAMMATAS = "שתף משימה עם חברייך לצוות";
export const ANUONE_WEO_SHARES_THE_TASK_WILL_SEE_IT_IN_USER_AREA = "כל מי שישותף במשימה יראה אותה באיזור האישי שלו";
export const GROUP_USERS = 'משתמשים בסביבת הארגון';
export const SHARED_USERS = 'משתמשים שנבחרו';
export const PARTICIPANTS = "משתתפים";
export const TYPE_A_NAME = 'הקלד שם...';
export const CHOOSE_A_DATE = 'בחר תאריך';
export const EDIT_TASK = 'עריכת משימה';
export const COPY_LINK = 'העתקת לינק';
export const SEND_LINK = 'שליחת לינק';
export const EMBED_IN_THE_LOG = 'שיבוץ ביומן';
export const DELETE = 'מחיקה';
export const CHANGE_NAME = "שנה שם";
export const ADD_ROW_UP = 'הוסף שלב מעל';
export const ADD_ROW_DOWN = 'הוסף שלב מתחת';
export const ADD_CHILD = 'הוסף שלב פנימי';
export const ADD = '+ הוספה';
export const ADD2 = 'הוסף';
export const TITLE_TASK = 'שם המשימה';
export const TITLE_TASK_PLACEHOLDER = 'הכנס שם למשימה';
export const TEXT_COPY_TASK_MODAL_HEADER_1 = 'העתקת משימה';
export const TEXT_COPY_TASK_MODAL_HEADER_2 = 'בחר ספרייה אליה תרצה לשייך משימה זו';
export const COPY_TO_USER_AREA = 'העתק לאזור האישי';
export const TEXT_LIBRARY = 'ספריית';
export const TEXT_LIBRARY_LINE_3 = 'מיד תועבר/י לספריית';
export const TEXT_DELETE_TASK_1 = 'מחיקה';
export const TEXT_DELETE_BTN = 'מחק משימה';
export const TASK_HISTORY_HEADER = 'היסטוריית משימה';
export const TASK_HISTORY_CREATED_IN_DATE = 'נוצר בתאריך';
export const TASK_HISTORY_UPDATE_IN_DATE = 'עודכן בתאריך';
export const BY = 'ע"י';
export const ONLY_MANAGER_CAN_DELETE_A_TASK = 'רק מנהל יכול למחוק משימה';
export const COPY_ARRAY_BTN = 'העתקת משימה';
export const DUPLICATED_SUCCESSFULLY = 'הועתק בהצלחה';
export const YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_COPY_TASK =
  'מיד תועבר/י למערך משימה';
export const SIGN_OUT = 'התנתק';
export const GENERAL_INTERNAL_ERROR = 'אירעה שגיאה. אנא בדוק הנתונים תקינים (הקישורים נכונים, ואת תהליך עבודה) ונסה שוב מאוחר יותר';
export const NOT_UNIQUE_EMAIL = "יש כבר משתמש במייל זה";
export const PERFORMER = 'אחראי';
export const ADD_DETAILS = 'הוסף פירוט';
export const EDIT_DETAILS = 'ערוך פירוט';
export const CLOSE = 'סגור';
export const STATUS = 'סטטוס';
export const SELECT_PERFORMER = 'בחר אחראי או הגדר תפקיד';
export const SELECT_LEADER = 'בחר מוביל משימה';
export const SUCCESS_SAVED = 'השינויים נשמרו בהצלחה';
export const SUCCESS_DELETED = 'נמחק בהצלחה';
export const TASK_TITLE_IS_REQUIRED_FIELD = 'שם המשימה הינו שדה חובה';
export const THE_CHANGES_NOT_SAVED = 'השינויים לא נשמרו, האם ברצונך להמשיך בכל זאת?';
export const DISCONNECTED_ALERT = 'אין חיבור אינטרנט אולם אל דאגה השינויים נשמרו.';
export const DISCONNECTED_ALERT_1 = 'משהו השתבש, בדוק את חיבור האינטרנט.';
export const DISCONNECTED_NEW_TASK = 'אין חיבור אינטרנט, נסה שנית מאוחר יותר.';
export const TASK_LEADING = 'מוביל משימה - ';
export const TASK_DEFAULT_TITLE = "הכנס שם למשימה";
export const ADD_ROLE_ITEM_AT_USER_DROPDOWN = "הוסף תפקיד/שם";

/* side bar*/
export const MY_DASHBOARD = "הדשבורד שלי";
export const USER_AREA = "איזור אישי";
export const TASKS_CONTAINER = "מאגר משימות";
export const MANAGER_SETTINGS = "הגדרות מנהל";
export const BASIC_TASKS = "משימות יסוד";

/* dashboard*/
export const HI = 'שלום, '
export const SEARCH_TASK = 'חיפוש משימה...'
export const SEARCH_WORDS = 'הקלד/י מילות חיפוש או בחר/י קטגוריה רלוונטית עבורך'
export const ADVANCED_SEARCH = 'חיפוש מתקדם'
export const NEW_TASK = 'משימה חדשה'
export const NEW_FOLSER = 'תיקיה חדשה'
export const FOLDERS = 'תיקיות';
export const TASKSּ = 'משימות';
export const AREA = "סביבת ";
export const FILES = "קבצים";
export const DRAFT = "טיוטא"


/* Aliases */
export const ADD_A_KEYWORD_TO_THE_SEARCH_ENGINE = 'הוסף מילת מפתח למנוע החיפוש'

/* comments */
export const COMMENTS_FROM_DUPLICATE_TASKS= ' הערות ממשימות מועתקות'

/* statistics */
export const ESSENCE = 'מהות'
export const PERCENTAGE_CHANGE = 'אחוז שינוי'
export const OF = 'מתוך'
export const HAVE_BEEN_MODIFIED = ' שונו'

