import { basePath, getForumMessage, sendForumMessage } from './paths';
import { makeRequest } from './middleWareToken';

// send message.
export async function postMessage(data) {
    return await makeRequest( basePath + sendForumMessage, 'POST', data);
}

// get message.
export async function getMessage(id) {
    return await makeRequest( basePath + getForumMessage + id, 'GET');
}