import React, { Component } from 'react';
import v from '../../ui/images/v.svg';

export default class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className="coverV">
                <div className="v" >
                    <img className="vImg" alt='v' src={v} />
                    <p className='vFirstRow' >{this.props.line_1}</p>
                    <p className='vSecendRow' >{this.props.line_2}</p>
                    <p className='vLastRow'>{this.props.line_3}...</p>
                </div>
            </div>
        )
   }
}