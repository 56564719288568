import React, { Component } from 'react';
import './chatBoxOther.css';

class ChatBoxOther extends Component {
    render() {
        return (
            <div>
                <div className="d-flex" >
                    <div className="box-next-to-message"></div>
                    <div className="div-chat-box-ather">
                        <span className="chat-text-ather">
                            {this.props.textMessage}
                        </span>
                        <div className="chat-box-footer-ather">
                            <span>
                                <span className="write-by-ather">
                                    נכתב ע"י
                                </span>
                                <span className="chat-name-ather">
                                    {this.props.userName}
                                </span>

                            </span>
                            <span className="write-by-ather">
                                {this.props.created} 
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChatBoxOther;