import React, { Component } from 'react';
import {
	CANCEL_TEXT_BTN, TEXT_COPY_TASK_MODAL_HEADER_1, TEXT_COPY_TASK_MODAL_HEADER_2,
	CREATE_LIBRARY_LABEL, COPY_ARRAY_BTN, TEXT_TASK_ARRAY, DUPLICATED_SUCCESSFULLY,
	YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_COPY_TASK,
	COPY_TO_USER_AREA
} from '../strings';
import { dataReceived, getAllData, restartTasksRequest, updateSelectedGroup } from '../../components/store/dashBoardStore/dashboardActions';
import { copyTask } from '../../components/apis/copyTask';
import Success from '../../components/CreateTaskPage/Success';
import { amazon } from '../../components/apis/paths';
import whitePlus from '../images/whitePlus.svg';
import arrow from '../images/arrow_down.svg';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { isAdminUser, isSuperUser, isUserStaff, isAreaUser } from '../../components/apis/middleWareToken';
import './copyTaskModal.css';
import userAreaImg from '../images/home_icon_blue.svg';
import { menuItems } from "../../ui/enums/sidebar"
import RectangleButton from '../../components/CreateTaskPage/screens/rectangleButton';


class CopyTaskModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			isRedirect: false,
			showSuccessAlert: false,
			textSuccessAlert_line_1: "",
			textSuccessAlert_line_2: "",
			myGroupId: null,
			myCategory: null,
			myAreas: null,
			openOption: false,
			selectedCategory: null,
			groupSelected: null,
      areaSelected: null,
			newTaskId: null,
			status: 2
		}
		this.isSuperUser = isSuperUser();
		this.isAreaUser = isAreaUser();
		this.isAdmin = isAdminUser();
		this.isStaff = isUserStaff();
	}

	componentDidMount() {
		let myGroupId = this.getUserInformation('groupId');
		let myCategory = !(this.isSuperUser || this.isAreaUser) ? this.props.categories.filter(category => category.id === parseInt(myGroupId)) : this.props.categories;
		this.setState({ myCategory: myCategory, myGroupId: myGroupId, myAreas: this.props.areas});
	}

	getUserInformation(title) {
		return localStorage.getItem(title)
	}

	toggle() {
		this.setState({
			open: !this.state.open
		});
	}

	async copyTaskOnClick() {
		if (this.state.selectedCategory !== null) {
			let ans = await copyTask(
        this.props.data.id, 
        { 'group_id': this.state.selectedCategory.id, 
          area_id: this.state.areaSelected ? this.state.areaSelected.id : null, 
          'status': this.state.status 
        });
			if (ans && ans.data && ans.data.success) {
				this.props.restartTasksRequest(false);
				await this.setState({ showSuccessAlert: true, newTaskId: ans.data.data.id });
				this.updateTasksArray(ans.data.data)
				setTimeout(() => this.setState({
					showSuccessAlert: false,
					open: !this.state.open,
					isRedirect: true,
				}), 3000);
			}
		}
	}

	selectCopyCategory(e, c, groupSelected, status, areaSelected) {
		this.setState({
			selectedCategory: c,
			openOption: !this.state.openOption,
			groupSelected: groupSelected,
			status: status,
      areaSelected: areaSelected
		});
	}
	/* push the new task response to tasks array*/
	updateTasksArray(newTask) {
		let tasks = this.props.tasks
		tasks.push(newTask)
		this.props.dataReceived(false)
		let dataObj = {
			tasks: tasks,
			categories: this.props.categories,
			tags: this.props.tags,
			areas: this.props.areas,
			folders: this.props.folders,
			isTasksReceived: true
		}
		this.props.getAllData(dataObj)
		this.props.dataReceived(true)
	}

  getUserAreaRow = (c, key)=>{
   return <div
      key={key}
      className='copy-task-modal-div-option'
      onClick={(e) => {
        this.selectCopyCategory(
          e, 
          { name: 'איזור אישי', logo: `${userAreaImg}` }, 
          menuItems.USER_AREA, 
          'draft'
          )
        this.setState({
          textSuccessAlert_line_1: `עותק של המשימה נוצר באזור הארגון / אזור אישי`,
          textSuccessAlert_line_2: ""
          })
        }
      }
      >
      <img
        // key={'option-img ' + i}
        src={c.logo !== null ? `${userAreaImg}` : ''}
        alt=""
        className="copy-task-modal-category-option-img"
      />
      <span 
      // key={'option-text ' + i}
        className="copy-task-modal-category-option-text">
        {COPY_TO_USER_AREA}
      </span>
    </div>
  } 

  getSelectMenuRow = (c, key, area)=>{
    return (this.isSuperUser || this.isAreaUser || this.isAdmin) 
      && <div
        key={key}
        className='copy-task-modal-div-option'
        onClick={(e) => {
          this.selectCopyCategory(
            e, 
            c, 
            menuItems.GROUP_ID + c.id + (area ? menuItems.AREA_ID + area.id : ""), 
            'publish',
            area ? area : null
            )
          this.setState({
            textSuccessAlert_line_1: TEXT_TASK_ARRAY + " " + this.props.data.title,
            textSuccessAlert_line_2: DUPLICATED_SUCCESSFULLY
          })

        }}
        >
        <img
          // key={'option-img ' + i}
          src={c.logo !== null ? `${c.logo}` : ''}
          alt=""
          className="copy-task-modal-category-option-img"
        />
        <span 
          // key={'option-text ' + i} 
          className="copy-task-modal-category-option-text">
          {c.name + (area? " - " + area.name : "")}
        </span>
      </div>
  }

  getYesodRows = (c,key)=>{
    return this.props.areas 
           && this.props.areas.length 
           && (this.isSuperUser || this.isAreaUser) 
           && this.props.areas.map((area, i)=>{
                return this.getSelectMenuRow(c, key + " - " + area.id, area, i)
      })
  }
  
	render() {
		return (
			this.state.isRedirect && this.state.open === false && this.state.newTaskId !== null
				?
				(this.props.updateSelectedGroup(this.state.groupSelected),
					<Redirect to={`/dashboard/task/${this.state.newTaskId}`}></Redirect>)
				:
				this.state.open && this.state.showSuccessAlert === false ?
					<div className='class-copy-task-main'>
						<div className='copy-task-modal'>
							<span className="copy-task-modal-header1">
								{TEXT_COPY_TASK_MODAL_HEADER_1} {` "${this.props.data.title}"`}
							</span>
							<span className="copy-task-modal-header2">
								{TEXT_COPY_TASK_MODAL_HEADER_2}
							</span>
							<label htmlFor="" className="select-label-to-copy-modal">
								{CREATE_LIBRARY_LABEL}
							</label>
							<div className="copy-task-select"
								onClick={() => this.setState({ openOption: !this.state.openOption })}>
								{this.state.selectedCategory !== null ?
									<div>
										<img
											src={this.state.selectedCategory.logo !== null ? `${this.state.selectedCategory.logo}` : ''}
											alt=""
											className="copy-task-modal-category-option-img" />
										<span className="copy-task-modal-category-option-text">
											{this.state.selectedCategory.name + (this.state.areaSelected? " - " + this.state.areaSelected.name : "")}
										</span>
									</div>
									: <React.Fragment />}
								<img src={arrow} alt="" className="copy-task-select-arrow-icon" />
								<div style={{ position: 'absolute', width: '100%', top: '42px' }}>
									{ this.state.openOption 
                    && this.state.myCategory 
                    && this.state.myCategory.length 
                    ? this.state.myCategory.map((c, i) => {
                        return (
                          <React.Fragment key={i}>
                            {i === 0 && this.getUserAreaRow(c)}
                            {c.id == 2 ? this.getYesodRows(c, i) : this.getSelectMenuRow(c, i )}												
                          </React.Fragment>
                          );
										  }) 
                    : <React.Fragment />}
								</div>
							</div>
							<div className='div-copy-task-buttons'>
								<RectangleButton
									style = {{
										background: "#E7EBFF",
										color: "#6178FF",
									}}
									onClick = {() => this.setState({
										open: !this.state.open,
										openOption: false,
										selectedCategory: null
									})}
									text = {CANCEL_TEXT_BTN}
									isAllowed = {true}
								/>
								<RectangleButton
									onClick = {() => this.copyTaskOnClick()}
									icon = {<img src={whitePlus} alt="" />}
									text = {COPY_ARRAY_BTN}
									isAllowed = {true}
								/>
							</div>
						</div>
					</div>
					: this.state.showSuccessAlert ?
						<Success
							line_1={this.state.textSuccessAlert_line_1}
							// line_1={`${TEXT_TASK_ARRAY} " ${this.props.data.title}"`}
							line_2={this.state.textSuccessAlert_line_2}
							line_3={`${YOU_WILL_IMMEDIATELY_BE_ASSIGNED_TO_THE_COPY_TASK} " ${this.props.data.title}"`}
						/>
						: <React.Fragment />
		);
	}
}
const mapStateToProps = (state) => {
	return {
		tasks: state.dashboard.tasks,
		user: state.login.user,
		categories: state.dashboard.categories,
		tags: state.dashboard.tags,
		areas: state.dashboard.areas,
		folders: state.dashboard.folders

	}
}

export default connect(
	mapStateToProps, { restartTasksRequest, getAllData, dataReceived, updateSelectedGroup }, null, { forwardRef: true })(CopyTaskModal);
