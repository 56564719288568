import React, { Component } from 'react';
import './CreateTaskPage.css';

class EmptyBox extends Component {

    render() {
        let ifWrites = 'CreateProcessCheckBoxAfterUserWrites';
        let ifEmpty = 'CreateProcessEmptyForPlus';
        return (
            <div className={!this.props.heWrites && this.props.isNew ? ifEmpty : ifWrites}></div>
            // <div className={!this.props.heWrites ? "CreateProcessEmptyForPlus" : "CreateProcessCheckBoxAfterUserWrites"}></div>
        );
    }
}

export default EmptyBox;