import React from "react";
import { ADD2 } from "../../../../ui/strings";
import UserLogo from "./sharing-userLogo";

const GroupUserRow = ({ user, onClick }) => {

  return (
    <div className="user-row" onClick={() => onClick()}>
      <div className="right-part">
        <UserLogo user={user} usegeFor={"groupUser"} />
        <div className="user-name">{`${user.first_name} ${user.last_name}`}</div>
      </div>
      <div className="left-part">
        <div className="user-plus">
          <div className="into-user-plus"></div>
          <div className="plus-icon"></div>
        </div>
      </div>
    </div>
  )
}

export default GroupUserRow