import React, {useEffect, useState} from "react";
import {getStatistics} from "../../../apis/restApi/taskApi";
import '../../../../utils/htmdiff.css'
import {ESSENCE, GROUP, HAVE_BEEN_MODIFIED, OF, PERCENTAGE_CHANGE} from "../../../../ui/strings";
let htmldiff = require('../../../../utils/htmldiff')
const Statistics = (props) => {

  const [statistics, setStatistics] = useState(null)

  useEffect(() => {
    if (statistics === null) {
      getStatistics(props.taskId)
        .then(res => {
          let response = res.data
          response.data && response.data.length > 0 && setStatistics(res.data.data)
        })
        .catch(e => console.log(e))
    }
  }, [statistics])

  let sumChanged =()=>{
    let changed = statistics.filter(u=> u.change != 0).length
    let sumChanged = changed.length / statistics.length
    let length = statistics.length
    return {changed:changed, sumChanged:sumChanged, length:length}
  }

  return (
    statistics !== null && <div className='pr-3 pl-3 pt-2'>
      <div className='pb-2'><b>{ESSENCE}</b></div>
      <table className='table table-bordered'>
        <thead className='text-center'>
        <th style={{width: '75%'}}>{ESSENCE}</th>
        <th style={{width: '10%'}}>{GROUP}</th>
        <th style={{width: '15%'}}>{PERCENTAGE_CHANGE}</th>
        </thead>
          <tbody className='pt-4'>
        {statistics.map(stat =>{
        let change = stat.change.toFixed(2) * 100
         return (<tr>
              <td style={{textAlign: 'start'}} dangerouslySetInnerHTML={{__html: htmldiff(props.taskDescription ,stat.description)}}></td>
              <td style={{textAlign: 'center'}}>{`${stat && stat.group_name}`}</td>
              {/*<td style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: }}>{}</td>*/}
              <td style={{
                backgroundColor: change > 0 ? '#fbb6c2': change < 0 && '#d4fcbc',
                textAlign: 'center',
                wordBreak: 'break-word'
              }}>% {Math.abs(change)}</td>
            </tr>)
        })}
          </tbody>
        </table>
      <div>{`${sumChanged().changed} ${OF} ${sumChanged().length}${HAVE_BEEN_MODIFIED}`}</div>
    </div>
  )
}
export default Statistics





