import React, {Component,} from 'react';
import {connect} from 'react-redux';
import {Route, BrowserRouter, Redirect} from 'react-router-dom';
import {isAllowedAccess} from './components/apis/middleWareToken';
import LoginPage from './components/containers/login/loginPage';
import Main from './components/pages/dashboard/main';
import spinner from "./ui/images/spiner.svg";

class First extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: true
    }
  }

  async componentDidMount() {
    if (!await isAllowedAccess()) {
      this.setState({isLogged: false});
    }
  }

  render() {

    let imgStyle = {
      position: "absolute",
      left: "50%",
      top: "45%",
      zIndex: "101"
    }
    return (
      <React.Fragment>
        {this.props.spinnerOn && <img style={imgStyle} src={spinner} alt={''}/>}
        <BrowserRouter>
          {(() => {
            if (!this.state.isLogged) {
              return <Redirect to='/login'/>
            } else if ((this.state.isLogged && window.location.href === "http://localhost:3000/") ||
              (this.state.isLogged && window.location.href === 'https://yesod.ravtech.co.il/') ||
              (this.state.isLogged && window.location.href === 'https://yesod-test.ravtech.co.il/')
            ) {
              return <Redirect to='/dashboard/userArea'/>
            }
          })()}

          <Route path='/login' component={LoginPage}/>
          <Route path='/dashboard' component={Main}/>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinnerOn: state.dashboard.spinnerOn
  }
}

export default connect(mapStateToProps)(First);
