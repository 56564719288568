import React, { Component } from 'react';
import pdfLogo from '../../ui/images/adobe-logo.png'
import poPotLogo from '../../ui/images/powerpoint.png'
import docxLogo from '../../ui/images/docx.png'
import excelLogo from '../../ui/images/excel.png';
import file from '../../ui/images/File.svg';
import wordLogo from '../../ui/images/word.png';
import jpegLogo from '../../ui/images/jpeg.png';
import delete_x  from '../../ui/images/delete_x.svg';

class Card extends Component {
    constructor(props) {
        super(props);

        this.file = this.props.file;
        this.type = this.props.type;
        this.name = this.props.name.substring(0, 18);
    }



    readFile = () => {
        if (this.props.url) {
            window.open(this.props.url)
        }
    }

    render() {
        var src;
        var color;
        var borderRight;
        let name = this.props.name.substring(0, 18);
        if (this.props.type == "pdf" || this.props.type == "application/pdf") {
            src = pdfLogo; color = { color: "#F84437" }; borderRight = { borderRight: "2px solid #F84437" }
        }
        else if (this.props.type == "image/png" || this.props.type == "png" || this.props.type == "JPG") { src = jpegLogo; color = { color: "#121A4E" }; borderRight = { borderRight: "2px solid #B5B4D2" } }
        else if (this.props.type == "pptx") { src = poPotLogo; color = { color: "#EE7B00" }; borderRight = { borderRight: "2px solid #F56F00" } }
        else if (this.props.type == "docx") { src = docxLogo; color = { color: "#ca00ee" }; borderRight = { borderRight: "2px solid #F56F00" } }
        else if (this.props.type == "xlsx") { src = excelLogo; color = { color: "#3CA241" }; borderRight = { borderRight: "2px solid #3CA241" } }
        else if (this.props.type == "word") { src = wordLogo; color = { color: "#1872D9" }; borderRight = { borderRight: "2px solid #1872D9" } }
        else if (this.props.type != "word" && this.props.type != "docx" && this.props.type != "xlsx" && this.props.type != "pdf") { src = file; color = { color: "#1872D9" }; borderRight = { borderRight: "2px solid #1872D9" } };

        return (
            <div className="CardBox" onClick={this.readFile} style={borderRight}>
                {this.props.handleDeleteFile ? <button
                 onClick={this.props.handleDeleteFile ? this.props.handleDeleteFile : null}
                 className='delete-x-btn'
                 >
                 <img src={delete_x} alt=""/>
                 </button> : <React.Fragment/>}
                <div style={{display:'flex', justifyContent:'space-between', marginTop: '13px'}}>
                    <div style={{display:'flex', flexDirection:'column', marginRight:'9.9%'}}>
                        <span className='headerOfCard' style={color}>{name}</span>
                        <span className='tipeOfFile'>{this.props.type}</span>
                    </div>
                    <img className="forImg" alt={`logo of${name}`} src={src} />
                </div>
            </div>
        )
    }
}
export default Card;
