import React, {Component, useEffect, useState} from 'react';
import './screens/taskPage.css'
import {useSelector} from "react-redux";

const TagsItems = ({taskTags, allTags, tagsHandler}) => {

  // const [tags, setTags] = useState()
  if (allTags) {
    return allTags.map((element, index) => {
      let selected = false
      // if (props.currentTask && props.currentTask.tags) {
      taskTags.forEach((tag, index) => {
        if (tag.id == element.id) {
          selected = true;
        }
      });
      // }
      return <Tag
        key={index}
        selected={selected}
        tagsHandler={tagsHandler}
        id={element.id}
        name={element.name}
      />
    })
  }
  return <React.Fragment/>
};



const Tag = (props) => {
  const [selected, setSelected] = useState(props.selected)

  useEffect(()=>{
    props.tagsHandler(props.id, selected)
  },[selected])

  function onClicked() {
    setSelected(!selected)
  }

  return <button
    // disabled={props.disabled}
    className={selected ? "TagSelected" : "TagNotSelected"}
    id={`Tag${props.id}`}
    onClick={() => onClicked()}
  >
    {props.name}
  </button>
}
// class Tag extends Component {
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             selected: this.props.selected,
//
//         }
//     }
//
//
//     buttonOnClick = () => {
//         if(!this.props.selected){
//             this.setState({ selected: !this.state.selected }, () => {
//                 if(this.props.tagsHandler){
//
//                     this.props.tagsHandler(this.props.id , this.state.selected)
//                 }
//
//             });
//         }else return
//     }
//
//     render() {
//         let disabled = this.props.disabled;
//         let id = this.props.id
//
//         return (
//             <>
//
//
//                     <button disabled={disabled} className={this.state.selected ? "TagSelected" : "TagNotSelected"} id={`Tag${id}`}
//                         onClick={this.buttonOnClick} >
//
//
//                         {this.props.name}
//                     </button>
//
//             </>
//         )
//     }
// }
export default TagsItems;
