import React, { Component } from 'react';
import { connect } from 'react-redux';
import {getCurrentTags, setStartFilter} from '../../components/store/dashBoardStore/dashboardActions';
import './tag.css';

class Tag extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    isTagPressed() {
        if (this.props.currentTags && this.props.currentTags.length > 0) {
            let a = this.props.currentTags.filter(e => e == this.props.tagName);
            return a.length > 0;
        } else return false
    }

    handleTagClick() {
        let newTagArr = Array.from(this.props.currentTags);
        if (this.amITagAll()) {
            newTagArr = ['הכל'];
            this.props.setStartFilter(false)
        } else {
            newTagArr = newTagArr.filter(e => e !== 'הכל');
            this.isTagPressed() ? newTagArr = newTagArr.filter(e => e !== this.props.tagName) : newTagArr.push(this.props.tagName);
            this.props.setStartFilter(true)
        }
        newTagArr.length > 0 ? this.props.getCurrentTags(newTagArr) : this.props.getCurrentTags(['הכל']);
    }

    amITagAll() {
        if (this.props.tagName == 'הכל') {
            return true;
        }
        else return false;
    }

    render() {
        let allIsPressed = this.props.currentTags.length === 1 && this.props.currentTags[0] === 'הכל';
        return (
            <div>
                <button
                    onClick={() => this.handleTagClick()}
                    // style={this.isTagPressed() || allIsPressed ? { backgroundColor: '#6178FF', color: '#FFFFFF' } : { background: '#FFFFFF' }}
                    // style={this.amITagAll() ? { backgroundColor: '#6178FF', color: '#FFFFFF' } : {}}
                    className={this.isTagPressed() || allIsPressed ? 'TagSelected':'TagNotSelected'/*'my-tag'*/}
                >
                    {this.props.tagName}
                </button>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tags: state.dashboard.tags,
        currentTags: state.dashboard.currentTags,
        isStartFilter:state.dashboard.isStartFilter

    }

}


export default connect(
    mapStateToProps,
    { getCurrentTags, setStartFilter }
)(Tag);

