export function prepareData(storedData, newLine, updateNewLine) {

    if (checkDataToSend(storedData)) {
        // let newStoreData = Object.assign({}, storedData);
        if (newLine.length > 0) {
            storedData.work_process.push({
                // newStoreData.work_process.push({
                id: null,
                description: newLine ? newLine : "<p>...</p>",
                // description: newLine,
                task_id: storedData.id ? storedData.id : null,
                parent_id: null,
                checked: false,
                children: [],
            });
            updateNewLine("<p></p>");
            // buildOrderForWorkProcess(storedData);
            // checkIfIsEmptyLine(storedData);
            // return true;

            //test
            let newStoreData = buildOrderForWorkProcess(storedData);
            return newStoreData;
            //test
        }
        else {
            // buildOrderForWorkProcess(storedData);
            // checkIfIsEmptyLine(storedData);
            // return true;

            //test
            let newStoreData = buildOrderForWorkProcess(storedData);
            return newStoreData;
            //test
        }
    }
    else return false
}

export function handleDeleteClick(isChild, fatherIndex, childIndex, task, callback) {
    let newTask = Object.assign({}, task)

    if (isChild) {
        newTask.work_process[fatherIndex].children.splice(childIndex, 1);
    }
    if (!isChild) {
        newTask.work_process.splice(fatherIndex, 1);
    }
    callback(newTask);
}

export function checkDataToSend(storedData) {
    if (!storedData.title || storedData.title.length < 1) {
        alert('חסר שם המשימה ');
        return false;
    } else if (!storedData.category || !storedData.category == undefined) {
        alert('צריך לבחור קטגוריה');
        return false;
    } else if (!storedData.group_id || storedData.group_id == undefined) {
        alert('צריך לבחור קבוצת המשימות');
        return false;
    }
    return true;
}

export function checkDataToSendWithOutAlert(storedData) {
    if (storedData && ( !storedData.title || storedData.title.length < 1)) {
        return false;
    } else if (storedData && ( !storedData.category || !storedData.category == undefined)) {
        return false;
    } else if (storedData && (!storedData.group_id || storedData.group_id == undefined)) {
        return false;
    }
    return true;
}

export function buildOrderForWorkProcess(storedData) {
    let p = "<p>...</p>";
    // let newStoredData = Object.assign({}, storedData);
    // let newStoredData = {...storedData};
    let newStoredData = JSON.parse(JSON.stringify(storedData));
    if (newStoredData.work_process && newStoredData.work_process.length > 0) {
        let counter = 1;
        for (let i = 0; i < newStoredData.work_process.length; i++) {
            let element = newStoredData.work_process[i];
            if (element.children.length > 0) {
                newStoredData.work_process[i].order = counter;
                if (element.description === "" || element.description === "<p></p>") {
                    newStoredData.work_process.splice(i, 1);
                    i--;
                }
                counter += 1
                for (let g = 0; g < element.children.length; g++) {
                    newStoredData.work_process[i].children[g].order = counter;
                    if (element.children[g].description === "" || element.children[g].description === "<p></p>" ) {
                        newStoredData.work_process[i].children.splice(g, 1);
                        g--
                    }
                    counter += 1;
                    
                }
            } else {
                newStoredData.work_process[i].order = counter;
                if (element.description === "" || element.description === "<p></p>") {
                    newStoredData.work_process.splice(i, 1);
                    i--;
                }
                counter += 1;
            }
        }
    }
    // Because copying object file is not copied so here I am manually inserting it.
    newStoredData.files = storedData.files;
    return newStoredData;
}

function checkIfIsEmptyLine(storedData) {

    let p = "<p>...</p>";
    if (storedData.work_process && storedData.work_process.length > 0) {
        let counter = 1;
        for (let i = 0; i < storedData.work_process.length; i++) {
            let element = storedData.work_process[i];
            if (element.children.length > 0) {
                // element.order = counter;
                if (element.description === "" || element.description === "<p></p>") {
                    storedData.work_process.splice(i, 1);
                    i--;
                }
                // counter += 1
                for (let g = 0; g < element.children.length; g++) {
                    // element.children[g].order = counter;
                    if (element.children[g].description === "" || element.children[g].description === "<p></p>") {
                        storedData.work_process[i].children.splice(g, 1);
                        g--;
                    }
                    // counter += 1;
                }
            } else {
                // element.order = counter;
                if (element.description === "" || element.description === "<p></p>") {
                    storedData.work_process.splice(i, 1);
                    i--;
                }
                // counter += 1;
            }
        }
    }
}