import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./quill.css";
import { TextMouseOverPopover } from "../popover/popover";

const Inline = Quill.import('blots/inline');

class CustomAttributes extends Inline {
  constructor(domNode, value) {
    super(domNode, value);

    const span = this.replaceWith(new Inline(Inline.create()));

    span.children.forEach(child => {
      if (child.attributes) child.attributes.copy(span);
      if (child.unwrap) child.unwrap();
    });

    // here we apply every attribute from <font> tag to span as a style
    Object.keys(domNode.attributes).forEach(function (key) {

      if (domNode.attributes[key].name !== "style") {
        let value = domNode.attributes[key].value;
        let name = domNode.attributes[key].name;
        if (name === "face")
          name = "font-family";
        span.format(name, value);
      }
    });

    this.remove();
    return span;
  }
}

CustomAttributes.blotName = "customAttributes";
CustomAttributes.tagName = "FONT";


class Editor extends Component {
  constructor(props) {
    super(props);

    this.state = { loadData: false, hover: false }
    this.ref = React.createRef(this.props.value);

    Quill.register(CustomAttributes, true);

    let icons = Quill.import("ui/icons");

    icons["undo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
    <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
  </svg>`;

    icons["redo"] = `<svg viewbox="0 0 18 18">
    <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
    <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
  </svg>`;

    // icons["custom"] = decodeURIComponent(JSON.parse('"\u{1F4DD}"'))
  }

  componentDidMount(){
    if (this.props.displayToolbar) {
      this.ref.current.focus()
    }
  }

  setOnHover = (mode) => {
    this.setState({ hover: mode })
  }

  myUndo = () => {
    if (this.ref &&
      this.ref.current &&
      this.ref.current.editor &&
      this.ref.current.editor.history &&
      this.ref.current.editor.history.stack &&
      this.ref.current.editor.history.stack.undo &&
      this.ref.current.editor.history.stack.undo.length === 1 &&
      !this.state.loadData
    ) {
      this.setState({ loadData: true })
      return this.clearHistory()
    }
    if (this.ref && this.ref.current) {
      let myEditor = this.ref.current.getEditor();
      return myEditor.history.undo();
    }
  }

  myRedo = () => {
    if (this.ref && this.ref.current) {
      let myEditor = this.ref.current.getEditor();
      return myEditor.history.redo();
    }
  }

  // addDetails = () => {
  //   this.props.setAddDetails()
  // }

  clearHistory = () => {
    if (this.ref && this.ref.current) {
      let myEditor = this.ref.current.getEditor();
      return myEditor.history.clear();
    }
  }

  onChange = (e) => {
    if (this.props.type === 'title') {
      if (e === '<p><br></p>') {
        this.props.setAddDetails()
      }
    }
    if (this.props.id === 'last') {
      this.props.onChange(e)
    } else if (e !== this.props.value && this.props.edit) {
      if (this.props && this.props.onChange) {
        if (this.props.details.field === 'description') {
          this.props.onChange('description', e)
        } else if (this.props.details.field === 'comments') {
          this.props.onChange(e)
        } else {
          let { parent, self, field } = this.props.details
          this.props.onChange(parent, self, field, e)
        }
      }
    }
  }

  render() {

    let toolbarOptions1 = [
      ['bold', 'italic', 'underline', { 'color': [] }, { 'background': [] }],
      [
        { 'align': 'center' }, { 'align': 'right' },
        // { 'indent': '+1' }, { 'indent': '-1' }, 
        { 'list': 'bullet' }
      ],
      ['undo', 'redo'],
      ['link'],
    ];
    let modules = {
      history: {
        delay: 1000,
        maxStack: 100,
        userOnly: false
      },
      toolbar: this.props.displayToolbar ? {
        container:
          this.props.type === 'title' ? toolbarOptions1 : toolbarOptions1,
        handlers: {
          'undo': this.myUndo,
          'redo': this.myRedo,
          // 'custom': this.addDetails
        }
      } : null,
    };

    let modulesForPopover = {
      toolbar: null
    };

    let formats = [
      'header',
      'align',
      'bold', 'italic', 'underline',
      'list', 'bullet',
      'indent', 'indent',
      'script', 'strike',
      'color', 'background',
      'link', 'image', 'direction',
    ];
    return (
      <div className={`${this.props.readOnly ? 'editMode' : ''}`}
        onMouseOver={() => this.props.showPopover ? this.setOnHover(true) : null}
        onMouseLeave={() => this.props.showPopover ? this.setOnHover(false) : null}
      >
        {/* {this.props.showPopover ?
          <TextMouseOverPopover
            id={this.props.id}
            open={this.state.hover}
            title={<ReactQuill
              style={{ border: 'none' }}
              readOnly={true}
              modules={modulesForPopover}
              value={this.props.value}
            />}
            popoverData={<ReactQuill
              readOnly={true}
              theme="snow"
              modules={modulesForPopover}
              value={this.props.popoverData}
            />}
          />
          : */}
          <ReactQuill
            id={this.props.id || 'a'}
            ref={this.ref}
            placeholder={this.props.id === 'last' ? "הוסף הערות..." : ''}
            theme="snow"
            readOnly={this.props.readOnly}
            modules={modules}
            formats={formats}
            value={this.props.value}
            onChange={this.onChange}            
            // preserveWhitespace={true}
          />
          {/* } */}
      </div>
    );
  }
}

export default Editor;
