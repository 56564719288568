import React, { useEffect, useState } from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
  SubMenu
} from "react-pro-sidebar";
import { Link } from 'react-router-dom'
import { ImCircleRight, ImCircleLeft, ImExit } from "react-icons/im";
import logo from '../../../ui/images/yesod_logo_name.svg'
import lists_icon from '../../../ui/images/lists_icon.svg'
import home_icon from '../../../ui/images/home_icon.svg'
import yesod_icon_clear from '../../../ui/images/yesod_icon_clear.svg'
import logo_yesod_navbar from '../../../ui/images/logo_yesod_navbar.svg'
import settings_icon from '../../../ui/images/settings_icon.svg'
import arrow_left from '../../../ui/images/arrow_left.svg'
import arrow_right from '../../../ui/images/arrow_rigth.svg'
import signOut from '../../../ui/images/SignOut.svg'
import Shitim_icon from '../../../ui/images/Shitim_icon.png'

import "react-pro-sidebar/dist/css/styles.css";
import "./Header.css";

import { SIGN_OUT, CREATE_NEW_FOLDER_TEXT, MY_DASHBOARD, TASKS_CONTAINER, USER_AREA, MANAGER_SETTINGS, BASIC_TASKS } from "../../../ui/strings";
import { menuItems } from "../../../ui/enums/sidebar"

import SidebarHeaderDetails from "./sidebarHeaderDetails.js";
import { isSuperUser, isUserStaff } from "../../apis/middleWareToken";
import { connect, useDispatch, useSelector } from "react-redux";
import { updateCurrentTasksArray, updateSelectedGroup } from "../../store/dashBoardStore/dashboardActions";

const Header = (props) => {
  //create initial menuCollapse state using useState hook
  const [menuCollapse, setMenuCollapse] = useState(false)
  const [isStaff, setIsStaff] = useState(null)
  const [isSuperuser, SetIsSuperuser] = useState(null)

  let tasks = useSelector(state => state.dashboard.tasks)
  let selectedGroup = useSelector(state => state.dashboard.groupSelected)
  let areas = useSelector(state => state.dashboard.areas)
  let dispatch = useDispatch()

  useEffect(() => {
    if (isStaff === null) {
      setIsStaff(isUserStaff());
    }
    if (isSuperuser === null) {
      SetIsSuperuser(isSuperUser());
    }
  }, [isStaff, isSuperuser])

  //create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    //condition checking to change state from true to false and vice versa
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const createInnerGroupItem = () => {
    let groupTasks = []
    let sum = 0;
    let key = 0;
    props.categories.map((item, i) => {
      sum = item.categories.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.count
      }, 0);
      if (item.id !== 2) {
        groupTasks.push(
          <MenuItem className='circleIcon' key={key} active={selectedGroup === menuItems.GROUP_ID + item.id}
            icon={<img className='class-Links' src={item.logo} width='25px' height='25px' />}>
            <Link onClick={() => {
              dispatch(updateSelectedGroup(menuItems.GROUP_ID + item.id))
              props.accessToken()
            }
            } to={'/dashboard?group=' + item.id} />
            {/*{`(${sum}) ${item.name.substring(0, 15)}`}*/}
            {item.name.substring(0, 15)}
          </MenuItem>)
      }
      key++;
    })
    props.categories.length && areas.length && areas.map(area => {
      groupTasks.push(
      <MenuItem 
        className='circleIcon' 
        key={key} 
        active={selectedGroup === menuItems.GROUP_ID + props.categories[0].id + menuItems.AREA_ID + area.id}
        icon={<img className='class-Links' src={yesod_icon_clear} />}>
        <Link 
          onClick={() => {
            dispatch(updateSelectedGroup(menuItems.GROUP_ID + props.categories[0].id + menuItems.AREA_ID + area.id))
            props.accessToken()
          }
        } 
        to={'/dashboard?group=' + props.categories[0].id + "&area=" + area.id} />
        {area.name}
      </MenuItem>)
      key++;
    })
    
    return groupTasks
  }

  const getUserInformation = (title) => {
    return localStorage.getItem(title)
  }

  return (
    <React.Fragment>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse} rtl={true}>
          <SidebarHeader>
            <div className='header-top'>
              <div className="closemenu" onClick={menuIconClick}>
                {/* changing menu collapse icon on click */}
                {menuCollapse ? <img src={arrow_left} /> : <img src={arrow_right} />}
              </div>
              <div className="logotext">
                <img className={!menuCollapse ? 'yesod_logo_sidebar' : 'yesod_logo_sidebar_close'}
                  src={!menuCollapse ? logo : logo_yesod_navbar} />
              </div>
            </div>
            <SidebarHeaderDetails
              userFirstName={getUserInformation('userFirstName')}
              userLastName={getUserInformation('userLastName')}
              role={!menuCollapse ? getUserInformation('userRole') : ''} />
          </SidebarHeader>

          <SidebarContent>
            <Menu iconShape="circle">
              <MenuItem id="userArea" active={selectedGroup === menuItems.USER_AREA}
                icon={<img className='class-Links' src={home_icon} />}>
                {USER_AREA}<Link onClick={() => {
                  dispatch(updateSelectedGroup(menuItems.USER_AREA))
                }} to='/dashboard/userArea' />
              </MenuItem>

              {isSuperuser ?
                <SubMenu
                  title={TASKS_CONTAINER}
                  icon={<img className='class-Links' src={lists_icon} />}>
                  {createInnerGroupItem()}
                  <MenuItem>
                    <div id={'addNewFolder'}
                      onClick={() => {
                        dispatch(updateSelectedGroup(null))
                        props.handleCreateNewFolderClick()
                      }}>{CREATE_NEW_FOLDER_TEXT}</div>
                  </MenuItem>
                </SubMenu>
                : createInnerGroupItem()
              }

              {isSuperuser ?
                <MenuItem
                  icon={<img className='class-Links' src={settings_icon} />}
                  active={selectedGroup === menuItems.MANAGER_SETTINGS}
                >{MANAGER_SETTINGS}
                  <Link
                    onClick={(e) => {
                      dispatch(updateSelectedGroup(menuItems.MANAGER_SETTINGS))
                    }} to='/dashboard/createuser'> </Link>
                </MenuItem> :
                <React.Fragment />}
            </Menu>
          </SidebarContent>

          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem icon={<img src={signOut} />}>{SIGN_OUT}<Link onClick={() => {
                localStorage.clear()
                window.location.href = '/login'
              }} to={'/'}> </Link>
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </React.Fragment>
  );
};

export default Header



