import React, { useEffect, useLayoutEffect, useRef } from 'react'
import PdfPageLayout from './pageLayout';
import './printPage.css'
import '../../components/CreateTaskPage/screens/taskPage.css'
import axios from 'axios';


import { useReactToPrint } from 'react-to-print';
import { find_matching_blocks } from '../htmldiff';
import { element } from 'prop-types';
// import TableText from './tablText';
// import "./tableText.css"

const PrintPage = (props) => {
    const componentRef = useRef();
    const handlePrint =  useReactToPrint({
        content: () => componentRef.current,
        documentTitle: props.task.title
    })
    useEffect(()=>{
        props.closeToPrint()
        handlePrint()
    },[])
    

    return (
        <React.Fragment>
            <div className="hiddenPage" ref={componentRef}>
             {/* <button className="rectangle-btn" style={{ position:"fixed", zIndex: 2 }} onClick={() => handlePrint()}>Generate PDF</button> */}
                <PdfPageLayout task = {props.task} categories = {props.categories}/>
                {/* <TableText/> */}

            </div>
        </React.Fragment>
    )
}

export default PrintPage;
