import { basePath, folders , specificTask , updateTasklocation as pathUpdateFolder} from './paths';
import { createRequest, makeRequest } from './middleWareToken';

export async function getAllFolders(){
   return await  createRequest(`${basePath}${folders}`, 'get')
 }

export async function createNewFolder(data) {
    return await makeRequest(basePath + folders, 'POST', data);
}

export async function updateFolder(id, data) {
    return await makeRequest(basePath + folders + "/" + id, 'PUT', data);
}


export async function deleteFolder(id) {
    return await makeRequest(basePath + folders + "/" + id , 'DELETE');
}