import {getUserInfo} from "../../CreateTaskPage/screens/auxiliaryFunctions";

export const taskData = {
    tags: [1, 1, 1, 1, 1, 3, 3],
    selectedTags: [],
    isTaskCreated: false,
    // currentTaskData: {
    //     group_id: parseInt(getUserInfo('groupId')) || 2,
    //     status_name: "publish",
    //     work_process: [],
    //     links: [],
    //     files: [],
    //     tags: [],
    //     category: null
    // },
  currentTaskData:{
    group_id: parseInt(getUserInfo('groupId')) || 2,
    category: 3,
    title: "",
    description: "<p><br></p>",
    status_name: "publish",
    files: [],
    tags: [],
    links: [],
    work_process: []
  },
    createdTask: {},
    isEdit: false,
    isCreate: false,
    newLine: '',
    advertisingOnClick: false,
    saveDraft: false
}

export const cleanedTaskData = {
    title: '',
    group_id: parseInt(getUserInfo('groupId')) || 2,
    status_name: "publish",
    work_process: [],
    links: [],
    files: [],
    category: null,
    description: ""
}
// export const cleanedTaskData = {
//   "id": null,
//   "group_id": parseInt(getUserInfo('groupId')),
//   "group_name": "",
//   "parent_id": null,
//   "version": 1,
//   "category": null,
//   "title": "",
//   "description": "<p><br></p>",
//   "leading_id": null,
//   "leading_name": "",
//   "created_by_id": null,
//   "status_name": "publish",
//   "files_count": 0,
//   "files": [],
//   "tags": [],
//   "links": [],
//   "work_process": []
// }
