import React, {useEffect, useState} from 'react'
import {addGroup, editGroup} from "../../components/apis/restApi/groupsApi";
import {useSelector} from "react-redux";
import PrimaryBtn from "../buttons/primaryBtn";

const CreateGroup = () => {
  const [name, setName] = useState('')
  const [logo, setLogo] = useState('')
  const [groupId, setGroupId] = useState('')
  const [mode, setMode] = useState(0)
  const groups = useSelector(state => state.dashboard.categories)

  useEffect(() => {


  }, [groups])

  function sendData() {
    switch (mode) {
      case 0:
        return addGroup({name, logo})
          .then(res => {
            // console.log(res)
          })
          .catch(e => console.log(e))
      case 1:
        let groupId = parseInt(document.getElementById("ddlViewBy").value)
        let groupName = groups.find(g => g.id === groupId).name
        return editGroup({name: groupName, logo, groupId})
          .then(res => {
            clear()
            // console.log(res)
          })
          .catch(e => console.log(e))
    }

  }

  function clear() {
    setName("")
    setLogo("")
    setGroupId("")
    document.getElementById("files").value = null;
    document.getElementById("organizationName").value = '';
  }


  return <div>
    <div class="btn-group" role="group" aria-label="Basic outlined example" style={{direction: "ltr"}}>
      <button type="button" className="btn btn-outline-primary" onClick={() => setMode(1)}>ערוך אירגון</button>
      <button type="button" className="btn btn-outline-primary" onClick={() => setMode(0)}>צור אירגון</button>
    </div>
    <div className="form-group" id='editGroup' style={{paddingTop: '30px'}}>
      <label htmlFor="organizationName">שם האירגון</label>

      {mode == 0 && <div>
        <input id='organizationName' type="text" className="form-control" placeholder="הכנס שם אירגון"
               onChange={(e) => setName(e.target.value)}/>
      </div>}
      {mode == 1 && <select className="custom-select my-1 mr-sm-2" id="ddlViewBy">
        <option selected>{groups.find(g => g.id === groupId)}</option>
        {groups.map(g => <option value={g.id}>{g.name}</option>)}
      </select>}
    </div>
    <div className="form-group">

    </div>

    <div className="form-group">
      <label htmlFor="files">תמונה/לוגו </label>
      <input type="file" className="form-control-file" id="files"
             onInput={(e) => setLogo(e.target.files[0])}/>
    </div>

    <div className="form-group pt-3">
      <div className={'create-user-btn'}>
        <PrimaryBtn width='142px' height='45px' primaryBtnText={mode == 0 ? 'צור' : mode == 1 ? 'עדכן' : ''}
                    primaryBtnOnClick={sendData}/>
        <span className='create-user-btn-cancel' onClick={() => clear()}>ביטול</span>
      </div>
    </div>
  </div>
}
export default CreateGroup
