import React, { Component } from 'react';
import {
    CANCEL_TEXT_BTN, CREATE_LIBRARY_HEADER,
    CREATE_LIBRARY_PLACEHOLDER_INPUT, CREATE_LIBRARY_LABEL,
    ADD_FILE_TEXT, ADD_FILE_BTN_IN_CREATE_LIBRARY_MODAL_TEXT,
    CREATE_LIBRARY_ACTION_TEXT, TEXT_LIBRARY, TEXT_LIBRARY_LINE_3,
    TEXT_SUCCESSFULLY_CREATED_FEMALE
} from '../strings';
import icon_add_file_in_create_library_modal from '../images/icon_add_file_in_create_library_modal.svg';
import { createNewCategory } from '../../components/apis/createNewCategory';
import Success from '../../components/CreateTaskPage/Success';
import { config } from '../../configuration/amazon';
import delete_x from '../images/delete_x.svg';
import { restartTasksRequest } from '../../components/store/dashBoardStore/dashboardActions'
import { Redirect } from 'react-router-dom';
import plus from '../images/whitePlus.svg';
import { connect } from 'react-redux';
import S3 from 'react-aws-s3';
import './modal.css';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            tempUrlFile: null,
            file: null,
            categoryName: '',
            isRedirect: false,
            showSuccessAlert: false
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    addFile = () => {
        this.upload.click()
    }

    newFile = (e) => {
        this.setState({
            tempUrlFile: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        });
    }

    async createLibrary() {

        if (this.state.file !== null) {
            if (this.state.categoryName === '') {
                this.setState({ categoryName: this.state.file.name });
            }
            if (this.state.categoryName !== '') {
                let formData = new FormData();
                formData.append('name', this.state.categoryName);
                formData.append('logo', this.state.file);
                let ans = await createNewCategory(formData);
                if (ans && ans.data && ans.data.success) {
                    this.setState({ showSuccessAlert: true });
                    this.props.restartTasksRequest(false);
                    setTimeout(() => this.setState({
                        showSuccessAlert: false,
                        open: !this.state.open,
                        isRedirect: true,
                        file: null,
                        tempUrlFile: null,
                        categoryName: ''
                    }), 3000);
                }
            }
        }
    }

    render() {

        return (
            this.state.isRedirect && this.state.open === false ? <Redirect to='/dashboard'></Redirect> :
                this.state.open && this.state.showSuccessAlert === false ?
                    <div className='modal-background'>
                        <div className="main-div-create-library-modal">
                            <span className="create-library-header">
                                {CREATE_LIBRARY_HEADER}
                            </span>
                            <label htmlFor="" className="create-library-label">
                                {CREATE_LIBRARY_LABEL}
                            </label>
                            <input type="text" className="create-library-input"
                                placeholder={CREATE_LIBRARY_PLACEHOLDER_INPUT}
                                onChange={(e) => this.setState({ categoryName: e.target.value })}
                            />
                            <div className="div-add-file-in-create-library-modal">
                                <span className='add-file-text'>{ADD_FILE_TEXT}</span>
                                <button
                                    className="add-file-to-create-library-modal-btn"
                                    onClick={this.addFile}
                                >
                                    <img
                                        src={icon_add_file_in_create_library_modal}
                                        alt=""
                                    />
                                    <span style={{ marginRight: '7px' }}>
                                        {ADD_FILE_BTN_IN_CREATE_LIBRARY_MODAL_TEXT}
                                    </span>
                                </button>
                                <input
                                    style={{ display: 'none' }}
                                    // accept="image/*"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    ref={(ref) => this.upload = ref}
                                    type="file"
                                    name="add-library"
                                    id="add-library"
                                    onChange={(e) => this.newFile(e)}
                                />
                            </div>
                            {this.state.file !== null ?
                                <div style={{ position: 'relative' }}>
                                    <img style={{ height: '40px', width: '60px', marginTop: '15px' }} src={this.state.tempUrlFile} />
                                    <img
                                        style={{ position: 'absolute', right: '46px', top: '6px' }}
                                        src={delete_x}
                                        alt=""
                                        onClick={() => { this.setState({ tempUrlFile: null, file: null }); this.upload.value = null }}
                                    />
                                </div>
                                : <React.Fragment/>}
                            <div className="create-library-modal-buttons">
                                <button
                                    className='create-library-modal-cancel-btn'
                                    onClick={() => this.setState({ open: !this.state.open, tempUrlFile: null, file: null, categoryName: '' })}
                                >
                                    {CANCEL_TEXT_BTN}
                                </button>
                                <button className="create-library-btn-action" onClick={() => this.createLibrary()}>
                                    <img style={{ marginLeft: '10px' }} src={plus} alt="" />
                                    {CREATE_LIBRARY_ACTION_TEXT}
                                </button>
                            </div>
                        </div>
                    </div>
                    : this.state.showSuccessAlert ?
                        <Success
                            line_1={TEXT_LIBRARY + ' "' + this.state.categoryName + '"'}
                            line_2={TEXT_SUCCESSFULLY_CREATED_FEMALE}
                            line_3={TEXT_LIBRARY_LINE_3 + ' "' + this.state.categoryName + '"'}
                        />
                        : <React.Fragment/>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(
    mapStateToProps, { restartTasksRequest }, null, { forwardRef: true })(Modal);
