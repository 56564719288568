import React, { useState, useRef} from "react";
import { useSelector } from "react-redux"

import { usePopper, Popper } from "react-popper";
import RectangleButton from "../rectangleButton";
import { SHARE } from "../../../../ui/strings";
import SharingModal from "./sharingModal";
import './sharingModal.css'
import SharedUsersOverview from "./shating-overview";

const SharingPopper = ({currentTask, setCurrentTask, editMode})=>{

  const users = useSelector(state => state.dashboard.users.filter(user => user.group_id == localStorage.getItem("groupId")));

  const [isOpen, setIsOpen] = useState(false)

    return (
    <React.Fragment>
      {!currentTask.shared.length ?
      <RectangleButton
        className = {"users-icon"}
        text={SHARE}
        icon={" "}
        onClick={()=>{setIsOpen(!isOpen)}}
        isAllowed={true}
        isEdit={editMode}
        />:
        <SharedUsersOverview 
          users = {users}
          owner = {currentTask.created_by_id}
          sharedUsers={currentTask.shared}
          openSharingModal={isOpen} 
          setOpenSharingModal = {setIsOpen} />}
    {isOpen &&
      <SharingModal
        id= {currentTask.id}
        alreadyShared = {currentTask.shared}
        currentTask = {currentTask}
        setCurrentTask = {setCurrentTask}
        openSharingModal = {isOpen}
        setOpenSharingModal = {setIsOpen}
        users = {users}
        />}
    </React.Fragment>
  )
}

export default SharingPopper