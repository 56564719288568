import React, {Component} from "react";
import {ESSENCE, TITLE_TASK, TITLE_TASK_PLACEHOLDER} from "../../../../ui/strings";
import lock from "../../../../ui/images/lock.svg"
import unlock from "../../../../ui/images/unlocked.svg"
import {connect} from "react-redux";
import Editor from "../editor/quill";

class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  handleState(field, value) {
    this.setState({[field]: value})
  }
  
  editTitle() {
    return <div className="form-group mr-3" style={{width: '60%', borderBottom: '2px solid rgba(18,26,78,0.6)'}}>
      <label className='edit-follow pl-2' htmlFor="edit-title">{`${TITLE_TASK}*`}</label>
      <input type="text" className="form-control" id="edit-title"
             placeholder={TITLE_TASK_PLACEHOLDER} defaultValue={this.props.title}
             style={{border: "none", boxSizing: 'none'}}
             onChange={(e) => this.props.onChange('title', e.target.value)}/>
    </div>;
  }
  
  render() {
    let logo = this.props.categories.find(category => category.id === this.props.groupId).logo;
    return (<div className='description' onClick={() => this.props.onClicked(0)}>
      <div className='d-flex justify-content-center pr-3'>
        <span className = "wrap-logo-img">
          
        <img className='logo' src={logo} width='66px' height='66px'/>
        {/* {this.props.editable === false && <img className='lock' src={lock} width='15px' height='15px'/>} */}
        </span>

      </div>
      {this.props.editMode && <div className='pb-3'>{this.editTitle()}</div>}
      <div className='title mb-3 pr-2'>{ESSENCE}</div>
      <div className={!this.props.editMode ? 'desc pb-4 pr-3' : 'pb-4 pr-2 pl-4'}>
          <Editor
          className='editor'
          readOnly={!this.props.editMode || !this.props.onSection}
          displayToolbar={this.props.editMode && this.props.onSection}
          details={{parentId: null, id: null, field: "description"}}
          value={this.props.description}
          edit={this.props.editMode}
          // type='rich'
          onChange={this.props.onChange}
        />
      </div>
    </div>);
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.dashboard.categories
  }
}
export default connect(mapStateToProps)(Description);
