import React, { Component } from 'react';
import {connect} from "react-redux";
import  './textInput.css'

class GroupSelectToRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSizeLabel: false
    }
  }

  handleChangeLocal(e) {
    this.setState({ value: e.target.value })
  }


  render() {
    let validationBorder = { borderBottom: '2px solid #FF8B90', width: this.props.width };
    return (
      <div className='class-text-main'>
        {this.state.value ?
          <label className='class-text-label-login-sm' htmlFor="group">{this.props.placeholder}</label> :
          <React.Fragment />}
        <select type="select"
               className="login-text-input"
               name="select"
               id="group"
               tabIndex={this.props.tabIndex}
               onKeyDown={this.props.keyPress}
               style={this.state.value && !this.props.validation ? { borderBottom: '2px solid rgba(18,26,78,0.6)', width: this.props.width } :
                 this.props.validation ? validationBorder : { width: this.props.width }}
                onChange={(e)=>{
                  this.props.onChange(this.props.category, e.target.value)
                  this.handleChangeLocal(e)}}
        >
          <option value="" disabled selected>בחר ארגון*</option>
          {this.props.categories.map(e=> <option value={e.id}>{e.name}</option>)}
        </select>
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    users: state.dashboard.users,
    categories: state.dashboard.categories
  }
}

export default connect(mapStateToProps)(GroupSelectToRegister);
