import React, { Component, useState } from 'react';
import { Collapse, Button, CardBody, Card, Tooltip } from 'reactstrap';
import arrow_up_blue from '../../../ui/images/arrow_up_blue.svg';
import arrow_down_blue from '../../../ui/images/arrow_down_blue.svg';
import arrow_up from '../../../ui/images/arrow_up.svg';
import arrow_down from '../../../ui/images/arrow_down.svg';
import arrow_down_disabled from '../../../ui/images/arrow_down_disabled.svg';
import './callapse.css'

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: this.props.isOpen
    }
  }

  handleState(field, value) {
    this.setState({ [field]: value })
  }

  // const [collapse, setCollapse] = useState(false);
  // const [status, setStatus] = useState('Closed');

  // const onEntering = () => setStatus('Opening...');
  //
  // const onEntered = () => setStatus('Opened');
  //
  // const onExiting = () => setStatus('Closing...');
  //
  // const onExited = () => setStatus('Closed');

  toggle = () => {
    if (this.props.disabled) {
      return
    }
    this.handleState('collapse', !this.state.collapse)
  };


  render() {
    return (
      <div style={{
        border: this.state.collapse ? '1px solid rgba(18,26,78,0.25)' : "",
        backgroundColor: "white",
        boxShadow: "0 6px 17px 0 rgba(18,26,78,0.08)"
      }} className='callapse'>

        <div className='callapse-close' onClick={this.toggle} style={{ marginBottom: '1rem', color: this.props.disabled ? 'lightgrey' : '', cursor: this.props.disabled ? 'default' : '' }}>
          <span className={this.props.progress ? 'd-flex justify-content-between align-items-center pl-2' : 'center-v'}>
            <div style={{ display: 'flex', alignItems: "center" }}>
              <div style={{ display: this.props.count ? 'inline-grid' : "", marginRight: "10px", marginLeft: "20px" }}>
                {this.props.count > 0 && <div className='num_of_children'>{this.props.count}
                </div>}
                <img src={this.state.collapse ? arrow_up_blue : arrow_down_blue} />
                {/* <img src={this.state.collapse ? arrow_up : this.props.disabled ? arrow_down_disabled : arrow_down} /> */}
              </div>
              {this.props.title}
            </div>
            <div>{this.props.progress}</div>
          </span>
        </div>

        <Collapse isOpen={this.state.collapse}>
          <div className='pt-3 pb-3' onClick={() => parseInt(this.props.id) !== this.props.section ? this.props.setOnSection(parseInt(this.props.id)) : null}>
            {this.props.body}
          </div>
        </Collapse>
      </div>
    );
  }
}

export default Section;
