import FilesItem from "../filesItem";
import LinksItems from "../linksItem";
import UploadFilesButton from "./uploadFilesBtn/uploadFilesBtn";
import AddLinkBtn from "../addLinkBtn/addLinkBtn";
import React, {useEffect, useState} from "react";

const FilesAndLinks = props => {
  const [files, setFiles] = useState(props.task.files)
  useEffect(()=>{
    setFiles(props.task.files)
  },[props.task.files])

  const [links, setLinks] = useState(props.task.links)
  useEffect(()=>{
    setLinks(props.task.links)
  },[props.task.links])

  /* delete file in task*/
  function onDeleteFileOrLink(index, type) {
    let task = {...props.task}
    task[type].splice(index, 1)
    props.onChange({...props.task, [type]:task[type]})
  }

  /* rename file in task*/
  // function onChangeFileName(index, value) {
  //   let suffix = props.task.files[index].name.split('.').pop()
  //   let fixSuffix = value.includes(suffix) ? value : value !=='' ? `${value.split('.')[0]}.${suffix}` : props.task.files[index].name
  //   let copyFiles = [...props.task.files]
  //   copyFiles[index].name = fixSuffix
  //     // setFiles(copyFiles)
  //   props.onChange({...props.task, 'files':copyFiles})
  // }

  return <div>
        <FilesItem task={props.task}
                   editMode={props.editMode}
                   onDelete={onDeleteFileOrLink}
                   onChange={props.onChange}
                   printMode = {props.printMode}/>
        <LinksItems links={props.task.links}
                    editMode={props.editMode}
                    onDelete={onDeleteFileOrLink}
                    printMode = {props.printMode}/>
    {props.editMode ?
      <span>
          <UploadFilesButton task={props.task} onChange={props.onChange}/>
          <AddLinkBtn task={props.task} onChange={props.onChange}/>
        </span> :<span/>}
      </div>
}
export default FilesAndLinks
