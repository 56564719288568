export function sortMyDateWithoutTime(str, longOrShort) {
    let splitData = str.split(' ')[0].split('-');
    let stringMonth = getNumOfMonthReturnMonthStringInHebrew(parseInt(splitData[1]), longOrShort);
    let myDate = splitData[2] + " " + stringMonth + " " + splitData[0];
    return myDate;
}

export function getNumOfMonthReturnMonthStringInHebrew(numOfMonth, longOrShort) {
    const monthLongNames = ["בינואר", "בפברואר", "במרס", "באפריל", "במאי", "ביוני",
        "ביולי", "באוגוסט", "בספטמבר", "באוקטובר", "בנובמבר", "בדצמבר"
    ];
    const monthShortNames = ["ינו'", "פבר'", "מרס", "אפר'", "מאי", "יוני",
        "יולי", "אוג'", "ספט'", "אוק'", "נוב'", "דצב'"
    ];
    if (longOrShort === 'longMonth') {
        return monthLongNames[numOfMonth - 1];
    }
    if (longOrShort === 'shortMonth') {
        return monthShortNames[numOfMonth - 1];
    }
}