import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCurrentTask } from '../store/taskStore/taskActions'

import { Button, Input } from 'reactstrap';
import './CreateTaskPage.css';
import LinkLogo from '../../ui/images/link.png'



class LinkManager extends Component {

    /**
     *
     *
     *
     */
    constructor(props) {

        super(props)
        this.state = {
            link: '',
            url: '',
            isCreatedLink: this.props.link && this.props.url
        }
    }
    linksHandleChange = (link, url) => {
        if (link === '') {
            link = this.state.url
        }
        if (this.props.currentTaskData && !this.props.currentTaskData.links) {
            this.props.currentTaskData.links = []
        }
        let links = this.props.currentTaskData.links.slice();
        links.push({
            text: link,
            url: url,
            task_id: this.props.currentTaskData.id ? this.props.currentTaskData.id : null,

        });
        let receivedTaskData = Object.assign({}, this.props.currentTaskData); // not sure if without this action does deep copy??
        receivedTaskData.links = links
        this.props.updateCurrentTask(receivedTaskData)
    }

    onChangeInputForUrl = (e) => {
        this.setState({ url: e.target.value });
    }
    onChangeInputForLink = (e) => {
        this.setState({ link: e.target.value });
    }
    createLink = (e) => {
        if (this.state.url.trim() !== '') {
            if (this.props.linkCreateToggle) {
                this.props.linkCreateToggle();
            }
            this.linksHandleChange(this.state.link, this.state.url);
            this.setState({ isCreatedLink: true });
        }
    }

    render() {

        return (

            !this.state.isCreatedLink ?
                <div className="linksHeader" >
                    <Input value={this.state.url} onChange={(e) => this.onChangeInputForUrl(e)} className="linkLine col-3" placeholder={"אנא הכנס URL"} />
                    <Input value={this.state.link} onChange={(e) => this.onChangeInputForLink(e)} className="linkLine col-3" placeholder={"הכנס טקסט ליצירת קישור"} />
                    <Button className='createLink' onClick={this.createLink}>
                        <span >צור קישור</span>
                    </Button>
                </div>
                : <React.Fragment/>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        categories: state.dashboard.categories,
        currentTaskData: state.task.task_undo.present.currentTaskData,

    }

}


export default connect(
    mapStateToProps,
    { updateCurrentTask }
)(LinkManager);
