
const school1 = {
    id: 1,
    name: "נופים"
}
const roleManager = {
    id: 1,
    name: "manager"
}

export const data = {
    "user": {
        "name": "Alex",
        "lastName": "Roitman",
        "role": roleManager,
        school: school1
    }
}

const tags = [ "הכל",  "ממלכי דתי", "סגל בית הספר", "חטיבה צעירה", "שילוב", "נהלים", "פדגוגיה",]
const tasks = []

const user = {
    id: 1,
    name: "Alex",
    lastName: 'Roitman',
    role: 'manager',
    school: 'nofim'
}
export const dashboardData = {
    tasks: [],
    folders:[],
    isTasksReceived: true,
    currentTasks:[],
    currentTasksFiltered: false,
    filteredFolders:[],
    tags:tags,
    areas:[],
    currentTags: ["הכל"],
    inputSearch: '',
    user: user,
    users: [],
    findSearch: false,
    categories:[],
    currentCategory:0,
    currentGroup:0,
    searchResults:[],
    isRoutesBounded: false,
    isStartFilter: false,
    isTaskDeleted: false,
    groupSelected:null,
    spinnerOn:false
}
