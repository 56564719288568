import {
    GET_CURRENT_TAGS,
    GET_USER_DATA,
    GET_ALL_DATA,
    GET_INPUT_SEARCH,
    UPDATE_CURRENT_TASKS_ARRAY,
    GET_CATEGORIES,
    GET_TAGS,
    DATA_RECEIVED,
    UPDATE_CURRENT_CATEGORIES,
    UPDATE_SEARCH_RESULTS,
    RESTART_REQUEST_TASKS,
    UPDATE_iS_ROUTES_BOUNDED,
    UPDATE_CURRENT_TASK_ARRAY_FILTERED,
    UPDATE_TASK_ARRAY,
    UPDATE_CURRENT_TASK_IN_ARRAY,
    // UPDATE_CURRENT_GROUP,
    START_FILTER,
    SPINNER_ON,
    TASK_DELETED,
    UPDATE_SELECTED_GROUP,
    GET_USERS, INSERT_NEW_TASK_TO_TASKS_ARRAY,
    UPDATE_FILTERED_FOLDERS,
    INSERT_NEW_FOLDER_IN_ALL_FOLDERS,
    UPDATE_FOLDER_IN_ALL_FOLDERS,
    REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR
} from './dashboardActionTypes';
import { UPDATE_CURRENT_TASK_DATA } from "../taskStore/taskActionType";

export function getInputChange(data) {

    return { type: GET_INPUT_SEARCH, payload: data }
}

export function getCurrentTags(tags) {

    return { type: GET_CURRENT_TAGS, payload: tags }
}

export function getUserData(data) {
    return { type: GET_USER_DATA, payload: data }
}

export function spinnerOn(data) {
    return { type: SPINNER_ON, payload: data }
}

export function setGroupUsers(data) {
    return { type: GET_USERS, payload: data }
}

export function getAllData(data) {
    return { type: GET_ALL_DATA, data: data }
}

export function updateCurrentTasksArray(data) {
    return { type: UPDATE_CURRENT_TASKS_ARRAY, data: data }
}

export function insertNewFolderInAllFoldersArr(data) {
    return { type: INSERT_NEW_FOLDER_IN_ALL_FOLDERS, payload: data }
}

export function updateFolderInAllFoldersArr(data) {
    return { type: UPDATE_FOLDER_IN_ALL_FOLDERS, payload: data }
}

export function removeFolderFromAllFolders(data) {
    return { type: REMOVE_FOLDER_FORM_ALL_FOLDERS_ARR, payload: data }
}

export function updateFilteredFolders(data) {
    return { type: UPDATE_FILTERED_FOLDERS, data: data }
}

export function getCategories(data) {
    return { type: GET_CATEGORIES, payload: data }
}

export function getTags(data) {
    return { type: GET_TAGS, payload: data }
}

export function setStartFilter(mode) {
    return { type: START_FILTER, payload: mode }
}

export function dataReceived(data) {
    return { type: DATA_RECEIVED, payload: data }
}

export function updateCurrentCategory(data) {
    return { type: UPDATE_CURRENT_CATEGORIES, payload: data }
}

export function updateSelectedGroup(data) {
    return { type: UPDATE_SELECTED_GROUP, payload: data }
}

export function insertNewTaskToTasksArray(data) {
    return { type: INSERT_NEW_TASK_TO_TASKS_ARRAY, payload: data }
}

export function updateCurrentTaskInArray(data) {
    return { type: UPDATE_CURRENT_TASK_IN_ARRAY, payload: data }
}

export function updateSearchResults(data) {
    return { type: UPDATE_SEARCH_RESULTS, payload: data }
}

export function restartTasksRequest(data) {
    return { type: RESTART_REQUEST_TASKS, payload: data }
}

export function updateIsRoutesBounded(data) {
    return { type: UPDATE_iS_ROUTES_BOUNDED, payload: data }
}

export function updateCurrentTaskArrayFiltered(data) {
    return { type: UPDATE_CURRENT_TASK_ARRAY_FILTERED, payload: data }
}

export function updateTaskArray(data) {
    return { type: UPDATE_TASK_ARRAY, payload: data }
}
export function taskDeleted(data) {
    return { type: TASK_DELETED, payload: data }
}
