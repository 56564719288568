import React, {Component} from 'react';
import missionArrayHistoryLogo from '../../../ui/images/mission_array_history_logo.svg';
import {getHebrewDate} from '../../../components/apis/getHebrewDate';
import {
  TASK_HISTORY_HEADER, TASK_HISTORY_CREATED_IN_DATE, TASK_HISTORY_UPDATE_IN_DATE, BY
} from '../../../ui/strings';
import {sortMyDateWithoutTime} from './moreInfoFunction';
import './taskMissionHistory.css';
import {getHistory} from "../../apis/restApi/taskApi";
import {format, formatRelative, subDays} from "date-fns";
import {es, he} from 'date-fns/locale'

class TaskMissionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hebrewDateArr: [],
      updatesArr: [],
      creator: this.props.creator !== undefined ? `${this.props.creator.first_name} ${this.props.creator.last_name}` : 'Unknown',
      isScrolled: false
    }
  }

  async componentDidMount() {
    getHistory(this.props.taskId)
      .then((res) => {
        let track = res.data.data
        track.map(async (elm, i) => {
          if (elm.action === 'u') {
            // let date = await this.date(elm.date_of_change);
            this.setState(prevState => {
              let updatesArr = Array.from(prevState.updatesArr);
              updatesArr.push(
                <div className='d-flex mt-15' key={'update-history-' + i}>
                  <img className='mission-array-update-history-logo' src={missionArrayHistoryLogo} alt=""/>
                  <div>
                    {/*{`${TASK_HISTORY_UPDATE_IN_DATE} ${sortMyDateWithoutTime(elm.date_of_change, 'longMonth')}  ${BY} ${elm.username}`}*/}
                    {`${TASK_HISTORY_UPDATE_IN_DATE} ${formatRelative(subDays(new Date(elm.date_of_change), 0), new Date(), {locale: he})}  ${BY} ${elm.username}`}
                  </div>
                </div>
              )
              return {updatesArr};
            });
          }
        });
      })
      .catch(e => console.log(e))

  }

  async date(myDate) {
    let date = myDate.split(' ')[0].split('-');
    let hebrewDate = await getHebrewDate(date[0], date[1], date[2]);
    if (hebrewDate && hebrewDate.data) {
      let hebrew = hebrewDate.data.hebrew;
      return hebrew
    }
  }

  updateScroll() {
    let element = document.getElementById("historyBox");
    if (element !== null) {
      const a = element.scrollTop
      element.scrollTop = element.scrollHeight;
      if (a !== element.scrollTop) {
        this.setState({isScrolled: true})
      }
    }
  }

  render() {
    let data = this.state.updatesArr.length > 0 ? this.state.updatesArr : [];
    !this.state.isScrolled && this.updateScroll()
    return (
      <div style={{minWidth: 'max-content'}} className='pl-3'>
        <div /*className = "mission-array-history-header"*/>
          {TASK_HISTORY_HEADER}
        </div>
        <div id='historyBox' style={{maxHeight: '250px', overflowY: 'scroll'}}>
          <div className="mission-array-history">
            <img className='mission-array-history-logo' src={missionArrayHistoryLogo} alt=""/>
            <div className="mission-array-history-text">
              {` ${TASK_HISTORY_CREATED_IN_DATE} ${formatRelative(subDays(new Date(this.props.created), 0), new Date(), {locale: he})} ${BY} ${this.state.creator}`}
            </div>
          </div>
          <div className="mission-array-history-updates">
            {data.map(div => div)}
          </div>
        </div>
      </div>
    );
  }
}

export default TaskMissionHistory;
