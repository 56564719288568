import axios from 'axios';
import jwt_decode from 'jwt-decode'
import { refresh, basePath } from './paths'

// var token = 'eyJ0eXAiO.../// jwt token';

// var decoded = jwt_decode(token);
// console.log(decoded);

/* prints:
 * { foo: "bar",
 *   exp: 1393286893,
 *   iat: 1393268893  }
 */


export function validateToken(name) {
  let token = localStorage.getItem(name);
  return token && ((jwt_decode(token).exp - 10) * 1000) > new Date().getTime()  // Ten seconds to be sure
}

export function getUserId() {
  let token = localStorage.getItem('refreshToken');
  if (token) {
    let decoded = jwt_decode(token)
    return decoded.user_id
  }
}

export function isUserStaff() {
  let token = localStorage.getItem('refreshToken');
  if (token) {
    let decoded = jwt_decode(token)
    // console.log('isUserStaff decoded, ', decoded);
    return decoded.is_stuff;
  }
}

export function isAdminUser() {
  let token = localStorage.getItem('refreshToken');
  if (token) {
    let decoded = jwt_decode(token)
    // console.log('isUserStaff decoded, ', decoded);
    return decoded.is_admin;
  }
}

export function isAreaUser() {
  let token = localStorage.getItem('refreshToken');
  if (token) {
    let decoded = jwt_decode(token)
    // console.log('isSuperUser decoded, ', decoded);
    return decoded.is_area_user;
  }
}

export function isSuperUser() {
  let token = localStorage.getItem('refreshToken');
  if (token) {
    let decoded = jwt_decode(token)
    // console.log('isSuperUser decoded, ', decoded);
    return decoded.is_superuser;
  }
}

export async function refreshToken() {
  let ans;

  if (validateToken('refreshToken')) {
    await axios({
      method: "POST",
      data: { refresh: localStorage.getItem('refreshToken') },
      url: basePath + refresh,
    }
    )
      .then(function (response) {
        //add to localStorage

        setTokenToLocalStorage('accessToken', response.data.data.access)
        ans = true
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    // redirect to login page
    // localStorage.clear();
    ans = false;
  }
  return ans
}

export function setTokenToLocalStorage(name, accessToken) {
  localStorage.setItem(name, accessToken);
}



export async function isAllowedAccess() {
  if (validateToken('accessToken')) {
    return true;
  } else {
    return refreshToken();
  }
}

export async function setTokenToHeaders() {
  if (await isAllowedAccess()) {
    return { Authorization: 'Bearer ' + localStorage.getItem('accessToken') };
  } else {
    // redirect to login page

    //Temporary!
    if (!await isAllowedAccess()) {
      return false;
    }
    //Temporary!

  }
}

export async  function createRequest(url, method, body ={}) {
  const headers = await setTokenToHeaders();
  if(method === 'get' || method === 'delete') {
    return await axios[method](url, {headers: headers})
  } else {
    return await axios[method](url, body, {headers: headers})}
}


export async function makeRequest(url, method, data = {}) {
  let answer;
  const headers = await setTokenToHeaders();
  if (headers) {
    await axios({
      method: method,
      data: data,
      url: url,
      headers: headers
    }
    )
      .then(function (response) {
        answer = response

      })
      .catch(function (error) {
        console.log(error);
      });

    return await answer;
    }
    if (!headers) return headers;
}
