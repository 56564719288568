import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { menuItems } from "../../../ui/enums/sidebar"
import { AREA, USER_AREA } from "../../../ui/strings"
import FoldersBuilder from "../../containers/dashBoardContainers/foldersBuilder"
import TasksBuilder from "../../containers/dashBoardContainers/tasksBuilder"
import './dashboard-v3.css'
import PatnItem from "../../containers/dashBoardContainers/ui/pathItem"
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { updateSelectedGroup } from "../../store/dashBoardStore/dashboardActions"
import AddFolsersAndTasks from "../../containers/dashBoardContainers/ui/addFolderAndFile"
import { useDrop } from "react-dnd"
import { dndTypes } from "../../containers/dashBoardContainers/ui/dndTypes"

const userAreaPath = "/dashboard/userArea"

const DashboardV3 = () => {

  const location = useLocation()
  const history = useHistory()

  const dashboardRef = useRef(null)

  const dispatch = useDispatch()

  let isDataReceived = useSelector(state => state.dashboard.isTasksReceived)
  const categories = useSelector(state => state.dashboard.categories)
  const areas = useSelector(state => state.dashboard.areas)
  const allFolders = useSelector(state => state.dashboard.folders)

  const [myGroup, setMyGroup] = useState({})
  const [filteredFoldersByRegion, setFilteredFoldersByRegion] = useState([])
  const [filteredFoldersByFolder, setFilteredFoldersByFolder] = useState([])
  const [currentFolder, setCurrentFolder] = useState(null)
  const [foldersPath, setFoldersPath] = useState([])
  const [filteredTasks, setFilteredTasks] = useState([])
  const [newFolderCreated, setNewFolderCreated] = useState(null)

  useEffect(() => {
    location.pathname === userAreaPath && dispatch(updateSelectedGroup(menuItems.USER_AREA))
  }, [])

  useEffect(() => {
    categories.length && setMyGroup(getMyGroup())
  }, [categories, location])

  useEffect(() => {
    if (myGroup.id && allFolders.length) {
      setCurrentFolder(getCurrentFolder())
      setFilteredFoldersByRegion(filterByRegion())
      dispatch(updateSelectedGroup(getSelectedGroup()))
    }
  }, [myGroup, allFolders])

  useEffect(() => {
    if (allFolders.length) {
      setFilteredFoldersByFolder(getFilteredFolders())
      setFoldersPath(path => handleFoldersPath(path))
    }
  }, [currentFolder, filteredFoldersByRegion])

  const [{ }, drop] = useDrop(() => ({
    accept: [dndTypes.TASK, dndTypes.FOLDER],
    canDrop: () => false,
    hover: (item, monitor) => {
      const elem = dashboardRef.current
      if (monitor.getClientOffset().y < 200 && elem.scrollTop > 0) {
        elem.scrollTop = elem.scrollTop - 15
      }
      else if (monitor.getClientOffset().y > elem.clientHeight - 100 && elem.scrollTop + elem.clientHeight < elem.scrollHeight) {
        elem.scrollTop = elem.scrollTop + 15
      }
    },
  }))

  const getMyGroup = () => {
    const myGroup = {}
    if (location.pathname === userAreaPath) {
      myGroup.id = parseInt(localStorage.getItem("groupId"))
      myGroup.name = USER_AREA
    }
    else {
      const locationParams = getParamsFromURl()
      myGroup.id = locationParams.group
      myGroup.name = categories.find(cat => cat.id == myGroup.id).name
      if (locationParams.area) {
        myGroup.areaId = locationParams.area
        myGroup.areaName = areas.find(area => area.id == myGroup.areaId).name
      }
    }
    return myGroup;
  }

  const getSelectedGroup = () => {
    if (myGroup.name) {
      if (myGroup.name == USER_AREA) {
        return menuItems.USER_AREA
      } else {
        return menuItems.GROUP_ID + myGroup.id + (myGroup.areaId ? menuItems.AREA_ID + myGroup.areaId : '')
      }
    }
  }

  const getCurrentFolder = () => {
    const locationParams = getParamsFromURl()
    if (locationParams.folder) {
      return allFolders.find(f => f.id === locationParams.folder)
    }
    return null
  }

  const getParamsFromURl = () => {
    const rawParams = location.search.split(/['?','&']/)
    const params = {}
    rawParams.forEach(param => {
      if (param) {
        const subParam = param.split("=")
        params[subParam[0]] = parseInt(subParam[1])
      }
    })
    if (!Object.keys(params).length) {
      params.group = localStorage.getItem("groupId")
    }
    return params
  }

  const filterByRegion = () => {
    let regionFolders = [];
    if (myGroup.name === USER_AREA) {
      regionFolders = allFolders.filter(folder => folder.user == localStorage.getItem("userId"))
    }
    else {
      regionFolders = allFolders.filter(folder => !folder.user && folder.group === myGroup.id)
      if (myGroup.areaId) {
        regionFolders = regionFolders.filter(folder => !folder.user && folder.area && folder.area === myGroup.areaId)
      }
    }
    return regionFolders
  }

  const getFilteredFolders = () => {
    const correctFolders = filteredFoldersByRegion.filter(folder => {
      return currentFolder === null
        ? folder.parent === null
        : folder.parent == currentFolder.id
    }).sort((a, b) => a.order - b.order)
    return correctFolders
  }

  const handleSelectPathItem = (folder) => {
    let urlParams = new URLSearchParams(location.search);
    folder ? urlParams.set("folder", folder.id) : urlParams.delete("folder")
    location.search = "?" + urlParams.toString()
    setCurrentFolder(folder)
    history.push(location.pathname + location.search)
  }

  const handleFoldersPath = (path) => {
    let copyPath = [...path]
    if (!currentFolder) {
      copyPath = []
    } else {
      if (copyPath.length) {
        let index = copyPath.findIndex(fold => fold.id === currentFolder.id)
        index != -1 ? copyPath.splice(index + 1, copyPath.length - index) : copyPath.push(currentFolder)
      } else {
        if (!currentFolder.parent) {
          copyPath.push(currentFolder)
        } else {
          setPathItemFromURL(currentFolder, copyPath)
        }
      }
    }
    return copyPath
  }

  const setPathItemFromURL = (folder, paths) => {
    if (folder.parent) {
      const parent = allFolders.find(f => f.id === folder.parent)
      setPathItemFromURL(parent, paths)
    }
    paths.push(folder)
  }

  const getPathContinuation = () => {
    const beginPath =
      <PatnItem
        key={Math.random()}
        folder={null}
        currentFolder={currentFolder}
        className={"bagin-path"}
        onClick={() => { handleSelectPathItem(null) }}
        body={`${AREA}${myGroup.name}${myGroup.areaName ? (" - " + myGroup.areaName) : ""}`}
      />
    let pathContinuation;
    if (foldersPath.length) {
      pathContinuation = foldersPath.map((folder, i) => {
        return (
          <PatnItem
            key={Math.random()}
            // key={folder.id * (i + 1) * 17}
            folder={folder}
            currentFolder={currentFolder}
            className={"continu-path"}
            onClick={() => { handleSelectPathItem(folder) }}
            body={<span>
              <span style={{ paddingRight: "7px" }}>{" > "}</span>
              <span style={{ paddingRight: "7px" }}>{folder.title}</span>
            </span>}
          />
        )
      })
      return [beginPath, ...pathContinuation]
    }
    return beginPath
  }

  return (
    isDataReceived ?
      <div ref={drop}>
        <div ref={dashboardRef} className={'div-all-cards-vertical '}>
          <div className="wrap-path">
            <div className="path-right-part">
              {areas.length > 0 && getPathContinuation()}
              {myGroup.id === 16 && myGroup.name === "מיתרים-לכיש" && <div className="metarim">התאמת מערכת יסוד למכינות קדם צבאיות התאפשרה בסיוע יק"א בישראל</div>}
            </div>
            <div className="path-left-part">
              <AddFolsersAndTasks
                myGroup={myGroup}
                currentFolder={currentFolder}
                setNewFolderCreated={setNewFolderCreated}
              />
            </div>
          </div>
          {
            filteredFoldersByFolder.length > 0 &&
            <FoldersBuilder
              filteredFolders={filteredFoldersByFolder}
              currentFolder={currentFolder}
              setCurrentFolder={setCurrentFolder}
              myGroup={myGroup}
              newFolderCreated={newFolderCreated}
              setNewFolderCreated={setNewFolderCreated}
            />
          }
          <TasksBuilder
            myGroup={myGroup}
            currentFolder={currentFolder}
            filteredTasks={filteredTasks}
            setFilteredTasks={setFilteredTasks}
          />
        </div>
      </div>
      : <React.Fragment />
  )
}

export default DashboardV3