import React, { Component } from 'react';
import { Row, Col, CustomInput, Input } from 'reactstrap';
import plus from '../../ui/images/plus.svg';
import { connect } from 'react-redux';
import { updateCurrentTask, updateNewLine } from '../store/taskStore/taskActions';
import RechEditor from './RechEditor';
import { returnWorkProcessLine, addNewWorkProcessLine } from './commonFunctions'
import EmptyBox from './emptyBox';
import DeleteButton from './deleteButton';


class SimpleProcessLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            description: this.props.description,
        }
    }


    async updateWorkProcess(value) {
        let currentTask = Object.assign({}, this.props.currentTaskData)
        let currentTaskWorkProcess = [...this.props.currentTaskData.work_process];
        if (this.props.isChild) {
            currentTaskWorkProcess[this.props.father_index].children[this.props.self_index] = returnWorkProcessLine(value,
                this.props.id ? this.props.id : null,
                this.props.task_id ? this.props.task_id : null,
                this.props.checked ? this.props.checked : false,
                [],
                this.props.parent_id
            )

        } else {
            currentTaskWorkProcess[this.props.self_index] = returnWorkProcessLine(value,
                this.props.id ? this.props.id : null,
                this.props.task_id ? this.props.task_id : null,
                this.props.checked ? this.props.checked : false,
                this.props.children ? this.props.children : [],
                null
            )

        }
        currentTask.work_process = currentTaskWorkProcess
        this.props.updateCurrentTask(currentTask)
    }

    addNewLine(isChild, isSmallPlus = null) {
        if (isChild) {
            this.props.updateCurrentTask(addNewWorkProcessLine(
                this.props.currentTaskData,
                "<p></p>",
                true,
                this.props.self_index,
                this.props.task_id ? this.props.task_id : null,
                this.props.id ? this.props.id : null,
            ))
        } else {
            if (this.props.isNew && isSmallPlus) {
                let father = returnWorkProcessLine(
                    this.props.newLine,
                    null,
                    this.props.task_id ? this.props.task_id : null,
                    false,
                    [],
                    null
                );
                father.children.push(
                    returnWorkProcessLine("<p></p>",
                        null,
                        this.props.task_id ? this.props.task_id : null,
                        false,
                        [],
                        null
                    )
                );
                let currentTask = Object.assign({}, this.props.currentTaskData);
                let currentTaskWorkProcess = [...currentTask.work_process];
                currentTaskWorkProcess.push(father);
                currentTask.work_process = currentTaskWorkProcess;
                this.props.updateCurrentTask(currentTask);
            } else {
                if (/* this.props.newLine.length > 0 */!isChild) {
                    this.props.updateCurrentTask(addNewWorkProcessLine(
                        this.props.currentTaskData,
                        this.props.isNew ? this.props.newLine : '<p></p>',
                        // this.props.newLine,
                        false,
                        this.props.self_index,
                        this.props.task_id ? this.props.task_id : null,
                        this.props.id ? this.props.id : null,
                    ));
                }
            }
            if (this.props.isNew) {
                this.props.updateNewLine('');
            }
        }
    }

    insertDataToState = (value) => {
        this.setState({ value: value })
        if (this.props.isNew) {
            this.props.updateNewLine(value)
        } else {
            this.updateWorkProcess(value)
        }
    }


    render() {
        // let showToolbar = this.state.value.length > 7 ? true : false;
        let heWrites = this.props.newLine && this.props.isNew !== '' ? true : false;
        return (
            <React.Fragment>
                <div className={this.props.className} style={{ display: 'flex' }} >
                    <div>
                        {this.props.isNew || !this.props.isChild ? <EmptyBox heWrites={heWrites} isNew={this.props.isNew} /> : <React.Fragment/>}
                        <div style={{ marginTop: '27px' }}>
                            {/*this.props.isNew*/!this.props.isChild ?
                                <React.Fragment>
                                    <div
                                        onClick={() => this.addNewLine(false)}
                                        className="CreateProcessEmptyForPlus d-flex pointer"
                                    >
                                        <img id="plus" className='m-auto' src={plus} style={{ marginLeft: "3px" }} />
                                    </div>
                                </React.Fragment>
                                : <React.Fragment/>}
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className={this.props.isChild ? 'childRow position-relative d-flex pb-4' : 'position-relative d-flex pb-4 w-100'} >
                            {this.props.isChild ? <EmptyBox /> : <React.Fragment/>}
                            <div className='mce-content'>
                                <RechEditor
                                    newLine={this.props.isNew}
                                    value={this.props.isNew ? this.props.newLine : this.props.description}
                                    toEdit={true}
                                    textToEdit={this.props.isNew ? this.props.newLine : this.props.description}
                                    inline={true}
                                    onChange={this.insertDataToState}
                                    height="10px"
                                    toolbar={'bold underline forecolor'}
                                // showToolbar={showToolbar}
                                />
                            </div>
                        </div>
                        {this.props.isNew ?
                            <div className="class-first-child-plus d-flex pointer"
                                onClick={() => this.addNewLine(false, true)}>
                                <img id="plus" className='m-auto' src={plus} style={{ marginLeft: "3px" }} />
                            </div> : <React.Fragment/>}
                    </div>
                    {!this.props.isNew ?
                        <DeleteButton
                            isChild={this.props.isChild}
                            fatherIndex={this.props.father_index}
                            childIndex={this.props.self_index}
                            handleDeleteClick={this.props.handleDeleteClick}
                        />
                        : <React.Fragment/>}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentTaskData: state.task.task_undo.present.currentTaskData,
        newLine: state.task.task.newLine
    }
}


export default connect(
    mapStateToProps,
    { updateCurrentTask, updateNewLine }
)(SimpleProcessLine);
