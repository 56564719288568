import {basePath, specificTask, singleFile} from './paths';
import {createRequest, makeRequest} from './middleWareToken';
import {TASK_TITLE_IS_REQUIRED_FIELD} from "../../ui/strings";

export async function putTaskById(data, taskId) {
  let response = null
    await createRequest(`${basePath}${specificTask}${taskId}`, 'put', data)
      .then(res => {
        response = res
      })
      .catch(e => {
        return handleErrors(e)
        response = e
      })
  return response
}

function handleErrors(e) {

  let error = e.response
  if (error.status === 404) {
    throw new Error(`${e.config.url} not found`);
  }
  if (error.status === 400) {
    switch (error.data.errors[0].field) {
      case 'title':
        return alert(TASK_TITLE_IS_REQUIRED_FIELD)
      default:
        return alert(error)
    }
  }
}
