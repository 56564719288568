import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Reorder, getParentListStyle } from "./utils";
import './tableStyle.css'
import { Parent } from "./parent";
import { format } from "date-fns";
import { ADD } from "../../../../ui/strings";
import RectangleButton from "../rectangleButton";

const Process = props => {

  const [tableHeight, setTableHeight] = useState(null)

  const { parents, setParents, addProcessStep, deleteRow, leftClickIndex, setLeftClickIndex, showAll, setShowAll, setDoneClicked } = props
  onDragEnd = onDragEnd.bind(onDragEnd);
  onBeforeDragStart = onBeforeDragStart.bind(onBeforeDragStart)

  function setIsOpen(index) {
    let p = [...parents]
    p[index].isOpen = !p[index].isOpen
    setParents(p)
  }

  function onBeforeDragStart() {
    setTableHeight({ height: `${document.getElementById('process_rows').offsetHeight}px` })
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (result.type === "parent") {
      const updatedTask = Reorder(
        parents,
        result.source.index,
        result.destination.index
      );
      setParents(updatedTask)
    } else {
      const updateChildren = Reorder(
        parents[parseInt(result.type, 10)].children,
        result.source.index,
        result.destination.index
      );

      const updatedTask = JSON.parse(JSON.stringify(parents));
      updatedTask[result.type].children = updateChildren;
      setParents(updatedTask)
    }
    setTableHeight({ height: 'auto' })

  }


  const handleClick = (e, type, details = 0) => {
    // Prevent event trigger on parent from child
    if (type === 'child') {
      e.stopPropagation()
    }
    if (e.type === 'click') {
      if (JSON.stringify(leftClickIndex) !== JSON.stringify(details)) {
        props.newTask
          ? setLeftClickIndex({ parentIndex: details.parentIndex, index: details.index })
          : setLeftClickIndex({ parentId: details.parentId, id: details.id, index: details.index })
      }
    } else if (e.type === 'contextmenu') {
      // this.handleState({parentId: index.parentId, index: index.index})
    }
  }

  /* return true if stand on specific row in table*/
  const isCurrentRow = (parent, self, index) => {
    if (!props.newTask && leftClickIndex !== null
      && leftClickIndex.parentId === parent
      && leftClickIndex.id === self
      && leftClickIndex.index === index) {
      return true
    }
    if (props.newTask && leftClickIndex !== null
      && leftClickIndex.parentIndex === parent
      && leftClickIndex.index === index) {
      return true
    }
    return false
  }


  const handleDescriptionsChanges = (parentId, id, field, value) => {
    let work_process = [...parents]
    if (props.newTask) {
      parentId === null
        ? work_process[id].description = value
        : work_process[parentId].children[id].description = value
    } else {
      work_process.forEach(t => parentId === null && t.id == id
        ? t.description = value
        : parentId === t.id
          ? t.children.forEach(c => c.id == id
            ? c.description = value
            : c)
          : t)
    }
    // setParents([...work_process])
    setParents(work_process)


  }


  const setRowProcessTitle = (parentId, id, field, value) => {
    let work_process = [...parents]
    if (props.newTask) {
      work_process[id].title = value
    } else {
      work_process.forEach(t => t.id == id ? t.title = value : t)
    }
    setParents(work_process)
  }

  /* this function will updated [checkboxes, end_date] change in this task */
  const handleTaskChanges = async (parent_id = null, id, key, value) => {
    let parentChecked = true
    if ((parent_id || id) !== null) {
      let task = [...parents]
      if (props.newTask) {
        let allSelected = true
        if (parent_id !== null) {
          task[parent_id].children[id][key] = value
          if (key === 'checked') {
            task[parent_id].children.forEach(c => !c.checked ? allSelected = false : null)
            task[parent_id].checked = allSelected
          }
        } else {
          task[id][key] = value
          if (key === 'checked' && value) {
            task[id].children.forEach(c => c.checked = true)
          }
        }
      } else {
        await task.forEach(t => t.id === parent_id ?
          (t.children.forEach(c => c.id === id ?
            (c[key] = value,
              !c.checked?
              // key === 'checked' && !value ?
                parentChecked = false : c
            ) : !c.checked ? parentChecked = false : c), t.checked = parentChecked)
          : t.id === id ?
            (t[key] = value,
              key === 'checked' && value ?
                t.children.forEach(c => c.checked = true)
                : t,
              key === 'end_date' && value ?
                t.children.forEach(c => !c.end_date ? c.end_date = value : '')
                : t
            )
            : t)
      }
      setParents(task)
      if (key === 'checked') {
        props.setDoneStatus(parents)
      }
    } else if (parent_id === null && id === null) {
      // let task = {...props.task}
      // task[key] = value.id
      // task.leading_name = `${value.first_name} ${value.last_name}`
      // props.setCurrentTask({...props.task, 'leading_name': task.leading_name, 'leading_id': task[key]})
    }
  }

  const setRole = (parentIndex, index, value) => {
    let task = [...parents]
    parentIndex !== undefined ? task[parentIndex].children[index].role = value : task[index].role = value
    setParents(task)
  }

  function endDateViewMode(obj, def) {
    return (
      <div>{obj ? format(new Date(obj), 'dd/MM/yyyy') : def}</div>)
  }

  const tableHeaders = () => {
    let yesod = props.task.group_id == 2
    return (
      <div className='tr d-flex justify-content-end pl-3 sub-title'>
        {!yesod && <td style={{ minWidth: '120px' }}
          className={'vertically'}>אחראי/ת</td>}
        {!yesod && <td style={{ minWidth: '150px' }} className={'vertically'}>תאריך יעד</td>}
        {!yesod && <td style={{ minWidth: '90px' }} className={'vertically'}>סטטוס</td>}
        <td style={{ minWidth: '70px' }} className={'vertically'}>פעולות</td>
      </div>)
  }

  return (
    <span>
      <table id='process_rows' style={tableHeight} 
      // onClick={props.onSection !== 1 ? () => props.setOnSection(1) : null}
        cellPadding='0px'>
        <thead>{tableHeaders()}</thead>
        <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>

          {parents && <Droppable droppableId="droppable" type="parent">
            {(provided, snapshot) => {
              return <div
                ref={provided.innerRef}
                style={getParentListStyle(snapshot.isDraggingOver)}
              >
                {parents.map((process, index) => {
                  // if (showAll && index > 4) {
                  //   return
                  // }
                  return <Parent 
                    key ={index}
                    process={process}
                    index={index}
                    setIsOpen={setIsOpen}
                    isOpen={process.isOpen}
                    isDone={process.checked}
                    setDoneClicked={setDoneClicked}
                    editMode={props.editMode}
                    isYesodTask={props.isYesodTask}
                    newTask={props.newTask}
                    task={props.task}
                    onChange={handleDescriptionsChanges}
                    setRowProcessTitle={setRowProcessTitle}
                    handleClick={handleClick}
                    handleTaskChanges={handleTaskChanges}
                    endDateViewMode={endDateViewMode}
                    addProcessStep={addProcessStep}
                    deleteRow={deleteRow}
                    isCurrentRow={isCurrentRow}
                    onSection={props.onSection}
                    setOnSection={props.setOnSection}
                    setRole={setRole}
                  />
                })}
              </div>
            }}
          </Droppable>}
        </DragDropContext>
      </table>
      <span>{props.editMode && <RectangleButton
        style={{ marginRight: 'auto' }}
        text={ADD}
        icon={''}
        onClick={() => addProcessStep([null, parents.length - 1, 'down'])}
        isAllowed={props.isAllowed}
        isEdit={props.editMode} />}
        {/*{props.parents.length > 5 &&*/}
        {/*<button className='showAll' onClick={() => setShowAll(!showAll)}>{!showAll ? 'צמצם' : 'הצג הכל'}</button>}*/}
      </span>
    </span>
  );
}

export default Process;
