import React from "react"
import { useSelector } from "react-redux";
import FilesAndLinks from "../../components/CreateTaskPage/screens/FilesAndLinks/filesAndLinks";

const PdfPageContent = (props) => {

    const users = useSelector(state => state.dashboard.users)
    const groups = useSelector(state => state.dashboard.categories)
    let logo = groups.find(group => group.id == props.task.group_id).logo;

    const getPerformerFullName = (val) => {
        let performer = users.find(u => u.id === val)
        let fullName = performer ? `${performer.first_name} ${performer.last_name}` : '__________'
        return fullName
    }

    const getFormatDate = (date) => {
        const dateToFormat = new Date(date)
        let dateFormat = date ? `${dateToFormat.getDate()}/${dateToFormat.getMonth() + 1}/${dateToFormat.getFullYear()}` : "__________"
        return dateFormat
    }

    const FillsTableContent = () => {
        // const content = props.task.work_process.map(process => {
        //     if (process.children.length == 0) {
        //         if (process.description != "<p></p>")
        //             return (
        //                 <tr className="row pr-5 tr-border-top tr-margin" >
        //                     <td className="col-8" dangerouslySetInnerHTML={{ __html: "<spen class = 'circlel'>○</spen>" + process.description }}>{ }</td>
        //                     <td className="col-2 date-text">{getPerformerFullName(process.performer)}</td>
        //                     <td className="col-2 date-text">{getFormatDate(process.end_date)}</td>
        //                 </tr>
        //             )
        //     }
        //     if (process.children.length > 0) {

        //         let ro = <span><tr className="row pr-5 tr-margin tr-breaks" >
        //         <td className="col-8" dangerouslySetInnerHTML={{ __html: "<spen class = 'circlel'>○</spen>" + process.description }}>{ }</td>
        //         <td className="col-2 date-text">{getPerformerFullName(process.performer)}</td>
        //         <td className="col-2 date-text">{getFormatDate(process.end_date)}</td>
        //          </tr>
        //          {process.children.map(child => {
        //                             return <tr className="row pr-5 tr-border-top tr-margin tr-child-margin tr-breaks" >
        //                                 <td className="col-8" dangerouslySetInnerHTML={{ __html: "<spen class = 'circlel circlel-child'>○</spen>" + child.description }}>{ }</td>
        //                                 <td className="col-2 date-text">{getPerformerFullName(child.performer)}</td>
        //                                 <td className="col-2 date-text">{getFormatDate(child.end_date)}</td>
        //                             </tr>
        //                         })}
        //          </span>
        //         return (ro)


        // <tr className="row tr-border-top">
        /* <table className="col-12"> */
        /* <thead className="thead-breaks"> */
        // <tr className="row pr-5 tr-margin tr-breaks" >
        //     <td className="col-8" dangerouslySetInnerHTML={{ __html: "<spen class = 'circlel'>○</spen>" + process.description }}>{ }</td>
        //     <td className="col-2 date-text">{getPerformerFullName(process.performer)}</td>
        //     <td className="col-2 date-text">{getFormatDate(process.end_date)}</td>
        // </tr>
        /* </thead> */
        /* <tbody className="tbody-breaks"> */
        // {process.children.map(child => {
        //     return <tr className="row pr-5 tr-border-top tr-margin tr-child-margin tr-breaks" >
        //         <td className="col-8" dangerouslySetInnerHTML={{ __html: "<spen class = 'circlel circlel-child'>○</spen>" + child.description }}>{ }</td>
        //         <td className="col-2 date-text">{getPerformerFullName(child.performer)}</td>
        //         <td className="col-2 date-text">{getFormatDate(child.end_date)}</td>
        //     </tr>
        // })}
        /* </tbody> */
        /* </table> */
        /* </tr> */
        //   )

        const content = [];
        props.task.work_process.forEach(process => {
            if (process.description != "<p></p>") {
                content.push(
                    <tr className="tr-border-top" >
                        <td className='circlel pb-3 pt-3'>○</td>
                        <td ></td>
                        <td className="pl-4 py-3 process-content" dangerouslySetInnerHTML={{ __html: /*"<spen class = ' circlel'>○</spen>" +*/ process.description }}>{ }</td>
                        <td className="pl-2 py-3 date-text">{getPerformerFullName(process.performer)}</td>
                        <td className="pl-2 py-3  date-text">{getFormatDate(process.end_date)}</td>
                        <td ></td>
                    </tr>)
            }
            if (process.children || process.children.length > 0) {
                process.children.forEach(child => {
                    if (child.description != "<p></p>") {
                        content.push(
                            <tr className=" " >
                                <td className='circlel circlel-child pb-3 pt-3'>○</td>
                                <td ></td>
                                <td className="pl-3 py-3 pr-5 td-border-top" dangerouslySetInnerHTML={{ __html:/* "<spen class = 'circlel circlel-child'>○</spen>" +*/ child.description }}>{ }</td>
                                <td className="pl-2 py-3 td-border-top date-text">{getPerformerFullName(child.performer)}</td>
                                <td className="pl-2 py-3 td-border-top date-text">{getFormatDate(child.end_date)}</td>
                                <td style={{ width: "20px" }} ></td>
                            </tr>)
                    }

                })
            }
        })

        return content

        // const content = props.task.work_process.map(process => {
        //     if (!process.children || process.children.length == 0) {
        //         if (process.description != "<p></p>")
        //             return (
        //                 <tr className="tr-border-top" >
        //                     <td className='circlel pb-3 pt-3'>○</td>
        //                     <td ></td>
        //                     <td  className="px-2 pb-3 pt-3" dangerouslySetInnerHTML={{ __html: /*"<spen class = ' circlel'>○</spen>" +*/ process.description }}>{ }</td>
        //                     <td className="px-2 pb-3 pt-3 date-text">{getPerformerFullName(process.performer)}</td>
        //                     <td className="px-2 pb-3 pt-3  date-text">{getFormatDate(process.end_date)}</td>
        //                 </tr>
        //             )
        //     }
        //     if (process.children && process.children.length > 0) {
        //         return (
        //             <tr  className=" tr-border-top" >
        //                 <td colSpan="4" >
        //                     {/* <table className="config-page ">
        //                         <colgroup>
        //                             <col style={{ width: "0px" }} />
        //                             <col style={{ width: "20px" }} />
        //                             <col style={{ width: "680px" }} />
        //                             <col style={{ width: "150px" }} />
        //                             <col style={{ width: "150px" }} />
        //                         </colgroup> */}
        //                         <thead className="">
        //                             <tr className="" >
        //                                 <td className='circlel pb-3 pt-3'>○</td>
        //                                 <td style={{ width: "20px" }}></td>
        //                                 <td className="pl-2 pb-3 pt-3" dangerouslySetInnerHTML={{ __html: /*"<spen class = 'circlel'>○</spen>" +*/ process.description }}>{ }</td>
        //                                 <td className="pl-2 pb-3 pt-3 date-text">{getPerformerFullName(process.performer)}</td>
        //                                 <td className="pl-2 pb-3 pt-3 date-text">{getFormatDate(process.end_date)}</td>
        //                             </tr>
        //                          </thead>
        //                          <tbody className="">
        //                             {process.children.map(child => {
        //                                 return (
        //                                     <tr className="tr-border-top " >
        //                                         <td></td>
        //                                         <td style = {{width : "20px"}} className='circlel circlel-child pb-3 pt-3'>○</td>
        //                                         <td /*style = {{paddingRight: "22px"}}*/ className="pl-2 pb-3 pt-3" dangerouslySetInnerHTML={{ __html:/* "<spen class = 'circlel circlel-child'>○</spen>" +*/ child.description }}>{ }</td>
        //                                         <td /*style = {{paddingRight: "22px"}}*/ className="pl-2 pb-3 pt-3 date-text">{getPerformerFullName(child.performer)}</td>
        //                                         <td /*style = {{paddingRight: "22px"}}*/ className="pl-2 pb-3 pt-3 date-text">{getFormatDate(child.end_date)}</td>
        //                                     </tr>
        //                                 )
        //                             })}
        //                          </tbody>
        //                     {/* </table> */}
        //                  </td>
        //              </tr>
        //         )
        //     }
        // })
        // return content;
    }

    return (
        <div className="content-config">
            <div>
                <h2 className="mein-title" style={{ float: "right" }}>{props.task.title}</h2>
                <div className="text-left">
                    <img src={logo} height="70px" />
                </div>
            </div>
            <h3 className="config descripation-title mb-3">מהות</h3>
            <div dangerouslySetInnerHTML={{ __html: props.task.description }}></div>
            <h3 className="config descripation-title ">תהליך</h3>
            {/* <table className="config-page">
                <thead>
                    <tr className="row pr-5 mt-4" >
                        <td className="col-8 font-weight-bold" >משימה</td>
                        <td className="col-2 font-weight-bold" >אחראי/ת</td>
                        <td className="col-2 font-weight-bold" >תאריך יעד</td>
                    </tr>
                </thead>
                <tbody> */}
            <table className="config-page" style={{ clear: "both" }} >
                <colgroup >
                    <col style={{ width: "50px" }} />
                    <col style={{ width: "0px" }} />
                    <col style={{ width: "680px" }} />
                    <col style={{ width: "150px" }} />
                    <col style={{ width: "150px" }} />
                    <col style={{ width: "0px" }} />
                </colgroup>
                <thead><tr className="pr-5 mt-4" >
                    <td className="circlel"></td>
                    <td ></td>
                    <td className="py-3 px-2 font-weight-bold">מוביל משימה - 
                        <span className = "font-weight-normal"> {getPerformerFullName(props.task.leading_id)}</span></td>
                    <td className="font-weight-bold" >אחראי/ת</td>
                    <td className="font-weight-bold" >תאריך יעד</td>
                    <td ></td>
                </tr></thead>
                <tbody >
                    {FillsTableContent()}
                </tbody>
            </table>
            {(props.task.files.length > 0 || props.task.links.length > 0) &&
                <div className='main-container-print' style={{ breakInside: "avoid" }}>
                    {/* <table>
                        <thead style={{breakInside: "avoid", breakAfter: "avoid" }}> */}
                    <h3 className="config descripation-title">חומרים לשימוש</h3>
                    {/* </thead>
                        <tbody > */}

                    <div className="">
                        <FilesAndLinks task={props.task} printMode={true}></FilesAndLinks>
                    </div>
                    {/* </tbody>
                    </table> */}
                </div>
            }
        </div>
    )
}

export default PdfPageContent