import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { getParentItemStyle } from "./utils";
import drag_dots from "../../../../ui/images/drag_dots.svg";
import arrowDownBlue from "../../../../ui/images/arrow_down_blue.svg";
import arrowUpBlue from "../../../../ui/images/arrow_up_blue.svg";
import plus from "../../../../ui/images/comment-plus-outline.svg";
import { StatusIcon } from "../statusMode";
import AdvancedFeatures from "../advancedFeatures";
import Child from "./childRow";
import './tableStyle.css'
import UsersDropDown from "../usersDropDown";
import { useSelector } from "react-redux";
import Datepicker from "../datepicker";
import Editor from "../editor/quill";
import { ADD_DETAILS, CLOSE, EDIT_DETAILS } from "../../../../ui/strings";
import MultipleEditors from "../editor/quillMultipleEditors";

export const Parent = (props) => {

  const {
    process,
    index,
    handleClick,
    isCurrentRow,
    onSection,
    onChange,
    setRowProcessTitle,
    handleTaskChanges,
    setRole,
    newTask,
    isDone,
    isYesodTask,
    editMode,
    endDateViewMode,
    addProcessStep,
    deleteRow,
    setDoneClicked
  } = props;

  const users = useSelector(state => state.dashboard.users)
  const [hover, setHover] = useState(false)
  const [addDetails, setAddDetails] = useState(false)
  const [currentEditor, setCurrentEditor] = useState(0)


  function getDetails(field = '') {
    return props.newTask
      ? { parent: null, self: props.index, field: field }
      : { parent: props.process.parent_id, self: props.process.id, field: field }
  }

  function onFocus() {
    return isCurrentRow(null, process.id, index) && editMode && onSection
  }

  function toggleAddDetails() {
    setAddDetails(!addDetails)
  }

  function onStatusChanged(process, index) {
    (!isYesodTask && newTask)
      ? handleTaskChanges(null, index, 'checked', !process.checked)
      : handleTaskChanges(process.parent_id, process.id, 'checked', !process.checked)
    setDoneClicked(true)
  }

  function calculateLeftPadding() {
    let left = window.document.getElementById('dots')
    if (left !== null) {
      let leftWidth = left.offsetWidth
      return leftWidth

    }
  }

  return <span id={index}>
    <Draggable
      key={props.process.id}
      draggableId={`parent_${index}`}
      index={props.index}
      isDragDisabled={!editMode}
    >
      {(provided, snapshot) => {
        return <div
          key={process.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getParentItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            process.isOpen,
            isDone,
            editMode,
            hover,
            onFocus()
          )}

          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={(e) => {
            if (editMode) {
              newTask
                ? handleClick(e, 'parent', { parentIndex: null, index: index })
                : handleClick(e, 'parent', {
                  parentId: null,
                  id: process.id,
                  index: index
                })
            }
          }}
        >
          <div className={`tr d-flex justify-content-end`} style={{ paddingBottom: process.isOpen ? '25px' : '0px' }}>
            <td id='dots' style={{ maxWidth: '50px', marginLeft: 'auto', display: process.isOpen ? 'block' : 'flex' }}
              className={'vertically'}>
              <div
                style={{ padding: process.isOpen ? '0px 0px 5px' : '0px 0px 0px 8px' }} {...provided.dragHandleProps}>
                {editMode && <img src={drag_dots} />}
              </div>
              <div
                style={{ minWidth: '50%', textAlign: 'center', cursor: 'pointer' }}
                onClick={() => props.process.children.length > 0 && props.setIsOpen(props.index)}>
                {props.process.children.length > 0 &&
                  <div style={{ display: 'inline-grid' }}>
                    <div
                      className='num_of_children'>{props.process.children.length}</div>
                    <img src={process.isOpen ? arrowUpBlue : arrowDownBlue} />
                  </div>}
              </div>
            </td>
            <td onClick={() => setCurrentEditor(1)}
              style={{
                textAlign: 'right',
                fontSize: '17px',
                width: '100%',
                marginRight: '10px'
              }}>
              <Editor
                key='desc'
                id={`p${index}`}
                readOnly={!onFocus()}
                displayToolbar={onFocus()}
                details={getDetails("row")}
                value={process.description}
                edit={editMode}
                newTask={newTask}
                onChange={onChange}
                // onChangeDesc={onChange}
                // onChangeTitle={setRowProcessTitle}
                setAddDetails={toggleAddDetails}
                // showPopover={!editMode && process.title !== '<p><br></p>'}
                popoverData={process.title}
                addDetails={addDetails}
              />
              {/*{(editMode || process.isOpen) && addDetails &&*/}
              {/*<div //className={props.isOpen && onFocus() && 'mb-5'}*/}
              {/*     // style={{marginRight: `${calculateLeftPadding() + (!editMode ? 50 : 18)}px`}}*/}
              {/*     style={{marginRight: `${(!editMode ? 50 : 18)}px`}}*/}
              {/*     onClick={()=> setCurrentEditor(2)}>*/}
              {/*  <Editor*/}
              {/*    key='title'*/}
              {/*    type='title'*/}
              {/*    id={`t${index}`}*/}
              {/*    readOnly={!onFocus() && currentEditor !== 2}*/}
              {/*    displayToolbar={onFocus() && currentEditor === 2 }*/}
              {/*    details={getDetails("row")}*/}
              {/*    value={process.title}*/}
              {/*    edit={editMode}*/}
              {/*    newTask={newTask}*/}
              {/*    onChange={setRowProcessTitle}*/}
              {/*    setAddDetails={setAddDetails}*/}
              {/*  />*/}
              {/*</div>}*/}
              {/*{editMode && onFocus() &&*/}
              {/*<div className='active-blue' style={{padding: '10px'}}*/}
              {/*     onClick={() => {*/}
              {/*       let toggle = currentEditor === 1 ? 2 : 1*/}
              {/*       setCurrentEditor(toggle)*/}
              {/*       setAddDetails(!addDetails)*/}
              {/*       }}>*/}
              {/*  <img src={plus} style={{marginLeft: '8px'}}/>*/}
              {/*  {addDetails ? CLOSE : process.title !== '<p><br></p>' ? EDIT_DETAILS : ADD_DETAILS}*/}
              {/*</div>}*/}
            </td>
            <td style={{ minWidth: '120px' }} className={'vertically'}>
              {users && !newTask &&
                <UsersDropDown
                  id={newTask ? index : process.id}
                  parent_id={newTask ? null : process.parent_id}
                  index={index}
                  editMode={editMode}
                  disabled={false}
                  multiple={false}
                  options={users}
                  value={process.performer}
                  field='performer'
                  onChange={handleTaskChanges}
                  setRole={setRole}
                  role={process.role}
                />}
            </td>
            <td style={{ minWidth: '150px' }} className={'vertically'}>
              {editMode && !newTask
                ? <Datepicker
                  id={newTask ? index : process.id}
                  parent_id={newTask ? index : process.parent_id}
                  value={process.end_date}
                  onChanged={handleTaskChanges}
                />
                : endDateViewMode(process.end_date, '')
              }
            </td>
            <td style={{ minWidth: '90px' }} className={'vertically'}>
              {process.checked
                ? <StatusIcon id={`pa-${index}`} done={true} onClicked={onStatusChanged}
                  object={process} index={index} />
                : <StatusIcon id={`pa-${index}`} done={false} onClicked={onStatusChanged}
                  object={process} index={index} />
              }
            </td>
            <td style={{ minWidth: '70px' }} className={'vertically'}>
              {users && <AdvancedFeatures
                onClick={addProcessStep}
                delete={deleteRow}
                details={{ parentIndex: null, index: index }}
                child={false}
                editMode={editMode}
              />}
            </td>
          </div>
          {/*{editMode && onFocus() &&*/}
          {/*<div className='active-blue' style={{padding: '10px'}}*/}
          {/*     onClick={() => {*/}
          {/*       let toggle = currentEditor === 1 ? 2 : 1*/}
          {/*       setCurrentEditor(toggle)*/}
          {/*       setAddDetails(!addDetails)*/}
          {/*     }}>*/}
          {/*  <img src={plus} style={{marginLeft: '8px'}}/>*/}
          {/*  {addDetails ? CLOSE : process.title !== '<p><br></p>' ? EDIT_DETAILS : ADD_DETAILS}*/}
          {/*</div>}*/}
          {/*{(editMode || process.isOpen) && addDetails &&*/}
          {/*<div className={props.isOpen && onFocus() && 'mb-5'}*/}
          {/*     style={{marginRight: `${calculateLeftPadding() + (!editMode ? 50 : 18)}px`}}*/}
          {/*     onClick={()=> setCurrentEditor(2)}>*/}
          {/*  <Editor*/}
          {/*    key='title'*/}
          {/*    type='title'*/}
          {/*    id={`t${index}`}*/}
          {/*    readOnly={!onFocus() && currentEditor !== 2}*/}
          {/*    displayToolbar={onFocus() && currentEditor === 2 }*/}
          {/*    details={getDetails("row")}*/}
          {/*    value={process.title}*/}
          {/*    edit={editMode}*/}
          {/*    newTask={newTask}*/}
          {/*    onChange={setRowProcessTitle}*/}
          {/*    setAddDetails={setAddDetails}*/}
          {/*  />*/}
          {/*</div>}*/}
          {process.isOpen &&
            <Child
              parentIndex={index}
              process={process}
              onChange={onChange}
              handleClick={handleClick}
              handleTaskChanges={handleTaskChanges}
              isDone={isDone}
              setDoneClicked={setDoneClicked}
              editMode={editMode}
              isYesodTask={isYesodTask}
              newTask={newTask}
              endDateViewMode={endDateViewMode}
              users={users}
              deleteRow={deleteRow}
              addProcessStep={addProcessStep}
              isCurrentRow={isCurrentRow}
              onSection={onSection}
              setOnSection={props.setOnSection}
              setRole={setRole}
            />}
        </div>
      }}
    </Draggable>
  </span>
}
export default Parent

